<div class="page page-landscape page-memory-text-only">

  <table class="page-layout-table">
    <tbody>

    <tr class="page-layout-table-body">
      <td class="story" width="50%" align="left" valign="top">
        <div class="description">{{ textLeft }}</div>
      </td>

      <td class="story" width="50%" align="left" valign="top">
        <div class="description">{{ textRight }}</div>
      </td>
    </tr>

    <tr class="page-layout-table-footer">
      <td colspan="2" align="center" valign="middle">
        <app-bio-print-footer [page]="page"></app-bio-print-footer>
      </td>
    </tr>

    </tbody>
  </table>
</div><!-- /page-memory-text -->
