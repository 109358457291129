import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, concatMap, map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {JarActions} from './index';
import {of} from 'rxjs';
import {JarModel} from '../../core/models/jar.model';
import {ResponseMessage} from '../../core/models/response-message.model';
import {AlertService} from '../../shared/components/alert/alert.service';
import {MemoryService} from '../../core/services/memory.service';
import {FileUploaderService} from '../../shared/components/file-uploader/file-uploader.service';
import {MemoryActions} from '../memories';

@Injectable()
export class JarsEffects {

  constructor(
    private actions$: Actions,
    private memoryService: MemoryService,
    private fileUploader: FileUploaderService,
    private alertService: AlertService
  ) {
  }

  getJarRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JarActions.getJarRequest),
      mergeMap(action =>
        this.memoryService.getMemoryJar(action.id).pipe(
          map((jar: JarModel) =>
            JarActions.getJarRequestSuccess({
              jar
            })
          ),
          catchError(error => {
            return of(JarActions.effectError({error}));
          })
        )
      )
    )
  );

  saveJarRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JarActions.saveJarRequest),
      mergeMap(action =>
        this.memoryService.saveMemoryJar(action.jar).pipe(
          concatMap((responseMessage: ResponseMessage) => [

            JarActions.saveJarRequestSuccess({
              responseMessage
            }),
            /*
            JarActions.uploadJarFilesRequest({
              jar: action.jar,
              files: action.files
            })
            */
          ]),
          catchError(error => {
            return of(JarActions.effectError({error}));
          })
        )
      )
    )
  );

  deleteJarRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JarActions.deleteJarRequest),
      mergeMap(action =>
        this.memoryService.deleteMemory(action.jar).pipe(
          tap((responseMessage: ResponseMessage) => {
            this.alertService.warning(responseMessage.message);
          }),

          map((responseMessage: ResponseMessage) =>
            JarActions.deleteJarRequestSuccess({responseMessage}),
          ),

          catchError(error => {
            return of(JarActions.effectError({error}));
          })
        )
      )
    )
  );

  /*
  * Need to move 'progress' tracking to store, otherwise readonly errors
  *
  uploadJarFilesRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JarActions.uploadJarFilesRequest),
      filter((action) => action.files && action.files.length > 0),
      mergeMap(action => {
          const url = this.memoryService.saveFilesUrl(action.jar);
          return this.fileUploader.uploadFiles(url, action.files)
            .pipe(
              map((files: FileModel[]) =>
                JarActions.uploadJarFilesRequestSuccess({jar: action.jar, files})
              ),
              catchError(error => {
                return of(JarActions.effectError({error}));
              })
            );
        }
      )
    )
  );
  */

  sortJarFilesRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JarActions.sortJarFilesRequest),
      mergeMap(action =>
        this.memoryService.sortFiles(action.jar, action.fileIds).pipe(
          tap((responseMessage: ResponseMessage) => {
            this.alertService.warning(responseMessage.message);
          }),

          map(responseMessage =>
            JarActions.sortJarFilesRequestSuccess({responseMessage})
          ),

          catchError(error => {
            return of(JarActions.effectError({error}));
          })
        )
      )
    )
  );


  deleteJarFileRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JarActions.deleteJarFileRequest),
      mergeMap(action =>
        this.memoryService.removeFile(action.jar, action.file).pipe(
          tap((responseMessage: ResponseMessage) => {
            this.alertService.warning(responseMessage.message);
          }),

          map(responseMessage =>
            JarActions.deleteJarRequestSuccess({responseMessage})
          ),

          catchError(error => {
            return of(JarActions.effectError({error}));
          })
        )
      )
    )
  );


  saveJarFileRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JarActions.saveJarFileRequest),
      mergeMap(action =>
        this.memoryService.patchFile(action.jar, action.file).pipe(
          tap((responseMessage: ResponseMessage) => {
            this.alertService.info(responseMessage.message);
          }),

          switchMap((responseMessage: ResponseMessage) => [
            JarActions.saveJarFileRequestSuccess({
              jar: action.jar,
              responseMessage
            }),
          ]),

          catchError(error => {
            return of(MemoryActions.effectError({error}));
          })
        )
      )
    )
  );

}



