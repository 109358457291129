import {Bio} from '../../core/models/bio.model';

export class UserBiosState {
  byId: BiosById;
}

export const initialUserState: UserBiosState = {
  byId: {},
};

export class BiosById {
  [id: number]: Bio;
}
