<form [formGroup]="editForm" (ngSubmit)="onSubmit()">
  <div class="modal-header">
    <h4 class="modal-title"> {{bioJar.title}} Memory Jar</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body modal-body-memory-edit">

    <div class="form-group">
      <label class="control-label text-center d-block">
        Add Image Files

        <button class="btn" type="button"
                [ngbPopover]="CommonText.Popover.FileUpload"
                popoverTitle="Images">
          <fa-icon [icon]="faQuestionCircle" class="ico-gold"></fa-icon>
          <span class="sr-only">Help</span>
        </button>

      </label>
      <app-file-uploader ngbAutofocus [fileTypes]="'image/*'" [memory]="bioJar" [fileList]="bioJar?.files"
                         preview="true"
                         [sortable]="true"
                         [removable]="true"
                         [editable]="true"
                         [maxSize]="userSubscription.fileSize"
                         [maxCount]="10"
                         [multiple]="true"
                         (fileSort)="sortFiles($event)"
                         (fileRemove)="removeFile($event)"
                         (fileEdit)="editFile($event)"
                         (fileClick)="showLightbox($event)"
                         (fileError)="showError($event)"></app-file-uploader>
    </div>

    <div class="form-group">
      <label class="control-label">
        Description
        <button class="btn" type="button"
                ngbPopover="Descriptions are limited to {{DESCRIPTION_LENGTH | number:'1.0-0'}} characters." popoverTitle="Description">
          <fa-icon [icon]="faQuestionCircle" class="ico-gold"></fa-icon>
          <span class="sr-only">Help</span>
        </button>
      </label>
      <textarea maxlength="{{DESCRIPTION_LENGTH}}" autosize formControlName="description" class="form-control form-control-lg input-bio"
                [ngClass]="{ 'is-invalid': submitted && f.description.errors }" placeholder="Add a detailed description"
                aria-label="Description" rows="5" [placeholder]="bioJar.placeholder"></textarea>

      <app-character-countdown [text]="f.description.value" [maxLength]="DESCRIPTION_LENGTH" ></app-character-countdown>

      <app-control-validation [control]="f.description" [submitted]="submitted"></app-control-validation>
    </div>

  </div>
  <div class="modal-footer">

    <button type="button" class="btn btn-light" (click)="activeModal.dismiss()">
      Cancel
    </button>

    <button type="submit" class="btn btn-gold">
      Save
    </button>
  </div>

</form>
<app-content-loader>Please wait... Encrypting files</app-content-loader>
