<div class="bio bio-public">

  <app-secured-header></app-secured-header>

  <main #main class="main">
    <router-outlet></router-outlet>
  </main><!--/container-->

  <div class="bio-build-ver">Build: {{env.version}}</div>

  <div class="bio-modal-container"><!-- Hacky way to have the modals behind alerts --></div>
  <app-alert></app-alert>
</div><!--/bio-->

