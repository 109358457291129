import { EventEmitter, Input, OnInit, Output, Directive } from '@angular/core';
import {Bio} from '../../../core/models/bio.model';
import { faPlusCircle, faMinusCircle, faPencilAlt } from '@fortawesome/free-solid-svg-icons';

@Directive()
export abstract class AbstractListDirective implements OnInit {

    @Input() public list: any[] = [];
    @Input() public selectable: boolean;
    @Input() public editable: boolean;
    @Input() public removable: boolean;

    @Output() public select: EventEmitter<any>;
    @Output() public edit: EventEmitter<any>;
    @Output() public remove: EventEmitter<any>;

    public faPencilAlt = faPencilAlt;
    public faPlusCircle = faPlusCircle;
    public faMinusCircle = faMinusCircle;

    constructor() {
        this.select = new EventEmitter<Bio>();
        this.selectable = false;

        this.edit = new EventEmitter<Bio>();
        this.editable = false;

        this.remove = new EventEmitter<Bio>();
        this.removable = false;
    }

    ngOnInit(): void {

    }

    public onSelect(event, item: any) {
        event.preventDefault();
        this.select.emit(item);
    }

    public onEdit(event, item: any) {
        event.preventDefault();
        this.edit.emit(item);
    }

    public onRemove(event, item: any) {
        event.preventDefault();
        this.remove.emit(item);
    }

}
