import {Component, Input, OnInit} from '@angular/core';
import {MemoryModel} from '../../../../core/models/memory.model';

@Component({
  selector: 'app-bio-print-photos',
  templateUrl: 'bio-print-photos.component.html'
})
export class BioPrintPhotosComponent implements OnInit {

  @Input()
  public memory: MemoryModel;

  @Input()
  public photos: any[];

  constructor() {
    this.memory = new MemoryModel();
    this.photos = [];
  }

  ngOnInit(): void {

  }

}
