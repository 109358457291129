<div class="modal-header">
  <h4 class="modal-title">PLAN CHANGE INFORMATION</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body modal-body-plan-upgrade">

  <p>
    By changing your plan you will be
    losing some of the fantastic benefits of your current plan.
  </p>

  <p>
    To adhere to the new plans limits you may have to remove
    some of your stored data.
  </p>

  <div class="row">
    <div class="col-6">

      <div *ngIf="currentPlan" class="user-plan-stats-box">
        <h3 class="text-center">Current Plan</h3>

        <ul class="list list-subscription-details">
          <li class="item item-detail">
            <div>
              <span *ngIf="currentPlan.memories > 0">{{ currentPlan.memories }}</span>
              <span *ngIf="currentPlan.memories == 0"> Unlimited </span>
              Memories <sup>(per Profile)</sup>
            </div>
          </li>

          <li class="item item-detail">
            <div>
              <span *ngIf="currentPlan.things > 0">{{ currentPlan.things }}</span>
              <span *ngIf="currentPlan.things==0">Unlimited </span>
              Things <sup>(per Profile)</sup>
            </div>
          </li>

          <li class="item item-detail">
            <div>
              <span *ngIf="currentPlan.passwords > 0">{{ currentPlan.passwords }}</span>
              <span *ngIf="currentPlan.passwords==0">Unlimited </span>
              Passwords <sup>(per Profile)</sup>
            </div>
          </li>

          <li class="item item-detail">
            <div>
              <span *ngIf="currentPlan.bios > 0">{{ currentPlan.bios }}</span>
              <span *ngIf="currentPlan.bios==0">Unlimited </span>
              Life Profiles
            </div>
          </li>

          <li class="item item-detail">
            <div>
              <span *ngIf="currentPlan.legacyBios > 0">{{ currentPlan.legacyBios }}</span>
              <span *ngIf="currentPlan.legacyBios==0">Unlimited </span>
              Legacy Profiles
            </div>
          </li>
        </ul>

      </div>

    </div>
    <div class="col-6">

      <div *ngIf="newPlan" class="user-plan-stats-box">
        <h3 class="text-center">New Plan</h3>

        <ul class="list list-subscription-details">
          <li class="item item-detail" [ngClass]="{'text-danger':(currentPlan.memories !== newPlan.memories )}">
            <div>
              <span *ngIf="newPlan.memories > 0">{{ newPlan.memories }}</span>
              <span *ngIf="newPlan.memories == 0"> Unlimited </span>
              Memories <sup>(per Profile)</sup>
            </div>
          </li>

          <li class="item item-detail" [ngClass]="{'text-danger':(currentPlan.things !==  newPlan.things )}">
            <div>
              <span *ngIf="newPlan.things > 0">{{ newPlan.things }}</span>
              <span *ngIf="newPlan.things==0">Unlimited </span>
              Things <sup>(per Profile)</sup>
            </div>
          </li>

          <li class="item item-detail" [ngClass]="{'text-danger':(currentPlan.passwords !== newPlan.passwords )}">
            <div>
              <span *ngIf="newPlan.passwords > 0">{{ newPlan.passwords }}</span>
              <span *ngIf="newPlan.passwords==0">Unlimited </span>
              Passwords <sup>(per Profile)</sup>
            </div>
          </li>

          <li class="item item-detail" [ngClass]="{'text-danger':(currentPlan.bios !== newPlan.bios )}">
            <div>
              <span *ngIf="newPlan.bios > 0">{{ newPlan.bios }}</span>
              <span *ngIf="newPlan.bios==0">Unlimited </span>
              Life Profiles
            </div>
          </li>

          <li class="item item-detail" [ngClass]="{'text-danger':(currentPlan.legacyBios !==  newPlan.legacyBios )}">
            <div>
              <span *ngIf="newPlan.legacyBios > 0">{{ newPlan.legacyBios }}</span>
              <span *ngIf="newPlan.legacyBios==0">Unlimited </span>
              Legacy Profiles
            </div>
          </li>
        </ul>

      </div>
    </div>
  </div>

  <p class="text-center">
    <a class="btn btn-lg btn-gold"  (click)="activeModal.dismiss('Cross click')">
      I understand
    </a>
  </p>

</div>
