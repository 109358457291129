<div class="modal-header">
  <h4 class="modal-title">MY LIFEJARS TERMS AND CONDITIONS</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="text-legal-doc">

    <p>
      Welcome to My LifeJars! We are passionate about helping you create your life bio and build an awesome legacy.
      Please read these terms and conditions. If you have any questions, these might be answered in our FAQs or you can contact us.
      These terms and conditions (Terms) are entered into between My LifeJars Pty Ltd ACN 632 790 526 (we, us or our) and you,
      together the Parties and each a Party. These Terms supplement and incorporate our privacy policy and website terms of use posted on the
      platform.
    </p>
    <p>
      We provide a platform where you can create a life bio, virtually store and track important things, write a life story as a series of memories,
      build a legacy and use any other functionality as made available to you (Platform). The Platform is available at <a href="https://mylifejars.com/">mylifejars.com</a> (Site) and
      may be available via other channels or addresses. You may be registered on the Platform as a user of your own account and/or as a guardian
      for another user’s account (Guardian) (each a User).
    </p>
    <p>
      In these Terms, you means (as applicable) (1) the person or entity registered with us as a User; or (2) the individual accessing or
      using the Platform.
    </p>

    <h2>Accepting these Terms</h2>

    <p>
      You accept these Terms by checking the box which states &ldquo;I accept these terms and conditions&rdquo;͘
      You must be at least 13 years old to use the Platform. If you are under 13 your parent or legal guardian may add content about
      you as an additional Profile to their account but you may not have your own account. Once you are over 13, your parent or legal
      guardian may invite you via email to access your Profile. If you are over 13 but under 18 years old or mentally incapacitated, you
      represent and warrant that you have permission from your parent or legal guardian to access and use the Platform, and they have
      accepted these Terms on your behalf. Where you are over 13 but under 18 years old, you will be required to have a Guardian nominated
      for your Profile to be able to use certain functionalities on the Platform.
    </p>
    <p>
      If you are a parent or legal guardian allowing a minor or mentally incapacitated person to access and/or use the Platform, you
      agree to: (1) supervise the minor’s or mentally incapacitated person’s use of the Platform, (2) assume all risks associated with, and
      liabilities resulting from, the minor’s or mentally incapacitated person’s use of the Platform, (3) ensure that the content on the
      Platform is suitable for the minor or mentally incapacitated person, (4) ensure all information submitted to us by the minor or minor or
      mentally incapacitated person is accurate; and (5) provide the consents, representations and warranties contained in these Terms on
      the minor’s or mentally incapacitated person’s behalf.
    </p>
    <p>
      We may amend these Terms at any time, by providing written notice to you. By continuing to use the Platform after the notice or
      30 days after notification (whichever date is earlier), you agree to the amended terms. If you do not agree to the amendment, you
      may terminate these Terms in accordance with the “Cancelling Memberships” clause below.
    </p>
    <p>
      We may use Google Maps/Earth mapping services, including Google Maps API(s). Your use of Google Maps/Earth is subject to the
      Google Maps/Google Earth Additional Terms of Service.
    </p>

    <h2>Your Account</h2>

    <p>
      You must register on the Platform and create an account (Account) to access the Platform’s features͘.
    </p>
    <p>
      You may only have 1 Account and you may use that account as a Tribester and as a Guardian and have several Profiles attached to that account.
    </p>
    <p>
      You must provide basic information when registering for an Account including your first name, last name and email address.
      Once you have registered an Account, your Account information will be used to create your profile, known as your Profile which
      you may then build.
    </p>
    <p>
      You agree to provide accurate, current and complete information during the registration process and regularly update such
      information to keep it accurate, current and complete.
    </p>
    <p>
      Your Account is personal and you must not transfer it to others. Where you are allowing a Guardian to take control of your Profile in
      accordance with the “Guardians” clause below, this is not a transfer of your Account but is instead the provision of access rights to
      your Profile by your nominated Guardian(s).
    </p>
    <p>
      You are responsible for keeping your Account details and your password confidential and you will be liable for all activity on your
      Account, including purchases made using your Account details. You agree to immediately notify us of any unauthorised use of your Account.
    </p>
    <p>
      When you create an Account, a free plan will automatically be created. You may choose a paid plan (Plan) at this time or when you have
      exceeded the number of Memories or Things you can upload, Profiles you can administer or data upload or storage limits. You may choose between
      different tiers of paid Plans with different services and different Plan periods (monthly or annual) as set out on our Platform.
    </p>

    <h2>Platform summary</h2>

    <p>
      The Platform is a place where you can create a Life Profile, virtually store things, write a life story, build a Legacy Profile and connect with
      other Users to share your content (but only where you choose to allow access). We provide the Platform as described on the Site,
      to Users and Guardians (including hosting and maintaining the Platform), we assist Users to connect and share content and allow
      Users to nominate Guardian’s to access their Profile (together the My LifeJars Services). You understand and agree that we only make
      available the Platform and the My LifeJars Services. We are not party to any agreement entered into between Users or between Users
      and Guardians and we have no control over the conduct of Users, Guardians or any other users of the Platform.
    </p>
    <p>
      A User may create a Life Profile by using the functionality on the Platform described as ‘Memory Jars’ to store memories and
      by using the functionality on the Platform described as ‘Things Jars’ to store information or things about the User’s life.
      A User that is a parent or legal guardian for a child may create a Life Profile page connected to the parent or legal guardian’s Account,
      about their child.
    </p>
    <p>
      On a User’s death, a User’s Life Profile can be transitioned to a Legacy Profile by their Guardian.
    </p>
    <p>
      A User may also create a Legacy Profile which is connected to the User’s account and is dedicated to that deceased individual.
      Users may add other Users to their family and friend network on the Platform, known as their Tribe. A User will only be added to
      another User’s Tribe where the User provides their consent. Users that become part of another User’s Tribe are known as the User’s Tribesters.
      The activities of Tribesters who are alive may be viewed by a User by accessing that Tribester’s Life Profile or via the User’s Life Wall which
      collates updates on all of a User’s living Tribesters͘ For a dead Tribester, a User may view the User’s Legacy Profile or view activities
      relating to their Legacy Profile via the User’s Remembrance Wall which collates updates on all of a User’s deceased Tribesters.
    </p>
    <p>
      Users may use ‘Memory Jars’ to write their life story, with a collection of images, text and other file formats stored in a computer- generated jar.
    </p>
    <p>
      Users may also virtually store things on the Platform via the ‘Things Jars’ functionality͘ For example, a User may store a digital copy
      of their Birth Certificate in a computer-generated jar. The User may also choose to add a location to identify where that ‘Thing’ is stored.
    </p>
    <p>
      From time to time, other functionalities may also be available via the Platform, such as a space where Users may insert Wishes and
      Wisdom to share with their selected Users.
    </p>

    <h2>Guardians</h2>

    <p>
      As a User you may appoint a Guardian who will be given a level of control over your Profile if/when (1) you are a child under 18 years
      of age; (2) your Guardian can demonstrate evidence of a power of attorney or enduring power of attorney to make decisions on
      your behalf; (3) your Guardian can demonstrate you are deceased; or (4) none of the matters in (1)-(3) apply, but you opt to give a
      Guardian a level of access to your Profile in conjunction with your own continuing access and use of your Profile.
      A Guardian cannot log into your Account but will be able to access content in your Profile based on the access and privacy settings you
      have chosen, and will be able to add certain types of content to your Account and approve certain requests, such as new Tribester requests.
    </p>
    <p>
      You may choose (1) not to appoint a Guardian; (2) appoint only one Guardian; or (3) appoint more than one Guardian. Where you
      appoint more than one Guardian you may give each Guardian different access permissions for your Account.
    </p>
    <p>
      You understand and agree that by nominating a Guardian, that Guardian will have control over at least some aspects of your Profile
      and may make decisions on your behalf depending on the access rights you grant them.
    </p>
    <p>
      If you are a child under 18 years of age but over 13 years of age, you will require a Guardian to be able to use some functionalities
      in your Profile.
    </p>
    <p>
      Nothing in this clause is intended to override any lawful appointment of a power of attorney, executor for your estate or any
      statement in your legal will or similar legal document which gives another person access to your Profile, appoints another person to make
      decisions on your behalf, or appoints another person as a prime Guardian for your Account.
    </p>

    <h2>Your Plan</h2>
    <p>
      It is free to register an Account on the Platform. However, we also offer paid Plans with extra functionality and because we care about our
      community, a percentage of any paid annual Plan will be donated to a charity that is one of our partner organizations.
    </p>
    <p>
      When you are creating an Account or after you have created an Account you may choose a paid Plan. Where you choose a paid Plan you agree to
      pay the membership fee set out on the Platform (Plan Fee) to use certain features on the Platform and benefit from your Plan.
    </p>
    <p>
      To the extent permitted by law, the Plan Fee is non-refundable and non-cancellable once paid.
    </p>
    <p>
      The Plan Fee will be charged upfront on a monthly basis for month to month Plans or upfront on a yearly basis for annual Plans, on the
      calendar day corresponding to when you created your Account or subscribed to the paid Plan (Payment Date). In some cases, your Payment
      Date may change, for example, if payment is unable to be processed or if your Plan began on a day not contained in a given month.
    </p>
    <p>
      Month to month Plans automatically continue until cancelled in accordance with the cancellation clause below. If you do not cancel your
      annual Plan in accordance with the cancellation clause below, it will be renewed for another year at the end of your current Plan term.
      We will notify you 30 days prior to the end of the current Plan term. We may modify our Plan and the Plan Fees from time to time.
      For month to month Plans, any price changes will apply to you no earlier than 30 days following notice to you. For annual Plans, the
      price changes will apply to you no earlier than the start of your renewed Plan. The notice may be provided at any time via email or via
      notification to your Account. If you do not agree to the price change, you may cancel your Plan in accordance with the
      cancellation clause below.
    </p>

    <h2>Changing your Plan</h2>
    <p>
      You may change your Plan to another Plan at any time in the Account page / manage Plan page (or similar) of your Account.
      The payment method linked to your Account will automatically be charged the Plan Fee for your new Membership tier on the Payment Date on
      which the change becomes effective. The change to the next month if you change your Membership before the next Payment Date.
    </p>

    <h2>Cancelling Plans</h2>
    <p>
      You may cancel your Plan at any time in the My Settings, Account Information page (or similar) of your Account. The cancellation of any
      paid Plan will apply to the next month for paid month to month Plans or the next year for paid annual Plans, if you cancel your paid
      Membership. If you cancel your paid Plan before the next Payment Date, you will be charged the Plan Fee on the next Payment Date and
      the cancellation will become effective for the following month for paid month to month Plans or the next year for paid annual Plans.
    </p>
    <p>
      Despite anything to the contrary, your Plan may also be immediately cancelled by us where a death certificate which, in our sole discretion,
      matches your Account details is presented to us.
    </p>

    <h2>Pausing Memberships</h2>
    <p>
      If you have had a continuous paid My LifeJars Gold Plan for more than 3 years you may choose to pause your Membership. You can pause your
      Membership by selecting the pause Plan in your Account settings. A paused Plan will allow you to log in and view the content in your
      Account but you will only be able to add or edit 3 Memories or Things per year to your Life Profile unless you resubscribe to a paid Plan.
      You can resubscribe via the functionality in your paused Account by selecting an appropriate plan. Your Account must be re-activated at
      least once every 3 years to stay active.
    </p>

    <h2>Transferring Plans</h2>
    <p>
      Your Account or Plan cannot be transferred to your Guardians. When My LifeJars receives notification of your death from any of your
      nominated Guardians, your Account will be paused and your Profile assigned to your Guardian(s). A paused Membership in this instance will
      allow your Guardian(s) access to the content in your Profile according to the rights you granted your Guardian(s). Your Guardian(s) will not
      be able to add or edit any of the content in your Profile, or accept any new Tribesters to your Tribe, unless they subscribe to a paid Plan.
      Your Account will be deleted one year after your death, and your Life Profile transitioned to your Legacy Profile if your Guardian has not already
      done this.
    </p>

    <h2>General</h2>
    <p>
      We provide a number of payment methods on the Platform, including our third party payment processors, currently Stripe and PayPal.
      The payment method you choose may be subject to additional terms and conditions imposed by the applicable third party payment processor.
      By making payment through a third party payment processor, you accept the applicable terms and conditions.
    </p>
    <p>
      In the absence of fraud or mistake, all payments made are final. If you make a payment by debit card or credit card, you warrant that the
      information you provide to us is true and complete, that you are authorised to use the debit card or credit card to make the payment, that
      your payment will be honoured by your card issuer, and that you will maintain sufficient funds in your account to cover the payment.
    </p>

    <h2>Promotional discounts</h2>
    <p>
      We may from time to time issue promotional discount codes for the Platform. To claim the discount, you must enter the promotional discount
      code at the time of subscribing to a Plan or changing a Plan, through the Platform. The conditions of use relating to promotional discount
      codes will be specified on the Platform at the time they are issued.
    </p>
    <p>
      The Platform allows you to upload and share content through your Profile, such as text, images and documents (User Content). User Content may
      be subject to upload limits, which may be determined by your Plan and as communicated to you from time to time.
    </p>
    <p>
      You agree that you are solely responsible for all User Content that you make available on or through our Platform. You represent and warrant
      that (1) you are either the sole and exclusive owner of all User Content or you have all rights, licences, consents and releases that are
      necessary to grant to us the rights in such User Content as contemplated by these Terms; and (2) neither the User Content nor the posting,
      uploading, publication, sending or receiving of the User Content or our use of the User Content on, through or by means of our Platform
      will infringe, misappropriate or violate a third party’s Intellectual Property Rights, or rights of publicity or privacy, or result in the
      violation of any applicable law or regulation.
    </p>
    <p>
      Despite anything to the contrary, to the maximum extent permitted by law, you agree to indemnify us and hold us harmless from and against
      all Liabilities, howsoever arising, suffered or incurred by us and arising from or in connection with any claim that any Liability we incur
      as a result of the User Content you make available on or through the Platform, including as a result of an Intellectual Property Breach.
    </p>

    <h2>Our Content</h2>
    <p>
      Unless otherwise indicated, we own or licence all rights, title and interest (including Intellectual Property Rights) in our Platform and
      the content and information we make available on the Platform (Content). Your use of our Platform and your use of and access to any Content
      does not grant or transfer to you any rights, title or interest in relation to our Platform or the Content. Subject to your compliance with
      these Terms, we grant you a personal, non-exclusive, royalty-free, revocable, worldwide, non- transferable licence to use our Platform on
      your personal device(s) and access and view any Content or User Content solely for your personal and non-commercial use, in accordance with
      these Terms. All other uses are prohibited without our prior written consent.
    </p>

    <h2>Prohibited conduct</h2>
    <p>
      Misusing Content: You must not, without the prior written consent of ourselves as the owner of the Content or the owner of the User Content
      (as applicable) or except as expressly permitted by these Terms, (1) copy or use, in whole or in part, any Content or User Content;
      (2) reproduce, reverse engineer, retransmit, distribute, disseminate, sell, publish, broadcast or circulate any Content or User Content to
      any third party other than via the Platform; or (3) breach any Intellectual Property Rights connected with our Platform, including by
      altering or modifying any of the Content, causing any of the Content to be framed or embedded in another website, or creating derivative
      works from the Content.
    </p>
    <p>
      Violence: You may not make specific threats of violence or wish for the serious physical harm, death, or disease of an individual or
      group of people. This includes, but is not limited to, threatening or promoting terrorism. You also may not affiliate with organizations
      that, whether by their own statements or activity both on and off the Platform, use or promote violence against civilians to further their
      causes.
    </p>
    <p>
      Suicide or self-harm: You may not promote or encourage suicide or self-harm on the Platform. When we receive reports that a person is
      threatening suicide or self-harm, we may take a number of steps to assist them, such as reaching out to that person and providing resources
      such as contact information for mental health service providers.
    </p>
    <p>
      Child sexual exploitation: You may not promote child sexual exploitation. If you find content that relates to child sexual exploitation,
      you must report it to us. Any content that relates to child sexual exploitation will be removed from the Platform immediately, without
      further notice.
    </p>
    <p>
      Abuse: You may not engage in the targeted harassment of someone, or incite other people to do so. You may not create fake accounts to
      harass another person (you may not create fake accounts at all). We consider abusive behaviour an attempt to harass, intimidate, or
      silence someone else’s voice͘
    </p>
    <p>
      Unwanted sexual advances: You may not direct abuse at someone by sending unwanted sexual content, objectifying them in a sexually explicit
      manner, or otherwise engaging in sexual misconduct.
    </p>
    <p>
      Hateful conduct: You may not promote violence against, threaten, or harass other people on the basis of race, ethnicity, national origin,
      sexual orientation, gender, gender identity, religious affiliation, age, disability, or serious disease.
    </p>
    <p>
      Hateful imagery and display names: You may not use hateful images in your profile picture or hateful symbols in your display name.
      You also may not use your display name, profile, Life Profile or Legacy Profile to engage in abusive behaviour, such as targeted harassment or
      expressing hate towards a person or group.
    </p>
    <p>
      Personal information: You may not publish or post other people's personal information without their express authorisation and permission.
      Definitions of personal information may vary depending on local laws. Please read our privacy policy, available here
    </p>
    <p>
      Intimate media: You may not post or share intimate photos or videos of someone that were produced or distributed without their consent͘
      You may not threaten to expose someone’s private information or intimate media͘ You also may not threaten to hack or break into someone’s
      digital information͘
    </p>
    <p>
      Impersonating others: You may not impersonate individuals, groups, or organizations in a manner that is intended to or does mislead,
      confuse, or deceive others.
    </p>

    <h2>Security and your privacy choices</h2>
    <p>
      We take the security of User Content seriously. All content uploaded onto our Platform (unless uploaded through a public setting) is
      encrypted on transit and during storage.
    </p>
    <p>
      We have also built into the Platform various controls which allow you to control who can view any User Content you share via the Platform
      and when. You can use the ‘secret keeper’ function to decide who will see the content you post, you can use the ‘time lock’ function to
      decide when it will be made visible and you can choose whether or not to use certain functionalities such as the location tag for ‘things’
      which you upload to the Platform͘.
    </p>
    <p>
      There is an overriding Privacy setting that is accessed from Edit Profile. Set to Public, your Profile will appear in Profile Searches and display your
      Profile Image, Name and Locality. Memories and Things will divert to their individual Secret Keeper Settings. Set to Private, your Profile will
      appear in search, but only your first and last name will be displayed. Profile image and all other identifying details will be concealed.
      This will also conceal Memories and Things that have the Public Secret Keeper setting. Members of your Tribe will still see all your Profile
      and Memory/Thing content as normal.
    </p>
    <p>
      You can read more about how we collect and handle your personal information in our privacy policy, available here:
    </p>

    <h2>Communication</h2>
    <p>
      We may contact you via the Platform using in-Account notifications, or via off-Platform communication channels, such as text message or email.
    </p>
    <p>
      You may be able to chat on the Platform with other Users, using any chat functionality made available to you. If any use of the chat
      functionality by you which is deemed by us to be prohibited conduct, including as set out in the “Prohibited Conduct” clause above, we
      may suspend or remove your access to the chat functionality.
    </p>

    <h2>Information only</h2>
    <p>
      The Content including any User Content is not comprehensive and is for general information purposes only. It does not take into account
      your specific needs, objectives or circumstances, and is not advice. While we use reasonable attempts to ensure the accuracy and completeness
      of the Content, to the extent permitted by law, we make no representation or warranty regarding the Content or any User Content. The Content
      and any User Content is subject to change without notice. We do not undertake to keep our Platform up-to-date and we are not liable if any
      Content or User Content is inaccurate or out-of-date.
    </p>

    <h2>Advice disclaimer</h2>
    <p>
      Our Platform is not intended to and should never replace the use of formal legal documents, such as a will, advance care directive or the
      appointment of an executor or legal guardian (or similar) in accordance with the applicable laws in your jurisdiction.
    </p>
    <p>
      We do not provide legal advice, legal services, health advice, health services, financial advice, financial services or funeral services.
      It is your responsibility to ensure you receive appropriate and tailored advice or services from a suitably qualified professional.
      You should carefully assess the suitability of any Content on our Platform to your individual situation and any reliance on any Content
      is at your own risk.
    </p>

  <h2>Warranties</h2>
  <p>
    You represent, warrant and agree that:
  </p>

  <ul>
    <li>
      (a)
      you will not use our Platform, including the Content, in any way that competes with our business;
    </li>
    <li>
      (b)
      there are no legal restrictions preventing you from entering into these Terms;
    </li>
    <li>
      (c)
      all information and documentation that you provide to us in connection with these Terms is true, correct and complete;
    </li>
    <li>
      (d)
      you have not relied on any representations or warranties made by us in relation to the Platform (including as to whether the Platform
      is or will be fit or suitable for your particular purposes), unless expressly stipulated in these Terms; and
    </li>
    <li>
      (e)
      you will be responsible for the use of any part of the Platform, and you must ensure that no person uses any part of the
      Platform: (1) to break any law or infringe any person’s rights (including Intellectual Property Rights) (2) to transmit, publish
      or communicate material that is illegal, defamatory, offensive, abusive, indecent, menacing or unwanted; or (3) in any way
      that damages, interferes with or interrupts the supply of the Platform.
    </li>
  </ul>

    <h2>Australian Consumer Law</h2>
    <p>
    Certain legislation, including the Australian Consumer Law (ACL) in the Competition and Consumer Act 2010 (Cth), and similar consumer
      protection laws and regulations, may confer you with rights, warranties, guarantees and remedies relating to the provision of the
      Platform by us to you which cannot be excluded, restricted or modified (Statutory Rights).
    </p>
    <p>
    If the ACL applies to you as a consumer, nothing in these Terms excludes your Statutory Rights as a consumer under the ACL. You agree
      that our Liability for the Platform and/or the My LifeJars Services provided to an entity defined as a consumer under the ACL is governed
      solely by the ACL and these Terms.
    </p>
    <p>
    Subject to your Statutory Rights, we exclude all express and implied warranties, and all material, work and services (including the Platform)
      are provided to you without warranties of any kind, either express or implied, whether in statute, at law or on any other basis.
    </p>
    <p>
    This clause will survive the termination or expiry of these Terms.
    </p>

    <h2>Exclusions to liability</h2>
    <p>
      Despite anything to the contrary, to the maximum extent permitted by law, we will not be liable for, and you waive and release us from and
      against, any Liability caused or contributed to by, arising from or connected with:
    </p>
    <ul>
      <li>(a) your acts or omissions;</li>
      <li>
        (b) any use or application of the My LifeJars Services by a person or entity other than you, or other than as reasonably contemplated by
        these Terms;
      </li>
      <li>
        (c) any aspect of User interaction including any advice provided, the performance of any services or the supply and delivery of any
        goods by a User;
      </li>
      <li>
        (d) any works, services, goods, materials or items which do not form part of the My LifeJars Services (as expressed in these Terms), or
        which have not been provided by us;
      </li>
      <li>
        (e) any third parties or any goods and services provided by third parties, including customers, end users, suppliers, software providers,
        transportation or logistics providers or other subcontractors which the provision of the Platform may be contingent on, or impacted by;
      </li>
      <li>
        (f) the My LifeJars Services being unavailable, or any delay in us providing the My LifeJars Services to you, for whatever reason; and/or
      </li>
      <li>
        (g) any event outside of our reasonable control.
      </li>
    </ul>
    <p>
      This clause will survive the termination or expiry of these Terms.
    </p>

    <h2>Limitations on liability</h2>
    <p>
      Despite anything to the contrary, to the maximum extent permitted by law:
    </p>
    <ul>
      <li>(a) we will not be liable for Consequential Loss;</li>
      <li>
        (b) our liability for any Liability under these Terms will be reduced proportionately to the extent the relevant Liability was caused
        or contributed to by the acts or omissions of you; and
      </li>
      <li>
        (c)our aggregate liability for any Liability arising from or in connection with these Terms will be limited to us resupplying the
        My LifeJars Services to you or, in our sole discretion, to us repaying you the amount of the Membership Fee paid by you to us in respect
        of the supply of the My LifeJars Services to which the Liability relates, or where there is no Membership Fee paid, $100. This clause
        will survive the termination or expiry of these Terms.
      </li>
    </ul>

    <h2>Termination</h2>
    <p>
      Subject to the “Cancelling Memberships” clause above, your Account and these Terms may be terminated by you at any time, using
      the ‘cancel account’ functionality (or similar) in the Account page section of your Account settings.
    </p>
    <p>
      We may suspend your Account or terminate these Terms immediately upon written notice to you, if:
    </p>
    <ul>
      <li>(a) you breach any material term of these Terms;</li>
      <li>(b) you breach any provision of these Terms and that breach has not been remedied within 10 business days of being notified by us;</li>
      <li>(c) there is any reason outside our control which has the effect of compromising our ability to provide the My LifeJars Services;</li>
      <li>(d) you are unable to pay your Membership Fee; or</li>
      <li>(e) your free Account and Profile is inactive for more than 3 years.</li>
    </ul>
    <p>
      These Terms will terminate immediately upon written notice by you, if we:
    </p>
    <ul>
      <li>
        (a) are in breach of a material term of these Terms, and that breach has not been remedied within 10 business days of being notified
        by you; or
      </li>
      <li>(b) we are unable to pay our debts as they fall due.</li>
    </ul>
    <p>
      Upon expiry or termination of these Terms:
    </p>
    <ul>
      <li>(a) we will remove your access to the Platform;</li>
      <li>(b) if your Membership was a paid Membership, we will provide you with a copy of your User Content in a portable format;</li>
      <li>
        (c) if your membership was a paid Membership, you may downgrade to a free Membership with limits to the amount of information that
        can be stored in your Account in accordance with the free Membership plan;
      </li>
      <li>(d) if your Membership was a free Membership, we may at our sole discretion delete all of your User Content with no Liability to you;</li>
      <li>(e) you agree that any payments made by you to us are not refundable to you; and</li>
      <li>
        (f) where we terminate the Terms for your breach, you also agree to pay us our additional costs arising from, or in connection with,
        such termination
      </li>
    </ul>
    <p>
      Termination of these Terms will not affect any rights or liabilities that a Party has accrued under it. This clause will survive the
      termination or expiry of these Terms.
    </p>

    <h2>General</h2>
    <p>
      Assignment: You must not assign or deal with the whole or any part of your rights or obligations under these Terms without our prior
      written consent.
    </p>
    <p>
      Disputes: In relation to a dispute, controversy or claim arising from, or in connection with, these Terms (including any question
      regarding its existence, validity or termination) (Dispute) a Party may not commence court proceedings relating to a Dispute without
      first meeting with a senior representative of the other Party to seek (in good faith) to resolve the Dispute. If the Parties cannot
      agree how to resolve the Dispute at that initial meeting, either Party may refer the matter to a mediator. If the Parties cannot agree
      on who the mediator should be, either Party may ask the Law Society of New South Wales to appoint a mediator. The mediator will decide
      the time, place and rules for mediation. The Parties agree to attend the mediation in good faith, to seek to resolve the Dispute.
      The costs of the mediation will be shared equally between the Parties. Nothing in this clause will operate to prevent a Party from
      seeking urgent injunctive or equitable relief from a court of appropriate jurisdiction.
    </p>
    <p>
      Force Majeure: We will not be liable for any delay or failure to perform our obligations under these Terms if such delay is due to any
      circumstance beyond our reasonable control.
    </p>
    <p>
      Governing law: These Terms governed by the laws of New South Wales. Each Party irrevocably and unconditionally submits to the exclusive
      jurisdiction of the courts operating in New South Wales and any courts entitled to hear appeals from those courts and waives any right
      to object to proceedings being brought in those courts.
    </p>
    <p>
      Notices: Any notice given under these Terms must be in writing addressed to us at the address at the end of these Terms or to you at
      the address in your Account. Any notice may be sent by standard post or email, and will be deemed to have been served on the expiry of
      48 hours in the case of post, or at the time of transmission in the case of transmission by email.
    </p>
    <p>
      Severance: If a provision of these Terms is held to be void, invalid, illegal or unenforceable, that provision is to be read down as
      narrowly as necessary to allow it to be valid or enforceable, failing which, that provision (or that part of that provision) will be
      severed from these Terms without affecting the validity or enforceability of the remainder of that provision or the other provisions
      in these Terms.
    </p>

    <h2>Definitions</h2>
    <p>
      Consequential Loss includes any consequential loss, indirect loss, real or anticipated loss of profit, loss of benefit, loss of revenue,
      loss of business, loss of goodwill, loss of opportunity, loss of savings, loss of reputation, loss of use and/or loss or corruption of
      data, whether under statute, contract, equity, tort (including negligence), indemnity or otherwise.
    </p>
    <p>
      Intellectual Property means any domain names, know-how, inventions, processes, trade secrets or confidential information; or
      circuit layouts, software, computer programs, databases or source codes, including any application, or right to apply, for
      registration of, and any improvements, enhancements or modifications of, the foregoing.
    </p>
    <p>
      Intellectual Property Breach means any breach by you of any of our Intellectual Property Rights (or any breaches of third party
      rights including any Intellectual Property Rights of third parties).
    </p>
    <p>
      Intellectual Property Rights means for the duration of the rights in any part of the world, any industrial or intellectual property
      rights, whether registrable or not, including in respect of Intellectual Property.
    </p>
    <p>
      Liability means any expense, cost, liability, loss, damage, claim, notice, entitlement, investigation, demand, proceeding or judgment
      (whether under statute, contract, equity, tort (including negligence), indemnity or otherwise), howsoever arising, whether direct or
      indirect and/or whether present, unascertained, future or contingent and whether involving a third party or a party to these Terms
      or otherwise.
    </p>
    <p>
      For any questions or notices, please contact us at:
      My LifeJars Pty Ltd ACN 632 790 526
      Email: hello@mylifejars.com
      Last update: 14 October 2019
      &copy; <a href="http://www.legalvision.com.au/">LegalVision</a> ILP Pty Ltd
    </p>
  </div>
</div>
