import {NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class AppDateFormatter extends NgbDateParserFormatter {

  private isoDateTimeRegex = [
    /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\+\d{2}:\d{2})$/, // ISO8601
    /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)$/, // PHP DateTime to Array
    /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}/, //  Basic ISO DateTime
    /^\d{4}-\d{2}-\d{2}$/, //  Basic ISO Date
  ];

  public readonly AU_DATE_FORMAT = 'DD/MM/YYYY';
  public readonly ISO_DATE_FORMAT = 'YYYY-MM-DD';

  public testDate(value: string) {
    for (let i = 0, l = this.isoDateTimeRegex.length; i < l; i++) {
      if (this.isoDateTimeRegex[i].test(value)) {
        return true;
      }
    }
    return false;
  }

  public parseISO(date: string) {
    if (typeof date === 'string' && this.testDate(date)) {
      return this.parseDateToNgb(new Date(date));
    }
    return null;
  }

  public parseDateToNgb(date: Date) {
    if (date instanceof Date) {
      const mdt = moment(date);
      return {year: mdt.year(), month: (mdt.month() + 1), day: mdt.date()};
    }
    return null;
  }

  public parse(value: string, format: string = this.AU_DATE_FORMAT): NgbDateStruct {
    if (value) {
      value = value.trim();
      const mdt = moment(value, format, true); // strict: 'value' MUST match 'format'
      return {year: mdt.year(), month: (mdt.month() + 1), day: mdt.date()};
    }
    return null;
  }

  public format(date: NgbDateStruct, format: string = this.AU_DATE_FORMAT): string {
    if (!date) {
      return '';
    }
    const mdt = moment([date.year, date.month - 1, date.day]);
    if (!mdt.isValid()) {
      return '';
    }
    return mdt.format(format);
  }

}
