import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {Router, ActivatedRoute, NavigationEnd, NavigationStart} from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import {AuthService} from '../../../core/services/auth.service';
import {AlertService} from '../../../shared/components/alert/alert.service';
import {ControlValidationService} from '../../../shared/components/control-validation/control-validation.service';
import {RegisterModel} from '../../../core/models/register.model';
import {first} from 'rxjs/operators';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LayoutService} from '../../../core/services/layout.service';
import {Subscription} from 'rxjs';
import {isPlatformBrowser} from '@angular/common';

@Component({ templateUrl: 'register.component.html' })
export class RegisterComponent implements OnInit, OnDestroy {
  private readonly subscription: Subscription;
  public registerForm: FormGroup;
  public loading = false;
  public submitted = false;

  public showJunkMailAlert = false;

  // convenience getter for easy access to form fields
  public get f() { return this.registerForm.controls; }

  constructor(
    @Inject(PLATFORM_ID) protected platformId,
    protected formBuilder: FormBuilder,
    protected route: ActivatedRoute,
    protected router: Router,
    protected authService: AuthService,
    protected alertService: AlertService,
    protected modalService: NgbModal,
    protected layoutService: LayoutService,
  ) {
    this.layoutService.setTitle( `Sign up - My LifeJars`  );

    this.subscription = new Subscription();
  }

  public ngOnInit() {
    this.registerForm = this.formBuilder.group({
      email: ['', [Validators.required, ControlValidationService.emailValidator] ],
      firstName: ['', Validators.required ],
      lastName: ['', Validators.required ],
      password: ['', [Validators.required, ControlValidationService.passwordValidator] ],
      passwordConfirm: ['', [Validators.required, ControlValidationService.passwordValidator] ]
    }, { validator: ControlValidationService.RepeatPasswordValidator });

  }

  private executeGtm(registerModel: RegisterModel){
    const isBrowser = isPlatformBrowser(this.platformId);
    if (isBrowser === true) {
      // @ts-ignore
      window.dataLayer = window.dataLayer || [];
      const gtmTag = {
        event: 'User Signed Up',
        properties: {
          object: 'User',
          action: 'Signed Up',
          user_id: '0',
          email: registerModel.email,
          first_name: registerModel.firstName,
          last_name: registerModel.lastName,
        },
      };
      // @ts-ignore
      window.dataLayer.push(gtmTag);
      console.log('GTM: User Signed Up Complete');
    }
  }

  public onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }

    this.loading = true;

    const registerModel = RegisterModel.make( this.registerForm.getRawValue() );
    this.authService.register(registerModel)
      .pipe(first())
      .subscribe(
        data => {
          this.loading = false;
          this.alertService.info(data.message);
          this.executeGtm(registerModel);

          this.showJunkMailAlert = true;
        },
        error => {
          this.loading = false;
        });
  }

  public showTerms(): string {
    return this.layoutService.showTerms();
  }

  public showPrivacy(): string {
    return this.layoutService.showPrivacy();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
