<form [formGroup]="editForm" (ngSubmit)="onSubmit()">
<div class="modal-header">
  <h4 class="modal-title">Change email</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss(false)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <form-validation [control]="editForm" [submitted]="submitted"></form-validation>

  <div class="form-group">
    <label class="control-label sr-only">Email</label>
    <input type="email" formControlName="email" class="form-control form-control-lg input-bio"
           [ngClass]="{ 'is-invalid': submitted && f.email.errors }" placeholder="Email" />
    <app-control-validation [control]="f.email" [submitted]="submitted"></app-control-validation>
  </div>

  <div class="form-group">
    <label class="control-label sr-only">Confirm Email</label>
    <input type="email" formControlName="emailConfirm" class="form-control form-control-lg input-bio"
           [ngClass]="{ 'is-invalid': submitted && f.emailConfirm.errors }" placeholder="Confirm Email" />
    <app-control-validation [control]="f.emailConfirm" [submitted]="submitted"></app-control-validation>
  </div>

  <p>
    Before your email is changed, you will have to confirm that the new email address is your own.
  </p>
  <p>
    We will send an email to the new address. Please check your emails and follow the instructions.
  </p>

  <div class="form-group text-center">
    <button type="submit" class="btn btn-gold">
      Change email
    </button>
  </div>

</div>
</form>
<app-content-loader></app-content-loader>
