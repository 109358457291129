import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

import {Bio} from '../../../../core/models/bio.model';
import {FileModel} from '../../../../core/models/file.model';
import {faImage} from '@fortawesome/free-solid-svg-icons';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../store';
import {BioActions} from '../../../../store/bios';
import {JarModel} from '../../../../core/models/jar.model';
import {JarSelectors} from '../../../../store/jars';
import {filter} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {JarType} from '../../../../core/common/enums';
import {IMemory, MemoryModel} from '../../../../core/models/memory.model';
import {MemoryActions} from '../../../../store/memories';
import {AlertService} from '../../../components/alert/alert.service';
import {selectCurrentBio} from '../../../../store/bios/bios.selectors';

@Component({
  templateUrl: 'jar-picker.component.html'
})
export class JarPickerComponent implements OnInit, OnDestroy {
  private readonly subscription: Subscription;

  @Input()
  public memory: IMemory;

  public bio: Bio;
  public jarType: JarType;
  public bioJar: JarModel;
  public memoryJars: JarModel[];

  constructor(
    public activeModal: NgbActiveModal,
    private store: Store<AppState>,
    private alertService: AlertService
  ) {
    this.subscription = new Subscription();
    this.jarType = JarType.Memory;


  }

  public ngOnInit() {
    this.jarType = MemoryModel.getJarType(this.memory);
    this.subscription.add(this.store.pipe(select(selectCurrentBio))
      .subscribe((bio: Bio) => {
        this.bio = bio;
        this.memoryJars = this.jarType === JarType.Memory
          ? this.bio.memoryJars
          : this.bio.thingJars;
      })
    );
  }

  public addToJar(event) {
    if ( !this.bioJar || !this.memory ){
      this.alertService.error(['Please select a Jar.']);
      return false;
    }

    this.store.dispatch(
      MemoryActions.shareMemoryRequest({
        jar: this.bioJar,
        memory: this.memory
      })
    );

    this.activeModal.close();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
