import {initialOptionState, OptionsState} from './options.state';
import {Action, createReducer, on} from '@ngrx/store';
import {OptionActions} from './index';

const reducer = createReducer<OptionsState>(
  initialOptionState,

  on(OptionActions.getOptionsSuccess, (state, {options}) => {
    return {
      ...state,
      ...options
    };
  })

);

export function OptionsReducer(state: OptionsState, action: Action) {
  return reducer(state, action);
}
