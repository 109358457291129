import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {faSearch, faPlusCircle} from '@fortawesome/free-solid-svg-icons';

import {BioService} from '../../../core/services/bio.service';
import {first} from 'rxjs/operators';
import {BioFilterModel} from '../../../core/models/bio-filter.model';
import {Bio} from '../../../core/models/bio.model';
import {Router} from '@angular/router';
import {BioSearchResult} from '../../../core/models/bio-search-result.model';
import {TribeFilterModel} from '../../../core/models/tribe-filter.model';
import {TribeService} from '../../../core/services/tribe.service';
import {UserFilterModel} from '../../../core/models/user-filter.model';
import {UserService} from '../../../core/services/user.service';
import {FriendInviteModalComponent} from '../friend-invite/friend-invite-modal.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

export enum SearchMode {
  Bios = 0,
  Tribe = 1,
  Users = 2
}

@Component({
  templateUrl: 'bio-search-modal.component.html'
})
export class BioSearchModalComponent implements OnInit {
  @Input() public isPicker: boolean;
  @Input() public searchMode: SearchMode;
  @Input() public bio: Bio;
  @Input() public excludeBios: Bio[];

  public searchForm: FormGroup;
  public submitted = false;

  private debounceTimer;
  public faSearch = faSearch;
  public faPlusCircle = faPlusCircle;

  // Pagination fields
  public page = 1;
  public results = 10;
  public pageCount = 1;

  // Data from API
  public pagedBios: BioSearchResult = new BioSearchResult();

  // convenience getter for easy access to form fields
  public get f() {
    return this.searchForm.controls;
  }

  constructor(
    public activeModal: NgbActiveModal,
    protected formBuilder: FormBuilder,
    protected modalService: NgbModal,
    protected router: Router,
    protected bioService: BioService,
    protected tribeService: TribeService,
    protected userService: UserService
  ) {
    this.isPicker = false;
    this.searchMode = SearchMode.Bios;
    this.excludeBios = [];

    this.searchForm = this.formBuilder.group({
      keywords: ['', [Validators.required, Validators.minLength(3)]]
    });

  }

  public ngOnInit() {
  }

  public viewBio(bio: Bio) {
    if (bio.isLegacy) {
      this.router.navigate(['/legacy', bio.slug]);
    } else {
      this.router.navigate(['/profiles', bio.slug]);
    }
    this.activeModal.close(bio);
  }

  public selectBio(bio: Bio) {
    this.activeModal.close(bio);
  }

  public onPageChange(page: number) {
    this.page = page;
    this.search();
  }

  // Ensure that the API isn't hammered when checkboxes are being selected
  public queueSearch() {
    if (typeof window !== 'undefined') {
      window.clearTimeout(this.debounceTimer);
      this.debounceTimer = window.setTimeout(() => {
        this.page = 1;
        this.search();
      }, 1000);
    }
  }

  public search() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.searchForm.invalid) {
      return;
    }

    switch (this.searchMode) {

      case SearchMode.Tribe: {

        const filters = new TribeFilterModel();
        filters.keywords = this.searchForm.get('keywords').value;
        filters.page = this.page;
        filters.results = this.results;
        filters.bio = this.bio ? this.bio.id : null;
        filters.excludeBios = this.excludeBios.map((bio: Bio) => {
          return bio.id;
        });

        this.tribeService.search(filters)
          .pipe(first())
          .subscribe(
            data => {
              this.pagedBios = data;
            });

      }
        break;

      case SearchMode.Users: {

        const filters = new UserFilterModel();
        filters.keywords = this.searchForm.get('keywords').value;
        filters.page = this.page;
        filters.results = this.results;

        this.userService.search(filters)
          .pipe(first())
          .subscribe(
            data => {

              // Need to convert this User List into Bios, with User Ref.
              const list = [];
              for (let i = 0, l = data.list.length; i < l; i++) {
                const bio = data.list[i].defaultBio;
                data.list[i].defaultBio = undefined;
                bio.user = data.list[i];
                list.push(bio);
              }

              this.pagedBios.list = list;
              this.pagedBios.count = data.count;
              this.pagedBios.page = data.page;
              this.pagedBios.pages = data.pages;
            });
      }
        break;

      case SearchMode.Bios:
      default: {

        const filters = new BioFilterModel();
        filters.keywords = this.searchForm.get('keywords').value;
        filters.page = this.page;
        filters.results = this.results;

        this.bioService.search(filters)
          .pipe(first())
          .subscribe(
            data => {
              this.pagedBios = data;
            });
      }
    }

  }

  public friendInvitation(event) {
    event.preventDefault();
    const modal = this.modalService.open(FriendInviteModalComponent, {});
    modal.componentInstance.bio = this.bio;
  }

}
