<div class="modal-header">
  <h4 class="modal-title">Search Profiles</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <div class="row">
    <div class="col-12">

      <div class="block block-search d-block">
        <form [formGroup]="searchForm">

          <div class="input-group">
            <input ngbAutofocus (keyup)="queueSearch()" type="text" formControlName="keywords" class="form-control"
                   [ngClass]="{ 'is-invalid': submitted && f.keywords.errors }" placeholder="Search..."
                   aria-label="Search Profiles"/>
            <div class="input-group-append">
              <button class="btn" type="button" (click)="search()">
                <span class="sr-only">Search</span>
                <fa-icon [icon]="faSearch"></fa-icon>
              </button>
            </div>
          </div>

          <app-control-validation [control]="f.keywords" [submitted]="submitted"></app-control-validation>
        </form>
      </div><!-- /block-search -->

    </div>
  </div>

  <div class="row">
    <div class="col-12">


      <div class="bios-list-container">

        <ul class="list list-bios">
          <li *ngFor="let item of pagedBios.list" class="item item-bio">
            <div class="row">
              <div class="col-2">
                <div class="thumb thumb-bio" [appBioTypeClass]="item.type">
                  <app-file-lazy-loader [bio]="item" [file]="item.profile"></app-file-lazy-loader>
                </div>
              </div>
              <div class="col-7">
                <h3 class="title">
                  {{ item.firstName }} {{ item.lastName }}
                  <app-ico-candle *ngIf="item.isLegacy"></app-ico-candle>
                </h3>
                <div class="secondary-identifier">
                  <div class="location" *ngIf="item.location">
                    {{ item.location }}.
                  </div>
                </div>
              </div>
              <div class="col-3 text-sm-right">

                <button *ngIf="!isPicker" type="button" class="btn btn-sm btn-hollow" (click)="viewBio(item)">
                  View
                </button>

                <button *ngIf="isPicker" type="button" class="btn btn-sm btn-hollow" (click)="selectBio(item)">
                  Select
                </button>

              </div>
            </div>

          </li>
        </ul>

        <app-paginator [page]="this.pagedBios.page" [pages]="this.pagedBios.pages"
                       (pageChange)="onPageChange($event)"></app-paginator>

      </div><!-- /bios-list-container -->

    </div>
  </div>

</div>
<div class="modal-footer justify-content-between">
  <p class="text-center w-100">
    Can't find them? <a href="#" (click)="friendInvitation($event)"><strong>Send them an invitation!</strong></a>
  </p>
</div>
<app-content-loader [scope]="'modal'"></app-content-loader>
