<div class="modal-header">
  <h4 class="modal-title">MY LIFEJARS - PRIVACY POLICY</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="text-legal-doc">

    <p>
      We understand that protecting your personal information is important. This Privacy Policy sets out our commitment to
      protecting the privacy of personal information provided to us, or otherwise collected by us, offline or online, including
      through our services, <a href="https://mylifejars.com/">mylifejars.com</a> (Services). In this Privacy Policy we, us or our
      means My LifeJars Pty Ltd ACN 632 790 526.
    </p>

    <p>
      This Privacy Policy takes into account the requirements of the Privacy Act 1988 (Cth) and the Australian Privacy Principles.
      In addition to the Australian laws, individuals located in the European Union (EU) may also have rights under the General
      Data Protection Regulation 2016/679 (GDPR). Appendix 1 outlines the details of the additional rights of individuals located
      in the EU as well as information on how we process the personal information of individuals located in the EU.
    </p>
    <p>
      For your security, any content that is uploaded and stored to your profile, known as your Profile for you to curate is
      encrypted (unless you change your account settings). This turns any data you upload into indecipherable text and will only
      be decrypted by us in order to:
    </p>

    <ul><li>
      provide to courts, tribunals, regulatory authorities and law enforcement officers, as required by law, in
      connection with any actual or prospective legal proceedings, or in order to establish, exercise or defend our
      legal rights; and/or

    </li><li>
      to investigate breaches of our Terms &amp; Conditions, see &ldquo;Prohibited Conduct&rdquo;.
      Personal information
    </li>
    </ul>

    <p>
      The types of personal information we may collect about you when you sign up for an account and use our Services includes:
    </p>

    <ul><li>
      your name;

    </li><li>
      your contact details, including your email address;

    </li><li>
      your credit card or other payment details (through our third party payment processor);

    </li><li>
      your preferences and/or opinions in any feedback you may provide to us;

    </li><li>
      details of products and services we have provided to you and/or that you have enquired about, and our
      response to you;

    </li><li>
      your browser session and geo-location data, device and network information, statistics on page views and
      sessions, acquisition sources, search queries and/or browsing behaviour;

    </li><li>
      information about your access and use of our Services, including through the use of Internet cookies, your
      communications with our online Services, the type of browser you are using, the type of operating system
      you are using and the domain name of your Internet service provider; and any other personal information requested by us.
    </li>
    </ul>

    <p>
      When you upload documents and information onto your Profile, we may also receive the following information about you:
    </p>

    <ul><li>
      images of you;

    </li><li>
      your date of birth;

    </li><li>
      your sensitive information;

    </li><li>
      any information you choose to input into our Services, such as copies of your personal documents and
      details about your life such as your favourite pet;

    </li><li>
      additional personal information that you provide to us, directly or indirectly, through your use of our
      Services, associated applications and/or accounts from which you permit us to collect information; and

    </li><li>
      any other personal information provided by you or a third party.

    </li><li>
      We may collect these types of personal information directly from you or from third parties.
    </li></ul>

    <h3>Collection and use of personal information</h3>

    <p>
      We may collect, hold, use and disclose personal information for the following purposes:
    </p>

    <ul><li>
      to enable you to access and use our Services, and associated applications;

    </li><li>
      to allow you to share your personal information with other users via our Services;

    </li><li>
      to contact and communicate with you about our Services;

    </li><li>
      for internal record keeping, administrative purposes, invoicing and billing purposes;

    </li><li>
      for analytics, market research and business development, including to operate and improve our Services
      and associated applications;

    </li><li>
      to run My LifeJars promotions and/or offer additional benefits to you;

    </li><li>
      for advertising and marketing, including to send you promotional information about our Services and our
      not for profit partners&rsquo; services only;

    </li><li>
      to comply with our legal obligations and resolve any disputes that we may have; and

    </li><li>
      if you have applied for employment with us; to consider your employment application.
      Disclosure of personal information to third parties
    </li></ul>

    <p>We may disclose personal information to:</p>

    <ul><li>
      third party service providers for the purpose of enabling them to provide their services, including (without
      limitation) IT service providers, data storage, web-hosting and server providers, marketing or advertising
      providers, professional advisors and payment systems operators;

    </li><li>
      our employees, contractors and/or related entities;

    </li><li>
      our existing or potential agents or business partners;

    </li><li>
      anyone to whom our business or assets (or any part of them) are, or may (in good faith) be, transferred;

    </li><li>
      credit reporting agencies, courts, tribunals and regulatory authorities, in the event you fail to pay for goods
      or services we have provided to you;

    </li><li>
      courts, tribunals, regulatory authorities and law enforcement officers, as required by law, in connection with
      any actual or prospective legal proceedings, or in order to establish, exercise or defend our legal rights;

    </li><li>
      third parties, including agents or sub-contractors, who assist us in providing information, products, services
      or direct marketing to you. This may include parties located, or that store data, outside of Australia; and

    </li><li>
      third parties to collect and process data. This may include parties that store data outside of Australia.
      Where the disclosure of your personal information is solely subject to Australian privacy laws, you acknowledge that some
      third parties may not be regulated by the Privacy Act and the Australian Privacy Principles in the Privacy Act and if any such
      third party engages in any act or practice that contravenes the Australian Privacy Principles, it would not be accountable
      under the Privacy Act and you will not be able to seek redress under the Privacy Act.
    </li>
    </ul>

    <h3>How we treat personal information that is also sensitive information</h3>

    <p>
      Sensitive information is a sub-set of personal information that is given a higher level of protection under the Australian
      Privacy Principles. Sensitive information means information relating to your racial or ethnic origin, political opinions,
      religion, trade union or other professional associations or memberships, philosophical beliefs, sexual orientation, sexual
      practices or sex life, criminal records, health information or biometric information.
    </p>

    <p>
      The type of sensitive information we may collect about you includes:
    </p>

    <ul><li>
      any sensitive information you choose to input into our Services; and

    </li><li>
      any sensitive information which another user, including where applicable your nominated guardian, chooses
      to input about you into our Services.
    </li>
    </ul>

    <p>
      We will not collect sensitive information about you without first obtaining your consent.
    </p>
    <p>
      Provided you consent, your sensitive information may only be used and disclosed for purposes relating to the specified
      purpose for which the sensitive information was collected, including to provide our Services to you.
      Sensitive information may also be used or disclosed if required or authorised by law.
    </p>

    <h3>Our commitment to you</h3>

    <p>
      Your personal information will:
    </p>

    <ul><li>
      be processed lawfully, fairly and in a transparent manner by us;

    </li><li>
      only be collected for the specific purposes we have identified in the &lsquo;collection and use of personal
      information&rsquo; clause above and personal information will not be further processed in a manner that is
      incompatible with the purposes we have identified;

    </li><li>
      be collected in a way that is adequate, relevant and limited to what is necessary in relation to the purpose
      for which the personal information is processed;

    </li><li>
      be kept up to date, where it is possible and within our control to do so (please let us know if you would like
      us to correct any of your personal information);

    </li><li>
      be kept in a form which permits us to identify you, but only for so long as necessary for the purposes for
      which the personal information was collected; and

    </li><li>
      be processed securely and in a way that protects against unauthorised or unlawful processing and against
      accidental loss, destruction or damage.
    </li>
    </ul>


    <h3>Your rights and controlling your personal information</h3>

    <p>
      Your choice: Please read this Privacy Policy carefully. If you provide personal information to us, you understand we will
      collect, hold, use and disclose your personal information in accordance with this Privacy Policy. You do not have to provide
      personal information to us, however, if you do not, it may affect your use of our Services.
    </p>
    <p>
      Information from third parties: If we receive personal information about you from a third party, we will protect it as set out
      in this Privacy Policy. If you are a third party providing personal information about somebody else, you represent and
      warrant that you have such person&rsquo;s consent to provide the personal information to us.
    </p>
    <p>
      Anonymity: Where practicable we will give you the option of not identifying yourself or using a pseudonym in your dealings
      with us.
    </p>
    <p>
      Restrict and unsubscribe: To object to processing for direct marketing/unsubscribe from our email database or opt-out of
      communications (including marketing communications), please contact us using the details below or opt-out using the opt-
      out facilities provided in the communication.
    </p>
    <p>
      Correction: If you believe that any information we hold about you is inaccurate, out of date, incomplete, irrelevant or
      misleading, please contact us using the details below. We will take reasonable steps to promptly correct any information
      found to be inaccurate, incomplete, misleading or out of date.
    </p>
    <p>
      Complaints: If you wish to make a complaint, please contact us using the details below and provide us with full details of
      the complaint. We will promptly investigate your complaint and respond to you, in writing, setting out the outcome of our
      investigation and the steps we will take in response to your complaint. You also have the right to contact the relevant
      authority in the country in which you are based.
    </p>

    <h3>Storage and security</h3>

    <p>
      We are committed to ensuring that the personal information we collect is secure. In order to prevent unauthorised access
      or disclosure, we have put in place suitable physical, electronic and managerial procedures, to safeguard and secure
      personal information and protect it from misuse, interference, loss and unauthorised access, modification and disclosure.
      We cannot guarantee the security of any personal information as defined above, that is transmitted to or by us over the
      Internet. The transmission and exchange of personal information is carried out at your own risk. Although we take
      measures to safeguard against unauthorised disclosures of information, we cannot assure you that the personal
      information we collect will not be disclosed in a manner that is inconsistent with this Privacy Policy.
    </p>

    <h3>Cookies and web beacons</h3>

    <p>
      We may use cookies on our online Services from time to time. Cookies are text files placed in your computer's browser to
      store your preferences. Cookies, by themselves, do not tell us your email address or other personally identifiable
      information. However, they do allow third parties, such as Google and Facebook, to cause our advertisements to appear on
      your social media and online media feeds as part of our retargeting campaigns. If and when you choose to provide our
      online Services with personal information, this information may be linked to the data stored in the cookie.
    </p>
    <p>
      You can block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies.
      However, if you use your browser settings to block all cookies (including essential cookies) you may not be able to access all
      or parts of our online Services.
    </p>
    <p>
      We may use web beacons on our online Services from time to time. Web beacons (also known as Clear GIFs) are small
      pieces of code placed on a web page to monitor the visitor&rsquo;s behaviour and collect data about the visitor&rsquo;s viewing of a web
      page. For example, web beacons can be used to count the users who visit a web page or to deliver a cookie to the browser
      of a visitor viewing that page.
    </p>
    <p>
      We may use Google Analytics to collect and process data. To find out how Google uses data when you use third party
      websites or applications, please see <a href="http://www.google.com/policies/privacy/partners/">www.google.com/policies/privacy/partners/</a> or any other URL Google may use from
      time to time.
    </p>

    <h3>Links to other websites</h3>

    <p>
      Our Services may contain links to other websites. We do not have any control over those websites and we are not
      responsible for the protection and privacy of any personal information which you provide whilst visiting those websites.
      Those websites are not governed by this Privacy Policy.
    </p>

    <h3>Amendments</h3>

    <p>
      We may, at any time and at our discretion, vary this Privacy Policy. We will notify you if we amend this Privacy Policy, by
      contacting you through the contact details you have provided to us. Any amended Privacy Policy is effective once we notify
      you of the change.
    </p>

    <p>
      For any questions or notices, please contact our Privacy Officer at:
      <br/>My LifeJars Pty Ltd ACN 632 790 526
      <br/>Email: hello@mylifejars.com
      <br/>Last update: 14 October 2019
      <br/>&copy; <a href="https://legalvision.com.au/">LegalVision ILP Pty Ltd</a>
    </p>

    <h2>APPENDIX 1 - ADDITIONAL RIGHTS FOR INDIVIDUALS LOCATED IN THE EU</h2>

    <p>
      Under the GDPR individuals located in the EU have extra rights which apply to their personal information. Personal
      information under the GDPR is often referred to as personal data and is defined as information relating to an identified or
      identifiable natural person (individual). This Appendix sets out the additional rights we give to individuals located in the EU,
      including how we process personal information lawfully, transparently and fairly. Please read the Privacy Policy above and
      this Appendix carefully and contact us at the details at the end of the Privacy Policy if you have any questions.
    </p>

    <h3>What personal information is relevant?</h3>

    <p>
      This Appendix applies to the personal information set out in the Privacy Policy above. This includes any sensitive
      information also listed in the Privacy Policy above which is known as &lsquo;special categories of data&rsquo; under the GDPR.
      How we process personal information
    </p>
    <p>
      We will process your personal information for our legitimate interest to allow you to access and use our website, to send
      you marketing content we think may be of interest to you, to contact you if you leave your contact details with us or if you
      otherwise initiate contact with us.
    </p>
    <p>
      We will rely on performing a contract to process your personal information where we are preparing to enter into a contract
      with you or we are carrying out our obligations under a contract with you.
    </p>
    <p>
      We will rely on a legal obligation to process your personal information where we are subject to a legal obligation.
    </p>
    <p>
      If we need to rely on consent, we will ask for consent to process any of your personal information for that specific purpose
      before we process your personal information for that reason. If you are under 16, we will take reasonable steps to obtain
      your parent or legal guardian&rsquo;s consent on your behalf.
    </p>
    <p>
      Upon written request, we may provide you with a list of the third parties we use to process your personal information.
    </p>

    <h3>Data Retention</h3>

    <p>
      We will only retain your personal information for as long as necessary to fulfil the purposes we collected it for, including for
      the purposes of satisfying any legal, accounting, or reporting requirements.
    </p>
    <p>
      To determine the appropriate retention period for personal information, we consider the amount, nature, and sensitivity of
      the personal information, the potential risk of harm from unauthorised use or disclosure of your personal information, the
      purposes for which we process your personal information, whether we can achieve those purposes through other means
      and the applicable legal requirements.
    </p>
    <p>
      In some circumstances you can ask us to delete your data: see &lsquo;access, erasure and data portability&rsquo; below for further
      information.
    </p>
    <p>
      In some circumstances we may anonymise your personal information (so that it can no longer be associated with you) for
      analytics, research or statistical purposes in which case we may use this anonymised information indefinitely without
      further notice to you.
    </p>

    <h3>Data Transfers</h3>

    <p>
      The countries to which we send data for the purposes listed above may not have the same data protection laws as the
      country in which you initially provided the information. If we transfer your personal information to third parties in other
      countries: (i) we will perform those transfers in accordance with the requirements of applicable law; and (ii) we will protect
      the transferred personal information in accordance with the Privacy Policy, as supplemented by this Appendix.
      Extra rights for EU individuals
    </p>
    <p>
      Objecting to processing: You have the right to object to processing of your personal information that is based on our
      legitimate interests or public interest. If this is done, we must provide compelling legitimate grounds for the processing
      which overrides your interests, rights and freedoms, in order to proceed with the processing of your personal information.
      Restricting processing: You have the right to request that we restrict the processing of your personal information if (i) you
      are concerned about the accuracy of your personal information; (ii) you believe your personal information has been
      unlawfully processed; (iii) you need us to maintain the personal information solely for the purpose of a legal claim; or (iv)
      we are in the process of considering your objection in relation to processing on the basis of legitimate interests.
    </p>
    <p>
      Access, erasure and data portability: You may have the right to request details of the personal information we hold about
      you, or to request that we erase the personal information we hold about you, or that we transfer this information to a third
      party.
    </p>
    <p>
      Rectification: If you believe that any information we hold about you is inaccurate, out of date, incomplete, irrelevant or
      misleading, please contact us using the details below. We will take reasonable steps to promptly correct any information
      found to be inaccurate, incomplete, misleading or out of date.
    </p>
  </div>
</div>
