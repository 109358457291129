import {AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {
  faSearch,
  faPlusCircle,
  faMinusCircle,
  faMapMarkerAlt,
  faBook,
  faComment,
  faShare
} from '@fortawesome/free-solid-svg-icons';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {debounceTime, filter, first, map} from 'rxjs/operators';
import {Subscription, fromEvent} from 'rxjs';
import {MemorySearchResult} from '../../../core/models/memory-search-result.model';
import {MemoryFilterModel} from '../../../core/models/memory-filter.model';
import {IMemory, MemoryModel} from '../../../core/models/memory.model';
import {LoaderService} from '../content-loader/loader.service';
import {LayoutService} from '../../../core/services/layout.service';
import {JarModel} from '../../../core/models/jar.model';
import {FileModel} from '../../../core/models/file.model';
import {LocationPickerComponent} from '../../modals/pickers/location/location-picker.component';
import {HtmlElementService} from '../../../core/services/html-element.service';
import {Bio} from '../../../core/models/bio.model';
import {PublicBioService} from '../../../core/services/public-bio.service';
import {CommentListModelComponent} from '../../modals/comment-list/comment-list-modal.component';
import {select, Store} from '@ngrx/store';
import {BioActions, BioSelectors} from '../../../store/bios';
import {AppState} from '../../../store';
import {MemoryActions, MemorySelectors} from '../../../store/memories';
import {JarPickerComponent} from '../../modals/pickers/jar-picker/jar-picker.component';


@Component({
  selector: 'app-bio-legacy-view',
  templateUrl: 'bio-legacy-view.component.html',
  styleUrls: ['bio-legacy-view.component.css']
})
export class BioLegacyViewComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  private readonly SEARCH_RESULTS_DEFAULT = 4;
  private readonly subscription: Subscription;
  private scrollEvent: Subscription;

  private debounceTimer;

  public faPlusCircle = faPlusCircle;
  public faMinusCircle = faMinusCircle;
  public faSearch = faSearch;
  public faMapMarkerAlt = faMapMarkerAlt;
  public faBook = faBook;
  public faComment = faComment;
  public faShare = faShare;

  // Display Bio
  public bio: Bio;

  // Form Values
  public filters: MemoryFilterModel;
  public keywords: string;
  public jarType: JarModel;

  // Data from API
  public pagedMemories: MemorySearchResult = new MemorySearchResult();
  public isSearching: boolean;
  public noMoreResults: boolean;

  public get MemoryJarTypes(): JarModel[] {
    return this.bio
      ? this.bio.memoryJars
      : [];
  }

  constructor(
    protected route: ActivatedRoute,
    protected store: Store<AppState>,
    protected modalService: NgbModal,
    protected publicBioService: PublicBioService,
    protected htmlService: HtmlElementService,
    protected layoutService: LayoutService,
    protected loaderService: LoaderService
  ) {
    this.isSearching = false;
    this.noMoreResults = true;

    // set the Default Jar Type to All (aka null)
    this.jarType = null;

    this.subscription = new Subscription();

    this.subscription.add(this.store
      .pipe(
        select(BioSelectors.selectDisplayBio)
      )
      .subscribe((bio: Bio) => {
        this.bio = bio;
        if (this.filters == null) {
          this.queueSearch();
        }
      })
    );

    this.subscription.add(this.store
      .pipe(select(MemorySelectors.selectMemorySearchResult))
      .subscribe((searchResults: MemorySearchResult) => {
        this.pagedMemories = searchResults;
      })
    );

  }

  public ngOnInit() {
  }

  public ngOnChanges(changes: SimpleChanges) {
    this.newSearch();
  }

  public ngAfterViewInit(): void {
    if (typeof window !== 'undefined') {
      this.scrollEvent = fromEvent(window, 'scroll')
        .pipe(
          debounceTime(250),
          map(event => event.target)
        )
        .subscribe((document: Document) => {
          this.onScroll(document.scrollingElement);
        });
    }
  }

  public onScroll(element: Element) {
    if (element instanceof Element && this.isSearching === false && this.noMoreResults === false ) {
      const scrollPosition = element.scrollTop + 60;
      const scrollHeight = element.scrollHeight - element.clientHeight;
      if (scrollPosition >= scrollHeight) {
        this.getNextSearchPage();
      }
    }
  }

  // Ensure that the API isn't hammered when checkboxes are being selected
  public queueSearch() {
    if (typeof window !== 'undefined') {
      window.clearTimeout(this.debounceTimer);
      this.debounceTimer = window.setTimeout(() => {
        this.newSearch();
      }, 1000);
    }
  }

  public getNextSearchPage() {

    // Prevent multiple searches
    if (this.isSearching === true || this.noMoreResults === true) {
      return;
    }

    // Check if there are more results pending
    if (this.pagedMemories.count === this.pagedMemories.list.length) {
      this.noMoreResults = true;
      return;
    }

    this.isSearching = true;
    this.filters = {...this.filters, page: (this.filters.page + 1)};
    this.store.dispatch(
      MemoryActions.searchPublicMemoriesRequest({
        bio: this.bio,
        filters: this.filters,
        appendResults: true
      })
    );

    this.isSearching = false;
  }

  public newSearch() {
    if (this.bio == null) {
      return;
    }

    // Reset the filters
    this.filters = new MemoryFilterModel();
    this.filters.includeParents = true;
    this.filters.results = this.SEARCH_RESULTS_DEFAULT;
    this.filters.keywords = this.keywords;

    // Set the Bio ID
    this.filters.bio = this.bio.id;

    // Set the Memory Jar ID
    this.filters.parentNodes = Bio.getJarIds(this.bio.memoryJars);
    if (this.jarType != null) {
      this.filters.parentNodes = [this.jarType.id];
    }

    // Get the new Search result page
    this.noMoreResults = false;

    // Reset the search Results
    this.store.dispatch(
      MemoryActions.searchPublicMemoriesRequest({
        bio: this.bio,
        filters: this.filters
      })
    );
  }

  public showLightbox(memoryOrJar: IMemory, file: FileModel) {
      this.layoutService.showLightbox(memoryOrJar, file);
  }

  public showRestingPin(event) {
    event.preventDefault();
    const modal = this.modalService.open(LocationPickerComponent, {size: 'lg'});
    modal.componentInstance.address = this.bio.restingLocation;
    modal.componentInstance.readonly = true;
  }

  public showComments(event) {
    event.preventDefault();

    const modal = this.modalService.open(CommentListModelComponent, {size: 'lg'});
    modal.componentInstance.type = 'bio';
    modal.componentInstance.conceptKeyword = 'Tribute';
  }

  public showMemoryComments(event, memory: IMemory) {
    event.preventDefault();

    this.store.dispatch(MemoryActions.setCurrentMemoryId({
      id: memory.id
    }));

    const modal = this.modalService.open(CommentListModelComponent, {size: 'lg'});
    modal.componentInstance.type = 'memory';
    modal.componentInstance.conceptKeyword = 'Recollection';
  }


  public addToJarPicker(event: any, memory: IMemory) {
    event.preventDefault();
    const modal = this.modalService.open(JarPickerComponent, {size: 'lg', backdrop: 'static'});
    modal.componentInstance.bio = this.bio;
    modal.componentInstance.memory = memory;
  }


  public ngOnDestroy() {
    if (this.scrollEvent instanceof Subscription) {
      this.scrollEvent.unsubscribe();
    }
    this.subscription.unsubscribe();
    this.store.dispatch( MemoryActions.resetSearchMemoriesRequest() );
  }

}
