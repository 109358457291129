import {IListOption} from '../core/models/option.model';
import {FileModel} from '../core/models/file.model';

export class AppStoreUtils {

  /*
  * This system filters a list by a Parent ID, creating a numeric range
  * e.g. Parent = 2, Child = 201, 202, 203, 204...
  *      Parent = 5, Child = ..507, 508, 509, 510
  */
  public static filterSubTypes(list: IListOption[], typeId: number): IListOption[] {
    return list.filter((item: IListOption) => {
      return (item.type === typeId);
    });

    /*
    const start = typeId * 100;
    const end = start + 99;
    return list.filter((item: OptionModel) => {
      return (item.id >= start && item.id <= end);
    });
    */
  }

  /*
  * Sort a FileModel List by a comparative List of IDs
  */
  public static sortFileListByIds(files: FileModel[], fileIds: number[] ): FileModel[]{
    const fileList: FileModel[] = [];
    for (let i = 0, l = fileIds.length; i < l; i++) {
      const fidx = files.map(f => f.id).indexOf(fileIds[i]);
      if (fidx >= 0) {
        fileList.splice(i, 0, files[fidx]);
      } else {
        fileList.push(files[fidx]);
      }
    }
    return fileList;
  }


}

