import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';


@Component({
  templateUrl: 'alert-popup.component.html'
})
export class AlertPopupComponent {
  @Input() public title: string;
  @Input() public message: string;
  @Input() public okText: string;
  @Input() public classes: string;

  constructor(
    public activeModal: NgbActiveModal
  ) {
    this.title = 'Alert';
    this.message = '-';
    this.okText = 'Ok';
  }

}
