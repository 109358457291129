<ng-template #headerControls>
  <div class="block block-btns text-right mt-sm-2" *ngIf="canEdit()">
    <button (click)="openPasswordModal()" class="btn btn-gold" type="button">
      <fa-icon [icon]="faPlusCircle" class="mr-1"></fa-icon>
      <span class="d-none d-sm-inline">Add Password</span>
      <span class="d-sm-none">New</span>
    </button>
  </div>
</ng-template>

<app-page-header title="Password Jars" [controls]="headerControls"></app-page-header>

<div class="container">

  <div class="row">
    <div class="col-12">

      <div class="row row-page-btns row-page-btns-space row-page-btns-things">
        <div class="btn-group btn-group-toggle" ngbRadioGroup [(ngModel)]="bioJar"
             (ngModelChange)="onBioJarChange()">

          <label *ngFor="let item of PasswordJarTypes; let index = index;" class="btn" ngbButtonLabel>
            <input type="radio" ngbButton [value]="item">
            <div class="sprite sprite-thing-jar"
                 [ngStyle]="(item.icon && item.icon.src) && {'background-image':'url('+item.icon.src+')'}"></div>
            <div class="text">{{ item.title }}</div>
          </label>

        </div>
      </div><!-- /row-page-btns -->

      <div *ngIf="bioJar" class="banner-container">
        <div class="banner banner-thing-jar"
             [ngStyle]="(bioJar.banner && bioJar.banner.src) && {'background-image':'url('+bioJar.banner.src+')'}">
          <div class="banner-title">
            <h1>{{ bioJar.title }}</h1>
          </div>
        </div>
      </div><!-- /banner-container -->

      <div class="card card-bio">
        <div class="card-body">

          <div class="row">
            <div class="col-12">

              <div class="btn-group-filters">

                <div ngbDropdown [autoClose]="'outside'" class="btn-group float-right" role="group">
                  <button ngbDropdownToggle type="button" class="btn dropdown-toggle"
                          aria-haspopup="true" aria-expanded="false">
                    Secret <span class="d-none d-sm-inline-block">Keeper</span>
                    <span *ngIf="filters.secretKeeperTypes.length"
                          class="dd-has-filters">{{ filters.secretKeeperTypes.length }}</span>
                  </button>
                  <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="btnGroupDrop1">

                    <label *ngFor="let item of secretKeeperTypeFilters; let index = index;" class="btn dropdown-item"
                           ngbButtonLabel>
                      <input type="checkbox" ngbButton (change)="queueSearch()"
                             [(ngModel)]="secretKeeperTypeFilters[index].selected" [value]="item.item">
                      {{ item.item.label }}
                    </label>

                  </div>
                </div><!-- /secretKeeper filter -->

                <div ngbDropdown [autoClose]="'outside'" class="btn-group float-right" role="group">
                  <button ngbDropdownToggle type="button" class="btn dropdown-toggle"
                          aria-haspopup="true" aria-expanded="false">
                    Time <span class="d-none d-sm-inline-block">Lock</span>
                    <span *ngIf="filters.timeLockTypes.length"
                          class="dd-has-filters">{{ filters.timeLockTypes.length }}</span>
                  </button>
                  <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="btnGroupDrop1">

                    <label *ngFor="let item of timeLockTypeFilters; let index = index;" class="btn dropdown-item"
                           ngbButtonLabel>
                      <input type="checkbox" ngbButton (change)="queueSearch()"
                             [(ngModel)]="timeLockTypeFilters[index].selected" [value]="item.item">
                      {{ item.item.label }}
                    </label>

                  </div>
                </div><!-- /timeLock filter -->

              </div><!-- /btn-group-filters -->

              <div class="block block-search mt-2 mb-4">
                <div class="input-group">
                  <input type="text" class="form-control" placeholder="Search..." aria-label="Search My LifeJars"
                         [(ngModel)]="keywords" (change)="queueSearch()">
                  <div class="input-group-append">
                    <button class="btn" type="button" (click)="search()">
                      <span class="sr-only">Search</span>
                      <fa-icon [icon]="faSearch"></fa-icon>
                    </button>
                  </div>
                </div>
              </div><!-- /block-search -->


              <div class="memories-list-container">

                <ul class="list list-memories list-memories-passwords">
                  <li *ngFor="let item of pagedPasswords.list" class="item item-memory item-memory-password">
                    <div class="item-inset">
                      <div class="row">
                        <div class="col-sm-12">
                          <h4 class="tag clearfix">

                          <span class="float-right smaller ml-2"><fa-icon [icon]="faEye"></fa-icon>
                            {{ (item.secretKeeper|options: 'secretKeeper')?.label }}</span>

                            <span class="float-right smaller ml-2"><fa-icon [icon]="faClock"></fa-icon>
                              {{ (item.timeLock|options: 'timeLock')?.label}}</span>

                          </h4>
                          <h3 class="title">
                            <span *ngIf="item.isSample"><em>Sample:</em></span>
                            {{ item.title }}
                          </h3>
                        </div>
                      </div>
                      <div class="row row-controls">
                        <div class="col-12 text-left">
                          <button (click)="openPasswordModal( item )" type="button" class="btn btn-sm btn-hollow"
                                  *ngIf="canEdit()">
                            <fa-icon [icon]="faPencilAlt"></fa-icon>
                            Edit
                          </button>
                          <button type="button" class="btn btn-sm btn-hollow ml-2 float-right float-sm-none"
                                  (click)="viewPassword(item)">
                            View
                          </button>
                        </div>
                      </div>

                    </div>
                  </li>
                </ul>

                <app-paginator [page]="pagedPasswords.page" [pages]="pagedPasswords.pages"
                               (pageChange)="onPageChange($event)"></app-paginator>

                <app-content-loader [scope]="'lazy'">Loading...</app-content-loader>
              </div><!-- /memories list -->

              <div class="block block-btns text-center mt-5" *ngIf="canEdit()">
                <button (click)="openPasswordModal()" class="btn btn-gold" type="button">
                  <fa-icon [icon]="faPlusCircle"></fa-icon>
                  Add Password
                </button>
              </div><!-- /block-btns -->

            </div>

          </div>

        </div>
      </div><!--/card -->

    </div>
  </div>

</div><!--/container-->
