import {initialMemoryState, MemoriesState} from './memories.state';
import {Action, createReducer, on} from '@ngrx/store';
import {DefaultMemoryActions, MemoryActions} from './index';
import {AppStoreUtils} from '../app-store.utils';
import {MemorySearchResult} from '../../core/models/memory-search-result.model';
import {MemoryModel} from '../../core/models/memory.model';

const mergeMemoryWithState = (state: MemoriesState, memory: MemoryModel) => {
  if (memory == null) {
    return state;
  }

  const idx = state.memorySearchResult.list.map(m => m.id).indexOf(memory.id);
  if (idx >= 0) {

    // Update
    return {
      ...state,
      currentMemoryId: memory.id,
      memorySearchResult: {
        ...state.memorySearchResult,
        ...{
          list: [
            ...state.memorySearchResult.list.slice(0, idx),
            memory,
            ...state.memorySearchResult.list.slice(idx + 1),
          ]
        }
      }
    };

  } else {

    // Create
    return {
      ...state,
      currentMemoryId: memory.id,
      memorySearchResult: {
        ...state.memorySearchResult,
        ...{
          list: [
            memory,
            ...state.memorySearchResult.list.slice(0)
          ],
          count: state.memorySearchResult.count + 1
        }
      }
    };
  }
};

const reducer = createReducer<MemoriesState>(
  initialMemoryState,

  on(MemoryActions.setCurrentMemoryId, (state, {id}) => {
    return {
      ...state,
      currentMemoryId: id
    };
  }),

  on(MemoryActions.resetSearchMemoriesRequest, (state) => {
    return {
      ...state,
      currentMemoryId: 0,
      memorySearchResult: new MemorySearchResult()
    };
  }),

  on(MemoryActions.searchMemoriesRequestSuccess, (state, {
    memorySearchResult,
    setFirstAsCurrent,
    appendResults
  }) => {

    let newSearchResult = memorySearchResult;
    if (appendResults === true) {
      newSearchResult = {
        ...state.memorySearchResult,
        ...{
          list: [
            ...state.memorySearchResult.list,
            ...memorySearchResult.list
          ]
        }
      };
    }

    if (setFirstAsCurrent && memorySearchResult.list.length > 0) {
      return {
        ...state,
        currentMemoryId: memorySearchResult.list[0].id,
        memorySearchResult: newSearchResult,
      };
    }

    return {...state, memorySearchResult: newSearchResult};
  }),

  on(MemoryActions.saveMemoryRequestSuccess, DefaultMemoryActions.saveDefaultMemorySuccess,
    (state, {responseMessage}) => {
    const memory = responseMessage.data;
    return mergeMemoryWithState(state, memory);
  }),

  on(MemoryActions.deleteMemoryRequestSuccess, DefaultMemoryActions.deleteDefaultMemorySuccess,
    (state, {memory}) => {
    const idx = state.memorySearchResult.list.map(m => m.id).indexOf(memory.id);
    return {
      ...state,
      memorySearchResult: {
        ...state.memorySearchResult,
        ...{
          list: [
            ...state.memorySearchResult.list.slice(0, idx),
            ...state.memorySearchResult.list.slice(idx + 1),
          ],
          count: state.memorySearchResult.count - 1
        }
      }
    };
  }),

  on(MemoryActions.deleteMemoryFileRequest, DefaultMemoryActions.deleteDefaultMemoryFileRequest, (state, {memory, file}) => {
    const midx = state.memorySearchResult.list.map(m => m.id).indexOf(memory.id);
    const fidx = memory.files.map(f => f.id).indexOf(file.id);

    return {
      ...state,
      memorySearchResult: {
        ...state.memorySearchResult,
        ...{
          list: [
            ...state.memorySearchResult.list.slice(0, midx),
            {
              ...memory,
              ...{
                files: [
                  ...memory.files.slice(0, fidx),
                  ...memory.files.slice(fidx + 1),
                ]
              }
            },
            ...state.memorySearchResult.list.slice(midx + 1),
          ]
        }
      }
    };
  }),
  
  on(DefaultMemoryActions.deleteDefaultBannerRequest, (state, {memory}) => {
    const midx = state.memorySearchResult.list.map(m => m.id).indexOf(memory.id);
    return {
      ...state,
      memorySearchResult: {
        ...state.memorySearchResult,
        ...{
          list: [
            ...state.memorySearchResult.list.slice(0, midx),
            {
              ...memory,
              ...{
                banner: null
              }
            },
            ...state.memorySearchResult.list.slice(midx + 1),
          ]
        }
      }
    };
  }),

  on(DefaultMemoryActions.deleteDefaultIconRequest, (state, {memory}) => {
    const midx = state.memorySearchResult.list.map(m => m.id).indexOf(memory.id);
    return {
      ...state,
      memorySearchResult: {
        ...state.memorySearchResult,
        ...{
          list: [
            ...state.memorySearchResult.list.slice(0, midx),
            {
              ...memory,
              ...{
                icon: null
              }
            },
            ...state.memorySearchResult.list.slice(midx + 1),
          ]
        }
      }
    };
  }),

  on(MemoryActions.saveMemoryFileMetaRequestSuccess, DefaultMemoryActions.saveDefaultMemoryFileMetaSuccess,
    (state, {memory, responseMessage}) => {
    const file = responseMessage.data || null;
    if ( file == null ){
      return state;
    }

    const midx = state.memorySearchResult.list.map(m => m.id).indexOf(memory.id);
    const fidx = memory.files.map(f => f.id).indexOf(file.id);
    return {
      ...state,
      memorySearchResult: {
        ...state.memorySearchResult,
        ...{
          list: [
            ...state.memorySearchResult.list.slice(0, midx),
            {
              ...memory,
              ...{
                files: [
                  ...memory.files.slice(0, fidx),
                  {
                    ...memory.files[fidx],
                    ...file
                  },
                  ...memory.files.slice(fidx + 1),
                ]
              }
            },
            ...state.memorySearchResult.list.slice(midx + 1),
          ]
        }
      }
    };
  }),

  on(MemoryActions.sortMemoryFilesRequest, DefaultMemoryActions.sortDefaultMemoryFilesRequest,
    (state, {memory, fileIds}) => {

    const fileList = AppStoreUtils.sortFileListByIds(memory.files, fileIds);

    const midx = state.memorySearchResult.list.map(m => m.id).indexOf(memory.id);
    return {
      ...state,
      memorySearchResult: {
        ...state.memorySearchResult,
        ...{
          list: [
            ...state.memorySearchResult.list.slice(0, midx),
            {
              ...memory,
              ...{
                files: fileList
              }
            },
            ...state.memorySearchResult.list.slice(midx + 1),
          ]
        }
      }
    };
  }),

  on(MemoryActions.uploadMemoryFilesRequestSuccess, DefaultMemoryActions.uploadDefaultMemoryFilesSuccess,
    (state, {memory, files}) => {
    const midx = state.memorySearchResult.list.map(m => m.id).indexOf(memory.id);
    if (midx < 0) {
      throw new Error('Memory doesn\'t exist in the store.');
    }
    return {
      ...state,
      memorySearchResult: {
        ...state.memorySearchResult,
        ...{
          list: [
            ...state.memorySearchResult.list.slice(0, midx),
            {
              ...memory,
              ...{
                files: [
                  ...memory.files,
                  ...files
                ]
              }
            },
            ...state.memorySearchResult.list.slice(midx + 1),
          ]
        }
      }
    };
  }),

  on(MemoryActions.incrementCommentCounter, (state, {memory}) => {
    if (memory == null) {
      return state;
    }
    const newMemory = {
      ...memory,
      ...{
        commentCount: memory.commentCount + 1
      }
    };
    return mergeMemoryWithState(state, newMemory);
  }),

  on(MemoryActions.decrementCommentCounter, (state, {memory}) => {
    if (memory == null) {
      return state;
    }
    const newMemory = {
      ...memory,
      ...{
        commentCount: memory.commentCount - 1
      }
    };
    return mergeMemoryWithState(state, newMemory);
  }),
);

export function MemoriesReducer(state: MemoriesState, action: Action) {
  return reducer(state, action);
}
