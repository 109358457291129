export class TribeFilterModel {
  // Bio ID
  public bio: number;

  // Excluded Bio IDs
  public excludeBios: number[];

  public includeLife = true;
  public includeLegacy = true;

  public keywords: string;
  public page = 0;
  public results = 0;

  constructor() {
    this.bio = null;
    this.excludeBios = [];
    this.includeLife = true;
    this.includeLegacy = true;
    this.keywords = '';
    this.page = 0;
    this.results = 0;
  }
}
