import {AppState} from '../index';
import {createSelector} from '@ngrx/store';
import {MemoryModel} from '../../core/models/memory.model';

export const selectMemoriesState = (state: AppState) => state.memories;

export const selectCurrentMemoryId = createSelector(
  selectMemoriesState,
  state => state.currentMemoryId
);

export const selectCurrentMemory = createSelector(
  selectMemoriesState,
  selectCurrentMemoryId,
  (state, id) =>
    state.memorySearchResult.list.find( (m) => m.id === id )
);

export const selectMemorySearchResult = createSelector(
  selectMemoriesState,
  state => state.memorySearchResult
);

export const selectAllMemories = createSelector(
  selectMemoriesState,
  state => state.memorySearchResult.list
);

export const selectMemories = createSelector(
  selectAllMemories,
  state => state.filter(memory => MemoryModel.isMemory(memory))
);

export const selectThings = createSelector(
  selectAllMemories,
  state => state.filter(memory => MemoryModel.isThing(memory))
);

