<div class="bio-print-container">

  <section *ngIf="bio" class="bio">
    <div class="bio-cover-bg">
      <img src="/assets/img/bg-legacy-cover.jpg" alt="">
    </div>
    <div class="page page-landscape page-bio-standard">

      <table style="width:100%;">
        <tbody>
        <tr>
          <td class="cover" width="50%" align="center" valign="middle">

            <table *ngIf="jarPhotos.length" class="bio-cover-jar-photos">
              <tbody>
              <tr>
                <td align="center" valign="middle" *ngFor="let item of jarPhotos; let index = index;">

                  <div class="img-border">
                    <div class="img-container" style="width:155px;height:335px">
                      <app-file-lazy-loader [memory]="item.jar" [file]="item.photo"></app-file-lazy-loader>
                    </div>
                  </div>

                </td>
              </tr>
              </tbody>
            </table>

            <table class="bio-cover-title">
              <tbody>
              <tr>
                <td align="center" valign="middle">

                  <h2 class="bio-subtitle">The legacy of</h2>


                  <h1 class="bio-name">
                    {{ bio.firstName }}
                    {{ bio.lastName }}
                    <span class="bio-prev-names" *ngFor="let name of bio.previousNames">
                     {{name}}
                    </span>
                  </h1>

                  <div class="bio-aka" *ngIf="bio.preferredNames?.length">
                    <p>Also known as</p>
                    <h2>
                     <span *ngFor="let item of bio.preferredNames; let isLast=last">
                       {{item}}{{isLast ? '' : ' or '}}
                     </span>
                    </h2>
                  </div>

                  <h4 class="bio-lifetime">
                    <span>{{ bio.birthday|date:'MMM d, y' }}</span>
                    to
                    <span>{{ bio.deathday|date:'MMM d, y' }}</span>
                  </h4>

                </td>
              </tr>
              </tbody>
            </table>

          </td>
        </tr>
        </tbody>
      </table>

    </div><!-- /page-memory-standard -->
  </section>


  <section class="memory" *ngFor="let item of pageList; let index = index;"
           [ngClass]="{ 'card-left': (index%2 == 0), 'card-right': (index%2 == 1) }">

    <app-bio-print-page-standard *ngIf="item.template == 'standard'"
                                 [photos]="item.photos"
                                 [date]="item.date"
                                 [location]="item.location"
                                 [title]="item.title"
                                 [text]="item.textLeft"
                                 [page]="(index+1)"
                                 [memory]="item.memory"></app-bio-print-page-standard>

    <app-bio-print-page-photos *ngIf="item.template == 'photos'"
                               [photos]="item.photos"
                               [page]="(index+1)"
                               [memory]="item.memory"></app-bio-print-page-photos>

    <app-bio-print-page-text *ngIf="item.template == 'text'"
                             [textLeft]="item.textLeft"
                             [textRight]="item.textRight"
                             [page]="(index+1)"
                             [memory]="item.memory"></app-bio-print-page-text>
  </section>

</div><!-- /bio-print-container -->
<app-content-loader></app-content-loader>
