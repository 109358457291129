import {Bio} from './bio.model';


export class CommentModel {
  public id: number;
  public bio: number;
  public bioNode: number;
  public bioFrom: Bio;
  public comment: string;
  public createdDate: string;
  public editedDate: string;

  constructor() {
    this.comment = '';
  }

  public static make(obj: object): CommentModel {
    if ( !obj || (Object.keys(obj).length === 0) ) {
      return null; // Object is null or empty
    }
    const model = new CommentModel();
    return {...model, ...obj};
  }

}
