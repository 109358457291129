import {createAction, props} from '@ngrx/store';
import {IMemory, MemoryModel} from '../../core/models/memory.model';
import {ResponseMessage} from '../../core/models/response-message.model';
import {FileModel} from '../../core/models/file.model';
import {MemoryFilterModel} from '../../core/models/memory-filter.model';
import {MemorySearchResult} from '../../core/models/memory-search-result.model';
import {Bio} from '../../core/models/bio.model';

export const setCurrentMemoryId = createAction(
  '[Memories] set current memory id request ',
  props<{ id: number }>()
);

export const resetSearchMemoriesRequest = createAction(
  '[Memories] reset search memories request'
);

export const searchMemoriesRequest = createAction(
  '[Memories] search memories request',
  props<{ filters: MemoryFilterModel, setFirstAsCurrent?: boolean, appendResults?: boolean }>()
);

export const searchPublicMemoriesRequest = createAction(
  '[Memories] search public memories request',
  props<{ bio: Bio, filters: MemoryFilterModel, setFirstAsCurrent?: boolean, appendResults?: boolean }>()
);

export const searchMemoriesRequestSuccess = createAction(
  '[Memories] search memories request success',
  props<{ memorySearchResult: MemorySearchResult, setFirstAsCurrent?: boolean, appendResults?: boolean }>()
);

export const saveMemoryRequest = createAction(
  '[Memories] save memory request',
  props<{ memory: MemoryModel, files: FileModel[] }>()
);

export const saveMemoryRequestSuccess = createAction(
  '[Memories] save memory request success',
  props<{ responseMessage: ResponseMessage }>()
);


export const deleteMemoryRequest = createAction(
  '[Memories] delete memory request',
  props<{ memory: IMemory }>()
);

export const deleteMemoryRequestSuccess = createAction(
  '[Memories] delete memory request success',
  props<{ memory: IMemory, responseMessage: ResponseMessage }>()
);

export const saveMemoryFileMetaRequest = createAction(
  '[Memories] save memory file meta request',
  props<{ memory: IMemory, file: FileModel }>()
);

export const saveMemoryFileMetaRequestSuccess = createAction(
  '[Memories] save memory file meta request success',
  props<{ memory: IMemory, responseMessage: ResponseMessage }>()
);

export const deleteMemoryFileRequest = createAction(
  '[Memories] delete memory file request',
  props<{ memory: IMemory, file: FileModel }>()
);

export const deleteMemoryFileRequestSuccess = createAction(
  '[Memories] delete memory file request success',
  props<{ responseMessage: ResponseMessage }>()
);

export const sortMemoryFilesRequest = createAction(
  '[Memories] sort memory files request',
  props<{ memory: IMemory, fileIds: number[] }>()
);

export const sortMemoryFilesRequestSuccess = createAction(
  '[Memories] sort memory files request success',
  props<{ responseMessage: ResponseMessage }>()
);

export const uploadMemoryFilesRequestSuccess = createAction(
  '[Memories] upload memory files request success',
  props<{ memory: IMemory, files: FileModel[] }>()
);

export const incrementCommentCounter = createAction(
  '[Memories] increment memory comment counter',
  props<{ memory: MemoryModel }>()
);

export const decrementCommentCounter = createAction(
  '[Memories] decrement memory comment counter',
  props<{ memory: MemoryModel }>()
);

export const shareMemoryRequest = createAction(
  '[Memories] share memory request',
  props<{ jar: IMemory, memory: IMemory }>()
);

export const effectError = createAction(
  '[Memories] effect error',
  props<{ error: any }>()
);
