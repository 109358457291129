import {Component, OnInit, OnDestroy, Input} from '@angular/core';
import {faAngleRight} from '@fortawesome/free-solid-svg-icons';

export class BreadcrumbNavItem {
  public route: string;
  public label: string;

  constructor(route: string, label: string ) {
    this.route = route;
    this.label = label;
  }
}

@Component({
  selector: 'app-breadcrumb',
  templateUrl: 'breadcrumb.component.html'
})
export class BreadcrumbComponent {
  @Input() list: BreadcrumbNavItem[];
  public faAngleRight = faAngleRight;
}
