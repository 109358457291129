import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class GeoLocationService {
  private currentCoords: GeolocationPosition;
  public currentCoordsSubject: BehaviorSubject <GeolocationPosition>;

  public get CurrentCoords() { return this.currentCoordsSubject; }

  constructor() {

    // Create a Subscribable source for the currentCoords
    // Note this FIRST!
    this.currentCoordsSubject = new BehaviorSubject<GeolocationPosition>(this.currentCoords);

    if ('geolocation' in navigator) {
      navigator.geolocation.watchPosition((pos: GeolocationPosition) => {
          this.currentCoordsSubject.next(pos);
        },
        (item) => {
          console.log('Position is not available');
        },
        {
          enableHighAccuracy: true,
          maximumAge: 5000, // 5-secs
          timeout: 30000 // 30-secs
        });
    }
  }

}
