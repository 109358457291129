import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Bio} from '../../../core/models/bio.model';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ControlValidationService} from '../../components/control-validation/control-validation.service';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../store';
import {TribeActions} from '../../../store/tribe';
import {OptionsState} from '../../../store/options/options.state';
import {selectOptions, selectRelationshipSubTypeByType} from '../../../store/options/options.selectors';
import {Subscription} from 'rxjs';
import {first} from 'rxjs/operators';
import {IListOption} from '../../../core/models/option.model';
import {Tribe} from '../../../core/models/tribe.model';
import {User} from '../../../core/models/user.model';
import {selectCurrentUser} from '../../../store/users/users.selectors';
import {UserActions} from '../../../store/users';
import {changeEmailRequest} from '../../../store/users/users.actions';

@Component({
  templateUrl: 'email-change-modal.component.html'
})
export class EmailChangeModalComponent implements OnInit, OnDestroy {
  private readonly subscription: Subscription;
  public user: User;
  public editForm: FormGroup;
  public submitted = false;

  // convenience getter for easy access to form fields
  public get f() {
    return this.editForm.controls;
  }

  constructor(
    public activeModal: NgbActiveModal,
    protected modalService: NgbModal,
    protected formBuilder: FormBuilder,
    protected store: Store<AppState>,
  ) {
    this.subscription = new Subscription();

    this.subscription.add(this.store.pipe(select(selectCurrentUser))
      .subscribe((user: User) => {
        this.user = user;
      })
    );
  }

  public ngOnInit() {
    this.editForm = this.formBuilder.group({
      email: ['', [Validators.required, ControlValidationService.emailValidator]],
      emailConfirm: ['', [Validators.required, ControlValidationService.emailValidator]]
    }, {validator: ControlValidationService.RepeatEmailValidator});
  }

  public onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.editForm.invalid) {
      return;
    }

    const model = this.editForm.getRawValue();
    this.store.dispatch(
      UserActions.changeEmailRequest({email: model.email, emailConfirm: model.emailConfirm})
    );

    this.activeModal.close(model);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
