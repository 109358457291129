import {Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import {FileUpload, FileUploaderService} from './file-uploader.service';
import {FileModel} from '../../../core/models/file.model';
import {Options} from 'sortablejs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {map} from 'rxjs/operators';
import {Bio} from '../../../core/models/bio.model';
import {IMemory} from '../../../core/models/memory.model';
import {ConfirmPopupComponent} from '../../modals/confirm-popup/confirm-popup.component';

@Component({
  selector: 'app-file-uploader',
  templateUrl: 'file-uploader.component.html',
  styleUrls: ['file-uploader.component.css']
})
export class FileUploaderComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('fileUploadInput') public fileUploadInput: ElementRef;

  @Input() public multiple: boolean;
  @Input() public removable: boolean;
  @Input() public editable: boolean;
  @Input() public disableUploads: boolean;
  @Input() public fileTypes: string;
  @Input() public maxSize: number; // Megabytes (MB)
  @Input() public maxCount: number; // Number of Uploads

  @Input() public fileList: FileModel[];

  // SortableJS Config
  @Input() public sortable: boolean;
  @Input() public sortOptions: Options;
  protected sortOptionsDefault: Options;

  @Output() public fileSort: EventEmitter<FileModel[]>;
  @Output() public fileClick: EventEmitter<FileModel>;
  @Output() public fileRemove: EventEmitter<FileModel>;
  @Output() public fileEdit: EventEmitter<FileModel>;
  @Output() public fileUploadComplete: EventEmitter<FileModel[]>;
  @Output() public fileError: EventEmitter<Error>;

  // Application specific
  @Input() public bio: Bio;
  @Input() public memory: IMemory;
  @Input() public downloadable: boolean;

  // This indicates that the Public API endpoints should be used
  @Input() public public: boolean;

  // Request the preview or thumbnail from server
  @Input() public preview: boolean;

  public queue: FileUpload[] = [];

  constructor(
    protected modalService: NgbModal,
    protected fileUploader: FileUploaderService
  ) {
    this.multiple = false;
    this.removable = false;
    this.disableUploads = false;
    this.fileTypes = '';
    this.maxSize = 0; // Any size
    this.maxCount = 1;

    this.fileList = [];

    this.fileSort = new EventEmitter<FileModel[]>();
    this.fileClick = new EventEmitter<FileModel>();
    this.fileRemove = new EventEmitter<FileModel>();
    this.fileEdit = new EventEmitter<FileModel>();
    this.fileUploadComplete = new EventEmitter<FileModel[]>();
    this.fileError = new EventEmitter<Error>();

    this.sortOptionsDefault = {
      sort: true,
      disabled: false,
      handle: '.sort-handle',
      filter: '.static',
      preventOnFilter: true,
      onUpdate: (event: any) => {
        this.fileSort.emit(this.fileList);
      },
      onEnd: (event: any) => {
        this.fileList = this.fileList.filter((f) => {
          return f !== null && f !== undefined;
        });
      }
    };

    // Application specific
    this.bio = null;
    this.memory = null;
    this.public = false;
    this.preview = false;
    this.downloadable = false;
    this.sortable = false;
  }

  public ngOnInit(): void {
    // Apply SortableJS Options
    this.sortOptions = {...this.sortOptionsDefault, ...this.sortOptions};

    // Remove NULLs
    this.fileList = (this.fileList || []).filter(f => f != null);
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Copy the Array to allow SortableJS mutation
    this.fileList = [...this.fileList].slice();
  }

  public onThumbClick(file: FileModel) {
    this.fileClick.emit(file);
  }

  public onThumbRemove(file: FileModel) {
    const modal = this.modalService.open(ConfirmPopupComponent, {size: 'sm', backdrop: 'static'});
    modal.componentInstance.title = 'Delete File';
    modal.componentInstance.message = 'Are you sure you want to delete this file?';
    modal.componentInstance.classes = 'danger';
    modal.result.then((result) => {
      this.fileList = (this.fileList || []).filter(f => f !== file);
      this.fileRemove.emit(file);
    }, (reason) => {
    });
  }

  public onThumbEdit(file: FileModel) {
    this.fileEdit.emit(file);
  }

  public fileDrop(e: any) {
    const files = e.target.files;

    if (this.multiple === false) {
      this.queue = [];
    }

    if (this.multiple === true && this.maxCount > 0 && (this.fileList.length + this.queue.length + files.length) > this.maxCount) {
      this.fileError.emit(new Error(`You can only upload ${this.maxCount} files here. Choose your best or most important.`));
      this.fileUploadInput.nativeElement.value = null;
      return;
    }

    for (let i = 0, l = files.length; i < l; i++) {

      if (this.fileUploader.isUnknown(files[i]) === true) {
        this.fileError.emit(new Error(`${files[i].name} is an unsupported file type`));
        continue;
      }

      if (this.maxSize > 0 && this.fileUploader.getMegabytes(files[i]) > this.maxSize) {
        this.fileError.emit(new Error(`${files[i].name} exceeds the ${this.maxSize}MB limit.`));
        continue;
      }

      const f = new FileUpload(files[i]);
      f.type = this.fileUploader.getSimpleType(f.file);
      if (this.fileUploader.isImage(f.file)) {
        let fileReader = new FileReader();
        fileReader.onload = (e2) => {
          f.preview = fileReader.result;
          fileReader = null;
        };
        fileReader.readAsDataURL(f.file);
      }

      this.queue.push(f);
    }

    // Empty the element
    this.fileUploadInput.nativeElement.value = null;
  }

  public fileCancel(file: FileUpload) {
    for (let i = 0, l = this.queue.length; i < l; i++) {
      if (this.queue[i] === file) {
        this.queue[i].abort = true; // Trigger the upload to Abort
        this.queue.splice(i, 1);
        break;
      }
    }
  }

  public fileCancelAll() {
    for (let i = (this.queue.length - 1); i >= 0; i--) {
      this.queue[i].abort = true; // Trigger the upload to Abort
      this.queue.splice(i, 1);
    }
  }

  public hasNewUploads() {
    return this.getNewUploads().length > 0;
  }

  public getNewUploads() {
    const list = [];
    for (let i = 0, l = this.queue.length; i < l; i++) {
      const file = this.queue[i];
      if (file.complete === false && file.abort === false && file.progress === 0) {
        list.push(this.queue[i]);
      }
    }
    return list;
  }

  public hasPendingUploads() {
    return this.getPendingUploads().length > 0;
  }

  public getPendingUploads() {
    const list = [];
    for (let i = 0, l = this.queue.length; i < l; i++) {
      const file = this.queue[i];
      if (file.complete === false && file.abort === false) {
        list.push(this.queue[i]);
      }
    }
    return list;
  }

  public uploadFiles(url: string): Observable<FileModel[]> {
    const newUploads = this.getNewUploads();
    return this.fileUploader.uploadFiles(url, newUploads)
      .pipe(map((files: FileModel[]) => {
        this.fileList.concat(files);
        this.queue = [];
        this.fileUploadComplete.emit(files);
        return files;
      }));
  }

  public ngOnDestroy(): void {
    this.fileCancelAll();
  }
}
