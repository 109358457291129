import {Component, Input} from '@angular/core';
import {AbstractControl, FormControl, FormGroup} from '@angular/forms';
import {ControlValidationService} from './control-validation.service';

@Component({
  selector: 'app-control-validation',
  templateUrl: 'control-validation.component.html'
})
export class ControlValidationComponent {
  @Input() control: FormControl | FormGroup | AbstractControl;
  @Input() submitted: boolean;

  constructor(private validationService: ControlValidationService) {
  }

  public get errorMessage() {
    if (!this.control) {
      console.log('CONTROL IS NULL: ', this.control);
      return null;
    }
    for (const propertyName in this.control.errors) {
      if (this.control.errors.hasOwnProperty(propertyName) && (this.control.touched || this.submitted)) {
        return ControlValidationService.getValidatorErrorMessage(propertyName, this.control.errors[propertyName]);
      }
    }
    return null;
  }
}

@Component({selector: 'form-validation', templateUrl: 'form-validation.component.html'})
export class FormValidationComponent extends ControlValidationComponent {
}
