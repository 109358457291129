
<app-page-header title="Account Features"></app-page-header>

<div class="container component-subscription-package">
  <div class="row">
    <div class="col-12">

      <form-validation [control]="subscriptionForm" [submitted]="submittedShowErrors"></form-validation>
      <form #formSubscription="ngForm" [formGroup]="subscriptionForm" (ngSubmit)="onSubmit()">

        <div class="page-cards mt-2 mb-5">

          <div class="row">
            <div class="col-12 text-center">

              <div ngbRadioGroup class="btn-group btn-group-toggle btn-group-subscriptions mb-4"
                   role="group" aria-label="Plan time frame" formControlName="timeFrameFilter">
                <label ngbButtonLabel class="btn btn-white">
                  <input ngbButton type="radio" autocomplete="off" [value]="1"> Monthly
                </label>
                <label ngbButtonLabel class="btn btn-white">
                  <input ngbButton type="radio" autocomplete="off" [value]="12"> Annual
                  <!--<div class="tooltip tooltip-top">Save 10% &amp; donate 10%</div>-->
                </label>
                <label ngbButtonLabel class="btn btn-white">
                  <input ngbButton type="radio" autocomplete="off" [value]="36"> One-time
                  <!--<div class="tooltip tooltip-right">Early-bird offer</div>-->
                </label>
              </div>

            </div>
          </div>

          <div class="row justify-content-center">

            <div class="col-lg-3 col-sm-6 subscription-package mb-4"
                 [ngClass]="{
                 'is-selected': item.id === selectedPlan?.id,
                 'is-highlight': item.highlight,
                 'is-tier1': item.tier == 1,
                 'is-tier2': item.tier == 2,
                 'is-tier3': item.tier == 3,
                 'is-current': item.productCode == userSubscription?.productCode,
                 'is-vault' : item.productCode == 'BJ-VAULT-01'
                 }"
                 *ngFor="let item of subscriptionPackages | listFilter: filterByTimeFrame: subscriptionForm.value['timeFrameFilter']">

              <div class="card card-bio full-height">
                <div class="card-heading pt-4 pb-4 pl-3 pr-3 {{ item.productCode | lowercase  }}">
                  <h3 [innerHTML]="item.title"></h3>
                </div>
                <div class="card-body p-3 text-centered">
                  <h4 class="price">
                    <sup>{{item.currencyCode}}</sup>{{ item.price | number : '1.2-2' }}<sub *ngIf="item.suffix">/{{item.suffix}}</sub>
                  </h4>

                  <!--<h5 *ngIf="item.months == 36" class="payment-type">* vault after 3-years<br/>at no cost to you</h5>-->
                  <h5 *ngIf="item.months == 12" class="payment-type">paid annually</h5>
                  <h5 *ngIf="item.months == 1" class="payment-type">paid monthly</h5>
                  <h5 *ngIf="item.months == 0" class="payment-type">&nbsp;</h5>

                  <div class="description mt-4" [innerHTML]="item.description"></div>

                  <ng-container *ngIf="item.productCode" [ngSwitch]="item.productCode">

                    <ng-container *ngSwitchCase="userSubscription?.productCode">
                      <div class="current-plan mt-4 p-3">
                        <h4>Current Plan</h4>
                      </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="'BJ-MAST-MONTHLY-01'">
                      <div class="master-class-plan mt-4 p-3">
                        <a class="btn btn-lg btn-block btn-gold max300-center"
                           href="#" data-opf-trigger="p2c217072f65">
                          <h4>Start Now</h4>
                        </a>
                      </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="'BJ-MAST-ANNUAL-01'">
                      <div class="master-class-plan mt-4 p-3">
                        <a class="btn btn-lg btn-block btn-gold max300-center"
                           href="#" data-opf-trigger="p2c217072f65">
                          <h4>Start Now</h4>
                        </a>
                      </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="'BJ-VAULT-01'">
                      <div class="current-plan mt-4 p-3">
                        <h4>Available Later</h4>
                      </div>
                    </ng-container>

                    <ng-container *ngSwitchDefault>
                      <div class="form-group mt-4">
                        <button (click)="changePlan(item)"
                                class="btn btn-lg btn-block btn-gold max300-center">
                          Start Now
                        </button>
                      </div>
                    </ng-container>
                  </ng-container>

                </div>

              </div>
            </div>

          </div>
        </div><!-- /page-cards -->


        <div class="page-cards mt-5 pb-5">
          <div class="row">

            <div class="col-12 text-centered">

              <h2 class="mb-4">Which My LifeJars Account Is Right For You?</h2>

              <p>
                <img class="d-block" src="assets/img/table-feature-comparison.jpg" alt="My LifeJars Account">
              </p>

            </div>

          </div>
        </div>


        <a #anchorElement id="anchorElement" name="anchorElement"></a>
        <div class="page-cards mt-5">
          <div class="row">

            <div class="col-lg-12 col-md-12 col-sm-12 text-centered">

              <h2 class="mb-4">Proudly Supporting</h2>

              <p>With every My LifeJars GOLD Account paid annually or one-time,
              My LifeJars will donate 10% to  one of our nonprofit
              partners of your choice selected from below.</p>

              <div class="sponsor-select btn-group btn-group-toggle mt-4 mb-4"
                   ngbRadioGroup
                   [ngClass]="{ 'disabled-look': requiresPartnerDonation === false }">

                <div class="btn-group" *ngFor="let item of donationPartners; let index = index;">
                  <label class="btn" ngbButtonLabel
                         [ngStyle]="{'background-image':'url(assets/img/sponsors/'+item.imageUrl+')'}">
                    <input type="radio" ngbButton
                           formControlName="donationPartner"
                           [value]="item.id"
                           class="input-bio custom-control-input"
                           [ngClass]="{ 'is-invalid': submittedShowErrors && f.donationPartner.errors }"/>
                    <span class="sr-only">{{ item.title }}</span>
                  </label>
                </div>

              </div>

              <app-control-validation [control]="f.donationPartner"
                                      [submitted]="submittedShowErrors"></app-control-validation>
            </div>

            <!--<div class="col-lg-4 col-md-4 col-sm-12">
              <h2 class="mb-4">Add Coupon Code</h2>
              <div class="form-group" [ngClass]="{'was-validated': coupon != null }">
                <div class="input-group  btn-in-text-box">
                  <input type="text" formControlName="couponCode" class="form-control form-control-lg input-bio"
                         [ngClass]="{ 'is-invalid': submittedShowErrors && f.couponCode?.errors }"
                         placeholder="Coupon Code"/>
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary" type="button" (click)="couponCodeVerify()">Verify
                    </button>
                  </div>
                </div>
                <app-control-validation [control]="f.couponCode"
                                        [submitted]="submittedShowErrors"></app-control-validation>
              </div>
            </div>-->

          </div>
        </div>

        <div class="page-steps mt-5">
          <div class="row">
            <div class="col-12">

              <div class="custom-control custom-checkbox checkbox-xl">
                <input type="checkbox" formControlName="acceptLegals" id="acceptLegalsId"
                       class="input-bio custom-control-input"
                       [ngClass]="{ 'is-invalid': submittedShowErrors && f.acceptLegals.errors }"/>
                <label class="custom-control-label" for="acceptLegalsId">
                  I have read the full <a href="{{showTerms()}}" target="_blank"><strong>Terms & Conditions</strong></a> and
                  understand that My LifeJars is not legally binding on Executors or any
                  other parties. Important documents will be prepared by a Lawyer/Attorney, then stored encrypted
                  in My LifeJars.
                </label>
                <app-control-validation [control]="f.acceptLegals"
                                        [submitted]="submittedShowErrors"></app-control-validation>
              </div>

            </div><!-- /page-steps payment-info-->
          </div>
        </div>

      </form>


      <div class="page-steps mt-5">

        <div class="row" *ngIf="selectedPlan != null && selectedPlan.productCode != userSubscription?.productCode">
          <div class="col-12">
            <h2 class="mb-4">Payment</h2>

            <div class="plan-change-summary mb-4">
              <div class="row">
                <div class="col-6">
                  <div class="row summary-current-plan">
                    <div class="col-3">
                      <label>Old:</label>
                    </div>
                    <div class="col-9">
                      {{ userSubscription.title | stripHtml }}
                      <br/>
                      {{selectedPlan.currencyCode}}{{ userSubscription.price | number : '1.2-2' }}
                      <span *ngIf="userSubscription.suffix">/{{userSubscription.suffix}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="row summary-new-plan">
                    <div class="col-3">
                      <label>New:</label>
                    </div>
                    <div class="col-9">
                      {{ selectedPlan.title | stripHtml }} <span *ngIf="coupon">({{ coupon.code }}) </span>
                      <br/>
                      <span *ngIf="coupon" style="text-decoration: line-through">
                        {{selectedPlan.currencyCode}}{{ selectedPlan.price | number : '1.2-2' }}
                        <span *ngIf="selectedPlan.suffix">/{{selectedPlan.suffix}}</span> &nbsp;
                      </span>
                      <span>
                      {{selectedPlan.currencyCode}}{{ finalAmount | number : '1.2-2' }}
                        <span *ngIf="selectedPlan.suffix">/{{selectedPlan.suffix}}</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div class="row" *ngIf="selectedPlan == null">
          <div class="no-payment-col col-12">

            <button type="button" class="submit-btn btn btn-lg btn-block btn-gold max300-center" disabled>
              Please select a Plan
            </button>

          </div>
        </div>

        <div class="row" *ngIf="selectedPlan != null && finalAmount == 0">
          <div class="no-payment-col col-12">

            <button type="button" class="submit-btn btn btn-lg btn-block btn-gold max300-center"
                    (click)="onFreePlanSubmit()">
              Confirm change
            </button>

          </div>
        </div>

        <div class="row" [ngClass]="{'payments-hide': finalAmount == 0}">

          <!-- <div class="paypal-payment-col col-md-6">
            <p>To pay by PayPal you must have an account</p>
            <div class="form-group">
              <div style="min-height: 100px; text-align: center;">
                <img src="https://www.paypalobjects.com/webstatic/mktg/Logo/pp-logo-200px.png" border="0"
                     alt="PayPal Logo">
              </div>
              <button type="button" class="submit-btn btn btn-lg btn-block btn-gold max300-center"
                      (click)="onPayPalSubmit()">
                Pay with Paypal
              </button>
            </div>
          </div> -->

          <div class="stripe-payment-col col-md-6 offset-md-3">
            <p class="text-center">Enter your Card number to pay securely by Stripe</p>
            <div class="form-group">
              <form novalidate (ngSubmit)="onStripeSubmit()">
                <div style="min-height: 76px; text-align: center;">

                  <ngx-stripe-card
                    [options]="cardOptions"
                    [elementsOptions]="elementsOptions"
                  ></ngx-stripe-card>

                </div>
                <button type="submit" class="submit-btn btn btn-lg btn-block btn-gold max300-center">
                  Pay with card
                </button>
              </form>
            </div>
          </div>

        </div>
      </div>


      <div class="page-cards mt-5 pb-5">
        <div class="row">

          <div class="col-12 text-centered">

            <h2 class="mb-4">PRESERVE Your Account</h2>

            <p>
              A PRESERVE account is FREE and available from 3 years of having a gold
              or one-time account. Keep all your content and add or edit 3 Memories, 3
              Things and 3 Passwords each year. Re-activate a GOLD account for as long
              as it takes to add further content, then change back to PRESERVE.
            </p>

            <p>
              When your Life Profile is transitioned to a Legacy, your content is PRESERVED
              for always, and your account is de-activated. Your Guardians control your
              Legacy according to the permission you have set them and the My
              LifeJars account they have.
            </p>

          </div>

        </div>
      </div>

      <div class="page-cards mt-5">
        <div class="row">

          <div class="col-12 text-centered">
            <p>
              <small>
                * My LifeJars is not Financial or Legal Advice

                <br>My LifeJars is not a financial or legal service. You should always consult
                with a qualified Attorney and Accountant. Vital documents,  passwords,
                and other content is encrypted and stored securely to be easily accessed by your
                Guardians such as Executors, Health Care Proxies and Enduring Guardians depending on permissions you set.
              </small>
            </p>
          </div>

        </div>
      </div>


    </div><!-- /col-12 -->
  </div><!-- /row -->

</div><!--/container-->
