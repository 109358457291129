import {Component, OnDestroy} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../store';
import {Subscription} from 'rxjs';
import {Bio} from '../../../../core/models/bio.model';
import {selectCurrentBio} from '../../../../store/bios/bios.selectors';

@Component({templateUrl: 'bio-type.component.html'})
export class BioTypeComponent implements OnDestroy{
  private readonly subscription: Subscription;
  public bio: Bio;
  constructor(
    protected store: Store<AppState>,
  ) {
    this.subscription = new Subscription();
    this.subscription.add(this.store.pipe(select(selectCurrentBio))
      .subscribe((bio: Bio) => {
        this.bio = bio;
      })
    );
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
