import {Component, Input, OnInit} from '@angular/core';
import {faChevronDown, faChevronUp} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-character-countdown',
  templateUrl: 'character-countdown.component.html',
  styleUrls: ['character-countdown.component.css']
})
export class CharacterCountdownComponent implements OnInit {
  @Input() text: string;
  @Input() maxLength: number;

  constructor() {
    this.text = '';
    this.maxLength = 250;
  }

  public ngOnInit(): void {
    if (this.text == null) {
      this.text = '';
    }
  }
}
