import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {IcoCandleComponent} from './candle/ico-candle.component';
import {IcoImageCountComponent} from './image-count/ico-image-count.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  entryComponents: [],
  declarations: [
    IcoCandleComponent,
    IcoImageCountComponent
  ],
  exports: [
    IcoCandleComponent,
    IcoImageCountComponent
  ],
  providers: []
})
export class IconsModule { }
