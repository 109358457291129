import {Directive, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges} from '@angular/core';
import {BioType} from '../../core/common/enums';

@Directive({
  selector: '[appBioTypeClass]',
})
export class BioTypeDirective implements OnInit, OnChanges {

  @Input()
  public appBioTypeClass: number;

  constructor(private renderer: Renderer2, private hostElement: ElementRef) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    this.renderer.removeClass(this.hostElement.nativeElement, 'is-person');
    this.renderer.removeClass(this.hostElement.nativeElement, 'is-pet');
    switch (this.appBioTypeClass){
      case BioType.Person:
        this.renderer.addClass(this.hostElement.nativeElement, 'is-person');
        break;
      case BioType.Pet:
        this.renderer.addClass(this.hostElement.nativeElement, 'is-pet');
        break;
    }
  }
}
