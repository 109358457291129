<header class="header header-secured">

  <button *ngIf="bio" [ngClass]="{'active':menuActive}" class="hamburger d-md-none" type="button"
          (click)="toggleMenu()" aria-expanded="false" aria-label="Toggle navigation">
    <div class="bar bar-top"></div>
    <div class="bar bar-middle"></div>
    <div class="bar bar-bottom"></div>
  </button>

  <div class="block block-logo">
    <a routerLink="/">
      <img src="/assets/img/mylifejars-logo-sm.png" alt="My LifeJars">
    </a>
  </div>


  <div *ngIf="bio" class="block block-sitewide-search">
    <app-sitewide-search></app-sitewide-search>
  </div>

  <div *ngIf="!bio" class="block block-profile" ngbDropdown placement="bottom-right">
    <div class="profile-name" ngbDropdownToggle aria-haspopup="true" aria-expanded="false">
      <a>
        About
      </a>
    </div>

    <div class="dropdown-menu" ngbDropdownMenu>
      <a class="dropdown-item" routerLink="/login">
        <fa-icon [icon]="faLock"></fa-icon>
        Sign in</a>
      <a class="dropdown-item" routerLink="/register">
        <fa-icon [icon]="faHeart"></fa-icon>
        Sign up</a>
      <div class="dropdown-divider"></div>
      <a class="dropdown-item" href="https://mylifejars.com/">
        <fa-icon [icon]="faInfoCircle"></fa-icon>
        About us</a>
    </div>
  </div>

  <div *ngIf="bio" class="block block-profile" ngbDropdown>

    <div class="profile-name" ngbDropdownToggle aria-haspopup="true" aria-expanded="false">
      <a>
        {{ bio?.preferredNames[0] || bio?.firstName }}  <span class="d-none d-sm-inline">{{ bio?.lastName }}</span>
      </a>
    </div>

    <a routerLink="/profiles/edit" class="profile-thumb" [appBioTypeClass]="bio?.type">
      <app-file-lazy-loader [bio]="bio" [file]="bio?.profile"></app-file-lazy-loader>
    </a>

    <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>

      <h6 class="dropdown-header">Current Profile</h6>
      <a class="dropdown-item" routerLink="/profiles/edit">
        {{ bio?.preferredNames[0] || bio?.firstName }} {{ bio?.lastName }}
      </a>

      <div class="wards" *ngIf="defaultBio && bio.id != defaultBio.id">
        <div class="dropdown-divider"></div>
        <h6 class="dropdown-header">My Profile</h6>
        <a class="dropdown-item" (click)="switchBio(defaultBio)">
          {{ defaultBio.firstName }} {{ defaultBio.lastName }}
        </a>
      </div>

      <div class="wards" *ngIf="userBios?.length">
        <div class="dropdown-divider"></div>
        <h6 class="dropdown-header" (click)="toggleAdmin($event)">My Wards</h6>
        <div class="dropdown-collapsible" [ngClass]="{'collapsed':adminIsVisible}">
          <a *ngFor="let item of userBios | slice:0:4" class="dropdown-item" (click)="switchBio(item)">
            {{ item.firstName }} {{ item.lastName }}
          </a>
          <a *ngIf="userBios.length > 4" class="dropdown-item" routerLink="/profiles/wards">
            <em>... Show more</em>
          </a>
        </div>
      </div>

      <div class="wards" *ngIf="user?.isAdmin">
        <div class="dropdown-divider"></div>
        <h6 class="dropdown-header" (click)="toggleAdmin($event)">Admin</h6>
        <div class="dropdown-collapsible" [ngClass]="{'collapsed':!adminIsVisible}">
          <a class="dropdown-item" routerLink="/admin/memory-jar-defaults">Memory Jar Defaults</a>
          <a class="dropdown-item" routerLink="/admin/memory-defaults">Memory Defaults</a>
          <a class="dropdown-item mt-2" routerLink="/admin/thing-jar-defaults">Thing Jar Defaults</a>
          <a class="dropdown-item" routerLink="/admin/thing-defaults">Thing Defaults</a>
          <a class="dropdown-item mt-2" routerLink="/admin/password-jar-defaults">Password Jar Defaults</a>
          <a class="dropdown-item" routerLink="/admin/password-defaults">Password Defaults</a>
          <!--<a class="dropdown-item" routerLink="/admin/memory-tags">Memory/Thing Tags</a>-->
        </div>
      </div>

      <div class="dropdown-divider"></div>

      <a class="dropdown-item" (click)="showWardModal($event)">
        <fa-icon [icon]="faPlusCircle"></fa-icon>
        Add ward</a>
      <a class="dropdown-item" routerLink="/settings">
        <fa-icon [icon]="faCog"></fa-icon>
        My Settings</a>
      <a class="dropdown-item" routerLink="/" (click)="logout()">
        <fa-icon [icon]="faSignOutAlt"></fa-icon>
        Sign out</a>
    </div>

  </div>

</header><!-- /header-secured -->


