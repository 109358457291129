import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {
  faCalendar, faMapMarkerAlt, faTag,
  faUserLock, faLock, faClock, faEye, faEyeSlash
} from '@fortawesome/free-solid-svg-icons';
import {MemoryModel} from '../../../../core/models/memory.model';
import {Bio} from '../../../../core/models/bio.model';
import {FileModel} from '../../../../core/models/file.model';
import {LayoutService} from '../../../../core/services/layout.service';
import {JarModel} from '../../../../core/models/jar.model';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../store';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {PasswordModel} from '../../../../core/models/password.model';
import {PasswordsActions, PasswordsSelectors} from '../../../../store/passwords';
import {JarActions, JarSelectors} from '../../../../store/jars';
import {MemoryActions} from '../../../../store/memories';
import {ModerationStatus} from '../../../../core/common/enums';

@Component({
  templateUrl: 'password-view-modal.component.html'
})
export class PasswordViewModalComponent implements OnInit {
  protected readonly subscription: Subscription;

  @Input()
  public bio: Bio;

  @Input()
  public memory: MemoryModel;
  public password: PasswordModel;
  public showPassword = false;

  public jar: JarModel;

  public faCalendar = faCalendar;
  public faMapMarker = faMapMarkerAlt;
  public faTag = faTag;
  public faUserLock = faUserLock;
  public faLock = faLock;
  public faClock = faClock;
  public faEye = faEye;
  public faEyeSlash = faEyeSlash;

  constructor(
    public activeModal: NgbActiveModal,
    protected modalService: NgbModal,
    protected store: Store<AppState>,
    protected layoutService: LayoutService
  ) {
    this.jar = new JarModel();
    this.memory = new MemoryModel();
    this.password = null; // new PasswordModel();

    this.subscription = new Subscription();
    this.subscription.add(this.store
      .pipe(
        select(JarSelectors.selectCurrentJar),
        filter((jar: JarModel) => jar != null)
      )
      .subscribe((jar: JarModel) => {
        this.jar = jar;
      })
    );

    this.subscription.add(this.store.pipe(
      select(PasswordsSelectors.selectCurrentPassword)
      )
        .subscribe((password: PasswordModel) => {
          this.password = password;
        })
    );

  }

  public ngOnInit() {

    // Existing Memory so Subscribe to the store
    if (this.memory.id) {

      // Do this first (So we don't end up with the wrong one)
      this.store.dispatch(
        MemoryActions.setCurrentMemoryId({ id: this.memory.id })
      );

      // Do this too so the Parent Node context is correct
      this.store.dispatch(
        JarActions.setCurrentJarId({ id: this.memory.parentNode })
      );

      // If there is an existing password, we'll retrieve it
      if (this.memory.passwordId != null) {
        this.store.dispatch(PasswordsActions.getCurrentPassword({
          id: this.memory.passwordId
        }));
      }

    }
  }

  public onShowPassword(event) {
    this.showPassword = !this.showPassword;
  }

  public showLightbox(file: FileModel) {
      this.layoutService.showLightbox(this.memory, file);
  }

  public dismissModal(data: any = null) {
    // Clear any stored passwords.
    this.store.dispatch(
      PasswordsActions.setCurrentPassword({password: null})
    );
    this.activeModal.close(data);
  }

}
