import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {AlertService} from '../../../../shared/components/alert/alert.service';
import {BreadcrumbNavItem} from '../../../../shared/components/breadcrumb/breadcrumb.component';

import {faSearch, faPlusCircle, faPencilAlt, faCaretRight} from '@fortawesome/free-solid-svg-icons';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {BioService} from '../../../../core/services/bio.service';
import {Bio} from '../../../../core/models/bio.model';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {selectCurrentBio} from '../../../../store/bios/bios.selectors';
import {AppState} from '../../../../store';
import {LayoutService} from '../../../../core/services/layout.service';
import {selectBioUsers} from '../../../../store/bio-users/bio-users.selectors';
import {BioUser} from '../../../../core/models/bio-user.model';
import {BioSearchModalComponent, SearchMode} from '../../../../shared/modals/bio-search/bio-search-modal.component';
import {BioUserRolesModalComponent} from '../../../../shared/modals/bio-user-roles/bio-user-roles-modal.component';
import {ConfirmPopupComponent} from '../../../../shared/modals/confirm-popup/confirm-popup.component';
import {BioUserActions} from '../../../../store/bio-users';

@Component({templateUrl: 'guardians.component.html'})
export class GuardiansComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly subscription: Subscription;

  public bio: Bio;
  public bioUsers: BioUser[];
  public guardianNotes: string = '';

  public faSearch = faSearch;
  public faPlusCircle = faPlusCircle;
  public faPencilAlt = faPencilAlt;
  public faCaretRight = faCaretRight;

  constructor(
    protected router: Router,
    protected store: Store<AppState>,
    protected modalService: NgbModal,
    protected alertService: AlertService,
    protected bioService: BioService,
    protected layoutService: LayoutService
  ) {

    this.layoutService.setTitle( `Guardians - My LifeJars`  );

    this.subscription = new Subscription();

    this.subscription.add(this.store
      .pipe(
        select(selectCurrentBio),
        filter( (bio: Bio) => bio != null )
      )
      .subscribe((bio: Bio) => {
        this.bio = bio;
      })
    );

    this.bioUsers = [];
    this.subscription.add(this.store.pipe(select(selectBioUsers))
      .subscribe((bioUsers: BioUser[]) => {
        this.bioUsers = bioUsers; // aka Guardians
      })
    );
  }

  public ngOnInit() {
  }

  public ngAfterViewInit(): void {
    this.layoutService.showTutorial('guardiansTutorial');
  }

  public openGuardianSearchModal(event: any) {
    event.preventDefault();

    const modal = this.modalService.open(BioSearchModalComponent, {});
    modal.componentInstance.searchMode = SearchMode.Users;
    modal.componentInstance.isPicker = true;
    modal.componentInstance.bio = this.bio;
    modal.result.then((bio: Bio) => {

      // Create new Bio User and Open Permissions
      const bioUser = new BioUser();
      bioUser.bio = this.bio;
      bioUser.user = bio.user;
      bioUser.userBio = bio;
      this.openGuardianPermissionsModal(bioUser);

    }, (reason) => {
      console.log(reason);
    });
  }

  public openGuardianPermissionsModal(bioUser: BioUser) {
    const modal = this.modalService.open(BioUserRolesModalComponent, {size: 'lg', backdrop: 'static'});
    modal.componentInstance.bio = this.bio;
    modal.componentInstance.bioUser = bioUser;
  }

  public removeGuardian(bioUser: BioUser) {

    const modal = this.modalService.open(ConfirmPopupComponent, {backdrop: 'static'});
    modal.componentInstance.title = 'Remove this Guardian ';
    modal.componentInstance.message = 'Are you sure you want to remove this Guardian?';
    modal.componentInstance.classes = 'danger';
    modal.result.then((result) => {
      this.store.dispatch(
        BioUserActions.deleteBioUserRequest({bioUser})
      );
    }, (reason) => {

    });
  }

  public updateGuardianNotes() {
    console.log('Guardian Notes:', this.guardianNotes);
    //this.store.dispatch(
     //BioUserActions.updateBioRequest({id: this.bio.id, notes: this.guardianNotes})
    //);
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
