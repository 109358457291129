
<div class="bg bg-cover"></div>

<div class="card card-bio card-bio-login">

  <div class="card-body">

    <div class="card-body-title text-center">
      <h2 class="text-gold font-weight-bold pb-3 mb-3">Reset password</h2>
      <p>Find your My LifeJars account</p>
    </div>

    <form [formGroup]="passwordResetForm" (ngSubmit)="onSubmit()">

      <div class="form-group">
        <label class="control-label sr-only">Email</label>
        <input type="text" formControlName="username" class="form-control form-control-lg input-bio"
               [ngClass]="{ 'is-invalid': submitted && f.username.errors }" placeholder="Email" />
        <app-control-validation [control]="f.username" [submitted]="submitted"></app-control-validation>
      </div>

      <div class="form-group">
        <button class="btn btn-lg btn-block btn-gold">
          Reset Password
        </button>
      </div>

      <app-junk-folder-alert [showAlert]="showJunkMailAlert"></app-junk-folder-alert>

      <div class="form-group small">
        <div class="row">
          <div class="col-12 text-center">
            <a class="text-black font-weight-bold" routerLink="/login" >&laquo; Back to Sign in</a>
          </div>
        </div>
      </div>

      <div class="form-group small">
        <div class="row">
          <div class="col-12 text-center">
            New to My LifeJars? <a class="text-black font-weight-bold"  routerLink="/create-account">Sign up &raquo;</a>
          </div>
        </div>
      </div>

    </form>

    <app-content-loader></app-content-loader>
  </div>
</div>
