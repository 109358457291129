import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {faCalendar, faMapMarkerAlt, faQuestionCircle, faUsers} from '@fortawesome/free-solid-svg-icons';
import {AlertService} from '../../../components/alert/alert.service';
import {TribesterPickerComponent} from '../../pickers/tribster/tribester-picker.component';
import {MemoryModel} from '../../../../core/models/memory.model';
import {filter, first} from 'rxjs/operators';
import {MemoryService} from '../../../../core/services/memory.service';
import {AppDateFormatter} from '../../../../core/format/date.format';
import {FileUploaderComponent} from '../../../components/file-uploader/file-uploader.component';
import {Bio} from '../../../../core/models/bio.model';
import {JarModel} from '../../../../core/models/jar.model';
import {LocationPickerComponent} from '../../pickers/location/location-picker.component';
import {ConfirmPopupComponent} from '../../confirm-popup/confirm-popup.component';
import {ResponseMessage} from '../../../../core/models/response-message.model';
import {FileModel} from '../../../../core/models/file.model';
import {LayoutService} from '../../../../core/services/layout.service';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../store';
import {BioActions} from '../../../../store/bios';
import {Subscription} from 'rxjs';
import {OptionsState} from '../../../../store/options/options.state';
import {MemoryActions, MemorySelectors} from '../../../../store/memories';
import {OptionSelectors} from '../../../../store/options';
import {selectCurrentUserSubscription} from '../../../../store/users/users.selectors';
import {UserSubscription} from '../../../../core/models/user-subscription.model';
import {FileEditModalComponent} from '../../file-edit/file-edit-modal.component';
import {CommonText} from '../../../../core/common/common-text';

@Component({
  templateUrl: 'memory-edit-modal.component.html'
})
export class MemoryEditModalComponent implements OnInit, OnDestroy {
  protected readonly subscription: Subscription;
  protected CONCEPT_KEYWORD: string;

  public readonly CommonText = CommonText;

  public readonly DESCRIPTION_LENGTH = 10000;

  @ViewChild(FileUploaderComponent)
  public fileUploader: FileUploaderComponent;

  @ViewChild('secretKeeper')
  public secretKeeper: ElementRef;

  @ViewChild('timeLock')
  public timeLock: ElementRef;

  @Input()
  public bio: Bio;

  @Input()
  public memory: MemoryModel;

  public editForm: FormGroup;
  public submitted = false;
  public faCalendar = faCalendar;
  public faMapMarker = faMapMarkerAlt;
  public faQuestionCircle = faQuestionCircle;
  public faUsers = faUsers;
  protected debounceTimer;

  public jar: JarModel;
  public optionsData: OptionsState;
  public userSubscription: UserSubscription;

  // convenience getter for easy access to form fields
  public get f() {
    return this.editForm.controls;
  }

  public get MemoryJarTypes(): JarModel[] {
    return this.bio.memoryJars;
  }

  public get MemoryTagTypes() {
    return this.optionsData.memoryTagTypes;
  }

  public get SecretKeeperTypes() {
    return this.optionsData.secretKeeperTypes;
  }

  public get TimeLockTypes() {
    return this.optionsData.timeLockTypes;
  }

  constructor(
    public activeModal: NgbActiveModal,
    protected formBuilder: FormBuilder,
    protected modalService: NgbModal,
    protected store: Store<AppState>,
    protected memoryService: MemoryService,
    protected dateFormatter: AppDateFormatter,
    protected layoutService: LayoutService,
    protected alertService: AlertService
  ) {
    this.CONCEPT_KEYWORD = 'Memory';

    this.jar = new JarModel();
    this.memory = new MemoryModel();

    this.subscription = new Subscription();
    this.optionsData = new OptionsState();
    this.subscription.add(this.store.pipe(select(OptionSelectors.selectOptions))
      .subscribe((options: OptionsState) => {
        this.optionsData = options;
      })
    );

    this.subscription.add(this.store.pipe(select(selectCurrentUserSubscription))
      .subscribe((userSub: UserSubscription) => {
        this.userSubscription = userSub;
      })
    );

  }

  public ngOnInit() {

    // Existing Memory so Subscribe to the store
    if (this.memory.id) {
      this.subscription.add(this.store
        .pipe(
          select(MemorySelectors.selectCurrentMemory),
          filter((memory: MemoryModel) => memory != null)
        )
        .subscribe((memory: MemoryModel) => {
          this.memory = memory;
        })
      );
    }

    this.editForm = this.formBuilder.group({
      title: ['', [Validators.required, Validators.maxLength(250)]],
      parentNode: [null, Validators.required], // aka Memory Jar
      memoryTag: [null, Validators.required],
      secretKeeper: [null, Validators.required],
      timeLock: [null, Validators.required],
      location: [''],
      date: [''],
      description: ['', [Validators.maxLength(this.DESCRIPTION_LENGTH)]],
      allowComments: [true],
      allowShare: [true]
    });

    this.editForm.patchValue(this.memory);

    // ngBootstrap Dates need to be in a 'stupid' format
    const ngbDate = this.dateFormatter.parse(this.memory.date, 'YYYY-MM-DD');
    this.editForm.get('date').setValue(ngbDate);

    // Update the jars on Init
    this.onJarChange(null);
  }

  public onJarChange(event) {
    const jarId = this.editForm.get('parentNode').value;
  }

  public onLocationChange(event) {
    event.target.blur(); // Without this we get exception: ExpressionChangedAfterItHasBeenCheckedError
    // event.preventDefault();

    const item = event.target.value;
    const modal = this.modalService.open(LocationPickerComponent, {size: 'lg', backdrop: 'static'});
    modal.componentInstance.address = this.memory.location;
    modal.result.then((result) => {
      this.editForm.get('location').setValue(result);
    }, (reason) => {
      console.log('Negative: ', reason);
    });
  }

  public enableTribeEditor(){
    const item: number = this.editForm.get('secretKeeper').value;
    return item === 4; // Tribester Only
  }

  public showTribeEditor(event){
    if (this.enableTribeEditor() === false) {
      return;
    }

    const modal = this.modalService.open(TribesterPickerComponent, {backdrop: 'static'});
    modal.componentInstance.bio = this.bio;
    modal.componentInstance.tribesters = [ ...[], ...this.memory.tribesters ];
    modal.result.then((tribesters: Bio[]) => {
      this.memory = {
        ...this.memory,
        ...{
          tribesters
        }
      };
      this.secretKeeper.nativeElement.focus();
    }, (reason) => {
      this.secretKeeper.nativeElement.focus();
    });
  }

  public onSecretKeeperChange(event) {
    event.target.blur(); // Without this we get exception: ExpressionChangedAfterItHasBeenCheckedError
    if (this.enableTribeEditor() === false) { // Tribester Only
      return;
    }
    return this.showTribeEditor(event);
  }

  public onTimeLockChange(event) {
    event.target.blur();
    // event.preventDefault();
  }

  public sortFiles(files: FileModel[]) {
    window.clearTimeout(this.debounceTimer);
    this.debounceTimer = window.setTimeout(() => {
      const fileIds = files.filter(f => f != null).map(f => f.id);
      this.store.dispatch(
        MemoryActions.sortMemoryFilesRequest({
          memory: this.memory,
          fileIds
        })
      );
    }, 1000);
  }

  public removeFile(file: FileModel) {
    this.store.dispatch(
      MemoryActions.deleteMemoryFileRequest({
        memory: this.memory,
        file
      })
    );
  }

  public editFile(file: FileModel){
    const modal = this.modalService.open(FileEditModalComponent, {size: 'md', backdrop: 'static'});
    modal.componentInstance.bio = this.bio;
    modal.componentInstance.memory = this.memory;
    modal.componentInstance.file = file;
  }

  public delete(event: any) {
    event.preventDefault();

    const modal = this.modalService.open(ConfirmPopupComponent, {size: 'sm', backdrop: 'static'});
    modal.componentInstance.title = 'Delete ' + this.CONCEPT_KEYWORD;
    modal.componentInstance.message = 'Are you sure you want to delete this ' + this.CONCEPT_KEYWORD + '?';
    modal.componentInstance.classes = 'danger';
    modal.result.then((result) => {
      this.store.dispatch(
        MemoryActions.deleteMemoryRequest({
          memory: this.memory
        })
      );
      this.activeModal.close(null);
    }, (reason) => {

    });
  }

  public showLightbox(file: FileModel) {
      this.layoutService.showLightbox(this.memory, file);
  }

  public showError(error: Error) {
    this.alertService.warning([error.message]);
  }

  public onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.editForm.invalid) {
      return;
    }

    const model = {...this.memory, ...this.editForm.getRawValue()};

    this.memoryService.saveMemory(model)
      .pipe(first())
      .subscribe(
        (responseMessage: ResponseMessage) => {
          const memory = responseMessage.data;

          this.store.dispatch(
            MemoryActions.saveMemoryRequestSuccess({
              responseMessage
            })
          );

          // Increase Bio Stats (if its a new memory)
          if (!this.memory.id) {
            this.store.dispatch(
              BioActions.incrementCurrentBioMemoryCounter({memory})
            );
          }

          if (this.fileUploader.hasPendingUploads()) {

            this.fileUploader.uploadFiles(this.memoryService.saveFilesUrl(memory))
              .pipe(first())
              .subscribe((files: FileModel[]) => {

                this.store.dispatch(
                  MemoryActions.uploadMemoryFilesRequestSuccess({
                    memory,
                    files
                  })
                );

                // Complete
                this.afterSubmit(memory);
              });

          } else {
            this.afterSubmit(memory);
          }

        });
  }

  public afterSubmit(data: any) {
    this.activeModal.close(data);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
