
<div class="bg bg-cover"></div>

<div class="card card-bio card-bio-login">

  <div class="card-body">

    <div class="card-body-title text-center">
      <h2 class="text-gold font-weight-bold pb-3 mb-3">Verify Identity</h2>
      <p>
        We've sent you an email with a one-time code to verify your identity.
      </p>
      <p>
        2 Factor Authentication, or 2FA, significantly improves login security to your My LifeJars.
        Manage this additional security from your settings menu.
      </p>
    </div>

    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">

      <div class="form-group">
        <label class="control-label sr-only">One-time code</label>
        <input autocomplete="off" type="text" formControlName="code" class="form-control form-control-lg input-bio text-center"
               [ngClass]="{ 'is-invalid': submitted && f.code.errors }" placeholder="One-time code" aria-label="One-time code" />
        <app-control-validation [control]="f.code" [submitted]="submitted"></app-control-validation>
      </div>

      <div class="form-group small">
        <div class="row">
          <div class="col-12 text-center">
            <a href="#" (click)="onResendCode($event)">Resend the one-time code?</a>
          </div>
        </div>
      </div>

      <app-junk-folder-alert [showAlert]="true"></app-junk-folder-alert>

      <div class="form-group">
        <button [disabled]="loading" class="btn btn-lg btn-block btn-gold">
          Sign in
        </button>
      </div>

      <div class="form-group small">
        <div class="row">
          <div class="col-12 text-center">
            New to My LifeJars? <a class="text-gold font-weight-bold" routerLink="/create-account">Sign up &raquo;</a>
          </div>
        </div>
      </div>
    </form>

    <app-content-loader></app-content-loader>
  </div>
</div>
