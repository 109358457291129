<ng-template #headerControls>
  <div class="block block-btns text-right mt-sm-2">
    <button (click)="takeATour($event)" class="btn btn-gold" type="button">
      <fa-icon [icon]="faPlay" class="mr-1"></fa-icon>
      <span class="d-inline d-sm-none">Tour</span>
      <span class="d-none d-sm-inline">Take a tour</span>
    </button>
  </div>
</ng-template>

<app-page-header title="Dashboard" [controls]="headerControls"></app-page-header>

<div class="container">

  <div class="block block-notifications"
       [ngClass]="{ 'mb-5': tribeActivity?.guardians.length || tribeActivity?.pending.length }">
    <div class="guardian-pending-container mt-3">
      <app-guardian-request-list [activityList]="tribeActivity?.guardians"
                                 (requestResponse)="requestResponse($event)"></app-guardian-request-list>
    </div>
    <div class="tribe-pending-container mt-3">
      <app-tribe-activity-list [activityList]="tribeActivity?.pending"
                               (requestResponse)="requestResponse($event)"></app-tribe-activity-list>
    </div>
  </div>

  <div class="row">

    <div class="col-md-4 mb-5">
      <app-dashboard-card
        title="Life Journal"
        route="/jars/memories"
        thumbSrc="/assets/img/dash-memories.jpg"
        tutorialRef="memoryJarsTutorial"
        documentationRef="memoryJarsTutorial"
      ></app-dashboard-card>
    </div>

    <div class="col-md-4 mb-5">
      <app-dashboard-card
        title="Store Documents & Things"
        route="/jars/things"
        thumbSrc="/assets/img/dash-things.jpg"
        tutorialRef="thingsJarsTutorial"
        documentationRef="thingsJarsTutorial"
      ></app-dashboard-card>
    </div>

    <div class="col-md-4 mb-5">
      <app-dashboard-card
        title="Add Passwords & Login Details"
        route="/jars/passwords"
        thumbSrc="/assets/img/dash-passwords.jpg"
        tutorialRef="passwordJarsTutorial"
        documentationRef="passwordJarsTutorial"
      ></app-dashboard-card>
    </div>

    <div class="col-md-4 mb-5">
      <app-dashboard-card
        title="Create An Online Memorial"
        route="/profiles/wards"
        thumbSrc="/assets/img/dash-memorial.jpg"
        tutorialRef="onlineMemorialTutorial"
        documentationRef="onlineMemorialTutorial"
      ></app-dashboard-card>
    </div>

    <div class="col-md-4 mb-5">
      <app-dashboard-card
        title="Invite Family & Friends"
        route="/profiles/life-wall"
        thumbSrc="/assets/img/dash-tribe.jpg"
        tutorialRef="tribeTutorial"
        documentationRef="tribeTutorial"
      ></app-dashboard-card>
    </div>

    <div class="col-md-4 mb-5">
      <app-dashboard-card
        title="Assign Guardians"
        route="/profiles/guardians"
        thumbSrc="/assets/img/dash-guardians.jpg"
        tutorialRef="guardiansTutorial"
        documentationRef="guardiansTutorial"
      ></app-dashboard-card>
    </div>

    <div class="col-md-4 mb-5">
      <app-dashboard-card
        title="View a sample Digital Legacy"
        route="/public/legacy/nelson-mandela"
        thumbSrc="/assets/img/dash-legacy-bio.jpg"
        tutorialRef="digitalLegacyTutorial"
        documentationRef="digitalLegacyTutorial"
      ></app-dashboard-card>
    </div>

    <div class="col-md-4 mb-5">
      <app-dashboard-card
        title="View a sample Life Profile"
        route="/profiles/{{this.bio?.slug}}"
        thumbSrc="/assets/img/dash-life-bio.jpg"
        tutorialRef="lifeJournalTutorial"
        documentationRef="lifeJournalTutorial"
      ></app-dashboard-card>
    </div>

    <div class="col-md-4 mb-5">
      <app-dashboard-card
        title="Edit a My LifeJars Profile"
        route="/profiles/edit"
        thumbSrc="/assets/img/dash-edit-bio.jpg"
        tutorialRef="editBioTutorial"
        documentationRef="editBioTutorial"
      ></app-dashboard-card>
    </div>

  </div>


  <div class="card card-bio mt-4">
    <div class="card-body">
      <h2 class="mb-4">My LifeJars Video Tutorials</h2>
      <p>
        <button class="btn btn-sm btn-gold float-right ml-2" (click)="hideTutorials($event)">Hide all</button>
        <button class="btn btn-sm btn-gold float-right" (click)="showTutorials($event)">Show all</button>
        Would you like to see all "pop-up" video tutorials again?
      </p>
      <app-content-loader scope="lazy">Saving...</app-content-loader>
    </div>
  </div><!--/card -->

</div><!--/container-->
