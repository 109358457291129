import {JarModel} from '../../core/models/jar.model';

export class JarsState {
  currentJarId: number;
  byId: JarsById;
}

export const initialJarState: JarsState = {
  currentJarId: 0,
  byId: {},
};

export class JarsById {
  [id: number]: JarModel;
}
