<ng-template #headerControls>
  <div class="block block-btns text-right mt-sm-2" *ngIf="canEdit()">
    <button (click)="openMemoryModal()" class="btn btn-gold" type="button">
      <fa-icon [icon]="faPlusCircle" class="mr-1"></fa-icon>
      <span class="d-none d-sm-inline">Add Memory</span>
      <span class="d-sm-none">New</span>
    </button>
  </div>
</ng-template>

<app-page-header title="Memory Jars" [controls]="headerControls"></app-page-header>

<div class="container">
  <div class="row">
    <div class="col-12">

      <div class="row row-page-btns row-page-btns-space" *ngIf="bio?.type === 1">
        <div class="btn-group btn-group-toggle" ngbRadioGroup [(ngModel)]="bioJar"
             (ngModelChange)="onBioJarChange();">

          <label *ngFor="let item of MemoryJarTypes; let index = index;" class="btn btn-white" ngbButtonLabel>
            <input type="radio" ngbButton [value]="item">
            <div class="sprite sprite-bios"
                 [ngStyle]="(item.icon && item.icon.src) && {'background-image':'url('+item.icon.src+')'}"></div>
            <span class="d-none d-sm-block">{{ item.title }}</span>
          </label>

        </div>
      </div><!-- /row-page-btns -->


      <div class="row">
        <div class="col-sm-12 col-md-4">

          <div class="card card-bio h-100">
            <div class="card-body">

              <div *ngIf="this.bioJar" class="memory-jar">
                <div class="memory-jar-preview">
                  <app-file-lazy-loader [memory]="bioJar" [file]="bioJar?.files[0]"
                                        (click)="showLightbox(bioJar?.files[0], bioJar)"></app-file-lazy-loader>

                  <button type="button" class="btn btn-sm btn-hollow" (click)="openMemoryJarModal()">
                    <fa-icon [icon]="faPencilAlt"></fa-icon>
                    <span class="sr-only">Edit</span>
                  </button>
                </div>

                <div *ngIf="bioJar.title=='Childhood'">
                  <h1 class="memory-jar-title">My life as a Child</h1>
                  <h2 class="memory-jar-subtitle">Birth to Teenager</h2>
                </div>

                <div *ngIf="bioJar.title=='Teenager'">
                  <h1 class="memory-jar-title">My life as a Teenager</h1>
                  <h2 class="memory-jar-subtitle">13 to 19 years old</h2>
                </div>

                <div *ngIf="bioJar.title=='Young Adult'">
                  <h1 class="memory-jar-title">My life as a Young Adult</h1>
                  <h2 class="memory-jar-subtitle">20 to 29 years old</h2>
                </div>
                <div *ngIf="bioJar.title=='Adult'">
                  <h1 class="memory-jar-title">My life as an Adult</h1>
                  <h2 class="memory-jar-subtitle">30 to 64 years old</h2>
                </div>
                <div *ngIf="bioJar.title=='Later Life'">
                  <h1 class="memory-jar-title">My Later Life</h1>
                  <h2 class="memory-jar-subtitle">65 and beyond...</h2>
                </div>

                <div class="memory-jar-description placeholder">

                  <app-expandable-text [text]="(bioJar.description || bioJar.placeholder)"
                                       [minLength]="200"></app-expandable-text>

                </div>

                <button type="button" class="btn btn-sm btn-hollow" (click)="openMemoryJarModal()"
                        *ngIf="canEdit()">
                  <fa-icon [icon]="faPencilAlt"></fa-icon>
                  Edit
                </button>

                <div class="file-uploader-container mt-4">
                  <app-file-uploader [fileList]="bioJar?.files" [memory]="bioJar" preview="true"
                                     (fileClick)="showLightbox( $event, bioJar )"
                  ></app-file-uploader>
                </div>
              </div><!-- /memory-jars-jar -->

            </div>
          </div><!--/card -->

        </div>
        <div class="col-sm-12 col-md-8">

          <div class="card card-bio h-100">
            <div class="card-body">

              <div class="btn-group-filters">
                <div class="btn-group">
                  <button type="button" class="btn" (click)="resetFilters()">
                    All
                  </button>
                  <div ngbDropdown [autoClose]="'outside'" class="btn-group" role="group">
                    <button ngbDropdownToggle type="button" class="btn dropdown-toggle"
                            aria-haspopup="true" aria-expanded="false">
                      <span class="d-none d-sm-inline-block">Memory</span> Tag
                      <span *ngIf="filters.memoryTagTypes.length"
                            class="dd-has-filters">{{ filters.memoryTagTypes.length }}</span>
                    </button>
                    <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                      <div class="btn-group-vertical">

                        <label *ngFor="let item of memoryTagTypeFilters; let index = index;" class="btn dropdown-item"
                               ngbButtonLabel>
                          <input type="checkbox" ngbButton (change)="queueSearch()"
                                 [(ngModel)]="memoryTagTypeFilters[index].selected" [value]="item.item">
                          {{ item.item.label }}
                        </label>

                      </div>

                    </div>
                  </div>

                </div>

                <div ngbDropdown [autoClose]="'outside'" class="btn-group float-right" role="group">
                  <button ngbDropdownToggle type="button" class="btn dropdown-toggle"
                          aria-haspopup="true" aria-expanded="false">
                    Secret <span class="d-none d-sm-inline-block">Keeper</span>
                    <span *ngIf="filters.secretKeeperTypes.length"
                          class="dd-has-filters">{{ filters.secretKeeperTypes.length }}</span>
                  </button>
                  <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="btnGroupDrop1">

                    <label *ngFor="let item of secretKeeperTypeFilters; let index = index;" class="btn dropdown-item"
                           ngbButtonLabel>
                      <input type="checkbox" ngbButton (change)="queueSearch()"
                             [(ngModel)]="secretKeeperTypeFilters[index].selected" [value]="item.item">
                      {{ item.item.label }}
                    </label>

                  </div>
                </div><!-- /secretKeeper filter -->

                <div ngbDropdown [autoClose]="'outside'" class="btn-group float-right" role="group">
                  <button ngbDropdownToggle type="button" class="btn dropdown-toggle"
                          aria-haspopup="true" aria-expanded="false">
                    Time <span class="d-none d-sm-inline-block">Lock</span>
                    <span *ngIf="filters.timeLockTypes.length"
                          class="dd-has-filters">{{ filters.timeLockTypes.length }}</span>
                  </button>
                  <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="btnGroupDrop1">

                    <label *ngFor="let item of timeLockTypeFilters; let index = index;" class="btn dropdown-item"
                           ngbButtonLabel>
                      <input type="checkbox" ngbButton (change)="queueSearch()"
                             [(ngModel)]="timeLockTypeFilters[index].selected" [value]="item.item">
                      {{ item.item.label }}
                    </label>

                  </div>
                </div><!-- /timeLock filter -->

              </div><!-- /btn-group-filters -->


              <div class="block block-search mt-2 mb-4">
                <div class="input-group">
                  <input type="text" class="form-control" placeholder="Search..." aria-label="Search My LifeJars"
                         [(ngModel)]="keywords" (change)="queueSearch()">
                  <div class="input-group-append">
                    <button class="btn btn-block" type="button" (click)="search()">
                      <span class="sr-only">Search</span>
                      <fa-icon [icon]="faSearch"></fa-icon>
                    </button>
                  </div>
                </div>
              </div><!-- /block-search -->

              <div class="memories-list-container">

                <ul class="list list-memories">
                  <li *ngFor="let item of pagedMemories.list" class="item item-memory">
                    <div class="row">
                      <div class="col-sm-3">
                        <div class="thumb thumb-memory mb-2">
                          <app-file-lazy-loader [memory]="item" [file]="item.files[0]"
                                                (click)="showLightbox(item.files[0], item )" preview="true"></app-file-lazy-loader>
                          <app-ico-image-count [count]="item?.files?.length"></app-ico-image-count>
                        </div>
                      </div>
                      <div class="col-sm-9">
                        <h4 class="tag clearfix">
                          <span class="font-weight-bold"><fa-icon [icon]="faTag"></fa-icon>
                            {{ (item.memoryTag|options: 'memoryTag')?.label }}</span>

                          <span class="float-right smaller ml-2"><fa-icon [icon]="faEye"></fa-icon>
                            {{ (item.secretKeeper|options: 'secretKeeper')?.label }}</span>

                          <span class="float-right smaller ml-2"><fa-icon [icon]="faClock"></fa-icon>
                            {{ (item.timeLock|options: 'timeLock')?.label}}</span>

                          <span *ngIf="item?.date" class="float-right smaller ml-2">
                            <fa-icon [icon]="faCalendar"></fa-icon>
                            {{ item.date | date: 'mediumDate' }}</span>

                        </h4>
                        <h3 class="title">
                          <span *ngIf="item.isSample"><em>Sample:</em></span>
                          {{ item.title }}
                        </h3>
                        <div class="description">
                          {{ (item.description || item.placeholder) | truncate:['200', '...'] }}
                        </div>
                      </div>
                    </div>
                    <div class="row row-controls">
                      <div class="col-6 col-sm-3 offset-sm-3 text-left">
                        <button (click)="openMemoryModal(item)" type="button" class="btn btn-sm btn-hollow"
                                *ngIf="item.isReference === false && canEdit()">
                          <fa-icon [icon]="faPencilAlt"></fa-icon>
                          Edit
                        </button>

                        <button (click)="removeShared(item)" type="button" class="btn btn-sm btn-hollow"
                                *ngIf="item.isReference === true">
                          <fa-icon [icon]="faShare"></fa-icon>
                          Remove
                        </button>

                      </div>
                      <div class="col-6 text-right">
                        <button (click)="viewMemory(item)" type="button" class="btn btn-sm btn-hollow ml-2">
                          View
                        </button>
                      </div>
                    </div>

                  </li>
                </ul>

                <app-paginator [page]="pagedMemories.page" [pages]="pagedMemories.pages"
                               (pageChange)="onPageChange($event)"></app-paginator>

                <app-content-loader [scope]="'lazy'">Loading...</app-content-loader>
              </div><!-- /memories-list-container -->

              <div class="block block-btns text-center mt-5" *ngIf="canEdit()">
                <button (click)="openMemoryModal()" class="btn btn-gold" type="button">
                  <fa-icon [icon]="faPlusCircle"></fa-icon>
                  Add Memory
                </button>
              </div><!-- /block-btns -->

            </div>
          </div><!--/card -->

        </div>
      </div>

    </div>
  </div>

</div><!--/container-->
