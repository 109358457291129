
  <ul *ngIf="activityList?.length" class="list-tribe list-tribe-pending">
    <li *ngFor="let item of activityList" class="list-tribe-item">
      <div class="inset">
        <div class="row">
          <div class="col-12">

            <div class="list-tribe-item-message">
              <div *ngIf="item.bio" class="list-tribe-item-preview mr-3" [appBioTypeClass]="item.bio.type"
                   [routerLink]="[ item.bio.isLegacy ? '/legacy' :  '/profiles',item.bio.slug]">
                <app-file-lazy-loader [bio]="item.bio" [file]="item.bio.profile"></app-file-lazy-loader>
              </div>
              <div class="list-tribe-item-text">
                {{ item.message }}
              </div>
            </div>


            <div class="list-tribe-item-btns text-md-right mt-2 mt-sm-0">
              <button *ngIf="item.status=='received'" type="button" class="btn btn-sm btn-gold" (click)="acceptTribeRequest(item)">
                Accept
              </button>

              <button *ngIf="item.status=='received'" type="button" class="btn btn-sm btn-blue" (click)="cancelTribeRequest(item)">
                Decline
              </button>

              <button *ngIf="item.status=='sent'" type="button" class="btn btn-sm btn-gold" (click)="resendTribeRequest(item)">
                Resend
              </button>

              <button *ngIf="item.status=='sent'" type="button" class="btn btn-sm btn-blue" (click)="cancelTribeRequest(item)">
                Cancel
              </button>
            </div>

          </div>
        </div>

      </div>
    </li>
  </ul>

