import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ResponseMessage} from '../models/response-message.model';
import {User} from '../models/user.model';
import {UserFilterModel} from '../models/user-filter.model';
import {UserSearchResult} from '../models/user-search-result.model';
import {Bio} from '../models/bio.model';
import {UserBio} from '../models/bio-ward.model';
import {AppDateFormatter} from '../format/date.format';
import {AppConfig} from './app-config.service';

@Injectable({providedIn: 'root'})
export class UserService implements OnDestroy {

  constructor(
    protected http: HttpClient,
    protected dateFormatter: AppDateFormatter
  ) {
  }

  public getOwnedBios(): Observable<ResponseMessage> {
    return this.http.get<ResponseMessage>(`${AppConfig.settings.apiBaseUrl}/user/owned-bios`);
  }

  public deleteUser(): Observable<any> {
    return this.http.delete<any>(`${AppConfig.settings.apiBaseUrl}/user/delete`);
  }

  public getUser(id?: number): Observable<User> {
    let url = `${AppConfig.settings.apiBaseUrl}/user`;
    if (id != null) {
      url = `${AppConfig.settings.apiBaseUrl}/user/${id}`;
    }
    return this.http.get<User>(url);
  }

  public getUserBios(id?: number): Observable<Bio[]> {
    let url = `${AppConfig.settings.apiBaseUrl}/user/bios`;
    if (id != null) {
      url = `${AppConfig.settings.apiBaseUrl}/user/${id}/bios`;
    }
    return this.http.get<Bio[]>(url)
      .pipe(map((bios: Bio[]) => {
        for (let i = 0, l = bios.length; i < l; i++) {
          bios[i] = Bio.make(bios[i]);
        }
        return bios;
      }));
  }

  public saveUser(model: User): Observable<ResponseMessage> {

    let url = `${AppConfig.settings.apiBaseUrl}/user/save`;
    if (model.id) {
      url = `${AppConfig.settings.apiBaseUrl}/user/${model.id}/save`;
    }

    return this.http.post<ResponseMessage>(url, {
      email: model.email,
      firstName: model.firstName,
      lastName: model.lastName,
      streetAddress: model.streetAddress,
      streetAddress2: model.streetAddress2,
      suburb: model.suburb,
      postcode: model.postcode,
      state: model.state,
      country: model.country,
    });
  }

  public passwordChange(oldPassword: string, password1: string, password2: string): Observable<ResponseMessage> {
    // Symfony requires the passwords to be sent as an Object for comparison
    const password = {
      password: password1,
      passwordConfirm: password2
    };
    return this.http.post<ResponseMessage>(`${AppConfig.settings.apiBaseUrl}/user/password-change`, {oldPassword, password});
  }

  public updateLoginSettings(enable2fa: boolean, rememberMe: boolean ): Observable<ResponseMessage> {
    return this.http.patch<ResponseMessage>(`${AppConfig.settings.apiBaseUrl}/user/login-settings`, {
      enable2fa,
      rememberMe
    });
  }

  public emailChange(email1: string, email2: string): Observable<ResponseMessage> {
    // Symfony requires the emails to be sent as an Object for comparison
    const email = {
      email: email1,
      emailConfirm: email2
    };
    return this.http.post<ResponseMessage>(`${AppConfig.settings.apiBaseUrl}/user/email-change`, {email});
  }


  public getUserMetaData(id?: number): Observable<ResponseMessage> {
    let url = `${AppConfig.settings.apiBaseUrl}/user/meta`;
    if (id != null) {
      url = `${AppConfig.settings.apiBaseUrl}/user/${id}/meta`;
    }
    return this.http.get<any>(url);
  }

  public saveUserMetaData(model: any, id?: number): Observable<ResponseMessage> {
    let url = `${AppConfig.settings.apiBaseUrl}/user/meta/update`;
    if (id != null) {
      url = `${AppConfig.settings.apiBaseUrl}/user/${id}/meta/update`;
    }
    return this.http.put<ResponseMessage>(url, model);
  }

  public search(filters: UserFilterModel): Observable<UserSearchResult> {
    return this.http.post<UserSearchResult>(`${AppConfig.settings.apiBaseUrl}/user/search`, filters);
  }

  public createUserBio(model: UserBio): Observable<ResponseMessage> {

    const data = {
      wardType: model.wardType,
      birthday: this.dateFormatter.format(model.birthday, 'YYYY-MM-DD'),
      firstName: model.firstName,
      lastName: model.lastName,
      gender: model.gender,
      deathday: undefined,
      email: undefined,
      phone: undefined,
      relationshipType: model.relationshipType,
      relationshipSubType: model.relationshipSubType,
      relationshipOther: ''
    };

    if (model.relationshipOther) {
      data.relationshipOther = model.relationshipOther;
    }

    if (model.email) {
      data.email = model.email;
    }

    if (model.phone) {
      data.phone = model.phone;
    }

    if (model.deathday !== null) {
      data.deathday = this.dateFormatter.format(model.deathday, 'YYYY-MM-DD');
    }

    return this.http.post<ResponseMessage>(`${AppConfig.settings.apiBaseUrl}/ward/save`, data);
  }


  public ngOnDestroy() {

  }

}
