<div class="modal-header">
  <h4 class="modal-title">Choose a Jar</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body modal-body-profile-picker">

  <div class="row row-page-btns row-page-btns-things">
      <div class="btn-group btn-group-toggle" ngbRadioGroup [(ngModel)]="bioJar">

        <label *ngFor="let item of memoryJars; let index = index;" class="btn" ngbButtonLabel>
          <input type="radio" ngbButton [value]="item">
          <div class="sprite sprite-thing-jar"></div>
          <div class="text">{{ item.title }}</div>
        </label>

      </div>
    </div><!-- /row-page-btns -->

  <div class="form-group text-center mt-3">
    <button ngbAutofocus (click)="addToJar($event)" type="button" class="btn btn-gold mr-2" [disabled]="!bioJar">
      Add to Jar
    </button>

    <button ngbAutofocus (click)="activeModal.dismiss()" type="button" class="btn btn-outline-dark">
      Cancel
    </button>
  </div>

</div>
<app-content-loader [scope]="'modal'"></app-content-loader>

