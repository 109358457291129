import {IJarOption, IListOption, IMemoryTagOption} from '../../core/models/option.model';
import {DonationPartner} from '../../core/models/donation-partner.model';
import {UserSubscription} from '../../core/models/user-subscription.model';

export class OptionsState {
  market: string;
  genderTypes: IListOption[] = [];
  addressTypes: IListOption[] = [];
  religions: IListOption[] = [];
  practicingReligionStatuses: IListOption[] = [];

  memoryJarTypes: IJarOption[] = [];
  thingJarTypes: IJarOption[] = [];
  passwordJarTypes: IJarOption[] = [];
  memoryTagTypes: IMemoryTagOption[] = [];
  thingTagTypes: IMemoryTagOption[] = [];
  passwordTagTypes: IMemoryTagOption[] = [];

  secretKeeperTypes: IListOption[] = [];
  timeLockTypes: IListOption[] = [];
  privacyStatus: IListOption[] = [];
  donationPartners: DonationPartner[] = [];
  subscriptions: UserSubscription[] = [];

  ethnicTypes: IListOption[] = [];
  customTraditions: IListOption[] = [];
  relationshipTypes: IListOption[] = [];
}

export const initialOptionState: OptionsState = {
  market: 'USD',
  genderTypes: [],
  addressTypes: [],
  religions: [],
  practicingReligionStatuses: [],
  memoryJarTypes: [],
  thingJarTypes: [],
  passwordJarTypes: [],
  memoryTagTypes: [],
  thingTagTypes: [],
  passwordTagTypes: [],
  secretKeeperTypes: [],
  timeLockTypes: [],
  privacyStatus: [],
  donationPartners: [],
  subscriptions: [],

  ethnicTypes: [],
  customTraditions: [],
  relationshipTypes: [],
};
