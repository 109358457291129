import {ActionReducer, ActionReducerMap, INIT, MetaReducer} from '@ngrx/store';
import {environment} from '../../environments/environment';
import {UserBiosReducer} from './user-bios/user-bios.reducer';
import {UserBiosState} from './user-bios/user-bios.state';
import {BiosState} from './bios/bios.state';
import {BiosReducer} from './bios/bios.reducer';
import {TribesState} from './tribe/tribe.state';
import {TribesReducer} from './tribe/tribe.reducer';
import {OptionsState} from './options/options.state';
import {OptionsReducer} from './options/options.reducer';
import {BioUsersState} from './bio-users/bio-users.state';
import {JarsState} from './jars/jars.state';
import {BioUsersReducer} from './bio-users/bio-users.reducer';
import {JarsReducer} from './jars/jars.reducer';
import {UsersReducer} from './users/users.reducer';
import {UsersState} from './users/users.state';
import {MemoriesState} from './memories/memories.state';
import {MemoriesReducer} from './memories/memories.reducer';
import {UserActions} from './users';
import {PasswordsState} from './passwords/passwords.state';
import {PasswordsReducer} from './passwords/passwords.reducer';
import {CommentsState} from './comments/comments.state';
import {CommentsReducer} from './comments/comments.reducer';

export interface AppState {
  options: OptionsState;
  users: UsersState;
  userBios: UserBiosState;
  bios: BiosState;
  bioUsers: BioUsersState;
  jars: JarsState;
  tribes: TribesState;
  memories: MemoriesState;
  passwords: PasswordsState;
  comments: CommentsState;
}

export const reducers: ActionReducerMap<AppState> = {
  options: OptionsReducer,
  users: UsersReducer,
  userBios: UserBiosReducer,
  bios: BiosReducer,
  bioUsers: BioUsersReducer,
  jars: JarsReducer,
  tribes: TribesReducer,
  memories: MemoriesReducer,
  passwords: PasswordsReducer,
  comments: CommentsReducer
};

export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    // console.log('state', state);
    // console.log('action', action);
    return reducer(state, action);
  };
}

export function logout(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    if ( action != null && action.type === UserActions.logout.type) {
      return reducer(undefined, {type: INIT});
    }
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<any>[] = !environment.production
  ? [debug, logout]
  : [logout];
