
<div class="bg bg-cover"></div>

<div class="card card-bio card-bio-login">

  <div class="card-body">

    <div class="card-body-title text-center">
      <h2 class="text-gold font-weight-bold pb-3 mb-3">Email Change Confirmation</h2>
    </div>

    <div *ngIf="hashError">
      <div class="form-group small">
        <div class="row">
          <div class="col-12 text-center">
            <a routerLink="/settings">Click here to restart your email change process &raquo;</a>
          </div>
        </div>
      </div>
    </div>

    <app-content-loader></app-content-loader>
  </div>
</div>
