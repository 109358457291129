<form [formGroup]="editForm" (ngSubmit)="onSubmit()">
  <div class="modal-header">
    <h4 class="modal-title">Create Profile</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body modal-body-form">
    <div>
      <form-validation [control]="editForm" [submitted]="submittedShowErrors"></form-validation>

      <div class="form-group">
        <label class="control-label">Ward Type</label>

        <div class="btn-group btn-group-lg d-flex btn-group-toggle" ngbRadioGroup formControlName="wardTypeA"
             (ngModelChange)="onWardTypeChange($event)">

          <label class="btn btn-hollow"
                 [ngClass]="{ 'is-invalid': submittedShowErrors && f.wardTypeA.errors }" ngbButtonLabel
                 aria-label="Person">
            <input type="radio" ngbButton [value]="1">
            Person
          </label>

          <label class="btn btn-hollow"
                 [ngClass]="{ 'is-invalid': submittedShowErrors && f.wardTypeA.errors }" ngbButtonLabel
                 aria-label="Pet">
            <input type="radio" ngbButton [value]="2">
            Pet
          </label>

        </div>
        <app-control-validation [control]="f.wardTypeA"
                                [submitted]="submittedShowErrors"></app-control-validation>
      </div>

      <div class="form-group">
        <label class="control-label">Living or Passed</label>

        <div class="btn-group btn-group-lg d-flex btn-group-toggle" ngbRadioGroup formControlName="wardTypeB"
             (ngModelChange)="onWardTypeChange($event)">

          <label class="btn btn-hollow"
                 [ngClass]="{ 'is-invalid': submittedShowErrors && f.wardTypeB.errors }" ngbButtonLabel
                 aria-label="Life">
            <input type="radio" ngbButton [value]="1">
            Life
            <div class="small">Active</div>
          </label>

          <label class="btn btn-hollow"
                 [ngClass]="{ 'is-invalid': submittedShowErrors && f.wardTypeB.errors }" ngbButtonLabel
                 aria-label="Legacy">
            <input type="radio" ngbButton [value]="2">
            Legacy
            <div class="small">Memorial</div>
          </label>

        </div>
        <app-control-validation [control]="f.wardType"
                                [submitted]="submittedShowErrors"></app-control-validation>
      </div>


      <div class="form-group" *ngIf="optionalAccountSetup">
        <label class="control-label">Create a My LifeJars Account</label>

        <div class="btn-group btn-group-lg d-flex btn-group-toggle" ngbRadioGroup formControlName="createAccount"
        (ngModelChange)="onCreateAccountChange($event)">

          <label class="btn btn-hollow"
                 [ngClass]="{ 'is-invalid': submittedShowErrors && f.createAccount.errors }" ngbButtonLabel
                 aria-label="Gender">
            <input type="radio" ngbButton [value]="0">
            No
          </label>

          <label class="btn btn-hollow"
                 [ngClass]="{ 'is-invalid': submittedShowErrors && f.createAccount.errors }" ngbButtonLabel
                 aria-label="Gender">
            <input type="radio" ngbButton [value]="1">
            Yes
          </label>

        </div>
        <app-control-validation [control]="f.createAccount"
                                [submitted]="submittedShowErrors"></app-control-validation>
      </div>


      <div class="additional-fields" *ngIf="showAccountSetup">

        <p class="mt-5">
          <strong>Setup a My LifeJars account for this Profile</strong>
        </p>

        <div class="form-group">
          <label class="control-label">Email </label>
          <input type="text" formControlName="email" class="form-control form-control-lg input-bio"
                 [ngClass]="{ 'is-invalid': submittedShowErrors && f.email.errors }" placeholder="Email" />
          <app-control-validation [control]="f.email" [submitted]="submittedShowErrors"></app-control-validation>
          <p class="small pt-2 pb-2">
            If this person is a child under the age of 13, or an adult that doesn't have an
            email account, do not setup a new account (answer 'No' above).
          </p>
        </div>

      </div><!-- /additional-fields -->


      <p class="mt-5">
        <strong>Ward's Information</strong>
      </p>

      <div class="form-group">
        <label class="control-label">First Name</label>
        <input type="text" formControlName="firstName" class="form-control form-control-lg input-bio"
               [ngClass]="{ 'is-invalid': submittedShowErrors && f.firstName.errors }" placeholder="First name"/>
        <app-control-validation [control]="f.firstName" [submitted]="submittedShowErrors"></app-control-validation>
      </div>

      <div class="form-group">
        <label class="control-label">Last Name</label>
        <input type="text" formControlName="lastName" class="form-control form-control-lg input-bio"
               [ngClass]="{ 'is-invalid': submittedShowErrors && f.lastName.errors }" placeholder="Last Name"/>
        <app-control-validation [control]="f.lastName" [submitted]="submittedShowErrors"></app-control-validation>
      </div>

      <div class="form-group">
        <label class="control-label">Gender</label>

        <div class="btn-group btn-group-lg d-flex btn-group-toggle" ngbRadioGroup formControlName="gender">

          <label *ngFor="let item of GenderTypes; let index = index;" class="btn btn-hollow"
                 [ngClass]="{ 'is-invalid': submittedShowErrors && f.gender.errors }" ngbButtonLabel
                 aria-label="Gender">
            <input type="radio" ngbButton [value]="item.id">
            {{ item.label }}
          </label>

        </div>
        <app-control-validation [control]="f.gender" [submitted]="submittedShowErrors"></app-control-validation>
      </div>


      <div class="form-group">
        <label class="control-label">Date of Birth</label>

        <div class="input-group">
          <input ngbDatepicker type="text" formControlName="birthday" class="form-control form-control-lg input-bio"
                 [ngClass]="{ 'is-invalid': submittedShowErrors && f.birthday.errors }" placeholder="DD/MM/YYYY"
                 aria-label="DD/MM/YYYY"
                 #d="ngbDatepicker" [placement]="['bottom-right','top-right']" />
          <div class="input-group-append btn-outline-secondary">
            <button class="btn" type="button" (click)="d.toggle()">
              <span class="sr-only">Calendar</span>
              <fa-icon [icon]="faCalendar"></fa-icon>
            </button>
          </div>
        </div>

        <app-control-validation [control]="f.birthday" [submitted]="submittedShowErrors"></app-control-validation>
      </div>

      <div *ngIf="showLegacySetup" class="form-group">
        <label class="control-label">Date of Passing</label>

        <div class="input-group">
          <input ngbDatepicker type="text" formControlName="deathday" class="form-control form-control-lg input-bio"
                 [ngClass]="{ 'is-invalid': submittedShowErrors && f.deathday.errors }" placeholder="DD/MM/YYYY"
                 aria-label="DD/MM/YYYY"
                 #d2="ngbDatepicker" [placement]="['bottom-right','top-right']"/>
          <div class="input-group-append btn-outline-secondary">
            <button class="btn" type="button" (click)="d2.toggle()">
              <span class="sr-only">Calendar</span>
              <fa-icon [icon]="faCalendar"></fa-icon>
            </button>
          </div>
        </div>

        <app-control-validation [control]="f.deathday" [submitted]="submittedShowErrors"></app-control-validation>
      </div>

      <p class="mt-5">
        <strong>Your relationship to the Ward</strong>
      </p>

      <div class="form-group row">
        <div class="col-6">

          <label class="control-label">Our relationship is:</label>
          <select formControlName="relationshipType" class="form-control form-control-lg input-bio"
                  [ngClass]="{ 'is-invalid': submittedShowErrors && f.relationshipType.errors }"
                  aria-label="Relationship Type" (ngModelChange)="onRelationshipChange($event)">
            <option [ngValue]="null" selected>Select one...</option>
            <option *ngFor="let item of relationshipTypes" [ngValue]="item.id">{{ item.label }}</option>
          </select>
          <app-control-validation [control]="f.relationshipType" [submitted]="submittedShowErrors"></app-control-validation>

        </div>
        <div class="col-6">

          <label class="control-label">They are:</label>
          <select formControlName="relationshipSubType" class="form-control form-control-lg input-bio"
                  [ngClass]="{ 'is-invalid': submittedShowErrors && f.relationshipSubType.errors }"
                  aria-label="Relationship" (ngModelChange)="onRelationshipSubChange($event)">>
            <option [ngValue]="null" selected>Select one...</option>
            <option *ngFor="let item of relationshipSubTypesFiltered" [ngValue]="item.id">{{ item.label }}</option>
            <option [ngValue]="-1">Other</option>
          </select>
          <app-control-validation [control]="f.relationshipSubType" [submitted]="submittedShowErrors"></app-control-validation>

        </div>
      </div>

      <div *ngIf="f.relationshipOther" class="form-group">
        <label class="control-label">Relationship Other</label>
        <input maxlength="250" type="text" formControlName="relationshipOther" class="form-control form-control-lg input-bio"
               [ngClass]="{ 'is-invalid': submittedShowErrors && f.relationshipOther.errors }" placeholder="Describe..."/>
        <app-control-validation [control]="f.relationshipOther" [submitted]="submittedShowErrors"></app-control-validation>
      </div>



      <p class="mt-5">
        <strong>Important</strong>
      </p>

      <div class="form-group">
        <div class="custom-control custom-checkbox">
          <input type="checkbox" formControlName="agreeToConditions" id="agreeToConditions"
                 class="input-bio custom-control-input"
                 [ngClass]="{ 'is-invalid': submittedShowErrors && f.agreeToConditions.errors }"/>

          <label class="custom-control-label" for="agreeToConditions">
            I acknowledge that I am a Guardian of this Profile and I
            will provide only true and accurate information.
          </label>

          <app-control-validation [control]="f.agreeToConditions"
                                  [submitted]="submittedShowErrors"></app-control-validation>
        </div>
      </div>

      <div class="form-group small">
        <div class="row">
          <div class="col-12">
            By confirming this transition, you agree to our
            <a href="{{showTerms()}}" target="_blank"><strong>Terms</strong></a> and confirm that you
            have read our
            <a href="{{showPrivacy()}}" target="_blank"><strong>Privacy Policy</strong></a>,
            including our Cookie Use.
          </div>
        </div>
      </div>

      <div class="form-group  text-center mt-4">

        <button type="submit" class="btn btn-gold pl-4 pr-4" [disabled]="disableSave">
          Create Profile
        </button>

        <button type="button" class="btn btn-outline-dark ml-3 pl-4 pr-4" (click)="activeModal.close(false)">
          Cancel
        </button>

        <form-validation [control]="editForm" [submitted]="submittedShowErrors"></form-validation>

      </div>

    </div>

  </div>
</form>
<app-content-loader></app-content-loader>
