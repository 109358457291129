<form [formGroup]="editForm" (ngSubmit)="onSubmit()">
<div class="modal-header">
  <h4 class="modal-title">{{ bioUser.userBio?.firstName }} {{ bioUser.userBio?.lastName }} has access to: </h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss(false)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body user-permissions">

  <form-validation [control]="editForm" [submitted]="submitted"></form-validation>

  <div class="row">

    <div class="col-6 col-sm-2 offset-sm-1">
      <div class="icon-jar">
        <img src="/assets/img/jar-on.png" alt="Jar">
        <span class="icon-jar-text">Memory<br/>Jars</span>
      </div>
      <div class="user-permissions-toggle mb-2">
        <div class="btn-group-vertical btn-group-toggle" ngbRadioGroup  formControlName="memoryJars">

          <label *ngFor="let item of UserPermissions; let index = index;" class="btn" ngbButtonLabel>
            <input type="radio" ngbButton [value]="item.id">
            <span>{{ item.label }}</span>
          </label>

        </div>
      </div>
    </div><!-- /Memory Jars Permissions -->

    <div class="col-6 col-sm-2">
      <div class="icon-jar">
        <img src="/assets/img/jar-on.png" alt="Jar">
        <span class="icon-jar-text">Things<br/>Jars</span>
      </div>
      <div class="user-permissions-toggle mb-2">
        <div class="btn-group-vertical btn-group-toggle" ngbRadioGroup  formControlName="thingsJars">

          <label *ngFor="let item of UserPermissions; let index = index;" class="btn" ngbButtonLabel>
            <input type="radio" ngbButton [value]="item.id">
            <span>{{ item.label }}</span>
          </label>

        </div>
      </div>
    </div><!-- /Things Jars Permissions -->

    <div class="col-6 col-sm-2">
      <div class="icon-jar">
        <img src="/assets/img/jar-on.png" alt="Jar">
        <span class="icon-jar-text">Password<br/>Jar</span>
      </div>
      <div class="user-permissions-toggle mb-2">
        <div class="btn-group-vertical btn-group-toggle" ngbRadioGroup  formControlName="passwordJars">

          <label *ngFor="let item of UserPermissions; let index = index;" class="btn" ngbButtonLabel>
            <input type="radio" ngbButton [value]="item.id">
            <span>{{ item.label }}</span>
          </label>

        </div>
      </div>
    </div><!-- /Password Jars Permissions -->

    <div class="col-6 col-sm-2">
      <div class="icon-jar">
        <img src="/assets/img/jar-on.png" alt="Jar">
        <span class="icon-jar-text">Life<br/>Profile</span>
      </div>
      <div class="user-permissions-toggle mb-2">
        <div class="btn-group-vertical btn-group-toggle" ngbRadioGroup  formControlName="lifeBio">

          <label *ngFor="let item of UserPermissions; let index = index;" class="btn" ngbButtonLabel>
            <input type="radio" ngbButton [value]="item.id">
            <span>{{ item.label }}</span>
          </label>

        </div>
      </div>
    </div><!-- /Life Profile Permissions -->

    <div class="col-6 col-sm-2">
      <div class="icon-jar">
        <img src="/assets/img/jar-on.png" alt="Jar">
        <span class="icon-jar-text">Future<br/>Legacy</span>
      </div>
      <div class="user-permissions-toggle mb-2">
        <div class="btn-group-vertical btn-group-toggle" ngbRadioGroup  formControlName="legacyBio">

          <label *ngFor="let item of UserPermissions; let index = index;" class="btn" ngbButtonLabel>
            <input type="radio" ngbButton [value]="item.id">
            <span>{{ item.label }}</span>
          </label>

        </div>
      </div>
    </div><!-- /Legacy Profile Permissions -->

  </div>


  <div class="form-group text-center text-sm-right">

    <button type="submit" class="btn btn-gold">
      Save
    </button>

    <button type="button" class="btn btn-outline-dark ml-3" (click)="activeModal.dismiss(false)">
      Cancel
    </button>

  </div>

</div>
</form>
<app-content-loader></app-content-loader>
