import {Component, OnInit, forwardRef, Input, OnDestroy} from '@angular/core';
import {
  ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, NG_VALIDATORS,
  FormGroup, Validator, Validators, AbstractControl, ValidationErrors
} from '@angular/forms';
import {OptionsService} from '../../../core/services/options.service';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../store';
import {IListOption} from '../../../core/models/option.model';
import {Subscription} from 'rxjs';
import {selectAddressType, selectAddressTypes, selectOptions} from '../../../store/options/options.selectors';
import {OptionsState} from '../../../store/options/options.state';

// Ref: https://medium.com/angular-in-depth/angular-nested-reactive-forms-using-cvas-b394ba2e5d0d
@Component({
  selector: 'app-common-address',
  templateUrl: './common-address.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CommonAddressComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CommonAddressComponent),
      multi: true
    }
  ]
})

export class CommonAddressComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {
  private readonly subscription: Subscription;
  @Input() submitted: boolean;
  @Input() withTypeField: boolean;

  public commonAddressForm: FormGroup;

  public addressTypes: IListOption[];

  // convenience getter for easy access to form fields
  public get f() {
    return this.commonAddressForm.controls;
  }

  constructor( protected store: Store<AppState> ) {
    this.withTypeField = true;

    this.subscription = new Subscription();
    this.subscription.add(this.store.pipe(select(selectAddressTypes))
      .subscribe((options: IListOption[]) => {
        this.addressTypes = options;
      })
    );

  }

  ngOnInit() {

    this.commonAddressForm = new FormGroup(
      {
        type: new FormControl(null, this.withTypeField ? Validators.required : []),
        streetAddress: new FormControl('', []),
        streetAddress2: new FormControl('', []),
        suburb: new FormControl('', []),
        postcode: new FormControl('', []),
        state: new FormControl('', []),
        country: new FormControl('', [])
      });

    // Set the Default type to 0:Primary
    this.commonAddressForm.get('type').setValue(0);
  }

  public get AddressTypeChoices() {
    return this.addressTypes;
  }

  public onTouched: () => void = () => {
  };

  writeValue(val: any): void {
    // tslint:disable-next-line:no-unused-expression
    val && this.commonAddressForm.setValue(val, {emitEvent: false});
  }

  registerOnChange(fn: any): void {
    this.commonAddressForm.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled
      ? this.commonAddressForm.disable()
      : this.commonAddressForm.enable();
  }

  validate(c: AbstractControl): ValidationErrors | null {
    // console.log('Address validation', c);
    return this.commonAddressForm.valid ? null : {
      customError: {valid: false, message: 'Address field(s) are invalid, please review form errors.'}
    };
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
