import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Bio} from '../../../core/models/bio.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TermsServiceModalComponent} from '../terms-service/terms-service-modal.component';
import {PrivacyPolicyModalComponent} from '../privacy-policy/privacy-policy-modal.component';
import {faCalendar} from '@fortawesome/free-solid-svg-icons';
import {BioService} from '../../../core/services/bio.service';
import {first} from 'rxjs/operators';
import {ResponseMessage} from '../../../core/models/response-message.model';
import {LoaderService, ScopedLoader} from '../../components/content-loader/loader.service';
import {LayoutService} from '../../../core/services/layout.service';

@Component({
  templateUrl: 'legacy-transition-modal.component.html'
})
export class LegacyTransitionModalComponent implements OnInit {
  @Input() public bio: Bio;

  public editForm: FormGroup;
  public submittedShowErrors = false;

  public faCalendar = faCalendar;

  // convenience getter for easy access to form fields
  public get f() {
    return this.editForm.controls;
  }

  constructor(
    public activeModal: NgbActiveModal,
    protected modalService: NgbModal,
    protected formBuilder: FormBuilder,
    protected bioService: BioService,
    protected loaderService: LoaderService,
    protected layoutService: LayoutService,
  ) {
    this.bio = null;
  }

  public ngOnInit() {
    this.editForm = this.formBuilder.group({
      deathday: [null, Validators.required],
      agreeToConditions: [null, Validators.requiredTrue]
    });
  }

  public onSubmit() {
    this.submittedShowErrors = true;

    // stop here if form is invalid
    if (this.editForm.invalid) {
      return;
    }

    const formData = this.editForm.getRawValue();

    this.bioService.legacyTransition(this.bio.id, formData.deathday)
      .pipe(first())
      .subscribe((msg: ResponseMessage) => {
        this.activeModal.close(true);
      });
  }

  public showTerms(): string {
    return this.layoutService.showTerms();
  }

  public showPrivacy(): string {
    return this.layoutService.showPrivacy();
  }

}
