import {initialPasswordState, PasswordsState} from './passwords.state';
import {Action, createReducer, on} from '@ngrx/store';
import {PasswordsActions} from './index';


const reducer = createReducer<PasswordsState>(
  initialPasswordState,

  on(PasswordsActions.setCurrentPassword, (state, {password}) => {
    return {
      ...state,
      currentPassword: password
    };
  })

);

export function PasswordsReducer(state: PasswordsState, action: Action) {
  return reducer(state, action);
}
