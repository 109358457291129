import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Bio} from '../models/bio.model';
import {BioFilterModel} from '../models/bio-filter.model';
import {FileModel} from '../models/file.model';
import {BioSearchResult} from '../models/bio-search-result.model';
import {IMemory} from '../models/memory.model';
import {MemoryFilterModel} from '../models/memory-filter.model';
import {MemorySearchResult} from '../models/memory-search-result.model';
import {AppConfig} from './app-config.service';

@Injectable({providedIn: 'root'})
export class PublicBioService {
  constructor(protected http: HttpClient) {}

  public getBio(slugOrId: string | number): Observable<Bio> {
    return this.http.get<Bio>(`${AppConfig.settings.apiBaseUrl}/public-bio/${slugOrId}`);
  }

  public getBioFile(bio: Bio, file: FileModel, getPreview: boolean = false): Observable<FileModel> {
    return this.http.get<FileModel>(`${AppConfig.settings.apiBaseUrl}/public-bio/${bio.slug}/file/${file.id}?preview=${getPreview}`);
  }

  public getMemoryFile(memory: IMemory, file: FileModel, getPreview: boolean = false): Observable<FileModel> {
    return this.http
      .get<FileModel>(`${AppConfig.settings.apiBaseUrl}/public-bio/memory/${memory.id}/file/${file.id}?preview=${getPreview}`);
  }

  public searchBios(filters: BioFilterModel): Observable<BioSearchResult> {
    const params = new HttpParams()
      .append('page', filters.page.toString())
      .append('results', filters.results.toString())
      .append('keywords', filters.keywords);
    return this.http.get<BioSearchResult>(`${AppConfig.settings.apiBaseUrl}/public-bio/search`, {params});
  }

  public searchMemories(bio: Bio, filters: MemoryFilterModel): Observable<MemorySearchResult> {
    const params = new HttpParams()
      .append('bio', filters.bio.toString())
      .append('parentNodes', JSON.stringify(filters.parentNodes))
      .append('includeParents', filters.includeParents ? '1' : '0')
      .append('memoryTagTypes', JSON.stringify(filters.memoryTagTypes))
      .append('secretKeeperTypes', JSON.stringify(filters.secretKeeperTypes))
      .append('timeLockTypes', JSON.stringify(filters.timeLockTypes))
      .append('fileTypes', JSON.stringify(filters.fileTypes))
      .append('page', filters.page.toString())
      .append('results', filters.results.toString())
      .append('keywords', filters.keywords || '');
    return this.http.get<MemorySearchResult>(`${AppConfig.settings.apiBaseUrl}/public-bio/${bio.slug}/memory/search`, {params});
  }

}
