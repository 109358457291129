import {AfterViewInit, Component, ElementRef, ViewChild, ViewEncapsulation} from '@angular/core';
import {environment} from '../../../../environments/environment';

@Component({
  templateUrl: 'public-layout.component.html',
  styleUrls: ['public-layout.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PublicLayoutComponent implements AfterViewInit {
  public env = environment;
  constructor() {}
  public ngAfterViewInit(): void {}
}
