import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable, Subscription} from 'rxjs';
import {Bio} from '../models/bio.model';
import {ResponseMessage} from '../models/response-message.model';
import {BioSearchResult} from '../models/bio-search-result.model';
import {TribeFilterModel} from '../models/tribe-filter.model';
import {select, Store} from '@ngrx/store';
import {selectCurrentBio} from '../../store/bios/bios.selectors';
import {AppState} from '../../store';
import {TribeActivityResult} from '../models/tribe-activity-result.model';
import {Tribe} from '../models/tribe.model';
import {AppConfig} from './app-config.service';

@Injectable({providedIn: 'root'})
export class TribeService implements OnDestroy {
  private readonly subscription: Subscription;
  private bio: Bio;

  constructor(
    private http: HttpClient,
    protected store: Store<AppState>
  ) {

    this.subscription = new Subscription();
    this.subscription.add(this.store.pipe(select(selectCurrentBio))
      .subscribe((bio: Bio) => {
        this.bio = bio;
      })
    );
  }

  public getTribeActivity(): Observable<TribeActivityResult> {
    return this.http.get<TribeActivityResult>(`${AppConfig.settings.apiBaseUrl}/tribe/activity`);
  }

  public search(filters: TribeFilterModel): Observable<BioSearchResult> {

    const params = new HttpParams()
      .append('bio', filters.bio.toString() )
      .append('excludeBios', JSON.stringify(filters.excludeBios) )
      .append('includeLife', filters.includeLife ? '1' : '0' )
      .append('includeLegacy', filters.includeLegacy ? '1' : '0')
      .append('keywords', filters.keywords || '' )
      .append('page', filters.page.toString() )
      .append('results', filters.results.toString() );

    return this.http.get<BioSearchResult>(`${AppConfig.settings.apiBaseUrl}/bio/tribe/search`, {params});
  }

  public sendTribeRequest(model: any, bioToId: number, bioFromId?: number): Observable<ResponseMessage> {

    // Use the Current Bio
    if (!bioFromId) {
      bioFromId = this.bio.id;
    }

    return this.http.post<ResponseMessage>(`${AppConfig.settings.apiBaseUrl}/tribe/request`, {
      relationshipType: model.relationshipType,
      relationshipSubType: model.relationshipSubType,
      relationshipOther: model.relationshipOther || '',
      bioFrom: bioFromId,
      bioTo: bioToId
    });
  }

  public updateTribeRequest(model: any, tribe: Tribe): Observable<ResponseMessage> {
    return this.http.patch<ResponseMessage>(`${AppConfig.settings.apiBaseUrl}/tribe/update`, {
      relationshipType: model.relationshipType,
      relationshipSubType: model.relationshipSubType,
      relationshipOther: model.relationshipOther || '',
      bioFrom: tribe.bioFrom,
      bioTo: tribe.bioTo
    });
  }

  public resendTribeRequest(bioToId: number): Observable<ResponseMessage> {
    return this.http.post<ResponseMessage>(`${AppConfig.settings.apiBaseUrl}/tribe/resend/request`, {
      bioTo: bioToId
    });
  }



  public acceptTribeRequest(bioToId: number, bioFromId?: number): Observable<ResponseMessage> {

    // Use the Current Bio
    if (!bioFromId) {
      bioFromId = this.bio.id;
    }

    return this.http.post<ResponseMessage>(`${AppConfig.settings.apiBaseUrl}/tribe/accept`, {
      bioFrom: bioFromId,
      bioTo: bioToId
    });
  }

  public cancelTribeRequest(bioToId: number, bioFromId?: number): Observable<ResponseMessage> {

    // Use the Current Bio
    if (!bioFromId) {
      bioFromId = this.bio.id;
    }

    return this.http.post<ResponseMessage>(`${AppConfig.settings.apiBaseUrl}/tribe/remove`, {
      bioFrom: bioFromId,
      bioTo: bioToId
    });
  }

  // todo: move to tribe service
  public inviteFriend(model: any, bio?: Bio): Observable<ResponseMessage> {
    return this.http.post<ResponseMessage>(`${AppConfig.settings.apiBaseUrl}/auth/invite-friend`, {
      email: model.email,
      firstName: model.firstName,
      lastName: model.lastName,
      relationshipType: model.relationshipType,
      relationshipSubType: model.relationshipSubType,
      relationshipOther: model.relationshipOther || '',
      bio: bio != null
        ? bio.id
        : null
    });
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
