<div *ngIf="bio && user && userSubscription" class="user-plan-stats-box">

  <ul class="list list-subscription-details" *ngIf="userSubscription">
    <li class="item item-detail" [ngClass]="{'text-danger':(userSubscription.memories > 0 && bio.memories >= userSubscription.memories )}">
      <div>
        {{ bio.memories }} /
        <span *ngIf="userSubscription.memories > 0">{{ userSubscription.memories }}</span>
        <span *ngIf="userSubscription.memories == 0"> Unlimited </span>
        Memories <sup>(per Profile)</sup>
      </div>
    </li>

    <li class="item item-detail" [ngClass]="{'text-danger':(userSubscription.things > 0 && bio.things >= userSubscription.things )}">
      <div>
        {{ bio.things }} /
        <span *ngIf="userSubscription.things > 0">{{ userSubscription.things }}</span>
        <span *ngIf="userSubscription.things==0">Unlimited </span>
        Things <sup>(per Profile)</sup>
      </div>
    </li>

    <li class="item item-detail" [ngClass]="{'text-danger':(userSubscription.passwords > 0 && bio.passwords >= userSubscription.passwords )}">
      <div>
        {{ bio.passwords }} /
        <span *ngIf="userSubscription.passwords > 0">{{ userSubscription.passwords }}</span>
        <span *ngIf="userSubscription.passwords==0">Unlimited </span>
        Passwords <sup>(per Profile)</sup>
      </div>
    </li>

    <li class="item item-detail" [ngClass]="{'text-danger':(userSubscription.bios > 0 && user.ownedBios >= userSubscription.bios )}">
      <div>
        {{ user.ownedBios }} /
        <span *ngIf="userSubscription.bios > 0">{{ userSubscription.bios }}</span>
        <span *ngIf="userSubscription.bios==0">Unlimited </span>
        Life Profiles
      </div>
    </li>

    <li class="item item-detail" [ngClass]="{'text-danger':(userSubscription.legacyBios > 0 && user.ownedLegacyBios >= userSubscription.legacyBios )}">
      <div>
        {{ user.ownedLegacyBios }} /
        <span *ngIf="userSubscription.legacyBios > 0">{{ userSubscription.legacyBios }}</span>
        <span *ngIf="userSubscription.legacyBios==0">Unlimited </span>
        Legacy Profiles
      </div>
    </li>
  </ul>

</div><!-- /user-plan-stats-box -->
