
<div class="modal-header modal-password-view">
  <h4 class="modal-title">
    {{ memory?.title }}
    <div class="small">
      <span class="btn btn-sm btn-gold"><fa-icon [icon]="faTag"></fa-icon>
        {{ jar?.title }}</span>
      <span class="btn btn-sm btn-blue ml-2"><fa-icon [icon]="faClock"></fa-icon>
        {{ (memory?.timeLock|options:'timeLock')?.label }}</span>
      <span class="btn btn-sm btn-blue ml-2"><fa-icon [icon]="faEye"></fa-icon>
        {{ (memory?.secretKeeper|options:'secretKeeper')?.label }}</span>
    </div>
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="dismissModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body modal-password-view">

  <div *ngIf="password" class="row">
    <div class="col-12">
      <ul class="password-dataset clearfix">

        <li *ngIf="password.username" class="password-data">
          <label class="password-data__label">Username / Email / Account ID</label>
          <div class="password-data__value">
            {{ password.username }}
          </div>
        </li>

        <li *ngIf="password.password" class="password-data">
          <label class="password-data__label">Password</label>
          <div class="password-data__value">
            <span *ngIf="!showPassword">**********</span>
            <span *ngIf="showPassword">{{ password.password }}</span>
            <button class="btn btn-sm" type="button" (click)="onShowPassword($event)">
              <span class="sr-only">Show/Hide</span>
              <fa-icon [icon]=" showPassword ? faEye : faEyeSlash"></fa-icon>
            </button>
          </div>
        </li>

        <li *ngIf="password.url" class="password-data">
          <label class="password-data__label">Website Address (URL)</label>
          <div class="password-data__value">
            {{ password.url }}
          </div>
        </li>

        <li *ngIf="password.mobile" class="password-data">
          <label class="password-data__label">Recovery/Associated Phone</label>
          <div class="password-data__value">
            {{ password.mobile }}
          </div>
        </li>

        <li *ngIf="password.email" class="password-data">
          <label class="password-data__label">Recovery/Associated Email</label>
          <div class="password-data__value">
            {{ password.email }}
          </div>
        </li>

        <li *ngIf="password.accountId" class="password-data">
          <label class="password-data__label">Membership # / Account # / Security #</label>
          <div class="password-data__value">
            {{ password.accountId }}
          </div>
        </li>

      </ul>

      <div *ngIf="memory?.description" class="password-data">
        <label class="password-data__label">Instructions</label>
        <app-expandable-text [text]="memory?.description" [minLength]="400"></app-expandable-text>
      </div>

    </div>
  </div>

</div>
<div class="modal-footer modal-memory-view">
  <button type="button" class="btn btn-outline-dark" (click)="dismissModal()">
    Close
  </button>
</div>
<app-content-loader></app-content-loader>
