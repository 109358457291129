import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertService} from '../../../../../shared/components/alert/alert.service';
import { FormBuilder} from '@angular/forms';
import {BioService} from '../../../../../core/services/bio.service';
import {AppDateFormatter} from '../../../../../core/format/date.format';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../store';
import {BioUserService} from '../../../../../core/services/bio-user.service';
import {LayoutService} from '../../../../../core/services/layout.service';
import {AbstractBioEditComponent} from '../abstract-bio-edit.component';

@Component({
  selector: 'app-bio-edit-person',
  templateUrl: 'bio-edit-person.component.html'
})
export class BioEditPersonComponent extends AbstractBioEditComponent{
  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected modalService: NgbModal,
    protected formBuilder: FormBuilder,
    protected store: Store<AppState>,
    protected bioService: BioService,
    protected bioUserService: BioUserService,
    protected alertService: AlertService,
    protected dateFormatter: AppDateFormatter,
    protected layoutService: LayoutService
  ) {
    super(router, route, modalService, formBuilder, store, bioService, bioUserService, alertService, dateFormatter, layoutService);
  }
}
