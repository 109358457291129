<app-page-header title="Edit Profile">
  <app-bio-share-link [bio]="bio"></app-bio-share-link>
</app-page-header>

<div class="container bio-edit-container">
  <div class="row">
    <div class="col-12">

      <div class="card card-bio">

        <div class="card-body">
          <div class="row">
            <div class="col-sm-4">

              <div class="bio-profile" [appBioTypeClass]="bio?.type">
                <app-file-lazy-loader [bio]="bio" [file]="bio?.profile"></app-file-lazy-loader>

                <button type="button" class="btn btn-sm btn-hollow" (click)="openProfilePicker($event)">
                  <fa-icon [icon]="faPencilAlt"></fa-icon>
                  <span class="sr-only">Edit</span>
                </button>
              </div>

              <div class="bio-summary mt-5">
                <ul>

                  <li *ngIf="bio?.emails?.length">
                    <label>Email</label>
                    <p>{{bio.emails[0]}}</p>
                  </li>

                  <li *ngIf="bio?.phones?.length">
                    <label>Phone</label>
                    <p>{{bio.phones[0]}}</p>
                  </li>

                  <li *ngIf="bio?.religion">
                    <label>Religion</label>
                    <p>{{ (bio.religion | options:'religion')?.label }}</p>
                  </li>

                  <li *ngIf="bio?.ethnicity">
                    <label>Ethnicity</label>
                    <p>{{ (bio.ethnicity| options:'ethnicity')?.label }}</p>
                  </li>

                </ul>
              </div>

            </div>
            <div class="col-sm-8">

              <div *ngIf="bio && (!bio.isLegacy || (!bio.isDefault && bio.isOwner))"
                   class="bio-options-menu float-right" ngbDropdown placement="bottom-right">
                <div class="profile-name" ngbDropdownToggle aria-haspopup="true" aria-expanded="false">
                  <a> Options </a>
                </div>

                <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>

                  <a class="dropdown-item" *ngIf="!bio.isLegacy" (click)="openLegacyTransitionModal($event)">
                    <app-ico-candle></app-ico-candle>
                    Transition to Legacy
                  </a>

                  <a class="dropdown-item" *ngIf="!bio.isDefault && bio.isOwner" (click)="transfer($event)">
                    <fa-icon [icon]="faExchangeAlt"></fa-icon>
                    Transfer Ownership
                  </a>

                  <a class="dropdown-item" *ngIf="!bio.isDefault && bio.isOwner" (click)="delete($event)">
                    <fa-icon [icon]="faUserTimes"></fa-icon>
                    Permanent Delete
                  </a>

                </div>

              </div>

              <h1 class="bio-edit-name">
                {{ bio?.firstName }} {{ bio?.lastName }}
                <app-ico-candle *ngIf="bio?.isLegacy"></app-ico-candle>
              </h1>

              <form-validation [control]="bioForm" [submitted]="submittedShowErrors"></form-validation>

              <form [formGroup]="bioForm" (ngSubmit)="onSubmit()">

                <div *ngIf="bio?.isLegacy" class="mb-5">

                  <div *ngIf="f.deathday" class="form-group">
                    <label class="control-label">Date of Passing</label>

                    <div class="input-group btn-in-text-box">
                      <input ngbDatepicker type="text" formControlName="deathday"
                             class="form-control form-control-lg input-bio"
                             [ngClass]="{ 'is-invalid': submittedShowErrors && f.deathday.errors }"
                             placeholder="DD/MM/YYYY" aria-label="DD/MM/YYYY"
                             #d2="ngbDatepicker" [placement]="['left']"/>
                      <div class="input-group-append btn-outline-secondary">
                        <button class="btn" type="button" (click)="d2.toggle()">
                          <span class="sr-only">Calendar</span>
                          <fa-icon [icon]="faCalendar"></fa-icon>
                        </button>
                      </div>
                    </div>

                    <app-control-validation [control]="f.deathday"
                                            [submitted]="submittedShowErrors"></app-control-validation>
                  </div>

                  <div *ngIf="f.restingLocation" class="form-group">
                    <label class="control-label">Add Resting Pin</label>

                    <div class="input-group btn-in-text-box">
                      <input (click)="onLocationChange($event)" type="text" formControlName="restingLocation"
                             class="form-control form-control-lg input-bio"
                             [ngClass]="{ 'is-invalid': submittedShowErrors && f.restingLocation.errors }"
                             placeholder="Resting Location" aria-label="Resting Location"/>
                      <div class="input-group-append btn-outline-secondary">
                        <button class="btn" type="button" (click)="onLocationChange($event)">
                          <span class="sr-only">Location</span>
                          <fa-icon [icon]="faMapMarker"></fa-icon>
                        </button>
                      </div>
                    </div>
                    <app-control-validation [control]="f.restingLocation"
                                            [submitted]="submittedShowErrors"></app-control-validation>

                  </div>

                  <div class="form-group text-center">
                    <div class="custom-control custom-checkbox form-control-lg">
                      <input  id="allowComments" class="custom-control-input" type="checkbox" formControlName="allowComments"
                              [ngClass]="{ 'is-invalid': submittedShowErrors && f.allowComments.errors }"/>
                      <label for="allowComments" class="custom-control-label">
                        Allow Tributes
                      </label>
                    </div>
                  </div>

                </div>


                <div class="form-group">
                  <label class="control-label">First Given Name</label>
                  <input type="text" formControlName="firstName" class="form-control form-control-lg input-bio"
                         [ngClass]="{ 'is-invalid': submittedShowErrors && f.firstName.errors }"
                         placeholder="First name"/>
                  <app-control-validation [control]="f.firstName"
                                          [submitted]="submittedShowErrors"></app-control-validation>
                </div>

                <div class="form-group">
                  <div formArrayName="middleNames">
                    <label class="control-label">Middle Given Name(s)</label>
                    <ul *ngIf="middleNames" [sortablejs]="middleNames.controls" [sortablejsOptions]="sortOptions">

                      <li class="btn-in-text-box input-group mb-3"
                          *ngFor="let middleName of middleNames.controls; let i=index">
                        <div class="input-group-prepend">
                          <button class="btn sort-handle" type="button">
                            <fa-icon [icon]="faArrowsAlt"></fa-icon>
                          </button>
                        </div>
                        <input type="text" formControlName={{i}} class="form-control form-control-lg input-bio">
                        <div class="input-group-append">
                          <button (click)="removeMiddleName(i)" class="btn btn-outline-secondary" type="button">X
                          </button>
                        </div>
                      </li>

                    </ul>

                    <a (click)="addMiddleName()" class="text-gold">+ Add Middle Given Name(s)</a>
                  </div>
                </div>

                <div class="form-group">
                  <label class="control-label">Last Legal Name</label>
                  <input type="text" formControlName="lastName" class="form-control form-control-lg input-bio"
                         [ngClass]="{ 'is-invalid': submittedShowErrors && f.lastName.errors }"
                         placeholder="Last Name"/>
                  <app-control-validation [control]="f.lastName"
                                          [submitted]="submittedShowErrors"></app-control-validation>
                </div>

                <div class="form-group">
                  <div formArrayName="previousNames">
                    <label class="control-label">Previous Last Name(s)</label>

                    <ul *ngIf="previousNames" [sortablejs]="previousNames.controls" [sortablejsOptions]="sortOptions">

                      <li class="btn-in-text-box input-group mb-3"
                          *ngFor="let previousName of previousNames.controls; let i=index">
                        <div class="input-group-prepend">
                          <button class="btn sort-handle" type="button">
                            <fa-icon [icon]="faArrowsAlt"></fa-icon>
                          </button>
                        </div>
                        <input type="text" formControlName={{i}} class="form-control form-control-lg input-bio">
                        <div class="input-group-append">
                          <button (click)="removePreviousName(i)" class="btn btn-outline-secondary" type="button">X
                          </button>
                        </div>
                      </li>

                    </ul>

                    <a (click)="addPreviousName()" class="text-gold">+ Add Previous Name</a>
                  </div>
                </div>


                <div class="form-group">
                  <div formArrayName="preferredNames">
                    <label class="control-label">AKA or Preferred Name(s)</label>

                    <ul *ngIf="preferredNames" [sortablejs]="preferredNames.controls"
                        [sortablejsOptions]="sortOptions">

                      <li class="btn-in-text-box input-group mb-3"
                          *ngFor="let preferredName of preferredNames.controls; let i=index">
                        <div class="input-group-prepend">
                          <button class="btn sort-handle" type="button">
                            <fa-icon [icon]="faArrowsAlt"></fa-icon>
                          </button>
                        </div>
                        <input type="text" formControlName={{i}} class="form-control form-control-lg input-bio">
                        <div class="input-group-append">
                          <button (click)="removePreferredName(i)" class="btn btn-outline-secondary" type="button">X
                          </button>
                        </div>
                      </li>

                    </ul>

                    <a (click)="addPreferredName()" class="text-gold">+ Add AKA or Preferred Name</a>
                  </div>
                </div>

                <div class="form-group mt-5">
                  <label class="control-label">Date of Birth</label>

                  <div class="input-group btn-in-text-box">
                    <input ngbDatepicker type="text" formControlName="birthday"
                           class="form-control form-control-lg input-bio"
                           [ngClass]="{ 'is-invalid': submittedShowErrors && f.birthday.errors }"
                           placeholder="DD/MM/YYYY" aria-label="Date of Birth"
                           #d="ngbDatepicker" [placement]="['left']"/>
                    <div class="input-group-append btn-outline-secondary">
                      <button class="btn" type="button" (click)="d.toggle()">
                        <span class="sr-only">Calendar</span>
                        <fa-icon [icon]="faCalendar"></fa-icon>
                      </button>
                    </div>
                  </div>

                  <app-control-validation [control]="f.birthday"
                                          [submitted]="submittedShowErrors"></app-control-validation>
                </div>

                <div class="form-group">
                  <label class="control-label">Gender</label>

                  <div class="btn-group btn-group-lg d-flex btn-group-toggle" ngbRadioGroup formControlName="gender">

                    <label *ngFor="let item of GenderTypes; let index = index;" class="btn btn-hollow"
                           [ngClass]="{ 'is-invalid': submittedShowErrors && f.gender.errors }" ngbButtonLabel
                           aria-label="Gender">
                      <input type="radio" ngbButton [value]="item.id">
                      {{ item.label }}
                    </label>

                  </div>
                  <app-control-validation [control]="f.gender"
                                          [submitted]="submittedShowErrors"></app-control-validation>
                </div>


                <div *ngIf="f.emails" class="form-group mt-5">
                  <div formArrayName="emails">
                    <label class="control-label">Email(s)</label>
                    <ul *ngIf="emails" class="list-unstyled" [sortablejs]="emails.controls" [sortablejsOptions]="sortOptions">

                      <li class="mb-3" *ngFor="let email of emails.controls; let i=index">
                        <div class="btn-in-text-box input-group">
                          <div class="input-group-prepend">
                            <button class="btn sort-handle" type="button">
                              <fa-icon [icon]="faArrowsAlt"></fa-icon>
                            </button>
                          </div>
                          <input type="text" formControlName={{i}} class="form-control form-control-lg input-bio"
                                 [ngClass]="{ 'is-invalid': submittedShowErrors && email.errors }">
                          <div class="input-group-append">
                            <button (click)="removeEmail(i)" class="btn btn-outline-secondary" type="button">X
                            </button>
                          </div>
                        </div>
                        <app-control-validation [control]="email"
                                                [submitted]="submittedShowErrors"></app-control-validation>
                      </li>

                    </ul>

                    <a (click)="addEmail()" class="text-gold">+ Add email(s)</a>
                  </div>
                </div>

                <div *ngIf="f.phones" class="form-group">
                  <div formArrayName="phones">
                    <label class="control-label">Phone(s)</label>
                    <ul *ngIf="phones" class="list-unstyled" [sortablejs]="phones.controls" [sortablejsOptions]="sortOptions">

                      <li class="mb-3" *ngFor="let phone of phones.controls; let i=index">
                        <div class="btn-in-text-box input-group">
                          <div class="input-group-prepend">
                            <button class="btn sort-handle" type="button">
                              <fa-icon [icon]="faArrowsAlt"></fa-icon>
                            </button>
                          </div>
                          <input type="text" formControlName={{i}} class="form-control form-control-lg input-bio"
                                 [ngClass]="{ 'is-invalid': submittedShowErrors && phone.errors }">
                          <div class="input-group-append">
                            <button (click)="removePhone(i)" class="btn btn-outline-secondary" type="button">X
                            </button>
                          </div>
                        </div>
                        <app-control-validation [control]="phone"
                                                [submitted]="submittedShowErrors"></app-control-validation>
                      </li>

                    </ul>

                    <a (click)="addPhone()" class="text-gold">+ Add phone(s)</a>
                  </div>
                </div>


                <div class="form-group mt-5">
                  <div formArrayName="addresses">
                    <label class="control-label">Address</label>
                    <div class="addresses-box card mb-3" *ngFor="let address of addresses.controls; let i=index">
                      <div class="card-body">
                        <app-common-address formControlName={{i}} [withTypeField]="true"
                                            [submitted]="submittedShowErrors"></app-common-address>
                        <app-control-validation [control]="f.addresses"
                                                [submitted]="submittedShowErrors"></app-control-validation>
                        <a (click)="removeAddress(i)" class="remove-link">- Remove Address</a>
                      </div>
                    </div>
                    <a (click)="addAddress()" class="text-gold">+ Add Address</a>
                  </div>
                </div>

                <div class="form-group mt-5">
                  <label class="control-label">Ethnicity</label>
                  <select formControlName="ethnicity" class="form-control form-control-lg input-bio"
                          [ngClass]="{ 'is-invalid': submittedShowErrors && f.ethnicity.errors }"
                          aria-label="Ethnicity">
                    <option [ngValue]="null" selected>Select one...</option>
                    <optgroup *ngFor="let group of ethnicityChoices" label="{{group.label}}">
                      <option *ngFor="let item of group.options" [ngValue]="item.id">{{ item.label }}</option>
                    </optgroup>

                  </select>
                  <app-control-validation [control]="f.ethnicity"
                                          [submitted]="submittedShowErrors"></app-control-validation>
                </div>

                <div class="form-group">
                  <label class="control-label">Religion</label>
                  <select formControlName="religion" class="form-control form-control-lg input-bio"
                          [ngClass]="{ 'is-invalid': submittedShowErrors && f.religion.errors }"
                          aria-label="Religion">
                    <option [ngValue]="null" selected>Select one...</option>
                    <option *ngFor="let item of Religions" [ngValue]="item.id">{{ item.label }}</option>
                  </select>
                  <app-control-validation [control]="f.religion"
                                          [submitted]="submittedShowErrors"></app-control-validation>
                </div>

                <div class="form-group">
                  <label class="control-label">Practicing Religion</label>

                  <div class="btn-group btn-group-lg d-flex btn-group-toggle" ngbRadioGroup
                       formControlName="practicingReligion">

                    <label *ngFor="let item of PracticingReligionStatuses; let index = index;" class="btn btn-hollow"
                           [ngClass]="{ 'is-invalid': submittedShowErrors && f.practicingReligion.errors }"
                           ngbButtonLabel aria-label="Practicing Religion">
                      <input type="radio" ngbButton [value]="item.id">
                      {{ item.label }}
                    </label>

                  </div>
                  <app-control-validation [control]="f.practicingReligion"
                                          [submitted]="submittedShowErrors"></app-control-validation>
                </div>

                <div class="form-group">
                  <label class="control-label">Customs or Traditions</label>

                  <ng-select
                    class="ng-select-lg input-bio "
                    [ngClass]="{ 'is-invalid': submittedShowErrors && f.customTradition.errors }"
                    [items]="CustomTraditionChoices"
                    [multiple]="true"
                    bindValue="id"
                    bindLabel="label"
                    groupBy="type"
                    [selectableGroup]="false"
                    [closeOnSelect]="false"
                    placeholder="Select Customs or Traditions"
                    formControlName="customTradition"
                  >
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                      <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" /> {{item.label}}
                    </ng-template>
                  </ng-select>

                  <app-control-validation [control]="f.customTradition"
                                          [submitted]="submittedShowErrors"></app-control-validation>
                </div>



                <div class="form-group mt-5">
                  <label class="control-label">Privacy</label>
                  <select formControlName="privacy" class="form-control form-control-lg input-bio"
                          [ngClass]="{ 'is-invalid': submittedShowErrors && f.privacy.errors }"
                          aria-label="Privacy">
                    <option [ngValue]="null" selected>Select one...</option>
                    <option *ngFor="let item of PrivacyChoices" [ngValue]="item.id">{{ item.label }}</option>
                  </select>
                  <app-control-validation [control]="f.privacy"
                                          [submitted]="submittedShowErrors"></app-control-validation>
                </div>


                <div class="form-group">
                  <button class="btn btn-lg btn-block btn-gold">
                    Update
                  </button>
                </div>

              </form>

            </div>
          </div>
        </div>

      </div><!--/card -->

      <a id="guardians" name="guardians"></a>
      <div class="card card-bio mt-5">
        <div class="card-body">

          <div class="row">
            <div class="col-sm-6">
              <h2>My Guardians</h2>
            </div>
            <div class="col-sm-6 text-sm-right">

              <div class="block block-btns">
                <button class="btn btn-gold" type="button" (click)="openGuardianSearchModal($event)">
                  <fa-icon [icon]="faPlusCircle"></fa-icon>
                  Invite a Guardian
                </button>
              </div>

            </div>
          </div>

          <div class="row">
            <div class="col-12">

              <div *ngIf="bioUsers" class="tribe-active-container mt-4">
                <app-guardian-list [list]="bioUsers"
                                   [editable]="true"
                                   (edit)="openGuardianPermissionsModal($event)"
                                   [removable]="true"
                                   (remove)="removeGuardian($event)"></app-guardian-list>
              </div>

            </div>
          </div>

        </div>
      </div><!--/card -->

    </div>
  </div>

</div><!--/container-->
