<div class="bio bio-secured" [ngClass]="{'sidebar-left-active':sideBarIsVisible}">

  <app-secured-header></app-secured-header>

  <main #main class="main main-secured">

    <app-secured-sidebar [navIsVisible]="true"></app-secured-sidebar>

    <section class="section section-dashboard">
      <router-outlet></router-outlet>
      <app-content-loader></app-content-loader>
    </section>

  </main><!-- /main -->
  <div class="bio-build-ver">Build: {{env.version}}</div>

  <app-alert></app-alert>
  <div class="bio-modal-container"><!-- Hacky way to have the modals behind alerts --></div>
</div><!--/bio-->

