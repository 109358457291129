import {Component, OnDestroy, OnInit} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import {AuthService} from '../../../core/services/auth.service';
import {AlertService} from '../../../shared/components/alert/alert.service';
import {ControlValidationService} from '../../../shared/components/control-validation/control-validation.service';
import {Subscription} from 'rxjs';
import {first} from 'rxjs/operators';
import {LoaderService} from '../../../shared/components/content-loader/loader.service';
import {LayoutService} from '../../../core/services/layout.service';

@Component({ templateUrl: 'password-change.component.html' })
export class PasswordChangeComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  public passwordChangeForm: FormGroup;
  public loading = false;
  public hashError = false;
  public submitted = false;
  private hash: string;

  // convenience getter for easy access to form fields
  public get f() { return this.passwordChangeForm.controls; }

  constructor(
    public router: Router,
    protected formBuilder: FormBuilder,
    protected route: ActivatedRoute,
    protected authService: AuthService,
    protected alertService: AlertService,
    protected loaderService: LoaderService,
    protected layoutService: LayoutService
  ) {

    this.layoutService.setTitle( `Password Change - My LifeJars`  );
  }

  public ngOnInit() {

    this.subscription = this.route.params.subscribe(params => {
      this.hash = params['hash'];
    });

    this.passwordChangeForm = this.formBuilder.group({
      password: ['', [Validators.required, ControlValidationService.passwordValidator] ],
      passwordConfirm: ['', [Validators.required, ControlValidationService.passwordValidator] ]
    }, { validator: ControlValidationService.RepeatPasswordValidator });

  }

  public onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.passwordChangeForm.invalid) {
      return;
    }

    this.loading = this.hashError = true;
    this.loaderService.showLoader();
    this.authService.passwordChange(this.hash, this.f.password.value, this.f.passwordConfirm.value)
      .pipe( first() )
      .subscribe(
        data => {
          this.loading = false;
          this.loaderService.hideLoader();
          this.alertService.info(data.message);
        },
        error => {
          this.hashError = true;
          this.loading = false;
          this.loaderService.hideLoader();
        });
  }


  ngOnDestroy() {
    if ( this.subscription instanceof Subscription) {
      this.subscription.unsubscribe();
    }
  }


}
