import {Component, Input, OnChanges} from '@angular/core';
import {AbstractListDirective} from '../abstract-list.directive';

@Component({
  selector: 'app-tribester-list',
  templateUrl: '../abstract-list.directive.html'
})
export class TribesterListComponent extends AbstractListDirective implements OnChanges {

  // We need to flatten this array so that it only includes the Bios
  // e.g. [ { user:{...}, bio:{...}, other:{...} } ] converted to [{...},{...}]
  @Input() public list: any[] = [];
  public ngOnChanges(): void {
    const newList = [];
    this.list.forEach(x => {
      newList.push(x.bio);
    });
    this.list = newList;
  }

}
