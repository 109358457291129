import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faSearch, faPlusCircle, faPencilAlt, faCaretRight } from '@fortawesome/free-solid-svg-icons';

@Component({ templateUrl: 'wishes.component.html' })
export class WishesComponent implements OnInit {
  public faSearch = faSearch;
  constructor( private router: Router ) {
  }
  public ngOnInit() {}
}
