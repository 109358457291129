import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {AuthService} from '../../../core/services/auth.service';
import {AlertService} from '../../../shared/components/alert/alert.service';
import {ControlValidationService} from '../../../shared/components/control-validation/control-validation.service';
import {Subscription} from 'rxjs';
import {first} from 'rxjs/operators';
import {LayoutService} from '../../../core/services/layout.service';
import {AppState} from '../../../store';
import {Store} from '@ngrx/store';
import {isPlatformBrowser} from '@angular/common';

@Component({
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  private readonly subscription: Subscription;
  public loginForm: FormGroup;
  public loading = false;
  public submitted = false;
  private returnUrl: string;
  private hash: string;

  // convenience getter for easy access to form fields
  public get f() {
    return this.loginForm.controls;
  }

  constructor(
    @Inject(PLATFORM_ID) protected platformId,
    protected formBuilder: FormBuilder,
    protected route: ActivatedRoute,
    protected router: Router,
    protected store: Store<AppState>,
    protected authService: AuthService,
    protected alertService: AlertService,
    protected layoutService: LayoutService
  ) {
    this.subscription = new Subscription();

    // redirect to dashboard if already logged in
    if (this.authService.hasAuthenticated()) {
      this.router.navigate(['/']);
    }

    this.layoutService.setTitle(`Sign in - My LifeJars`);
  }

  public ngOnInit() {
    const isBrowser = isPlatformBrowser(this.platformId);

    // This is to handle a registration verification request
    // !! We don't want this to trigger Server Side !!
    if (isBrowser === true) {
      this.subscription.add(this.route.params.subscribe(params => {
          this.hash = params.hash;
          if (this.hash) {
            this.authService.verify(this.hash)
              .pipe(first())
              .subscribe(
                data => {
                  this.alertService.info(data.message);
                });
          }
        })
      );
    }

    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required, ControlValidationService.emailValidator]],
      password: ['', Validators.required],
      rememberMe: [false]
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
  }

  private executeGtm(){
    const isBrowser = isPlatformBrowser(this.platformId);
    if (isBrowser === true) {
      // @ts-ignore
      window.dataLayer = window.dataLayer || [];
      const gtmTag = {
        event: 'User Signed In',
        properties: {
          object: 'User',
          action: 'Signed In',
          user_id: '0',
          email: this.f.username.value,
          first_name: 'Unknown',
          last_name: 'Unknown',
        },
      };
      // @ts-ignore
      window.dataLayer.push(gtmTag);
      console.log('GTM: User Signed In Complete');
    }
  }

  public onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.authService.loginStep1(this.f.username.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        (data) => {
          this.executeGtm();
          this.alertService.info(data.message);
          if (data.data != null && data.data.enable2fa === true) {
            this.router.navigate(['login-code']);
          } else {
            this.router.navigate(['']);
          }
        });
  }

  public showTerms(): string {
    return this.layoutService.showTerms();
  }

  public showPrivacy(): string {
    return this.layoutService.showPrivacy();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
