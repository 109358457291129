import {Component} from '@angular/core';
import {MemoryDefaultModalComponent} from '../memory-default/memory-default-modal.component';
import {AppStoreUtils} from '../../../../store/app-store.utils';

@Component({
  templateUrl: '../memory-default/memory-default-modal.component.html'
})
export class ThingDefaultModalComponent extends MemoryDefaultModalComponent {
  public CONCEPT_KEYWORD = 'Thing';

  public get JarTypes() {
    return this.optionsData.thingJarTypes;
  }

  public get TagTypes() {
    return this.optionsData.thingTagTypes;
  }

  public onJarChange(event) {
    const jarId = this.editForm.get('jarTag').value;
    if (jarId !== null) {
      this.tagsFiltered = AppStoreUtils.filterSubTypes(this.TagTypes, jarId);
    }
  }
}
