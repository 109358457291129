
<div class="page page-landscape page-photos-only">
  <table class="page-layout-table">
    <tbody>

    <tr *ngIf="photos?.length == 1"  class="page-layout-table-body">
      <td colspan="2" class="photos" width="100%" align="center" valign="middle">
        <div class="img-container" style="width: 127mm; height: 153mm;">
          <app-file-lazy-loader [memory]="memory" [file]="photos[0]"></app-file-lazy-loader>
        </div>
      </td>
    </tr>

    <tr *ngIf="photos?.length == 2"  class="page-layout-table-body">
      <td class="photos" width="50%" align="left" valign="top">
        <div class="img-container" style="width: 127mm; height: 153mm;">
          <app-file-lazy-loader [memory]="memory" [file]="photos[0]"></app-file-lazy-loader>
        </div>
      </td>
      <td class="photos" width="50%" align="right" valign="bottom">
        <div class="img-container" style="width: 127mm; height: 153mm;">
          <app-file-lazy-loader [memory]="memory" [file]="photos[1]"></app-file-lazy-loader>
        </div>
      </td>
    </tr>

    <tr *ngIf="photos?.length == 3"  class="page-layout-table-body">
      <td class="photos" width="50%" align="left" valign="middle">
        <div class="img-container" style="width: 127mm; height: 153mm;">
          <app-file-lazy-loader [memory]="memory" [file]="photos[0]"></app-file-lazy-loader>
        </div>
      </td>
      <td class="photos x2" width="50%" align="right" valign="top">
        <div class="img-container" style="width: 127mm; height: 75mm;">
          <app-file-lazy-loader [memory]="memory" [file]="photos[1]"></app-file-lazy-loader>
        </div>
        <div class="img-container" style="width: 127mm; height: 75mm;">
          <app-file-lazy-loader [memory]="memory" [file]="photos[2]"></app-file-lazy-loader>
        </div>
      </td>
    </tr>

    <tr *ngIf="photos?.length >= 4"  class="page-layout-table-body">
      <td class="photos x2" width="50%" align="center" valign="middle">
        <div class="img-container" style="width: 127mm; height: 75mm;">
          <app-file-lazy-loader [memory]="memory" [file]="photos[0]"></app-file-lazy-loader>
        </div>
        <div class="img-container" style="width: 127mm; height: 75mm;">
          <app-file-lazy-loader [memory]="memory" [file]="photos[1]"></app-file-lazy-loader>
        </div>
      </td>
      <td class="photos x2" width="50%" align="center" valign="middle">
        <div class="img-container" style="width: 127mm; height: 75mm;">
          <app-file-lazy-loader [memory]="memory" [file]="photos[2]"></app-file-lazy-loader>
        </div>
        <div class="img-container" style="width: 127mm; height: 75mm;">
          <app-file-lazy-loader [memory]="memory" [file]="photos[3]"></app-file-lazy-loader>
        </div>
      </td>
    </tr>

    <tr class="page-layout-table-footer">
      <td colspan="2" align="center" valign="middle">
        <app-bio-print-footer [page]="page"></app-bio-print-footer>
      </td>
    </tr>

    </tbody>
  </table>
</div><!-- /page-memory-photos -->
