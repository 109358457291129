import {Component, Input, OnInit} from '@angular/core';
import {User} from '../../../core/models/user.model';
import {UserSubscription} from '../../../core/models/user-subscription.model';
import {Bio} from '../../../core/models/bio.model';

@Component({
  selector: 'app-user-plan-stats-box',
  templateUrl: 'user-plan-stats-box.component.html',
  styleUrls: ['user-plan-stats-box.component.css']
})
export class UserPlanStatsBoxComponent implements OnInit {

  @Input()
  public user: User;

  @Input()
  public userSubscription: UserSubscription;

  @Input()
  public bio: Bio;

  constructor() {}

  public ngOnInit(): void {

  }

}
