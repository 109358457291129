import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentLoaderComponent } from './content-loader.component';

@NgModule({
  imports: [CommonModule],
  entryComponents: [],
  declarations: [ContentLoaderComponent],
  exports: [ContentLoaderComponent],
  providers: [],
})
export class ContentLoaderModule {}
