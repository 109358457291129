import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation, ViewRef} from '@angular/core';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {LayoutService} from '../../../core/services/layout.service';
import {LoaderService} from '../../../shared/components/content-loader/loader.service';
import {HtmlElementService} from '../../../core/services/html-element.service';
import {environment} from '../../../../environments/environment';

@Component({
  templateUrl: 'secured-layout.component.html',
  encapsulation: ViewEncapsulation.None
})
export class SecuredLayoutComponent implements OnInit, AfterViewInit, OnDestroy {
  public env = environment;

  public sideBarIsVisible: boolean;

  private subscription: Subscription[] = [];

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected layoutService: LayoutService,
    protected loaderService: LoaderService,
  ) {
    this.subscription.push(layoutService.SidebarStatus.subscribe((status: boolean) => {
      this.sideBarIsVisible = status;
    }));

    this.subscription.push(router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const tree = router.parseUrl(router.url);
        if (tree.fragment) {
          const element = document.querySelector('#' + tree.fragment);
          if (element) {
            element.scrollIntoView({behavior: 'smooth'});
          }
        }
      }
    }));

  }

  public ngOnInit() {

    // Scroll to top on route change
    this.subscription.push(this.router.events.subscribe(event => {
        if (event instanceof NavigationStart) {
          window.scroll(0, 0);
          this.loaderService.hideAll();
        }
      })
    );
  }

  public ngAfterViewInit(): void {}

  public ngOnDestroy() {
    this.subscription.forEach((item: Subscription) => {
      item.unsubscribe();
    });
  }

}
