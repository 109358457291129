import {AppState} from '../index';
import {createSelector} from '@ngrx/store';
import {Bio} from '../../core/models/bio.model';

export const selectUserBiosState = (state: AppState) => state.userBios;

export const selectUserBiosById = createSelector(
  selectUserBiosState,
  state => state.byId
);

export const selectUserBios = createSelector(
  selectUserBiosById,
  state => Object.keys(state).map(key => state[key])
    .filter((bio: Bio) => bio != null).sort((a: Bio, b: Bio) => {
      if (b.isDefault === true) {
        return 1;
      } else {
        if (a.firstName < b.firstName) {
          return -1;
        } else {
          return 1;
        };
      }
    })
);

export const selectUserBioById = (id: number) => createSelector(
  selectUserBiosById,
  byId => {
    return byId[id] || null;
  }
);
