import {createAction, props} from '@ngrx/store';
import {User} from '../../core/models/user.model';
import {ResponseMessage} from '../../core/models/response-message.model';
import {UserSubscription} from '../../core/models/user-subscription.model';
import {Bio} from '../../core/models/bio.model';

export const logout = createAction(
  '[Users] logout'
);

export const logoutSuccess = createAction(
  '[Users] logout success'
);

export const setCurrentUser = createAction(
  '[Users] set current user success',
  props<{ user: User }>()
);

export const incrementCurrentUserBioCounter = createAction(
  '[Users] increment current user bio counter',
  props<{ bio: Bio }>()
);

export const decrementCurrentUserBioCounter = createAction(
  '[Users] decrement current user bio counter',
  props<{ bio: Bio }>()
);

export const setCurrentUserSubscription = createAction(
  '[Users] set current user subscription success',
  props<{ userSubscription: UserSubscription }>()
);

export const getUserRequest = createAction(
  '[Users] get user request',
  props<{ id: number }>()
);

export const getUserRequestSuccess = createAction(
  '[Users] get user request success',
  props<{ user: User }>()
);

export const saveUserRequest = createAction(
  '[Users] save users request',
  props<{ user: User }>()
);

export const saveUserRequestSuccess = createAction(
  '[Users] save users request success',
  props<{ responseMessage: ResponseMessage }>()
);

export const changePasswordRequest = createAction(
  '[Users] change password request',
  props<{ oldPassword: string, password: string, passwordConfirm: string  }>()
);

export const changePasswordRequestSuccess = createAction(
  '[Users] change password request success',
  props<{ responseMessage: ResponseMessage }>()
);

export const changeEmailRequest = createAction(
  '[Users] change email request',
  props<{ email: string, emailConfirm: string  }>()
);

export const changeEmailRequestSuccess = createAction(
  '[Users] change email request success',
  props<{ responseMessage: ResponseMessage }>()
);


export const saveUserMetaDataRequest = createAction(
  '[Users] save user meta request',
  props<{ user?: User, metaData: any }>()
);

export const resetTutorialsRequest = createAction(
  '[Users] reset tutorials request',
  props<{ user?: User, metaData: any }>()
);

export const saveUserMetaDataRequestSuccess = createAction(
  '[Users] save user meta request success',
  props<{ responseMessage: ResponseMessage }>()
);

export const getUserSubscriptionRequest = createAction(
  '[Users] get user subscription request',
  props<{ id: number }>()
);

export const getUserSubscriptionRequestSuccess = createAction(
  '[Users] get user subscription request success',
  props<{ userSubscription: UserSubscription }>()
);

export const updateUserSubscriptionRequest = createAction(
  '[Users] update user subscription request',
  props<{ id: string, alertChange?: boolean, alertCancelled?: boolean }>()
);

export const updateUserSubscriptionRequestSuccess = createAction(
  '[Users] update user subscription request success',
  props<{ userSubscription: UserSubscription }>()
);


export const getOwnedBiosRequest = createAction(
  '[Users] get owned bios request'
);

export const getOwnedBiosSuccess = createAction(
  '[Users] get owned bios request success',
  props<{ bios: Bio[] }>()
);

export const deleteUserRequest = createAction(
  '[Users] delete user request'
);

export const updateLoginSettingsRequest = createAction(
  '[Users] update login settings request',
  props<{ enable2Fa: boolean; rememberMe: boolean  }>()
);

export const updateLoginSettingsSuccess = createAction(
  '[Users] update login settings success',
  props<{ responseMessage: ResponseMessage }>()
);

export const effectError = createAction(
  '[Users] effect error',
  props<{ error: any }>()
);
