import {Bio} from './bio.model';

export class Tribe {
  id: number;
  status: number;
  statusLabel: string;

  bioFrom: number;
  relationshipSubType: number;
  relationshipOther: string;
  sender: boolean;

  bioTo: number;
  inverseSubType: number;
  inverseOther: string;
  receiver: boolean;

  constructor() {
    this.id = null;
    this.status = null;
    this.statusLabel = '';
    this.bioTo = null;
    this.bioFrom = null;
    this.sender = false;
    this.receiver = false;
  }

  public static make(obj: object): Tribe {
    if ( !obj || (Object.keys(obj).length === 0) ) {
      return null; // Object is null or empty
    }
    return {...new Tribe(), ...obj };
  }

  public static makePending(toBio: Bio, fromBio: Bio, isReceiver?: boolean): Tribe {
    const model = new Tribe();
    model.bioFrom = toBio.id;
    model.bioTo = fromBio.id;
    model.status = 0;
    model.statusLabel = 'Pending';
    model.receiver = !!isReceiver;
    model.sender = !isReceiver;
    return model;
  }

  public static makeActive(toBio: Bio, fromBio: Bio, isReceiver?: boolean): Tribe {
    const model = new Tribe();
    model.bioFrom = toBio.id;
    model.bioTo = fromBio.id;
    model.status = 1;
    model.statusLabel = 'Active';
    model.receiver = !!isReceiver;
    model.sender = !isReceiver;
    return model;
  }

}
