import {initialCommentState, CommentsState} from './comments.state';
import {Action, createReducer, on} from '@ngrx/store';
import {CommentActions} from './index';

const reducer = createReducer<CommentsState>(
  initialCommentState,

  on(CommentActions.setCurrentCommentId, (state, {id}) => {
    return {
      ...state,
      currentCommentId: id
    };
  }),

  on(CommentActions.resetSearchCommentsRequest, (state) => {
    return {
      ...state,
      currentCommentId: 0,
      comments: []
    };
  }),

  on(CommentActions.searchCommentsRequestSuccess, (state, {comments, setFirstAsCurrent}) => {
    let newState = {...state, comments};
    if (setFirstAsCurrent && comments.length > 0) {
      newState = {
        ...state,
        currentCommentId: comments[0].id,
        comments,
      };
    }
    return newState;
  }),

  on(CommentActions.saveCommentRequestSuccess, (state, {responseMessage}) => {
    const comment = responseMessage.data;
    const idx = state.comments.map(m => m.id).indexOf(comment.id);
    if (idx >= 0) {

      // Update
      return {
        ...state,
        comments: [
          ...state.comments.slice(0, idx),
          comment,
          ...state.comments.slice(idx + 1),
        ]
      };
    } else {

      // Create
      return {
        ...state,
        comments: [
          comment,
          ...state.comments.slice(0)
        ]
      };
    }
  }),

  on(CommentActions.deleteCommentRequestSuccess, (state, {comment, responseMessage}) => {
    const idx = state.comments.map(m => m.id).indexOf(comment.id);
    return {
      ...state,
      comments: [
        ...state.comments.slice(0, idx),
        ...state.comments.slice(idx + 1),
      ]
    };
  })

);

export function CommentsReducer(state: CommentsState, action: Action) {
  return reducer(state, action);
}
