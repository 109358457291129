<div class="container">

  <h1>Tag Defaults</h1>

  <div class="block block-search mt-2 mb-4">
    <div class="input-group">
      <input type="text" class="form-control" placeholder="Search..." aria-label="Search My LifeJars"
             [(ngModel)]="keywords" (change)="queueSearch()">
      <div class="input-group-append">
        <button class="btn btn-block" type="button" (click)="search()">
          <span class="sr-only">Search</span>
          <fa-icon [icon]="faSearch"></fa-icon>
        </button>
      </div>
    </div>
  </div><!-- /block-search -->

  <div class="table-responsive">
    <table class="table table-striped table-bordered table-hover">
      <thead>
      <tr>
        <th>#</th>
        <th>Title</th>
        <th>Placeholder</th>
        <th>Jar</th>
        <th>Tag</th>
        <th>Secret</th>
        <th>Timelock</th>
        <th>&nbsp;</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of pagedMemories.list" >
        <td>{{ item.id }}</td>
        <td>{{ item.title }}</td>
        <td>{{ item.placeholder }}</td>
        <td>{{ item.jar?.label }}</td>
        <td>{{ (item.memoryTag|options: 'memoryTag')?.label }}</td>
        <td>{{ (item.secretKeeper|options: 'secretKeeper')?.label }}</td>
        <td>{{ (item.timeLock|options: 'timeLock')?.label }}</td>
        <td>
          <button type="button" class="btn btn-sm btn-primary" (click)="edit(item)">Edit</button>
        </td>
      </tr>
      </tbody>
    </table>
  </div><!-- /responsive-table -->

  <app-paginator [page]="pagedMemories.page" [pages]="pagedMemories.pages"
                 (pageChange)="onPageChange($event)"></app-paginator>

</div><!--/container-->
