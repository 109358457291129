import {createAction, props} from '@ngrx/store';
import {JarModel} from '../../core/models/jar.model';
import {ResponseMessage} from '../../core/models/response-message.model';
import {FileModel} from '../../core/models/file.model';
import {MemoryModel} from '../../core/models/memory.model';

export const setJarsRequest = createAction(
  '[Jars] set jars request ',
  props<{ jars: JarModel[] }>()
);

export const setCurrentJarId = createAction(
  '[Jars] set current jar id request ',
  props<{ id: number }>()
);

export const getJarRequest = createAction(
  '[Jars] get jar request',
  props<{ id: number }>()
);

export const getJarRequestSuccess = createAction(
  '[Jars] get jar request success',
  props<{ jar: JarModel }>()
);

export const saveJarRequest = createAction(
  '[Jars] save jar request',
  props<{ jar: JarModel, files: FileModel[] }>()
);

export const saveJarRequestSuccess = createAction(
  '[Jars] save jar request success',
  props<{ responseMessage: ResponseMessage }>()
);

export const deleteJarRequest = createAction(
  '[Jars] delete jar request',
  props<{ jar: JarModel }>()
);

export const deleteJarRequestSuccess = createAction(
  '[Jars] delete jar request success',
  props<{ responseMessage: ResponseMessage }>()
);

export const saveJarFileRequest = createAction(
  '[Jars] save jar file request',
  props<{ jar: JarModel, file: FileModel }>()
);

export const saveJarFileRequestSuccess = createAction(
  '[Jars] save jar file request success',
  props<{ jar: JarModel, responseMessage: ResponseMessage }>()
);

export const deleteJarFileRequest = createAction(
  '[Jars] delete jar file request',
  props<{ jar: JarModel, file: FileModel }>()
);

export const deleteJarFileRequestSuccess = createAction(
  '[Jars] delete jar file request success',
  props<{ responseMessage: ResponseMessage }>()
);

export const sortJarFilesRequest = createAction(
  '[Jars] sort jar files request',
  props<{ jar: JarModel, fileIds: number[] }>()
);

export const sortJarFilesRequestSuccess = createAction(
  '[Jars] sort jar files request success',
  props<{ responseMessage: ResponseMessage }>()
);

/*
* Need to move 'progress' tracking to store, otherwise readonly errors
*
export const uploadJarFilesRequest = createAction(
  '[Jars] upload jar files request',
  props<{ jar: JarModel, files: FileModel[] }>()
);
*/

export const uploadJarFilesRequestSuccess = createAction(
  '[Jars] upload jar files request success',
  props<{ jar: JarModel, files: FileModel[] }>()
);

export const effectError = createAction(
  '[Jars] effect error',
  props<{ error: any }>()
);
