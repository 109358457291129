<form [formGroup]="editForm" (ngSubmit)="onSubmit()">
  <div class="modal-header">
    <h4 class="modal-title">Password</h4>
    <button type="button" class="close" aria-label="Close" (click)="dismissModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

 
  <div class="modal-body modal-body-password-edit">

    <div class="modal-header-buttons d-flex justify-content-end ml-auto mt-3">
      <button type="button" class="btn btn-light mr-2" (click)="dismissModal()">
        Cancel
      </button>
      <button type="submit" class="btn btn-gold mr-2">
        Save
      </button>
    </div>

    <div class="form-group">
      <label class="control-label">Password Title</label>
      <input  type="text" formControlName="title" class="form-control form-control-lg input-bio"
             [ngClass]="{ 'is-invalid': submitted && f.title.errors }" placeholder="Password Title" aria-label="Password Title" />
      <app-control-validation [control]="f.title" [submitted]="submitted"></app-control-validation>
    </div>

    <div class="form-group row">
      <div class="col-6 col-sm-4">
        <label class="control-label">Jar</label>
        <select formControlName="parentNode" class="form-control input-bio" (change)="onJarChange($event)"
               [ngClass]="{ 'is-invalid': submitted && f.parentNode.errors }" aria-label="Jar">
          <option [ngValue]="null" disabled>Select one...</option>
          <option *ngFor="let item of MemoryJarTypes" [ngValue]="item.id">{{ item.title }}</option>
        </select>
        <app-control-validation [control]="f.parentNode" [submitted]="submitted"></app-control-validation>
      </div>

      <div class="col-6 col-sm-4">
        <label class="control-label">Secret Keeper</label>
        <select #secretKeeper formControlName="secretKeeper" class="form-control input-bio" (change)="onSecretKeeperChange($event)"
                [ngClass]="{ 'is-invalid': submitted && f.secretKeeper.errors }" aria-label="Secret Keeper">
          <option [ngValue]="null" disabled>Select one...</option>
          <option *ngFor="let item of SecretKeeperTypes" [ngValue]="item.id">{{ item.label }}</option>
        </select>
        <button type="button" class="btn btn-sm"
                *ngIf="enableTribeEditor()"
                (click)="showTribeEditor($event)">
          <fa-icon [icon]="faUsers" class="ico-gold"></fa-icon> Select Tribesters
        </button>
        <app-control-validation [control]="f.secretKeeper" [submitted]="submitted"></app-control-validation>
      </div>

      <div class="col-6 col-sm-4">
        <label class="control-label">Time Lock</label>
        <select #timeLock formControlName="timeLock" class="form-control input-bio" (change)="onTimeLockChange($event)"
                [ngClass]="{ 'is-invalid': submitted && f.timeLock.errors }" aria-label="Time Lock">
          <option [ngValue]="null" disabled>Select one...</option>
          <option *ngFor="let item of TimeLockTypes" [ngValue]="item.id">{{ item.label }}</option>
        </select>
        <app-control-validation [control]="f.timeLock" [submitted]="submitted"></app-control-validation>
      </div>

    </div>

    <div class="form-group">
      <label class="control-label">Username / Email / Account ID</label>
      <div class="input-group">
            <input  type="text" formControlName="username" class="form-control input-bio"
              [ngClass]="{ 'is-invalid': submitted && f.username.errors }" placeholder="Username / Email / Account ID" aria-label="Username/Email" />
              <div class="input-group-append btn-outline-secondary">
                <button class="btn" type="button"  (click)="copyToClipboard(f.username.value)">
                  <fa-icon [icon]="faClipboard"  ></fa-icon>
                </button>
              </div>
        </div>
      <app-control-validation [control]="f.username" [submitted]="submitted"></app-control-validation>
    </div>

    <div class="form-group">
      <label class="control-label">Password</label>

      <div class="input-group">
        <input [type]="showPassword ? 'text' : 'password'" formControlName="password" class="form-control input-bio"
               [ngClass]="{ 'is-invalid': submitted && f.password.errors }" placeholder="Password" aria-label="Password" />
        <div class="input-group-append btn-outline-secondary">
          <button class="btn" type="button" (click)="onShowPassword($event)">
            <span class="sr-only">Show/Hide</span>
            <fa-icon [icon]=" showPassword ? faEye : faEyeSlash"></fa-icon>
          </button>
        </div>
      </div>

      <app-control-validation [control]="f.password" [submitted]="submitted"></app-control-validation>
    </div>

    <p><strong>Additional:</strong></p>

    <div class="form-group">
      <label class="control-label">Website Address (URL)</label>
      <div class="input-group">
        <input  type="text" formControlName="url" class="form-control input-bio"
              [ngClass]="{ 'is-invalid': submitted && f.url.errors }" placeholder="https://www.example.com" aria-label="Website Address (URL)" />
          <div class="input-group-append btn-outline-secondary">
            <button class="btn" type="button"  (click)="copyToClipboard(f.url.value)">
              <fa-icon [icon]="faClipboard"  ></fa-icon>
            </button>
          </div>
    </div>

      
      <app-control-validation [control]="f.url" [submitted]="submitted"></app-control-validation>
    </div>

    <div class="form-group">
      <label class="control-label">Recovery/Associated Phone</label>
      
      <div class="input-group">
        <input type="text" formControlName="mobile" class="form-control input-bio"
             [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }" placeholder="Recovery Phone" aria-label="Recovery Phone" />
          <div class="input-group-append btn-outline-secondary">
            <button class="btn" type="button"  (click)="copyToClipboard(f.mobile.value)">
              <fa-icon [icon]="faClipboard"  ></fa-icon>
            </button>
          </div>
      </div>
      
      <app-control-validation [control]="f.mobile" [submitted]="submitted"></app-control-validation>
    </div>

    <div class="form-group">
      <label class="control-label">Recovery/Associated Email</label>
      <div class="input-group">
        <input type="text" formControlName="email" class="form-control input-bio"
        [ngClass]="{ 'is-invalid': submitted && f.email.errors }" placeholder="Recovery Email" aria-label="Recovery Email" />
          <div class="input-group-append btn-outline-secondary">
            <button class="btn" type="button"  (click)="copyToClipboard(f.email.value)">
              <fa-icon [icon]="faClipboard"  ></fa-icon>
            </button>
          </div>
      </div>
      
      
      <app-control-validation [control]="f.email" [submitted]="submitted"></app-control-validation>
    </div>

    <div class="form-group">
      <label class="control-label">Membership # / Account # / Security #</label>
      <div class="input-group">
       
      <input  type="text" formControlName="accountId" class="form-control input-bio"
      [ngClass]="{ 'is-invalid': submitted && f.accountId.errors }" placeholder="Membership # / Account # / Security #"
      aria-label="Membership # / Account # / Security #" />
          <div class="input-group-append btn-outline-secondary">
            <button class="btn" type="button"  (click)="copyToClipboard(f.accountId.value)">
              <fa-icon [icon]="faClipboard"  ></fa-icon>
            </button>
          </div>
      </div>
      
      <app-control-validation [control]="f.accountId" [submitted]="submitted"></app-control-validation>
    </div>

    <div class="form-group">
      <label class="control-label">
        Instructions
        <button class="btn" type="button"
                ngbPopover="Instructions are limited to 5,000 characters." popoverTitle="Instructions">
          <fa-icon [icon]="faQuestionCircle" class="ico-gold"></fa-icon>
          <span class="sr-only">Help</span>
        </button>
      </label>
      <textarea maxlength="{{DESCRIPTION_LENGTH}}" autosize formControlName="description" class="form-control input-bio"
                [ngClass]="{ 'is-invalid': submitted && f.description.errors }" placeholder="Add any additional instructions"
                aria-label="Description" rows="5" [placeholder]="memory.placeholder"></textarea>

      <app-character-countdown [text]="f.description.value" [maxLength]="DESCRIPTION_LENGTH" ></app-character-countdown>

      <app-control-validation [control]="f.description" [submitted]="submitted"></app-control-validation>
    </div>

  </div>
  <div class="modal-footer">
    <button *ngIf="memory.id" type="button" class="btn btn-outline-dark mr-auto"
            (click)="delete($event)">
      Delete
    </button>

    <button type="button" class="btn btn-light" (click)="dismissModal()">
      Cancel
    </button>

    <button  type="submit" class="btn btn-gold">
      Save
    </button>
  </div>

</form>
<app-content-loader>Please wait... Encrypting files</app-content-loader>
