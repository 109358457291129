
<ng-template #headerControls>
  <div class="block block-btns text-right mt-sm-2">
    <button (click)="friendInvitation()" class="btn btn-gold" type="button">
      <fa-icon [icon]="faEnvelope" class="mr-1"></fa-icon>
      <span class="d-none d-sm-inline">Invite to My LifeJars</span>
      <span class="d-sm-none">Invite</span>
    </button>

    <button (click)="openAddBioModal()" class="btn btn-gold ml-2" type="button">
      <fa-icon [icon]="faPlusCircle" class="mr-1"></fa-icon>
      <span class="d-none d-sm-inline">Add Tribester</span>
      <span class="d-sm-none">Tribe</span>
    </button>
  </div>
</ng-template>

<app-page-header title="Life Wall" [controls]="headerControls"></app-page-header>

<div class="container">
  <div class="row">
    <div class="col-12">

      <div class="block block-search mt-1 mb-4">
        <div class="input-group">
          <input type="text" class="form-control" placeholder="Search Profiles on this Life Wall..." aria-label="Search Profiles" [(ngModel)]="keywords" (change)="queueSearch()">
          <div class="input-group-append">
            <button class="btn" type="button" (click)="search()">
              <span class="sr-only">Search</span>
              <fa-icon [icon]="faSearch"></fa-icon>
            </button>
          </div>
        </div>
      </div><!-- /block-search -->

      <div class="block block-notifications mb-4" *ngIf="tribeActivity?.guardians.length || tribeActivity?.pending.length">
        <div class="guardian-pending-container">
          <app-guardian-request-list [activityList]="tribeActivity?.guardians" (requestResponse)="requestResponse($event)"></app-guardian-request-list>
        </div>
        <div class="tribe-pending-container">
          <app-tribe-activity-list [activityList]="tribeActivity?.pending" (requestResponse)="requestResponse($event)"></app-tribe-activity-list>
        </div>
      </div>

      <div *ngIf="tribeSearchResult" class="tribe-active-container">

        <app-bio-list [list]="tribeSearchResult?.list"></app-bio-list>

        <app-paginator [page]="tribeSearchResult.page" [pages]="tribeSearchResult.pages"
                       (pageChange)="onPageChange($event)"></app-paginator>
      </div>


<!--      <div class="block text-center py-3">-->

<!--        <button (click)="friendInvitation()" class="btn btn-gold" type="button">-->
<!--          <fa-icon [icon]="faEnvelope" class="mr-1"></fa-icon>-->
<!--          <span class="d-none d-sm-inline">Invite to My LifeJars</span>-->
<!--          <span class="d-sm-none">Invite</span>-->
<!--        </button>-->

<!--        <button (click)="openAddBioModal()" class="btn btn-gold ml-2" type="button">-->
<!--          <fa-icon [icon]="faPlusCircle" class="mr-1"></fa-icon>-->
<!--          <span class="d-none d-sm-inline">Add Tribester</span>-->
<!--          <span class="d-sm-none">Tribe</span>-->
<!--        </button>-->

<!--      </div>-->

    </div>
  </div>

</div><!--/container-->
