
<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="dismiss($event)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <iframe width="100%" height="420" [src]="videoSrc | safe:'resourceUrl'" frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
</div>

<div class="modal-footer text-center">

  <div class="custom-control custom-checkbox form-control-lg">
    <input id="dontShowAgain" class="custom-control-input" [(ngModel)]="dontShowAgain" type="checkbox" value="{{ tutorialRef }}">
    <label class="custom-control-label" for="dontShowAgain">I don't need to see this again.</label>
  </div>

  <button class="btn btn-gold pl-4 pr-4" type="button" (click)="dismiss($event)">Close</button>

</div>
