import {AppState} from '../index';
import {createSelector} from '@ngrx/store';

export const selectCommentsState = (state: AppState) => state.comments;

export const selectCurrentCommentId = createSelector(
  selectCommentsState,
  state => state.currentCommentId
);

export const selectCurrentComment = createSelector(
  selectCommentsState,
  selectCurrentCommentId,
  (state, id) =>
    state.comments.find( (m) => m.id === id )
);

export const selectCommentSearchResult = createSelector(
  selectCommentsState,
  state => state.comments
);

export const selectAllComments = createSelector(
  selectCommentsState,
  state => state.comments
);
