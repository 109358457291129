import {FileModel} from './file.model';
import {Bio} from './bio.model';
import {IListOption} from './option.model';
import {PasswordModel} from './password.model';
import {BioType, JarType, NodeType} from '../common/enums';

export interface IMemory {
  id: number;
  nodeType: NodeType;
  isDefault: boolean;
  isReference: boolean;
  jarTag: number;
  memoryTag: number;
  secretKeeper: number;
  timeLock: number;
  title: string;
  files: FileModel[];

  bio?: number;
  parentNode?: number;
  date?: any;
  description?: string;
  placeholder?: string;
  allowComments?: boolean;
  commentCount?: number;
  allowShare?: boolean;
  shareCount?: number;
}

export class MemoryModel implements IMemory {
  public id: number;
  public bio: number;
  public nodeType: NodeType;
  public title: string;
  public placeholder: string;
  public jarTag: number;
  public memoryTag: number;
  public secretKeeper: number;
  public timeLock: number;
  public isDefault: boolean;
  public isReference: boolean;
  public files: FileModel[];

  public parentNode: number; // aka Memory Jar
  public description: string;
  public date: any;
  public location: string;
  public tribesters: Bio[];

  public allowComments: boolean;
  public commentCount: number;

  public allowShare: boolean;
  public shareCount: number;

  public passwordId: number;
  public password: PasswordModel;

  // Indicates a pre-loaded memory/Thing
  public isSample: boolean;
  public sampleType: number;

  // Jar Icon and Banner
  public icon: FileModel;
  public banner: FileModel;

  // Jar Option (Used with Memory Defaults)
  public jar: IListOption;

  constructor() {
    this.id = null;
    this.nodeType = 0;

    this.secretKeeper = null;
    this.timeLock = null;
    this.memoryTag = null;
    this.jarTag = null;

    this.bio = null;
    this.parentNode = null;
    this.title = '';
    this.description = '';
    this.placeholder = '';
    this.location = '';
    this.date = null;
    this.files = [];
    this.tribesters = [];

    this.allowComments = true;
    this.commentCount = 0;

    this.allowShare = true;
    this.shareCount = 0;

    this.isDefault = false;
    this.isReference = false;
    this.isSample = false;
    this.sampleType = BioType.Unknown;

    this.icon = null;
    this.banner = null;

    this.jar = null;

    this.passwordId = null;
    this.password = new PasswordModel();
  }

  public static make(obj: object): MemoryModel {
    if (!obj || (Object.keys(obj).length === 0)) {
      return null; // Object is null or empty
    }
    return {...new MemoryModel(), ...obj};
  }

  public static isMemory(memory: IMemory) {
    return [10, 11, 12, 13].indexOf(memory.nodeType) >= 0;
  }

  public static isThing(memory: IMemory) {
    return [20, 21, 22, 23].indexOf(memory.nodeType) >= 0;
  }

  public static isPassword(memory: IMemory) {
    return [30, 31, 32, 33].indexOf(memory.nodeType) >= 0;
  }

  public static getJarType(memory: IMemory): JarType {
    if (MemoryModel.isMemory(memory)) {
      return JarType.Memory;
    } else if (MemoryModel.isThing(memory)) {
      return JarType.Thing;
    } else if (MemoryModel.isPassword(memory)) {
      return JarType.Password;
    }
    return null;
  }
}
