import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {faSearch, faPlusCircle, faPencilAlt, faCaretRight} from '@fortawesome/free-solid-svg-icons';
import {BreadcrumbNavItem} from '../../../shared/components/breadcrumb/breadcrumb.component';
import {LayoutService} from '../../../core/services/layout.service';

@Component({templateUrl: 'support.component.html'})
export class SupportComponent implements OnInit {
  public faSearch = faSearch;

  constructor(
    protected router: Router,
    protected layoutService: LayoutService
  ) {
    this.layoutService.setTitle( `Support - My LifeJars`  );
  }

  public ngOnInit() {
  }

  public showTutorial(event, tutorialRef: string) {
    event.preventDefault();
    this.layoutService.showTutorial( tutorialRef, true );
  }
}
