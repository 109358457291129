<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="dismiss($event)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div [ngSwitch]="ref">
    <div *ngSwitchCase="'memoryJarsTutorial'">
      <p>
      Start capturing a life journal one memory at a time with the Memory Jars.
        Use the timeline and prompts for guidance; 5 memory Jars each containing a summary memory and different memory types.
      </p>
      <p>
        The number of Memory Jars displayed will be dependent on age. i.e. An Adult will only see 4 Jars.
      </p>
      <p>
      To provide a journal with structure, and make it enjoyable to read, My LifeJars restricts the
        amount of text to 5000 characters and total of 10 image, voice or video files uploaded to each memory.
      </p>
      <p>
      Capture your own journal, help someone write their story, or record a digital legacy for a loved one
        that has died, by creating a separate Life Profile or Legacy for each person.
      </p>
      <p>
      <strong>Don’t let old photos, diaries and journals end up in a box in the closet,
        basement or shoved in a drawer… that makes us all feel guilty because we are
        missing out on stories, lessons and memories from people we love.</strong>
      </p>
      <p>
        <strong>Save your family history – to share with generations to come.</strong>
      </p>
    </div>
    <div *ngSwitchCase="'thingsJarsTutorial'">
      <p>
        Use the Things Jars to help categorize, store and find vital documents, important
        information and other things. There are 6 Things Jars: Personal Certificates,
        Vital Documents, Important Information, Business Essentials, Real Life Things,
        Significant Bits &amp; Pieces.
      </p>
      <p>
      4 sample things in each Jar help to add information that a person or Guardians may need access
      to - or family, friends and future generations might be interested in.
      </p>
      <p>
      A location of where the item is stored can be added along with details of the
      precise location of an item in the description.
      </p>
      <p>
      <strong>It’s important to clean up digital footprints yet provide easy access
      to documents and other things who will need it – without compromising
      privacy and security of data.</strong>
      </p>
      <p>
        <strong>Capture your own personal information, help someone do organize their life.
      Provide access in an easy, safe and timely manner to others not in the same
      physical location.</strong>
      </p>
      <p>
        <strong>Transparency reduces the risk of family conflict and potential elder abuse.</strong>
      </p>
    </div>
    <div *ngSwitchCase="'passwordJarsTutorial'">
      <p>
      Save passwords and login details in My LifeJars so that all personal information is secure in one place.
      </p>
      <p>
      There are 8 Password Jars with prompt to store passwords and login details: Emails accounts, social media,
      banking & finance, government, utilities, business, devices and other.
      </p>
      <p>
      Password Jars can be used to store other useful access information too, such a bitcoin
      account or software licence details & keys as only the title filed is a mandatory.
      </p>
      <p>
        <strong>Products like Google’s password manager or apps like Last Past are based on ‘client side’
      technology and it is only a matter of time before there will be a security breach.</strong>
      </p>
      <p>
        <strong>My LifeJars multi-layer ‘server-side’ encryption technology, ensures passwords and other access
      information will be safe for at least the next 40 years from a brute force attack.</strong>
      </p>
      <p>
        <strong>Passwords, keys and login details can be stored safely for Executors for many years to come.</strong>
      </p>
    </div>
    <div *ngSwitchCase="'onlineMemorialTutorial'">
      <p>
      In My LifeJars a Legacy Profile is a combination of an Online Memorial and a Digital Legacy.
      </p>
      <p>
      It is one place where all information about a person's life can be gathered and curated in a
      digital format to be preserved for the future.
      </p>
      <p>
      A Legacy Profile can be either created after a person has died or if a Life Profile has already been
      created it can be transitioned into a Legacy Profile and more content added.
      </p>
      <p>
        Create a person’s Legacy by going to the ward panel then select <strong>'+ Add Ward'</strong> and ‘Legacy Profile’
      and enter your relationship to the person and their details.
      </p>
      <p>
      From edit Profile add further information including the Resting Pin to add the location of the person’s grave,
      scattered ashes, urn or memorial plaque.  A candle icon is displayed next to the name of a Legacy Profile.
      </p>
      <p>
        <strong>Stay connected and reflect and celebrate the lives of loved ones without having to travel to graves,
      or memorials by visiting your individual Remembrance Wall. Read tributes and read
      others that will be preserved for always.</strong>
      </p>
    </div>
    <div *ngSwitchCase="'tribeTutorial'">
      <p>
      A person can be invited to join the My LifeJars App or invited to join a Tribe if they are already a My LifeJars user.
      </p>
      <p>
      If a person is already a My LifeJars user, from the Life Wall select the button <strong>'Add Tribester'</strong>.
      </p>
      <p>
      To invite a person to join the My LifeJars App and a Tribe, select <strong>'Invite to My LifeJars'</strong>.
      </p>
      <p>
      As a Guardian of a Ward’s Profile switch the Profile being edited then invite people to join the Ward’s Tribe.
      </p>
      <p>
        <strong>Enjoy building a My LifeJars Tribe and setting the Time Lock and Secret Keeper settings to
      each item of content. Each person accessing a Profile gets their unique view of a journal or
      legacy that can be viewed online or printed.</strong>
      </p>
    </div>
    <div *ngSwitchCase="'guardiansTutorial'">
      <p>
      In My LifeJars, a Guardian is defined as any person that may assist another person in a
      formal or informal role and can manage their Virtual Jars (Memory, Things Passwords)
      depending on the access rights granted.
      </p>
      <p>
      A Ward is the person that receives help from their Guardian.
      </p>
      <p>
      To assign a Guardian from the page Guardians, select 'Invite Guardians'.
      Set permissions. An email will be sent to the persons to accept the responsibility
      of being a My LifeJars Guardian.
      </p>
      <p>
        <strong>My LifeJars really makes it quick, easy, safe and secure to gather together a life story,
      important information and passwords to pass on to those that matter. Start doing
      this in bite-size bits before somethings happens to you or your family.</strong>
      </p>
    </div>
    <div *ngSwitchCase="'digitalLegacyTutorial'">
      <p>
      See how memories are collated to form a Digital Legacy in an online version and book
      format that can be printed on a home computer.
      </p>
      <p>
      Things (like birth certificates) will be available soon in a future release of My LifeJars.
      </p>
      <p>
      The content displayed will be dependent on its Time Lock and secret keeper settings and will
      be different for everyone and could change over time.
      </p>
      <p>
      The link that is shared outside of My LifeJars in emails, messages or on
      social media platforms will only display public content.
      </p>
    </div>
    <div *ngSwitchCase="'lifeJournalTutorial'">
      <p>
      See how memories are collated to form a Life Journal and view Things and passwords.
      </p>
      <p>
      The content displayed will be dependent on the Time Lock and secret keeper
      settings, so this information could change over time!
      </p>
    </div>
    <div *ngSwitchCase="'editBioTutorial'">
      <p>
      A person’s information is updated in Edit Profile. Select the dropdown
      the top menu on the RHS which gives access to bios, adding and managing wards
      (by switching wards), settings and logging out of My LifeJars.
      </p>
      <p>
      Selecting the Current Profile, add or edit personal details. Any photos uploaded to the
      Memory Jars summary memory can be used as a profile image.
      </p>
      <p>
      Information on ethnicity, religion customs & traditions ensure a Tribe and future
      generations get to appreciate and understand what’s important to a person with
      regard to their identity and the cultural aspects of their heritage.
      </p>
      <p>
        My LifeJars provides an overriding Privacy setting to protect identity which can also be changed in Edit Profile.
      </p>
      <p>
        <strong>My LifeJars: Preserving Digital Footprints Today, for Tomorrow’s Legacies</strong>
      </p>
    </div>
  </div>
</div>

<div class="modal-footer text-center">
  <button class="btn btn-gold pl-4 pr-4" type="button" (click)="dismiss($event)">Close</button>
</div>
