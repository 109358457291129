import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';

import {IMemory, MemoryModel} from '../models/memory.model';
import {AppDateFormatter} from '../format/date.format';
import {MemoryFilterModel} from '../models/memory-filter.model';
import {MemorySearchResult} from '../models/memory-search-result.model';
import {ResponseMessage} from '../models/response-message.model';
import {JarModel} from '../models/jar.model';
import {FileModel} from '../models/file.model';
import {Bio} from '../models/bio.model';
import {AppConfig} from './app-config.service';
import {NodeType} from '../common/enums';

@Injectable({providedIn: 'root'})
export class MemoryService {

  constructor(
    private http: HttpClient,
    private dateFormatter: AppDateFormatter
  ) {
  }

  public getMemoryJar(id: number): Observable<JarModel> {
    return this.http.get<JarModel>(`${AppConfig.settings.apiBaseUrl}/memory-jar/${id}`);
  }

  public saveMemoryJar(model: JarModel): Observable<ResponseMessage> {

    // Insert
    let url = `${AppConfig.settings.apiBaseUrl}/memory-jar/save`;

    // Update
    if (model.id) {
      url = `${AppConfig.settings.apiBaseUrl}/memory-jar/${model.id}/save`;
    }
    return this.http.put<ResponseMessage>(url, {
      bio: model.bio,
      metaData: {
        description: model.description
      }
    });
  }

  // This will get both a Memory or Memory Jar
  public getMemory(id: number): Observable<MemoryModel> {
    return this.http.get<MemoryModel>(`${AppConfig.settings.apiBaseUrl}/memory/${id}`);
  }

  public saveMemory(model: MemoryModel): Observable<ResponseMessage> {
    if (model.isReference === true) {
      throw new Error('Shared/Referenced memories can\'t be saved.');
    }

    // Insert
    let url = `${AppConfig.settings.apiBaseUrl}/memory/save`;

    // Update
    if (model.id) {
      url = `${AppConfig.settings.apiBaseUrl}/memory/save/${model.id}`;
    }

    const isoDate = this.dateFormatter.format(model.date, 'YYYY-MM-DD');

    return this.http.post<ResponseMessage>(url, {
      bio: model.bio,
      parentNode: model.parentNode,
      secretKeeper: model.secretKeeper,
      timeLock: model.timeLock,
      memoryTag: model.memoryTag,
      title: model.title,
      date: isoDate,
      allowComments: model.allowComments,
      allowShare: model.allowShare,
      tribesters: model.tribesters.map((bio: Bio) => {
        return bio.id;
      }),
      metaData: {
        description: model.description,
        location: model.location,
      },

      // Passwords are ONLY applicable to Password memories
      password: model.password != null
        ? model.password
        : undefined
    });
  }

  public deleteMemory(memory: IMemory | JarModel): Observable<ResponseMessage> {
    return this.http.delete<ResponseMessage>(`${AppConfig.settings.apiBaseUrl}/memory/${memory.id}/delete`);
  }

  public getFile(memory: IMemory, file: FileModel, getPreview: boolean = false): Observable<FileModel> {
    return this.http.get<FileModel>(`${AppConfig.settings.apiBaseUrl}/memory/${memory.id}/file/${file.id}?preview=${getPreview}`);
  }

  public saveFilesUrl(memory: IMemory | JarModel): string {
    return `${AppConfig.settings.apiBaseUrl}/memory/${memory.id}/file/save`;
  }

  public sortFiles(memory: IMemory, fileIds: number[]): Observable<ResponseMessage> {
    return this.http.post<ResponseMessage>(`${AppConfig.settings.apiBaseUrl}/memory/${memory.id}/file/sort`, {
      files: fileIds
    });
  }

  public removeFile(memory: IMemory, file: FileModel): Observable<ResponseMessage> {
    return this.http.delete<ResponseMessage>(`${AppConfig.settings.apiBaseUrl}/memory/${memory.id}/file/${file.id}/remove`);
  }

  public patchFile(memory: IMemory, file: FileModel): Observable<ResponseMessage> {
    return this.http.patch<ResponseMessage>(`${AppConfig.settings.apiBaseUrl}/memory/${memory.id}/file/${file.id}/update`, {
      title: file.title,
      // todo: add extra fields if we need them
    });
  }

  public search(filters: MemoryFilterModel): Observable<MemorySearchResult> {

    const params = new HttpParams()
      .append('bio', filters.bio.toString())
      .append('parentNodes', JSON.stringify(filters.parentNodes))
      .append('includeParents', filters.includeParents ? '1' : '0')
      .append('memoryTagTypes', JSON.stringify(filters.memoryTagTypes))
      .append('secretKeeperTypes', JSON.stringify(filters.secretKeeperTypes))
      .append('timeLockTypes', JSON.stringify(filters.timeLockTypes))
      .append('fileTypes', JSON.stringify(filters.fileTypes))
      .append('page', filters.page.toString())
      .append('results', filters.results.toString())
      .append('keywords', filters.keywords || '')
      .append('orderBy', filters.orderBy || '');

    return this.http.get<MemorySearchResult>(`${AppConfig.settings.apiBaseUrl}/memory/search`, {params});
  }

  public shareMemory(jar: IMemory, memory: IMemory): Observable<ResponseMessage> {
    return this.http.post<ResponseMessage>(`${AppConfig.settings.apiBaseUrl}/jar/${jar.id}/memory/${memory.id}/share`, {});
  }

  /* Admin  */
  public searchDefaultMemories(filters: MemoryFilterModel, nodeType?: NodeType): Observable<MemorySearchResult> {

    const params = new HttpParams()
      .append('page', filters.page.toString())
      .append('results', filters.results.toString())
      .append('keywords', filters.keywords || '')
      .append('orderBy', filters.orderBy || '');

    let url = '';
    switch (nodeType) {
      case NodeType.PasswordDefault:
        url = `${AppConfig.settings.apiBaseUrl}/admin/password-defaults`;
        break;

      case NodeType.PasswordJarDefault:
        url = `${AppConfig.settings.apiBaseUrl}/admin/password-jar-defaults`;
        break;

      case NodeType.ThingDefault:
        url = `${AppConfig.settings.apiBaseUrl}/admin/thing-defaults`;
        break;

      case NodeType.ThingJarDefault:
        url = `${AppConfig.settings.apiBaseUrl}/admin/thing-jar-defaults`;
        break;

      case NodeType.MemoryJarDefault:
        url = `${AppConfig.settings.apiBaseUrl}/admin/memory-jar-defaults`;
        break;

      case NodeType.MemoryDefault:
      default:
        url = `${AppConfig.settings.apiBaseUrl}/admin/memory-defaults`;
    }

    return this.http.get<MemorySearchResult>(url, {
      params
    });
  }

  public saveDefaultMemory(model: IMemory): Observable<ResponseMessage> {

    // Insert
    let url = `${AppConfig.settings.apiBaseUrl}/admin/memory-default/save`;

    // Update
    if (model.id) {
      url = `${AppConfig.settings.apiBaseUrl}/admin/memory-default/${model.id}/save`;
    }

    return this.http.put<ResponseMessage>(url, {
      title: model.title,
      placeholder: model.placeholder,
      jarTag: model.jarTag,
      memoryTag: model.memoryTag,
      secretKeeper: model.secretKeeper,
      timeLock: model.timeLock,
    });
  }

  public publishDefaultMemory(model: IMemory): Observable<ResponseMessage> {
    return this.http.post<ResponseMessage>(`${AppConfig.settings.apiBaseUrl}/admin/memory-default/${model.id}/publish`, {});
  }

  public saveDefaultJar(model: IMemory): Observable<ResponseMessage> {

    // Insert
    let url = `${AppConfig.settings.apiBaseUrl}/admin/memory-default/save`;

    // Update
    if (model.id) {
      url = `${AppConfig.settings.apiBaseUrl}/admin/memory-default/${model.id}/save`;
    }

    return this.http.put<ResponseMessage>(url, {
      title: model.title,
      placeholder: model.placeholder,
      jarTag: model.jarTag,
    });
  }

  public deleteDefaultMemory(memory: IMemory): Observable<ResponseMessage> {
    return this.http.delete<ResponseMessage>(`${AppConfig.settings.apiBaseUrl}/admin/memory-default/${memory.id}/delete`);
  }

  public getDefaultFile(memory: IMemory, file: FileModel, getPreview: boolean = false): Observable<FileModel> {
    return this.http.get<FileModel>(`${AppConfig.settings.apiBaseUrl}/admin/memory-default/${memory.id}/file/${file.id}?preview=${getPreview}`);
  }

  public saveDefaultFilesUrl(memory: IMemory) {
    return `${AppConfig.settings.apiBaseUrl}/admin/memory-default/save/${memory.id}/files`;
  }

  public saveDefaultBannerUrl(memory: IMemory) {
    return `${AppConfig.settings.apiBaseUrl}/admin/memory-default/save/${memory.id}/banner`;
  }

  public saveDefaultIconUrl(memory: IMemory) {
    return `${AppConfig.settings.apiBaseUrl}/admin/memory-default/save/${memory.id}/icon`;
  }

  public patchDefaultFile(memory: IMemory, file: FileModel): Observable<ResponseMessage> {
    return this.http.patch<ResponseMessage>(`${AppConfig.settings.apiBaseUrl}/admin/memory-default/${memory.id}/file/${file.id}/update`, {
      title: file.title,
      // todo: add extra fields if we need them
    });
  }

  public removeDefaultFile(memory: IMemory, file: FileModel): Observable<ResponseMessage> {
    return this.http.delete<ResponseMessage>(`${AppConfig.settings.apiBaseUrl}/admin/memory-default/${memory.id}/file/${file.id}/remove`);
  }

  public removeDefaultBanner(memory: IMemory, file: FileModel): Observable<ResponseMessage> {
    return this.http.delete<ResponseMessage>(`${AppConfig.settings.apiBaseUrl}/admin/memory-default/${memory.id}/banner/${file.id}/remove`);
  }

  public removeDefaultIcon(memory: IMemory, file: FileModel): Observable<ResponseMessage> {
    return this.http.delete<ResponseMessage>(`${AppConfig.settings.apiBaseUrl}/admin/memory-default/${memory.id}/icon/${file.id}/remove`);
  }

  public sortDefaultFiles(memory: IMemory, fileIds: number[]): Observable<ResponseMessage> {
    return this.http.patch<ResponseMessage>(`${AppConfig.settings.apiBaseUrl}/admin/memory-default/${memory.id}/file/sort`, {
      files: fileIds
    });
  }

}
