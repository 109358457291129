<ng-template #headerControls>
  <div class="block block-btns text-right mt-sm-2" *ngIf="canEdit()">
    <button (click)="openThingModal()" class="btn btn-gold" type="button">
      <fa-icon [icon]="faPlusCircle" class="mr-1"></fa-icon>
      <span class="d-none d-sm-inline">Add Thing</span>
      <span class="d-sm-none">New</span>
    </button>
  </div>
</ng-template>

<app-page-header title="Things Jars" [controls]="headerControls"></app-page-header>

<div class="container">
  <div class="row">
    <div class="col-12">

      <div class="row row-page-btns row-page-btns-space row-page-btns-things" *ngIf="bio?.type === 1">
        <div class="btn-group btn-group-toggle" ngbRadioGroup [(ngModel)]="bioJar"
             (ngModelChange)="onBioJarChange()">

          <label *ngFor="let item of ThingJarTypes; let index = index;" class="btn" ngbButtonLabel>
            <input type="radio" ngbButton [value]="item">
            <div class="sprite sprite-thing-jar"
                 [ngStyle]="(item.icon && item.icon.src) && {'background-image':'url('+item.icon.src+')'}"></div>
            <div class="text">{{ item.title }}</div>
          </label>

        </div>
      </div><!-- /row-page-btns -->

      <div *ngIf="bioJar" class="banner-container">
        <div class="banner banner-thing-jar"
             [ngStyle]="(bioJar.banner && bioJar.banner.src) && {'background-image':'url('+bioJar.banner.src+')'}">
          <div class="banner-title">
            <h1>{{ bioJar.title }}</h1>
          </div>
        </div>
      </div><!-- /banner-container -->

      <div class="card card-bio">
        <div class="card-body">

          <div class="row">
            <div class="col-sm-6">

              <div class="btn-group-filters">
                <div class="btn-group">
                  <button type="button" class="btn" (click)="resetFilters()">
                    All
                  </button>
                  <div ngbDropdown [autoClose]="'outside'" class="btn-group" role="group">
                    <button ngbDropdownToggle type="button" class="btn dropdown-toggle"
                            aria-haspopup="true" aria-expanded="false">
                      <span class="d-none d-sm-inline-block">Thing</span> Tag
                      <span *ngIf="filters.memoryTagTypes.length"
                            class="dd-has-filters">{{ filters.memoryTagTypes.length }}</span>
                    </button>
                    <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                      <div class="btn-group-vertical">

                        <label *ngFor="let item of thingTagTypeFilters; let index = index;" class="btn dropdown-item"
                               ngbButtonLabel>
                          <input type="checkbox" ngbButton (change)="queueSearch()"
                                 [(ngModel)]="thingTagTypeFilters[index].selected" [value]="item.item">
                          {{ item.item.label }}
                        </label>

                      </div>

                    </div>
                  </div>


                </div>

                <div ngbDropdown [autoClose]="'outside'" class="btn-group float-right" role="group">
                  <button ngbDropdownToggle type="button" class="btn dropdown-toggle"
                          aria-haspopup="true" aria-expanded="false">
                    Secret <span class="d-none d-sm-inline-block">Keeper</span>
                    <span *ngIf="filters.secretKeeperTypes.length"
                          class="dd-has-filters">{{ filters.secretKeeperTypes.length }}</span>
                  </button>
                  <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="btnGroupDrop1">

                    <label *ngFor="let item of secretKeeperTypeFilters; let index = index;" class="btn dropdown-item"
                           ngbButtonLabel>
                      <input type="checkbox" ngbButton (change)="queueSearch()"
                             [(ngModel)]="secretKeeperTypeFilters[index].selected" [value]="item.item">
                      {{ item.item.label }}
                    </label>

                  </div>
                </div><!-- /secretKeeper filter -->

                <div ngbDropdown [autoClose]="'outside'" class="btn-group float-right" role="group">
                  <button ngbDropdownToggle type="button" class="btn dropdown-toggle"
                          aria-haspopup="true" aria-expanded="false">
                    Time <span class="d-none d-sm-inline-block">Lock</span>
                    <span *ngIf="filters.timeLockTypes.length"
                          class="dd-has-filters">{{ filters.timeLockTypes.length }}</span>
                  </button>
                  <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="btnGroupDrop1">

                    <label *ngFor="let item of timeLockTypeFilters; let index = index;" class="btn dropdown-item"
                           ngbButtonLabel>
                      <input type="checkbox" ngbButton (change)="queueSearch()"
                             [(ngModel)]="timeLockTypeFilters[index].selected" [value]="item.item">
                      {{ item.item.label }}
                    </label>

                  </div>
                </div><!-- /timeLock filter -->

              </div><!-- /btn-group-filters -->

              <div class="block block-search mt-2 mb-4">
                <div class="input-group">
                  <input type="text" class="form-control" placeholder="Search..." aria-label="Search My LifeJars"
                         [(ngModel)]="keywords" (change)="queueSearch()">
                  <div class="input-group-append">
                    <button class="btn" type="button" (click)="search()">
                      <span class="sr-only">Search</span>
                      <fa-icon [icon]="faSearch"></fa-icon>
                    </button>
                  </div>
                </div>
              </div><!-- /block-search -->


              <div class="memories-list-container">

                <ul class="list list-memories">
                  <li *ngFor="let item of pagedThings.list" class="item item-memory"
                      [ngClass]="{ 'active' : item==previewThing }">
                    <div class="row">
                      <div class="col-sm-12">
                        <h4 class="tag clearfix">

                          <span class="font-weight-bold"><fa-icon [icon]="faTag"></fa-icon>
                            {{ (item.memoryTag|options: 'thingTag')?.label }}</span>

                          <span class="float-right smaller ml-2"><fa-icon [icon]="faEye"></fa-icon>
                            {{ (item.secretKeeper|options: 'secretKeeper')?.label }}</span>

                          <span class="float-right smaller ml-2"><fa-icon [icon]="faClock"></fa-icon>
                            {{ (item.timeLock|options: 'timeLock')?.label}}</span>

                          <span *ngIf="item?.date" class="float-right smaller ml-2">
                            <fa-icon [icon]="faCalendar"></fa-icon>
                            {{ item.date | date: 'mediumDate' }}</span>
                        </h4>
                        <h3 class="title">
                          <span *ngIf="item.isSample"><em>Sample:</em></span>
                          {{ item.title }}
                        </h3>
                        <div class="description">
                          {{ (item.description || item.placeholder) | truncate:['200', '...'] }}
                        </div>
                      </div>
                    </div>
                    <div class="row row-controls">
                      <div class="col-12 col-sm-6 text-left">
                        <button (click)="openThingModal(item)" type="button" class="btn btn-sm btn-hollow"
                                *ngIf="item.isReference === false && canEdit()">
                          <fa-icon [icon]="faPencilAlt"></fa-icon>
                          Edit
                        </button>

                        <button (click)="removeShared(item)" type="button" class="btn btn-sm btn-hollow"
                                *ngIf="item.isReference === true">
                          <fa-icon [icon]="faShare"></fa-icon>
                          Remove
                        </button>

                        <button type="button" class="btn btn-sm btn-hollow ml-2 float-right float-sm-none"
                                (click)="viewThing(item)">
                          View
                        </button>
                      </div>
                      <div class="d-none d-sm-block col-6 text-right">

                        <button type="button" class="btn btn-sm btn-hollow ml-2" (click)="setPreviewThing(item)"
                                [ngClass]="{ 'btn-gold' : item.id==previewThing?.id, 'btn-hollow' : item.id!=previewThing?.id }">
                          <span *ngIf="item!=previewThing">Preview</span>
                          <fa-icon *ngIf="item==previewThing" [icon]="faCaretRight"></fa-icon>
                        </button>
                      </div>
                    </div>

                  </li>
                </ul>

                <app-paginator [page]="pagedThings.page" [pages]="pagedThings.pages"
                               (pageChange)="onPageChange($event)"></app-paginator>

                <app-content-loader [scope]="'lazy'">Loading...</app-content-loader>
              </div><!-- /memories list -->

              <div class="block block-btns text-center mt-5" *ngIf="canEdit()">
                <button (click)="openThingModal()" class="btn btn-gold" type="button">
                  <fa-icon [icon]="faPlusCircle"></fa-icon>
                  Add Thing
                </button>
              </div><!-- /block-btns -->

            </div>
            <div class="col-sm-6 d-none d-sm-block">

              <div *ngIf="!previewThing" class="thing-preview empty mt-5 mt-sm-0">
                <span class="thing-preview-text">Nothing in this Jar</span>
              </div>

              <div *ngIf="previewThing" class="thing-preview mt-5 mt-sm-0">
                <div class="thing-preview-img">

                  <app-file-lazy-loader [memory]="previewThing" [file]="previewThingFile" [downloadable]="true"
                                        (click)="showLightbox(previewThing, previewThingFile)"></app-file-lazy-loader>

                  <button type="button" class="btn btn-sm btn-hollow" (click)="openThingModal(previewThing)">
                    <fa-icon [icon]="faPencilAlt"></fa-icon>
                    <span class="sr-only">Edit</span>
                  </button>

                </div>
              </div>

              <app-file-uploader *ngIf="previewThing?.files.length > 1"
                                 [fileList]="previewThing?.files"
                                 [memory]="previewThing"
                                 [disableUploads]="true"
                                 [sortable]="false"
                                 preview="true"
                                 (fileClick)="setPreviewThingFile($event)"></app-file-uploader>

            </div><!-- /thing-preview -->

          </div>

        </div>
      </div><!--/card -->

    </div>
  </div>

</div><!--/container-->
