import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {faChevronDown, faChevronUp} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-user-role-text',
  templateUrl: 'user-role-text.component.html',
  styleUrls: ['user-role-text.component.css']
})
export class UserRoleTextComponent implements OnInit, OnChanges {
  @Input() role: number;
  public roleText: string;

  constructor() {
    this.role = 0;
  }

  protected convertRoleToText(role: number): string {
    switch (role) {
      case 0:
        return 'No Access';

      case 1:
        return 'Read Only';

      case 2:
        return 'Full Access';

      default:
        return 'Unknown';
    }
  }

  public ngOnInit(): void {
    this.roleText = this.convertRoleToText(this.role);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.roleText = this.convertRoleToText(this.role);
  }
}
