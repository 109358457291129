import {createAction, props} from '@ngrx/store';
import {BioUser} from '../../core/models/bio-user.model';
import {ResponseMessage} from '../../core/models/response-message.model';

export const setBioUsers = createAction(
  '[BioUsers] set bio users ',
  props<{ bioUsers: BioUser[] }>()
);

export const setCurrentBioUserId = createAction(
  '[BioUsers] set current bioUser id',
  props<{ id: number }>()
);

export const getBioUserRequest = createAction(
  '[BioUsers] get bioUser request',
  props<{ id: number }>()
);

export const getBioUserSuccess = createAction(
  '[BioUsers] get bioUser success',
  props<{ bioUser: BioUser }>()
);

export const getBioUsersRequest = createAction(
  '[BioUsers] get bio users request',
  props<{ slugOrId: string|number }>()
);

export const getBioUsersSuccess = createAction(
  '[BioUsers] get bio users success',
  props<{ bioUsers: BioUser[] }>()
);

export const saveBioUserRequest = createAction(
  '[BioUsers] save bio user request',
  props<{ bioUser: BioUser }>()
);

export const saveBioUserSuccess = createAction(
  '[BioUsers] save bio user success',
  props<{ bioUser: BioUser }>()
);

export const resendBioUserInviteRequest = createAction(
  '[BioUsers] resend bio user invite request',
  props<{ bioUser: BioUser }>()
);

export const deleteBioUserRequest = createAction(
  '[BioUsers] delete bio user request',
  props<{ bioUser: BioUser }>()
);

export const deleteBioUserSuccess = createAction(
  '[BioUsers] delete bio user success',
  props<{ responseMessage: ResponseMessage, bioUser: BioUser }>()
);

export const effectError = createAction(
  '[BioUsers] effect error',
  props<{ error: any }>()
);
