import {Component, Input} from '@angular/core';
import {PlatformLocation} from '@angular/common';
import {Bio} from '../../../core/models/bio.model';
import {AlertService} from '../alert/alert.service';

@Component({
  selector: 'app-bio-share-link',
  templateUrl: 'bio-share-link.component.html'
})
export class BioShareLinkComponent {

  @Input()
  public bio: Bio;

  public domain: string;

  constructor(
    protected platformLocation: PlatformLocation,
    protected alertService: AlertService
  ) {
    this.bio = null;
    this.domain = platformLocation.hostname;
  }

  public getUrl(includeDomain: boolean = true): string {
    if (this.bio == null) {
      return '';
    }

    let url = '';
    if (this.bio.isLegacy === true && this.bio.privacy === 3) {
      url = `/public/legacy/${this.bio.slug}`;

    } else if (this.bio.isLegacy === true) {
      url = `/legacy/${this.bio.slug}`;

    } else {
      url = `/profiles/${this.bio.slug}`;
    }

    if (includeDomain === true) {
      url = 'https://' + this.domain + url;
    }

    return url;
  }

  public onCopy(text: string) {
    this.alertService.info([`${text} has been copied to your clipboard.`]);
  }

}
