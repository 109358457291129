<div class="modal-header">
  <h4 class="modal-title">Choose an image from one of your Memory Jars.</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body modal-body-profile-picker">

  <ng-container *ngIf="bio">

    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">

      <ng-container *ngFor="let item of memoryJars; let index = index;">
        <li [ngbNavItem]="index">

          <a ngbNavLink>{{ item.title }}</a>

          <ng-template ngbNavContent>

            <app-file-uploader [disableUploads]="true" [fileList]="item?.files"
                               [memory]="item"
                               (fileClick)="selectFile($event)"
                               preview="true"></app-file-uploader>

            <div *ngIf="!item.files.length" class="mt-5 mb-5">
              <p class="text-center">
                You haven't added any images to your
                <strong>{{ item.title }} Memory Jar</strong>.
              </p>
              <p class="text-center mt-1">
                <a routerLink="/jars/memories" (click)="activeModal.dismiss()">
                  <fa-icon [icon]="faImage"></fa-icon>
                  Click here to upload.
                </a>
              </p>
            </div>

          </ng-template>
        </li>
      </ng-container>

    </ul>

    <div [ngbNavOutlet]="nav" class="mt-2"></div>

  </ng-container>

  <div class="form-group text-center">
    <button ngbAutofocus (click)="activeModal.dismiss()" type="button" class="btn btn-gold">
      Done
    </button>
  </div>

</div>
<app-content-loader [scope]="'modal'"></app-content-loader>

