import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {ResponseMessage} from '../models/response-message.model';
import {CommentModel} from '../models/comment.model';
import {AppConfig} from './app-config.service';

@Injectable({providedIn: 'root'})
export class CommentService {

  constructor(private http: HttpClient) {
  }

  public getBioComments(idOrSlug: string): Observable<CommentModel[]> {
    return this.http.get<CommentModel[]>(`${AppConfig.settings.apiBaseUrl}/bio/${idOrSlug}/comments`);
  }

  public getMemoryComments(id: number): Observable<CommentModel[]> {
    return this.http.get<CommentModel[]>(`${AppConfig.settings.apiBaseUrl}/memory/${id}/comments`);
  }

  public saveComment(model: CommentModel): Observable<ResponseMessage> {

    const payload = {
      bio: model.bio,
      bioNode: model.bioNode,
      comment: model.comment
    };

    if (model.id) {
      // Update
      const url = `${AppConfig.settings.apiBaseUrl}/bio/${payload.bio}/comments/${model.id}/save`;
      return this.http.patch<ResponseMessage>(url, payload);

    } else {
      // Insert
      const url = `${AppConfig.settings.apiBaseUrl}/bio/${payload.bio}/comments/save`;
      return this.http.post<ResponseMessage>(url, payload);
    }
  }

  public deleteComment(model: CommentModel): Observable<ResponseMessage> {
    return this.http.delete<ResponseMessage>(`${AppConfig.settings.apiBaseUrl}/comments/${model.id}/delete`);
  }

}
