import {FileUpload} from '../../shared/components/file-uploader/file-uploader.service';

export class FileModel extends FileUpload {
  public id: number;
  public previewId: number;
  public moderation: number;
  public mime: string;
  public title: string;
  public src: string;

  constructor(file?: File) {
    super(file);
    this.id = null;
    this.previewId = null;
    this.moderation = 0; // 0=Pending, 1=Approved, 2=Rejected
    this.mime = '';
    this.title = '';
    this.src = '';
  }

  public static make(obj: object): FileModel {
    if (!obj || (Object.keys(obj).length === 0)) {
      return null; // Object is null or empty
    }
    return {...new FileModel(null), ...obj};
  }
}
