import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BioUser} from '../../../core/models/bio-user.model';
import {Bio} from '../../../core/models/bio.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MemoryModel} from '../../../core/models/memory.model';
import {UserBioActions} from '../../../store/user-bios';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store';

@Component({
  templateUrl: 'guardian-authority-modal.component.html'
})
export class GuardianAuthorityModalComponent implements OnInit {

  @Input()
  public bio: Bio;

  public editForm: FormGroup;
  public submitted = false;

  // convenience getter for easy access to form fields
  public get f() {
    return this.editForm.controls;
  }

  constructor(
    public activeModal: NgbActiveModal,
    protected modalService: NgbModal,
    protected formBuilder: FormBuilder
  ) {
    this.bio = null;
  }

  public ngOnInit() {
    this.editForm = this.formBuilder.group({
      acceptTerms: [null, Validators.requiredTrue]
    });
  }

  public onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.editForm.invalid) {
      return;
    }

    // Accepted
    this.activeModal.close(true);
  }

}
