import {MemorySearchResult} from '../../core/models/memory-search-result.model';

export class MemoriesState {
  currentMemoryId: number;
  memorySearchResult: MemorySearchResult;
}

export const initialMemoryState: MemoriesState = {
  currentMemoryId: 0,
  memorySearchResult: new MemorySearchResult(),
};
