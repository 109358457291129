import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {TribeService} from '../../core/services/tribe.service';
import {catchError, concatMap, map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {TribeActions} from './index';
import {TribeActivityResult} from '../../core/models/tribe-activity-result.model';
import {BioSearchResult} from '../../core/models/bio-search-result.model';
import {ResponseMessage} from '../../core/models/response-message.model';
import {AlertService} from '../../shared/components/alert/alert.service';
import {BioActions} from '../bios';
import {Tribe} from '../../core/models/tribe.model';

@Injectable()
export class TribesEffects {

  constructor(
    private actions$: Actions,
    private tribeService: TribeService,
    private alertService: AlertService,
  ) {
  }

  getTribeActivityRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TribeActions.getTribeActivityRequest),
      mergeMap(action =>
        this.tribeService.getTribeActivity().pipe(
          map((tribeActivityResult: TribeActivityResult) =>
            TribeActions.getTribeActivityRequestSuccess({
              tribeActivityResult
            })
          ),
          catchError(error => {
            return of( TribeActions.effectError({error}) );
          })
        )
      )
    )
  );


  searchTribeRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TribeActions.searchTribeRequest),
      mergeMap(action =>
        this.tribeService.search(action.filters).pipe(
          map((tribeSearchResult: BioSearchResult) =>
            TribeActions.searchTribeRequestSuccess({
              tribeSearchResult
            })
          ),
          catchError(error => {
            return of( TribeActions.effectError({error}) );
          })
        )
      )
    )
  );


  addTribeRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TribeActions.addTribeRequest),
      mergeMap(action =>
        this.tribeService.sendTribeRequest(action.model, action.toBio.id, action.fromBio?.id).pipe(
          tap((responseMessage: ResponseMessage) => {
            this.alertService.info(responseMessage.message);
          }),

          concatMap((responseMessage: ResponseMessage) => [
            BioActions.updateDisplayBioTribeRelationship({
              tribeRelationship: Tribe.makePending(action.toBio, action.fromBio)
            }),
            TribeActions.getTribeActivityRequest()
          ]),

          catchError(error => {
            return of( TribeActions.effectError({error}) );
          })
        )
      )
    )
  );


  updateTribeRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TribeActions.updateTribeRequest),
      mergeMap(action =>
        this.tribeService.updateTribeRequest(action.model, action.tribe).pipe(
          tap((responseMessage: ResponseMessage) => {
            this.alertService.info(responseMessage.message);
          }),

          concatMap((responseMessage: ResponseMessage) => [
            TribeActions.updateTribeSuccess({
              bio: responseMessage.data
            })
          ]),

          catchError(error => {
            return of( TribeActions.effectError({error}) );
          })
        )
      )
    )
  );


  resendTribeRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TribeActions.resendTribeRequest),
      mergeMap(action =>
        this.tribeService.resendTribeRequest(action.toBio.id).pipe(
          tap((responseMessage: ResponseMessage) => {
            this.alertService.info(responseMessage.message);
          }),

          map((responseMessage: ResponseMessage) => {
              return TribeActions.getTribeActivityRequest();
            }
          ),

          catchError(error => {
            return of( TribeActions.effectError({error}) );
          })
        )
      )
    )
  );

  removeTribeRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TribeActions.removeTribeRequest),
      mergeMap(action =>
        this.tribeService.cancelTribeRequest(action.toBio.id, action.fromBio?.id).pipe(
          tap((responseMessage: ResponseMessage) => {
            this.alertService.info(responseMessage.message);
          }),

          map((responseMessage: ResponseMessage) => {
              return TribeActions.getTribeActivityRequest();
            }
          ),

          catchError(error => {
            return of( TribeActions.effectError({error}) );
          })
        )
      )
    )
  );

  acceptTribeRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TribeActions.acceptTribeRequest),
      mergeMap(action =>
        this.tribeService.acceptTribeRequest(action.toBio.id, action.fromBio?.id).pipe(
          tap((responseMessage: ResponseMessage) => {
            this.alertService.info(responseMessage.message);
          }),

          map((responseMessage: ResponseMessage) =>
            TribeActions.getTribeActivityRequest()
          ),

          catchError(error => {
            return of( TribeActions.effectError({error}) );
          })
        )
      )
    )
  );

  inviteFriendRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TribeActions.inviteFriendRequest),
      mergeMap(action =>
        this.tribeService.inviteFriend(action.model, action.fromBio).pipe(
          tap((responseMessage: ResponseMessage) => {
            this.alertService.info(responseMessage.message);
          }),

          map((responseMessage: ResponseMessage) =>
            TribeActions.getTribeActivityRequest()
          ),

          catchError(error => {
            return of( TribeActions.effectError({error}) );
          })
        )
      )
    )
  );
}
