<div class="modal-header">
    <h4 class="modal-title">
      {{ conceptKeyword }}s for
      <span *ngIf="displayBio">{{ displayBio.firstName }} {{ displayBio.lastName }}</span>
      <span *ngIf="memory">{{ memory.title }}</span>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <div class="row">
      <div class="col-12">
        <form [formGroup]="commentForm" (ngSubmit)="onSubmit()">

        <div class="form-group">
          <textarea maxlength="{{COMMENT_LENGTH}}" autosize formControlName="comment" class="form-control input-bio"
                    [ngClass]="{ 'is-invalid': submitted && f.comment.errors }"
                    placeholder="Add your own {{ conceptKeyword }}"
                    [attr.aria-label]="conceptKeyword" rows="3"></textarea>

          <app-character-countdown [text]="f.comment.value" [maxLength]="COMMENT_LENGTH" ></app-character-countdown>

          <app-control-validation [control]="f.comment" [submitted]="submitted"></app-control-validation>
        </div>

          <div class="form-group text-center">
            <button type="submit" class="btn btn-gold">
              Save
            </button>

            <button *ngIf="comment?.id" type="button" class="btn btn-outline-dark ml-3" (click)="onResetCommentEdit()">
              Cancel
            </button>

          </div>

        </form>
      </div>
    </div>

    <div class="row">
      <div class="col-12">

        <ul class="comments-list" *ngIf="comments.length > 0">
          <li class="comments-list_comment" *ngFor="let item of comments">

            <div class="comments-list_comment__thumb">
              <app-file-lazy-loader [bio]="item.bioFrom" [file]="item.bioFrom?.profile"></app-file-lazy-loader>
            </div>

            <div class="comments-list_comment__body">

              <div *ngIf="currentBio?.id == displayBio?.id || item.bioFrom?.id == currentBio?.id"
                   class="comments-list_comment__controls">

                <button type="button" class="btn btn-sm" (click)="onEditComment($event, item)">
                  <fa-icon [icon]="faPencilAlt"></fa-icon>
                  <span class="sr-only">Edit</span>
                </button>

                <button type="button" class="btn btn-sm" (click)="onDeleteComment($event, item)">
                  <fa-icon [icon]="faTimesCircle"></fa-icon>
                  <span class="sr-only">Remove</span>
                </button>
              </div>

              <div class="comments-list_comment__author">
                {{ item.bioFrom?.firstName }} {{ item.bioFrom?.lastName }}
                <app-ico-candle *ngIf="item.bioFrom?.isLegacy"></app-ico-candle>
              </div>

              <div class="comments-list_comment__date">
                {{ item.createdDate | date: 'mediumDate' }}
              </div>

              <div class="comments-list_comment__text">
                <app-expandable-text [text]="item.comment" [minLength]="400"></app-expandable-text>
              </div>

            </div>

          </li>
        </ul>


      </div>
    </div>

  </div>
  <div class="modal-footer">

    <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('Close')">
      Close
    </button>

  </div>
<app-content-loader [scope]="'modal'"></app-content-loader>
