import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {faArrowsAlt, faExclamationTriangle, faPencilAlt} from '@fortawesome/free-solid-svg-icons';
import {FileUpload, FileUploaderService} from '../file-uploader.service';
import {Bio} from '../../../../core/models/bio.model';
import {MemoryModel} from '../../../../core/models/memory.model';
import {JarModel} from '../../../../core/models/jar.model';
import {FileModel} from '../../../../core/models/file.model';
import {ModerationStatus} from '../../../../core/common/enums';

@Component({
  selector: 'app-file-uploader-thumb',
  templateUrl: 'file-uploader-thumb.component.html',
  styleUrls: ['../file-uploader.component.css', 'file-uploader-thumb.component.css']
})
export class FileUploaderThumbComponent implements OnChanges {
  @Input() public file: FileModel;
  @Input() public removable: boolean;
  @Input() public sortable: boolean;
  @Input() public editable: boolean;
  @Output() public fileClick: EventEmitter<FileModel>;
  @Output() public fileRemove: EventEmitter<FileModel>;
  @Output() public fileEdit: EventEmitter<FileModel>;

  // Application specific
  @Input() public bio: Bio;
  @Input() public memory: MemoryModel | JarModel;
  @Input() public downloadable: boolean;

  // This indicates that the Public API endpoints should be used
  @Input() public public: boolean;

  // Request the preview or thumbnail from server
  @Input() public preview: boolean;

  public faArrowsAlt = faArrowsAlt;
  public faExclamationTriangle = faExclamationTriangle;
  public faPencilAlt = faPencilAlt;

  public ModerationStatus = ModerationStatus;

  public tooltip: string;

  constructor(protected fileUploader: FileUploaderService) {
    this.file = null;
    this.removable = false;
    this.fileClick = new EventEmitter<FileModel>();
    this.fileRemove = new EventEmitter<FileModel>();
    this.fileEdit = new EventEmitter<FileModel>();

    this.bio = null;
    this.memory = null;
    this.downloadable = false;
    this.public = false;
    this.preview = false;
    this.tooltip = '';
  }

  public updateTooltip() {
    if (!this.file) {
      this.tooltip = '';
      return;
    }
    if (this.file.moderation === ModerationStatus.Rejected) {
      this.tooltip = this.file.title + ': This file appears to contain content that is not' +
                                          'suitable for My LifeJars and has been removed.';
    } else if (this.file.moderation === ModerationStatus.Pending) {
      this.tooltip = this.file.title + ': We are currently processing your file.';
    } else {
      this.tooltip = this.file.title;
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.updateTooltip();
  }

  public onClick(event, file: FileModel) {
    event.preventDefault();
    this.fileClick.emit(file);
  }

  public onRemove(event, file: FileModel) {
    event.preventDefault();
    this.fileRemove.emit(file);
  }

  public onEdit(event, file: FileModel) {
    event.preventDefault();
    this.fileEdit.emit(file);
  }

  public isImage(file: FileModel) {
    return this.fileUploader.isImage(file);
  }

  public isDocument(file: FileModel) {
    return this.fileUploader.isDocument(file);
  }

  public isVideo(file: FileModel) {
    return this.fileUploader.isVideo(file);
  }

  public isAudio(file: FileModel) {
    return this.fileUploader.isAudio(file);
  }

  public isUnknown(file: FileModel) {
    return this.fileUploader.isUnknown(file);
  }

}
