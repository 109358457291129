import {ChangeDetectorRef, Injector, OnDestroy, Pipe, PipeTransform} from '@angular/core';
import {AsyncPipe} from '@angular/common';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../store';
import {throwError} from 'rxjs';
import {OptionSelectors} from '../../store/options';
import {JarSelectors} from '../../store/jars';

@Pipe({
  name: 'options',
  pure: false
})
export class OptionsPipe implements PipeTransform, OnDestroy {
  private asyncPipe: AsyncPipe;

  constructor(
    private store: Store<AppState>,
    private injector: Injector
  ) {
    this.asyncPipe = new AsyncPipe(injector.get(ChangeDetectorRef));
  }

  transform(value: any, selector: string): any {
    let storeSelector = null;
    switch (selector.toLowerCase()) {
      case 'gender':
        storeSelector = OptionSelectors.selectGenderType;
        break;

      case 'address':
        storeSelector = OptionSelectors.selectAddressType;
        break;

      case 'religion':
        storeSelector = OptionSelectors.selectReligion;
        break;

      case 'practicingreligion':
        storeSelector = OptionSelectors.selectPracticingReligionStatus;
        break;

      case 'ethnicity':
        storeSelector = OptionSelectors.selectEthnicity;
        break;

      case 'customtradition':
        storeSelector = OptionSelectors.selectCustomTradition;
        break;

      case 'memoryjar':
      case 'thingjar':
      case 'passwordjar':
        storeSelector = OptionSelectors.selectMemoryJarType;
        break;

      case 'memorytag':
      case 'thingtag':
      case 'passwordtag':
        storeSelector = OptionSelectors.selectMemoryTagType;
        break;

      case 'secretkeeper':
        storeSelector = OptionSelectors.selectSecretKeeperType;
        break;

      case 'timelock':
        storeSelector = OptionSelectors.selectTimeLockType;
        break;

      case 'relationshiptype':
        storeSelector = OptionSelectors.selectRelationshipType;
        break;

      case 'privacy':
        storeSelector =  OptionSelectors.selectPrivacyStatus;
        break;

      case 'biojar':
        storeSelector =  JarSelectors.selectJarById;
        break;

      case 'nodetype':
        storeSelector =  OptionSelectors.selectNodeType;
        break;
    }

    if (storeSelector != null) {
      return this.asyncPipe.transform(this.store.pipe(select( storeSelector(value) )));
    }

    return throwError('Unknown selector in OptionsPipe.');
  }

  ngOnDestroy() {
    this.asyncPipe.ngOnDestroy();
  }

}
