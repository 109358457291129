import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {Router} from '@angular/router';
import {ActivatedRoute} from '@angular/router';
import {faSearch, faPlusCircle, faUserEdit} from '@fortawesome/free-solid-svg-icons';
import {User} from '../../../core/models/user.model';
import {UserSubscription} from '../../../core/models/user-subscription.model';
import {Subscription} from 'rxjs';
import {Bio} from '../../../core/models/bio.model';
import {BioService} from '../../../core/services/bio.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ControlValidationService} from '../../../shared/components/control-validation/control-validation.service';
import {SubscriptionsService} from '../../../core/services/subscriptions.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AlertService} from '../../../shared/components/alert/alert.service';
import {LayoutService} from '../../../core/services/layout.service';
import {select, Store} from '@ngrx/store';
import {selectCurrentUser, selectCurrentUserSubscription} from '../../../store/users/users.selectors';
import {AppState} from '../../../store';
import {selectCurrentBio} from '../../../store/bios/bios.selectors';
import {UserActions} from '../../../store/users';
import {selectUserBios} from '../../../store/user-bios/user-bios.selectors';
import {first, mergeMap} from 'rxjs/operators';
import {ResponseMessage} from '../../../core/models/response-message.model';
import {GoogleTagManagerService} from 'angular-google-tag-manager';
import {Actions, ofType} from '@ngrx/effects';
import {EmailChangeModalComponent} from '../../../shared/modals/email-change/email-change-modal.component';
import {DeleteAccountModalComponent} from '../../../shared/modals/delete-account/delete-account-modal.component';
import {isPlatformBrowser} from '@angular/common';

@Component({templateUrl: 'settings.component.html'})
export class SettingsComponent implements OnInit, OnDestroy {
  private readonly subscription: Subscription;

  public faSearch = faSearch;

  public userSubscription: UserSubscription;
  public user: User;
  public bio: Bio;
  public wards: Bio[];

  public memoryRatio: number;
  public thingRatio: number;

  public showUserFormErrors = false;
  public userForm: FormGroup;

  public showPasswordFormErrors = false;
  public passwordForm: FormGroup;
  public formErrorMsg: string;

  public enable2Fa: boolean;
  public rememberMe: boolean;

  public invoiceNumber: string;

  public faPlusCircle = faPlusCircle;
  public faUserEdit = faUserEdit;

  // convenience getter for easy access to form fields
  public get f() {
    return this.userForm.controls;
  }

  public get f2() {
    return this.passwordForm.controls;
  }

  constructor(
    @Inject(PLATFORM_ID) protected platformId,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private actions: Actions,
    private store: Store<AppState>,
    private bioService: BioService,
    private subscriptionsService: SubscriptionsService,
    private modalService: NgbModal,
    private layoutService: LayoutService
  ) {

    this.layoutService.setTitle(`My Settings - My LifeJars`);

    this.userForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      addressDetails: new FormControl('')
    });

    this.passwordForm = this.formBuilder.group({
      oldPassword: ['', [Validators.required] ],
      password: ['', [Validators.required, ControlValidationService.passwordValidator] ],
      passwordConfirm: ['', [Validators.required, ControlValidationService.passwordValidator] ]
    }, { validator: ControlValidationService.RepeatPasswordValidator });

    this.subscription = new Subscription();

    this.subscription.add(this.store.pipe(select(selectCurrentUser))
      .subscribe((user: User) => {
        this.user = user;

        this.userForm.patchValue(this.user);
        this.userForm.get('addressDetails').patchValue({
          streetAddress: user.streetAddress,
          streetAddress2: user.streetAddress2,
          suburb: user.suburb,
          postcode: user.postcode,
          state: user.state,
          country: user.country,
          type: null
        });

        this.enable2Fa = this.user.enable2Fa;
        this.rememberMe = this.user.rememberMe;
      })
    );

    this.subscription.add(this.store.pipe(select(selectCurrentUserSubscription))
      .subscribe((userSub: UserSubscription) => {
        this.userSubscription = userSub;
        this.updateRatios();
      })
    );

    this.subscription.add(this.store.pipe(select(selectCurrentBio))
      .subscribe((bio: Bio) => {
        this.bio = bio;
        this.updateRatios();
      })
    );

    this.wards = [];
    this.subscription.add(this.store.pipe(select(selectUserBios))
      .subscribe((bios: Bio[]) => {
        this.wards = bios; // aka Wards
      })
    );

    this.subscription.add( this.actions
      .pipe(
        ofType(UserActions.changePasswordRequestSuccess),
        select( result => result.responseMessage )
      )
      .subscribe((result) => {
        this.showPasswordFormErrors = false;
        this.passwordForm.reset();
      })
    );

  }

  public ngOnInit() {
    const action = this.route.snapshot.paramMap.get('action') || '';
    const invoiceNumber = this.route.snapshot.paramMap.get('invoiceNumber') || '';

    switch (action) {
      case 'paypal-capture':
        this.subscriptionsService.capturePayment(invoiceNumber)
          .pipe(first())
          .subscribe((response: ResponseMessage) => {
            this.store.dispatch(UserActions.updateUserSubscriptionRequest({
              id: invoiceNumber,
              alertChange: true
            }));
          });
        break;

      case 'paypal-cancel':
        this.subscriptionsService.cancelSubscription(invoiceNumber)
          .pipe(first())
          .subscribe((response: ResponseMessage) => {

          });
        break;

      case 'renewal-cancelled':
        this.store.dispatch(UserActions.updateUserSubscriptionRequest({
          id: null,
          alertCancelled: true
        }));
        break;

      case 'paypal-complete':
      case 'payment-complete':
        this.store.dispatch(UserActions.updateUserSubscriptionRequest({
          id: invoiceNumber,
          alertChange: true
        }));
        break;

      default:
        this.store.dispatch(UserActions.updateUserSubscriptionRequest({
          id: null
        }));
        break;
    }

  }

  public setUserFields(user: User) {
    this.user = user;
    this.userForm.patchValue(this.user);
  }

  public onSubmit() {

    // stop here if form is invalid
    this.showUserFormErrors = true;
    this.formErrorMsg = '';
    if (this.userForm.invalid) {
      return;
    }

    const userForm = this.userForm.getRawValue();
    this.store.dispatch(
      UserActions.saveUserRequest({
        user: {
          ...this.user,
          ...userForm,
          ...userForm.addressDetails
        }
      })
    );

  }


  public onPasswordSubmit() {

    // stop here if form is invalid
    this.showPasswordFormErrors = true;
    this.formErrorMsg = '';
    if (this.passwordForm.invalid) {
      return;
    }

    const passwordForm = this.passwordForm.getRawValue();
    this.store.dispatch(
      UserActions.changePasswordRequest({
        oldPassword: passwordForm.oldPassword,
        password: passwordForm.password,
        passwordConfirm: passwordForm.passwordConfirm,
      })
    );
  }

  public onLoginSettingsChange() {
    this.store.dispatch(
      UserActions.updateLoginSettingsRequest({
        enable2Fa: this.enable2Fa,
        rememberMe: this.rememberMe,
      })
    );
  }

  public updateRatios() {
    if (!this.userSubscription || !this.bio) {
      return;
    }

    this.memoryRatio = this.userSubscription.memories > 0
      ? (this.bio.memories / this.userSubscription.memories) * 100
      : 0;

    this.thingRatio = this.userSubscription.things > 0
      ? (this.bio.things / this.userSubscription.things) * 100
      : 0;
  }


  public changeEmailModal(event){
    const modal = this.modalService.open(EmailChangeModalComponent, {backdrop: 'static'});
  }

  public deleteAccount(event){
    const modal = this.modalService.open(DeleteAccountModalComponent, {backdrop: 'static'});
  }


  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public openLink() {
    window.open('https://mylifejars.com/pricing-and-features/', '_blank');
  }

}
