import {Component, Input, OnInit} from '@angular/core';
@Component({
  selector: 'app-ico-image-count',
  templateUrl: 'ico-image-count.component.html',
  styleUrls: ['../ico.component.css', 'ico-image-count.component.css']
})
export class IcoImageCountComponent implements OnInit {
  @Input() public count: number;
  constructor() {
    this.count = 0;
  }
  public ngOnInit(): void {
  }
}
