import {Component, Input, TemplateRef} from '@angular/core';

@Component({
  selector: 'app-page-header',
  templateUrl: 'page-header.component.html'
})
export class PageHeaderComponent {
  @Input()
  public title: string;

  @Input()
  public controls: TemplateRef<any>;
}
