
<div *ngIf="bio" class="card card-legacy card-legacy-cover" [ngClass]="{ 'anim-fadein':bio }" >
  <div class="corner corner-tl"></div>
  <div class="corner corner-br"></div>
  <div class="card-body">

    <div class="row align-items-center">
      <div class="col-sm-5 text-center ">

        <div class="bio-profile-shadow">
          <div class="bio-profile-border">
            <div class="bio-profile-border">
              <div class="bio-profile">
                <app-file-lazy-loader [bio]="bio" [file]="bio.profile" [public]="true"></app-file-lazy-loader>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="col-sm-7 text-center ">

        <h2 class="bio-subtitle">The legacy of</h2>

        <h1 class="bio-name">
          {{ bio.firstName }}
          {{ bio.lastName }}
          <span class="bio-prev-names" *ngFor="let name of bio.previousNames">
                   {{name}}
                </span>
        </h1>

        <div class="bio-aka" *ngIf="bio.preferredNames?.length">
          <p>Also known as</p>
          <h2>
                 <span *ngFor="let item of bio.preferredNames; let isLast=last">
                   {{item}}{{isLast ? '' : ' or '}}
                </span>
          </h2>
        </div>

        <h4 class="bio-lifetime">
          <span>{{ bio.birthday|date:'MMM d, y' }}</span>
          to
          <span>{{ bio.deathday|date:'MMM d, y' }}</span>
        </h4>

        <div class="resting-pin-container mt-3">
          <button *ngIf="bio.restingLocation" (click)="showRestingPin($event)" class="btn btn-gold" type="button">
            <fa-icon [icon]="faMapMarkerAlt"></fa-icon>
            Resting Pin
          </button>

          <a class="btn btn-gold ml-2" [routerLink]="['/print/book',bio.slug]" target="_blank">
            <fa-icon [icon]="faBook"></fa-icon>
            Legacy Book
          </a>

          <button  *ngIf="bio.allowComments" class="btn btn-gold ml-2" (click)="showComments($event)" type="button">
            <fa-icon [icon]="faComment"></fa-icon>
            Tributes ({{ bio.commentCount }})
          </button>
        </div>

      </div>
    </div><!-- /row -->

  </div>
</div><!-- /card-legacy-cover -->


<div *ngIf="bio" class="block block-search mt-4 mb-4" [ngClass]="{ 'anim-fadein':bio }">
  <div class="input-group">
    <input type="text" class="form-control" placeholder="Search..."
           aria-label="Search Tribe" [(ngModel)]="keywords" (change)="queueSearch()">

    <select class="form-control" [(ngModel)]="jarType">
      <option [value]="null">Any Jar</option>
      <option *ngFor="let jar of MemoryJarTypes" [ngValue]="jar">{{ jar.title }}</option>
    </select>

    <div class="input-group-append">
      <button class="btn" type="button" (click)="newSearch()">
        <span class="sr-only">Search</span>
        <fa-icon [icon]="faSearch"></fa-icon>
      </button>
    </div>

  </div>
</div><!-- /block-search -->


<div class="card card-legacy card-legacy-memory anim-fadein"
     *ngFor="let item of pagedMemories.list; let index = index;"
     [ngClass]="{ 'card-left': (index%2 == 0), 'card-right': (index%2 == 1) }">

  <div class="corner corner-tl"></div>
  <div class="corner corner-br"></div>
  <div class="card-body">

    <!-- 11: MemoryJar | 21: ThingJar -->
    <div class="block block-jar-title" *ngIf="item.nodeType ==  11 || item.nodeType ==  21">
      <h3 class="title">
        {{ item.title }}
      </h3>
    </div>

    <div class="photos">

      <div class="photo-preview">
        <app-file-lazy-loader [memory]="item" [file]="item.files[0]" [public]="true"
                              (click)="showLightbox(item, item.files[0])"></app-file-lazy-loader>
      </div>

      <div class="photo-list" *ngIf="item?.files.length > 1">
        <ul class="file-uploader-list">
          <li class="file-uploader-list-item" *ngFor="let f of item.files">
            <app-file-uploader-thumb
              preview="true"
              [file]="f"
              [removable]="false"
              [sortable]="false"
              [memory]="item"
              [public]="true"(fileClick)="showLightbox(item, f)"></app-file-uploader-thumb>
          </li>
        </ul>
      </div><!-- /photo-list -->

    </div><!-- /photos -->


    <div class="story">

      <!-- 11: MemoryJar | 21: ThingJar -->
      <div class="block block-memory-title mb-4" *ngIf="item.nodeType !==  11 && item.nodeType !==  21">
        <h4 *ngIf="item.date" class="date mb-1">
          {{ item.date|date:'MMM d, y' }}
        </h4>
        <h3 class="title">
          {{ item.title }}
        </h3>
        <h4 *ngIf="item.location" class="location mt-1">
          <fa-icon [icon]="faMapMarkerAlt"></fa-icon>
          {{ item.location}}
        </h4>
      </div>

      <div class="description">{{(item.description || item.placeholder)}}</div>

      <button *ngIf="item.allowShare" class="btn btn-gold mt-3 mr-2" (click)="addToJarPicker($event, item)" type="button">
        <fa-icon [icon]="faShare"></fa-icon>
        Add to my Jar
      </button>

      <button *ngIf="item.allowComments" class="btn btn-gold mt-3" (click)="showMemoryComments($event, item)" type="button">
        <fa-icon [icon]="faComment"></fa-icon>
        Recollections ({{item.commentCount}})
      </button>


    </div><!-- /story -->


  </div>
</div><!-- /card-legacy-memory -->

<div class="lazy-load-indicator">
  <app-content-loader [scope]="'lazy'">Loading more...</app-content-loader>
</div>

