import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {OptionsService} from '../../core/services/options.service';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {OptionActions} from './index';
import {OptionsState} from './options.state';

@Injectable()
export class OptionsEffects {

  constructor(
    private actions$: Actions,
    private optionService: OptionsService
  ) {
  }

  getOptionsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OptionActions.getOptions),
      mergeMap(action =>
        this.optionService.getOptions().pipe(
          map((options: OptionsState) =>
            OptionActions.getOptionsSuccess({
              options
            })
          ),
          catchError(error => {
            return of(OptionActions.effectError({error}));
          })
        )
      )
    )
  );

}
