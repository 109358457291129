import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SecuredBioPrintComponent} from './secured-bio-print.component';
import {BioPrintPhotosComponent} from './photos/bio-print-photos.component';
import {BioPrintStoryComponent} from './story/bio-print-story.component';
import {BioPrintFooterComponent} from './footer/bio-print-footer.component';
import {FileUploaderModule} from '../../../shared/components/file-uploader/file-uploader.module';
import {ContentLoaderModule} from '../../../shared/components/content-loader/content-loader.module';
import {BioPrintPageStandardComponent} from './page-standard/bio-print-page-standard.component';
import {BioPrintPagePhotosComponent} from './page-photos/bio-print-page-photos.component';
import {BioPrintPageTextComponent} from './page-text/bio-print-page-text.component';

@NgModule({
  imports: [
    CommonModule,
    FileUploaderModule,
    ContentLoaderModule
  ],
  entryComponents: [],
  declarations: [
    SecuredBioPrintComponent,
    BioPrintPageStandardComponent,
    BioPrintPagePhotosComponent,
    BioPrintPageTextComponent,
    BioPrintPhotosComponent,
    BioPrintStoryComponent,
    BioPrintFooterComponent,
  ],
  exports: [
    SecuredBioPrintComponent,
    BioPrintPageStandardComponent,
    BioPrintPagePhotosComponent,
    BioPrintPageTextComponent,
    BioPrintPhotosComponent,
    BioPrintStoryComponent,
    BioPrintFooterComponent,
  ],
  providers: []
})
export class SecuredBioPrintModule { }
