import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-junk-folder-alert',
  templateUrl: 'junk-folder-alert.component.html'
})
export class JunkFolderAlertComponent {
  @Input()
  public showAlert: boolean;
}
