/// <reference types="@types/googlemaps" />
import {AfterViewInit, Component, ElementRef, Input, NgZone, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {GeoLocationService} from '../../../../core/services/geo-location.service';
import {MapsAPILoader} from '@agm/core';
import {faSearch} from '@fortawesome/free-solid-svg-icons';
import {Coords} from '../../../../core/models/coords.model';
import {LoaderService, ScopedLoader} from '../../../components/content-loader/loader.service';

@Component({
  templateUrl: 'location-picker.component.html',
  styleUrls: ['location-picker.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class LocationPickerComponent implements OnInit, AfterViewInit {
  @ViewChild('search') public searchInput: ElementRef;
  @Input() public address: string;
  @Input() public readonly: boolean;
  public loader: ScopedLoader;
  public submitted = false;
  public mapCoords: Coords;
  public markerCoords: Coords;
  public zoom: number;

  public faSearch = faSearch;

  protected geoCoder: google.maps.Geocoder;

  constructor(
    public activeModal: NgbActiveModal,
    protected geoService: GeoLocationService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private loaderService: LoaderService
  ) {
    this.mapCoords = new Coords();
    this.markerCoords = new Coords();
    this.zoom = 10;

    this.loader = this.loaderService.getScopedLoader('modal-picker');
    this.loader.show();
  }

  public ngOnInit() {

  }

  public ngAfterViewInit() {

    // load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder();

      const autoComplete = new google.maps.places.Autocomplete(this.searchInput.nativeElement, {
        // types: ['address']
      });

      autoComplete.addListener('place_changed', () => {
        this.ngZone.run(() => {

          // get the place result
          const place: google.maps.places.PlaceResult = autoComplete.getPlace();

          // verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          // set latitude, longitude and zoom
          this.mapCoords.lat = this.markerCoords.lat = place.geometry.location.lat();
          this.mapCoords.lng = this.markerCoords.lng = place.geometry.location.lng();
          this.zoom = 15;
          this.getAddress(this.markerCoords.lat, this.markerCoords.lng);
        });
      });

      // Load the Currently Saved Address
      if (this.address) {
        this.getLatLon(this.address);
      }

      // Find the Users Location
      else {
        this.geoService.CurrentCoords.subscribe(
          (pos: GeolocationPosition) => {

            // We have a custom Address, ignore change
            if (this.address) {
              return;
            }

            // verify result
            if (pos === undefined || pos === null) {
              return;
            }

            this.mapCoords.lat = this.markerCoords.lat = pos.coords.latitude;
            this.mapCoords.lng = this.markerCoords.lng = pos.coords.longitude;
            this.zoom = 10;
            this.getAddress(this.markerCoords.lat, this.markerCoords.lng);
          });

      }

      this.loader.hide();
    });

  }

  public onMapClick($event) {
    this.markerCoords.lat = $event.coords.lat;
    this.markerCoords.lng = $event.coords.lng;
    this.getAddress(this.markerCoords.lat, this.markerCoords.lng);
  }


  public getAddress(latitude, longitude) {

    this.loader.show();
    this.geoCoder.geocode({location: {lat: latitude, lng: longitude}}, (results, status) => {

      if (status === google.maps.GeocoderStatus.OK) {
        if (results[0]) {

          // verify result
          if (results[0].formatted_address === undefined || results[0].formatted_address === null) {
            return;
          }

          this.address = results[0].formatted_address;
          this.zoom = 18;
        } else {
          window.alert('No results found');
        }
      } else {
        alert('Geocode was not successful for the following reason: ' + status);
      }

      this.loader.hide();
    });
  }

  public getLatLon(address: string) {

    this.loader.show();
    this.geoCoder.geocode({address}, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        if (results[0]) {

          // verify result
          if (results[0].geometry === undefined || results[0].geometry === null) {
            return;
          }

          this.mapCoords.lat = this.markerCoords.lat = results[0].geometry.location.lat();
          this.mapCoords.lng = this.markerCoords.lng = results[0].geometry.location.lng();
          this.zoom = 18;
        } else {
          window.alert('No results found');
        }
      } else {
        alert('Geocode was not successful for the following reason: ' + status);
      }

      this.loader.hide();
    });
  }

  public onSubmit() {
    this.submitted = true;

    this.activeModal.close(this.address);
  }


}
