import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Bio} from '../../../core/models/bio.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../store';
import {of, Subject, Subscription, throwError} from 'rxjs';
import {User} from '../../../core/models/user.model';
import {UserActions, UserSelectors} from '../../../store/users';
import {ControlValidationService} from '../../components/control-validation/control-validation.service';
import {catchError, delay, first, flatMap, map, mergeMap, repeat, repeatWhen, takeUntil, takeWhile, tap} from 'rxjs/operators';
import {UserService} from '../../../core/services/user.service';
import {AuthService} from '../../../core/services/auth.service';
import {Router} from '@angular/router';

@Component({
  templateUrl: 'delete-account-modal.component.html'
})
export class DeleteAccountModalComponent implements OnInit, OnDestroy {
  private readonly subscription: Subscription;
  public user: User;
  public ownedBios: Bio[];
  public editForm: FormGroup;
  public submitted = false;

  // convenience getter for easy access to form fields
  public get f() {
    return this.editForm.controls;
  }

  constructor(
    public activeModal: NgbActiveModal,
    protected modalService: NgbModal,
    protected formBuilder: FormBuilder,
    protected store: Store<AppState>,
    protected router: Router,
    protected authService: AuthService,
    protected userService: UserService
  ) {
    this.subscription = new Subscription();

    this.subscription.add(this.store.pipe(select(UserSelectors.selectCurrentUser))
      .subscribe((user: User) => {
        this.user = user;
      })
    );

    this.subscription.add(this.store.pipe(select(UserSelectors.selectOwnedBios))
      .subscribe((bios: Bio[]) => {
        this.ownedBios = bios;
      })
    );

    this.store.dispatch(UserActions.getOwnedBiosRequest());
  }

  public ngOnInit() {
    this.editForm = this.formBuilder.group({
      deleteConfirm: ['', [ControlValidationService.deleteValidator]]
    });
  }

  public onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.editForm.invalid) {
      return;
    }

    this.store.dispatch( UserActions.deleteUserRequest() );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
