import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';


@Component({
  templateUrl: 'confirm-popup.component.html'
})
export class ConfirmPopupComponent implements OnInit {
  @Input() public title: string;
  @Input() public message: string;
  @Input() public yesText: string;
  @Input() public noText: string;
  @Input() public classes: string;

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal
  ) {
    this.title = 'Confirm';
    this.message = 'Do you want to continue?';
    this.yesText = 'Yes';
    this.noText = 'No';
  }

  public ngOnInit() {

  }

  public yes( data?: any ) {
    this.activeModal.close(true);
  }

  public no( data?: any ) {
    this.activeModal.dismiss(false);
  }

}
