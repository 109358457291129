<div class="common-address-container" [formGroup]="commonAddressForm">

  <div class="form-group" *ngIf="withTypeField">
    <label class="control-label">Address Type</label>
    <select formControlName="type" class="form-control form-control-lg input-bio"
            [ngClass]="{ 'is-invalid': submitted && f.type.errors }" aria-label="Type">
      <option [ngValue]="null" selected>Select one...</option>
      <option *ngFor="let item of AddressTypeChoices" [ngValue]="item.id">{{ item.label }}</option>
    </select>
    <app-control-validation [control]="f.type" [submitted]="submitted"></app-control-validation>
  </div>

  <div class="form-group">
    <label class="control-label">Street Address</label>
    <input type="text" formControlName="streetAddress" placeholder="Street Address" class="form-control form-control-lg input-bio">
    <app-control-validation [control]="f.streetAddress" [submitted]="submitted"></app-control-validation>
  </div>

  <div class="form-group">
    <input type="text" formControlName="streetAddress2" placeholder="Street Address" class="form-control form-control-lg input-bio">
    <app-control-validation [control]="f.streetAddress2" [submitted]="submitted"></app-control-validation>
  </div>

  <div class="row">
    <div class="col-sm">
      <div class="form-group">
        <label class="control-label">Town/Suburb</label>
        <input type="text" formControlName="suburb" placeholder="Town/Suburb" class="form-control form-control-lg input-bio">
        <app-control-validation [control]="f.suburb" [submitted]="submitted"></app-control-validation>
      </div>
    </div>

    <div class="col-sm">
      <div class="form-group">
        <label class="control-label">State/County</label>
        <input type="text" formControlName="state" placeholder="State/County" class="form-control form-control-lg input-bio">
        <app-control-validation [control]="f.state" [submitted]="submitted"></app-control-validation>
      </div>
    </div>

  </div>

  <div class="row">

    <div class="col-sm">
      <div class="form-group">
        <label class="control-label">ZIP/Postcode</label>
        <input type="text" formControlName="postcode" placeholder="Postcode" class="form-control form-control-lg input-bio">
        <app-control-validation [control]="f.postcode" [submitted]="submitted"></app-control-validation>
      </div>
    </div>

    <div class="col-sm">
      <div class="form-group">
        <label class="control-label">Country</label>
        <input type="text" formControlName="country" placeholder="Country" class="form-control form-control-lg input-bio">
        <app-control-validation [control]="f.country" [submitted]="submitted"></app-control-validation>
      </div>
    </div>

  </div>
</div>
