import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../../../core/services/auth.service';
import {faSearch, faPencilAlt, faPlusCircle, faCog, faSignOutAlt, faInfoCircle, faLock, faHeart} from '@fortawesome/free-solid-svg-icons';
import {Bio} from '../../../core/models/bio.model';
import {Subscription} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BioUser} from '../../../core/models/bio-user.model';
import {LayoutService} from '../../../core/services/layout.service';
import {User} from '../../../core/models/user.model';
import {UserSubscription} from '../../../core/models/user-subscription.model';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../store';
import {selectCurrentBio} from '../../../store/bios/bios.selectors';
import {BioActions} from '../../../store/bios';
import {selectBioUsers} from '../../../store/bio-users/bio-users.selectors';
import {selectCurrentUser, selectCurrentUserSubscription} from '../../../store/users/users.selectors';
import {selectUserBios} from '../../../store/user-bios/user-bios.selectors';
import {WardFormModalComponent} from '../../../shared/modals/ward-form/ward-form-modal.component';
import {UserActions} from '../../../store/users';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'app-secured-header',
  templateUrl: 'secured-header.component.html',
  styleUrls: ['secured-header.component.css']
})
export class SecuredHeaderComponent implements OnInit, OnDestroy {
  private readonly subscription: Subscription;

  public bio: Bio;
  public bioUsers: BioUser[]; // Guardians

  public defaultBio: Bio;
  public userBios: Bio[]; // Wards

  public user: User;
  public userSubscription: UserSubscription;

  public menuActive: boolean;

  public readonly faSearch = faSearch;
  public readonly faPencilAlt = faPencilAlt;
  public readonly faPlusCircle = faPlusCircle;
  public readonly faCog = faCog;
  public readonly faSignOutAlt = faSignOutAlt;
  public readonly faInfoCircle = faInfoCircle;
  public readonly faLock = faLock;
  public readonly faHeart = faHeart;

  public adminIsVisible: boolean;

  public searchModel: any;

  constructor(
    protected router: Router,
    protected store: Store<AppState>,
    protected authService: AuthService,
    protected modalService: NgbModal,
    protected layoutService: LayoutService
  ) {

    this.subscription = new Subscription();

    this.subscription.add(this.store.pipe(select(selectCurrentUser))
      .subscribe((user: User) => {
        this.user = user;
      })
    );

    this.subscription.add(this.store.pipe(select(selectCurrentUserSubscription))
      .subscribe((userSub: UserSubscription) => {
        this.userSubscription = userSub;
      })
    );

    this.userBios = []; //  aka Wards
    this.subscription.add(this.store.pipe(select(selectUserBios), filter(b => b != null))
      .subscribe((bios: Bio[]) => {
        this.userBios = bios.filter(b => b.isDefault === false);
        this.defaultBio = bios.find(b => b.isDefault === true);
      })
    );

    this.subscription.add(this.store.pipe(select(selectCurrentBio))
      .subscribe((bio: Bio) => {
        this.bio = bio;
      })
    );

    this.bioUsers = []; // aka Guardians
    this.subscription.add(this.store.pipe(select(selectBioUsers))
      .subscribe((bioUsers: BioUser[]) => {
        this.bioUsers = bioUsers;
      })
    );
  }

  public ngOnInit() {
  }

  public toggleAdmin(event) {
    event.preventDefault();
    this.adminIsVisible = this.user.isAdmin
      ? !this.adminIsVisible
      : false;
  }

  public toggleMenu() {
    this.menuActive = this.layoutService.toggleSidebar();
  }

  public showWardModal(event) {
    const modal = this.modalService.open(WardFormModalComponent, {backdrop: 'static'});
  }

  public switchBio(bio: Bio) {
    this.store.dispatch(
      BioActions.switchBioRequest({
        slugOrId: bio.slug // Current Bio
      })
    );

  }

  public logout() {
    this.store.dispatch(UserActions.logout());
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
