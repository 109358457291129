import {AfterViewInit, Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TribeService} from '../../../core/services/tribe.service';
import {Bio} from '../../../core/models/bio.model';
import {Subscription} from 'rxjs';
import {LayoutService} from '../../../core/services/layout.service';
import {select, Store} from '@ngrx/store';
import {selectCurrentBio} from '../../../store/bios/bios.selectors';
import {AppState} from '../../../store';
import {TribeActivityResult} from '../../../core/models/tribe-activity-result.model';
import {selectTribeActivity} from '../../../store/tribe/tribe.selectors';
import {faPlay} from '@fortawesome/free-solid-svg-icons';
import {UserActions} from '../../../store/users';

@Component({templateUrl: 'dashboard.component.html'})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly subscription: Subscription;
  public tribeActivity: TribeActivityResult;
  public bio: Bio;

  public faPlay = faPlay;

  constructor(
    @Inject(PLATFORM_ID) protected platformId,
    protected router: Router,
    protected route: ActivatedRoute,
    protected store: Store<AppState>,
    protected tribeService: TribeService,
    protected layoutService: LayoutService
  ) {

    this.layoutService.setTitle( `Dashboard - My LifeJars`  );

    this.subscription = new Subscription();

    this.subscription.add(this.store.pipe(select(selectCurrentBio))
      .subscribe((bio: Bio) => {
        this.bio = bio;
      })
    );

    this.tribeActivity = new TribeActivityResult();
    this.subscription.add(this.store.pipe(select(selectTribeActivity))
      .subscribe((tribeActivity: TribeActivityResult) => {
        this.tribeActivity = tribeActivity;
      })
    );

  }

  public ngOnInit(): void { }

  ngAfterViewInit(): void {
    // this.layoutService.showTutorial('dashboardTutorial');
  }

  public takeATour(event: any){
    event.preventDefault();
    this.layoutService.showTutorial('dashboardTutorial', true);
  }

  public requestResponse(response: boolean) {
    // todo
  }

  public showTutorials(event) {
    this.store.dispatch(
      UserActions.resetTutorialsRequest({
        metaData: {
          memoryJarsTutorial: null,
          thingsJarsTutorial: null,
          passwordJarsTutorial: null,
          onlineMemorialTutorial: null,
          tribeTutorial: null,
          guardiansTutorial: null,
          dashboardTutorial: null,
          digitalLegacyTutorial: null,
          lifeJournalTutorial: null,
          editBioTutorial: null,
        }
      })
    );
  }

  public hideTutorials(event) {
    this.store.dispatch(
      UserActions.resetTutorialsRequest({
        metaData: {
          memoryJarsTutorial: true,
          thingsJarsTutorial: true,
          passwordJarsTutorial: true,
          onlineMemorialTutorial: true,
          tribeTutorial: true,
          guardiansTutorial: true,
          dashboardTutorial: true,
          digitalLegacyTutorial: true,
          lifeJournalTutorial: true,
          editBioTutorial: true,
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
