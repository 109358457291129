<div class="modal-header" [ngClass]="classes">
  <h4 class="modal-title">{{ title }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss(false)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" [ngClass]="classes">
  <div class="confirm-message">
    <p [innerHTML]="message"></p>
  </div>
</div>
<div class="modal-footer text-center" [ngClass]="classes">
  <button class="btn btn-success pl-3 pr-3" type="button" (click)="ok()">{{okText}}</button>
</div>
