
export class CouponModel {
  public code: string;
  public description: string;
  public products: string[] = [];
  public amount: number;
  public percent: number;

  constructor() {
    this.code = '';
    this.description = '';
    this.products = [];
    this.amount = 0.00;
    this.percent = 0.00;
  }

  public static make(obj: object): CouponModel {
    if ( !obj || (Object.keys(obj).length === 0) ) {
      return null; // Object is null or empty
    }
    const model = new CouponModel();
    return {...model, ...obj};
  }

}
