import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Subscription} from 'rxjs';
import {User} from '../../../core/models/user.model';
import {Bio} from '../../../core/models/bio.model';
import {UserSubscription} from '../../../core/models/user-subscription.model';
import {select, Store} from '@ngrx/store';
import {selectCurrentUser, selectCurrentUserSubscription} from '../../../store/users/users.selectors';
import {AppState} from '../../../store';
import {selectCurrentBio} from '../../../store/bios/bios.selectors';

@Component({
  templateUrl: 'plan-upgrade-modal.component.html'
})
export class PlanUpgradeModalComponent implements OnDestroy  {
  private readonly subscription: Subscription;
  public user: User;
  public userSubscription: UserSubscription;
  public bio: Bio;

  constructor(
    public activeModal: NgbActiveModal,
    protected modalService: NgbModal,
    protected store: Store<AppState>
  ) {
    this.subscription = new Subscription();

    this.subscription.add(this.store.pipe(select(selectCurrentUser))
      .subscribe((user: User) => {
        this.user = user;
      })
    );

    this.subscription.add(this.store.pipe(select(selectCurrentBio))
      .subscribe((bio: Bio) => {
        this.bio = bio;
      })
    );

    this.subscription.add(this.store.pipe(select(selectCurrentUserSubscription))
      .subscribe((userSub: UserSubscription) => {
        this.userSubscription = userSub;
      })
    );
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
