import {Routes, RouterModule} from '@angular/router';

import {AuthGuard} from './core/guards/auth.guard';

import {PublicLayoutComponent} from './feature/_layout/public-layout/public-layout.component';
import {SecuredLayoutComponent} from './feature/_layout/secured-layout/secured-layout.component';
import {LoginComponent} from './feature/public/login/login.component';
import {RegisterComponent} from './feature/public/register/register.component';
import {PasswordResetComponent} from './feature/public/password-reset/password-reset.component';
import {PasswordChangeComponent} from './feature/public/password-change/password-change.component';
import {DashboardComponent} from './feature/secured/dashboard/dashboard.component';
import {MemoryJarsComponent} from './feature/secured/jars/memory-jars/memory-jars.component';
import {ThingJarsComponent} from './feature/secured/jars/thing-jars/thing-jars.component';
import {LifeWallComponent} from './feature/secured/bios/life-wall/life-wall.component';
import {BioViewComponent} from './feature/secured/bios/view/bio/bio-view.component';
import {SupportComponent} from './feature/secured/support/support.component';
import {SettingsComponent} from './feature/secured/account-settings/settings.component';
import {FeaturesComponent} from './feature/secured/account-features/features.component';
import {MemoryDefaultsComponent} from './feature/admin/memory-defaults/memory-defaults.component';
import {AdminAuthGuard} from './core/guards/admin-auth.guard';
import {ThingDefaultsComponent} from './feature/admin/thing-defaults/thing-defaults.component';
import {RestingWallComponent} from './feature/secured/bios/resting-wall/resting-wall.component';
import {LegacyViewComponent} from './feature/secured/bios/view/legacy/legacy-view.component';
import {SecuredBioPrintComponent} from './feature/_layout/secured-bio-print/secured-bio-print.component';
import {PublicLegacyViewComponent} from './feature/public/bios/legacy/legacy-view.component';
import {PasswordJarsComponent} from './feature/secured/jars/password-jars/password-jars.component';
import {PasswordDefaultsComponent} from './feature/admin/password-defaults/password-defaults.component';
import {GuardiansComponent} from './feature/secured/bios/guardians/guardians.component';
import {WardsComponent} from './feature/secured/bios/wards/wards.component';
import {EmailChangeComponent} from './feature/public/email-change/email-change.component';
import {BioTypeComponent} from './feature/secured/bios/edit/bio-type.component';
import {MemoryJarDefaultsComponent} from './feature/admin/memory-jar-defaults/memory-jar-defaults.component';
import {ThingJarDefaultsComponent} from './feature/admin/thing-jar-defaults/thing-jar-defaults.component';
import {PasswordJarDefaultsComponent} from './feature/admin/password-jar-defaults/password-jar-defaults.component';
import {LoginCodeComponent} from './feature/public/login-code/login-code.component';

const routes: Routes = [

  // Admin Routes
  {
    path: 'admin',
    component: SecuredLayoutComponent,
    canActivate: [AdminAuthGuard],
    children: [
      {path: '', component: MemoryDefaultsComponent, pathMatch: 'full'},
      {path: 'memory-jar-defaults', component: MemoryJarDefaultsComponent},
      {path: 'memory-defaults', component: MemoryDefaultsComponent},
      {path: 'thing-jar-defaults', component: ThingJarDefaultsComponent},
      {path: 'thing-defaults', component: ThingDefaultsComponent},
      {path: 'password-jar-defaults', component: PasswordJarDefaultsComponent},
      {path: 'password-defaults', component: PasswordDefaultsComponent}
      // {path: 'memory-tags', component: MemoryDefaultsComponent}
    ]
  },

  // Secured Routes
  {
    path: '',
    component: SecuredLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {path: '', component: DashboardComponent},
      {path: 'legacy/:slug', component: LegacyViewComponent},

      {path: 'bios/life-wall', component: LifeWallComponent},
      {path: 'bios/remembrance-wall', component: RestingWallComponent},
      {path: 'bios/wards', component: WardsComponent},
      {path: 'bios/guardians', component: GuardiansComponent},
      {path: 'bios/edit/:id', component: BioTypeComponent},
      {path: 'bios/edit', component: BioTypeComponent},
      {path: 'bios/:slug', component: BioViewComponent},
      {path: 'bios', component: LifeWallComponent},

      {path: 'profiles/life-wall', component: LifeWallComponent},
      {path: 'profiles/remembrance-wall', component: RestingWallComponent},
      {path: 'profiles/wards', component: WardsComponent},
      {path: 'profiles/guardians', component: GuardiansComponent},
      {path: 'profiles/edit/:id', component: BioTypeComponent},
      {path: 'profiles/edit', component: BioTypeComponent},
      {path: 'profiles/:slug', component: BioViewComponent},
      {path: 'profiles', component: LifeWallComponent},

      {path: 'jars/passwords/:keywords', component: PasswordJarsComponent},
      {path: 'jars/passwords', component: PasswordJarsComponent},
      {path: 'jars/things/:keywords', component: ThingJarsComponent},
      {path: 'jars/things', component: ThingJarsComponent},
      {path: 'jars/memories/:keywords', component: MemoryJarsComponent},
      {path: 'jars/memories', component: MemoryJarsComponent},
      {path: 'jars', component: MemoryJarsComponent},

      {path: 'settings/:action/:invoiceNumber', component: SettingsComponent},
      {path: 'settings/:action', component: SettingsComponent},
      {path: 'settings', component: SettingsComponent},
      {path: 'features', component: FeaturesComponent},

      {path: 'support', component: SupportComponent},
    ]
  },


  // Public Routes
  {
    path: '',
    component: PublicLayoutComponent,
    children: [
      {path: 'login', component: LoginComponent},
      {path: 'login-code', component: LoginCodeComponent},
      {path: 'verify', component: LoginComponent},
      {path: 'verify/:hash', component: LoginComponent},
      {path: 'create-account', component: RegisterComponent},
      {path: 'password-reset', component: PasswordResetComponent},
      {path: 'password-change', redirectTo: 'password-change/'},
      {path: 'password-change/:hash', component: PasswordChangeComponent},
      {path: 'create-password/:hash', component: PasswordChangeComponent},
      {path: 'email-change/:hash', component: EmailChangeComponent},
      {path: 'register', redirectTo: 'create-account', pathMatch: 'full'},
      {path: 'public/legacy/:slug', component: PublicLegacyViewComponent},
    ]
  },

  // Print Routes
  {
    path: 'print/book/:slug',
    component: SecuredBioPrintComponent,
    canActivate: [AuthGuard]
  },

  // otherwise redirect to dashboard
  {path: '**', redirectTo: ''}
];

export const AppRoutingModule = RouterModule.forRoot(
  routes,
  {
    anchorScrolling: 'enabled',
    enableTracing: false, // <-- debugging purposes only
    relativeLinkResolution: 'legacy'
}
);
