import {Component, Input, OnInit} from '@angular/core';
@Component({
  selector: 'app-ico-candle',
  templateUrl: 'ico-candle.component.html',
  styleUrls: ['../ico.component.css', 'ico-candle.component.css']
})
export class IcoCandleComponent implements OnInit {
  constructor() {
  }
  public ngOnInit(): void {
  }
}
