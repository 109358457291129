import {Component, Input} from '@angular/core';
import {BioUser} from '../../../../core/models/bio-user.model';
import {AbstractListDirective} from '../abstract-list.directive';
import {faExclamationCircle, faUserLock, faUserShield} from '@fortawesome/free-solid-svg-icons';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../store';
import {BioUserActions} from '../../../../store/bio-users';

@Component({
  selector: 'app-guardian-list',
  templateUrl: 'guardian-list.component.html'
})
export class GuardianListComponent extends AbstractListDirective {
  @Input() public list: BioUser[] = [];
  public faExclamationCircle = faExclamationCircle;
  public faUserLock = faUserLock;
  public faUserShield = faUserShield;

  constructor(
    protected store: Store<AppState>
  ) {
    super();
  }

  public onResend(event, item: any): void {
    event.stopPropagation();
    this.store.dispatch(
      BioUserActions.resendBioUserInviteRequest({bioUser: item})
    );
  }

}
