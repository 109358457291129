import {BioUser} from '../../core/models/bio-user.model';

export class BioUsersState {
  currentBioUserId: number;
  byId: BioUsersById;
}

export const initialBioUserState: BioUsersState = {
  currentBioUserId: 0,
  byId: {},
};

export class BioUsersById {
  [id: number]: BioUser;
}
