<div class="secured-banner">
  <div class="slide">

      <div class="col-sm-12 col-md-9 col-lg-7">
          <h1 class="pb-3">My LifeJars for Building Life Profiles &amp; Curating Awesome Legacies</h1>
          <p>Connect with your tribe, write unique life stories...</p>
          <p>Experience easy & secure access to encrypted personal data, manage physical & digital footprints.</p>
      </div>


  </div>
</div><!-- /secured-banner -->


