
<div class="bg bg-cover"></div>

<div class="card card-bio card-bio-login">

  <div class="card-body">

    <div *ngIf="router.url.indexOf('/password-change') >=0" class="card-body-title text-center">
      <h2 class="text-gold font-weight-bold pb-3 mb-3">Change password</h2>
      <p>You can now change your password</p>
    </div>

    <div *ngIf="router.url.indexOf('/create-password') >=0" class="card-body-title text-center">
      <h2 class="text-uppercase text-gold font-weight-bold">Create password</h2>
      <p>You can now create your password</p>
    </div>

    <div *ngIf="hashError">
      <div class="form-group small">
        <div class="row">
          <div class="col-12 text-center">
            <a routerLink="/password-reset">Click here to restart password change process &raquo;</a>
          </div>
        </div>
      </div>
    </div>

    <form-validation [control]="passwordChangeForm" [submitted]="submitted"></form-validation>

    <form [formGroup]="passwordChangeForm" (ngSubmit)="onSubmit()">

      <div class="form-group">
        <label class="control-label sr-only">Password</label>
        <input type="password" formControlName="password" class="form-control form-control-lg input-bio"
               [ngClass]="{ 'is-invalid': submitted && f.password.errors }" placeholder="New Password" />
        <app-control-validation [control]="f.password" [submitted]="submitted"></app-control-validation>
      </div>

      <password-strength-meter [password]="f.password.value"></password-strength-meter>

      <div class="form-group">
        <label class="control-label sr-only">Confirm Password</label>
        <input type="password" formControlName="passwordConfirm" class="form-control form-control-lg input-bio"
               [ngClass]="{ 'is-invalid': submitted && f.passwordConfirm.errors }" placeholder="Confirm New Password" />
        <app-control-validation [control]="f.passwordConfirm" [submitted]="submitted"></app-control-validation>
      </div>

      <div class="form-group">
        <button [disabled]="loading" class="btn btn-lg btn-block btn-gold">
          Save Password
        </button>
      </div>

      <div class="form-group small">
        <div class="row">
          <div class="col-12 text-center">
            <a class="text-gold " routerLink="/login" >&laquo; Back to Sign in</a>
          </div>
        </div>
      </div>

    </form>

    <app-content-loader></app-content-loader>
  </div>
</div>
