import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, concatMap, map, mergeMap,  tap} from 'rxjs/operators';
import {CommentActions} from './index';
import {concat, of} from 'rxjs';
import {ResponseMessage} from '../../core/models/response-message.model';
import {AlertService} from '../../shared/components/alert/alert.service';
import {FileUploaderService} from '../../shared/components/file-uploader/file-uploader.service';
import {CommentService} from '../../core/services/comment.service';
import {CommentModel} from '../../core/models/comment.model';

@Injectable()
export class CommentsEffects {

  constructor(
    private actions$: Actions,
    private commentService: CommentService,
    private fileUploader: FileUploaderService,
    private alertService: AlertService
  ) {
  }

  searchCommentRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommentActions.searchCommentsRequest),
      concatMap(action => concat(
        of(CommentActions.resetSearchCommentsRequest()),

        this.commentService.getBioComments(action.bio.slug).pipe(
          map((comments: CommentModel[]) =>
            CommentActions.searchCommentsRequestSuccess({
              comments,
              setFirstAsCurrent: action.setFirstAsCurrent
            })
          ),
          catchError(error => {
            return of(CommentActions.effectError({error}));
          })
        )
        )
      )
    )
  );

  searchMemoryCommentRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommentActions.searchMemoryCommentsRequest),
      concatMap(action => concat(
        of(CommentActions.resetSearchCommentsRequest()),

        this.commentService.getMemoryComments(action.bioNode.id).pipe(
          map((comments: CommentModel[]) =>
            CommentActions.searchCommentsRequestSuccess({
              comments,
              setFirstAsCurrent: action.setFirstAsCurrent
            })
          ),
          catchError(error => {
            return of(CommentActions.effectError({error}));
          })
        )
        )
      )
    )
  );

  saveCommentRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommentActions.saveCommentRequest),
      mergeMap(action =>
        this.commentService.saveComment(action.comment).pipe(
          tap((responseMessage: ResponseMessage) => {
            this.alertService.info(responseMessage.message);
          }),

          concatMap((responseMessage: ResponseMessage) => [
            CommentActions.saveCommentRequestSuccess({
              responseMessage
            }),
          ]),

          catchError(error => {
            return of(CommentActions.effectError({error}));
          })
        )
      )
    )
  );

  deleteCommentRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommentActions.deleteCommentRequest),
      mergeMap(action =>
        this.commentService.deleteComment(action.comment).pipe(
          tap((responseMessage: ResponseMessage) => {
            this.alertService.warning(responseMessage.message);
          }),

          concatMap((responseMessage: ResponseMessage) => [
            CommentActions.deleteCommentRequestSuccess({comment: action.comment, responseMessage})
          ]),

          catchError(error => {
            return of(CommentActions.effectError({error}));
          })
        )
      )
    )
  );

}



