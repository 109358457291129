import {BrowserModule} from '@angular/platform-browser';
import {environment} from '../environments/environment';
import {APP_ID, APP_INITIALIZER, Inject, NgModule, PLATFORM_ID} from '@angular/core';
import {HttpClientModule, HTTP_INTERCEPTORS, HttpClientXsrfModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbDateParserFormatter, NgbModalConfig, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AutosizeModule} from 'ngx-autosize';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {TokenInterceptor} from './core/interceptors/token.interceptor';
import {ErrorInterceptor} from './core/interceptors/error.interceptor';
import {AppDateFormatter} from './core/format/date.format';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AlertComponent} from './shared/components/alert/alert.component';
import {BreadcrumbComponent} from './shared/components/breadcrumb/breadcrumb.component';
import {ControlValidationComponent, FormValidationComponent} from './shared/components/control-validation/control-validation.component';
import {LoginComponent} from './feature/public/login/login.component';
import {LoginCodeComponent} from './feature/public/login-code/login-code.component';
import {RegisterComponent} from './feature/public/register/register.component';
import {PasswordResetComponent} from './feature/public/password-reset/password-reset.component';
import {PasswordChangeComponent} from './feature/public/password-change/password-change.component';
import {DashboardComponent} from './feature/secured/dashboard/dashboard.component';
import {BioEditPersonComponent} from './feature/secured/bios/edit/edit-person/bio-edit-person.component';
import {MemoryJarsComponent} from './feature/secured/jars/memory-jars/memory-jars.component';
import {WishesComponent} from './feature/secured/wishes/wishes.component';
import {PublicLayoutComponent} from './feature/_layout/public-layout/public-layout.component';
import {SecuredLayoutComponent} from './feature/_layout/secured-layout/secured-layout.component';
import {SecuredSidebarComponent} from './feature/_layout/secured-sidebar/secured-sidebar.component';
import {SecuredHeaderComponent} from './feature/_layout/secured-header/secured-header.component';
import {SecuredBannerComponent} from './feature/_layout/secured-banner/secured-banner.component';
import {JarEditModalComponent} from './shared/modals/memory-jar/edit/jar-edit-modal.component';
import {MemoryEditModalComponent} from './shared/modals/memory/edit/memory-edit-modal.component';
import {LocationPickerComponent} from './shared/modals/pickers/location/location-picker.component';
import {BioModalConfigProvider} from './core/providers/bio-modal-config.provider';
import {PaginatorComponent} from './shared/components/paginator/paginator.component';
import {ThingJarsComponent} from './feature/secured/jars/thing-jars/thing-jars.component';
import {ThingEditModalComponent} from './shared/modals/thing/edit/thing-edit-modal.component';
import {LifeWallComponent} from './feature/secured/bios/life-wall/life-wall.component';
import {BioSearchModalComponent} from './shared/modals/bio-search/bio-search-modal.component';
import {BioViewComponent} from './feature/secured/bios/view/bio/bio-view.component';
import {ProfilePickerComponent} from './shared/modals/pickers/profile-picker/profile-picker.component';
import {SortablejsModule} from 'ngx-sortablejs';
import {WisdomComponent} from './feature/secured/wisdom/wisdom.component';
import {SettingsComponent} from './feature/secured/account-settings/settings.component';
import {FeaturesComponent} from './feature/secured/account-features/features.component';
import {SupportComponent} from './feature/secured/support/support.component';
import {CommonAddressComponent} from './shared/forms/common-address/common-address.component';
import {AgmCoreModule, LAZY_MAPS_API_CONFIG} from '@agm/core';
import {TribesterPickerComponent} from './shared/modals/pickers/tribster/tribester-picker.component';
import {AlertPopupComponent} from './shared/modals/alert-popup/alert-popup.component';
import {ConfirmPopupComponent} from './shared/modals/confirm-popup/confirm-popup.component';
import {ConfirmSubscriptionPopupComponent} from './shared/modals/subscription/confirm-popup/confirm-subscription-popup.component';
import {MemoryViewModalComponent} from './shared/modals/memory/view/memory-view-modal.component';
import {LightboxModalComponent} from './shared/modals/lightbox/lightbox-modal.component';
import {FriendInviteModalComponent} from './shared/modals/friend-invite/friend-invite-modal.component';
import {TribeActivityListComponent} from './shared/components/tribe-activity-list/tribe-activity-list.component';
import {PrivacyPolicyModalComponent} from './shared/modals/privacy-policy/privacy-policy-modal.component';
import {TermsServiceModalComponent} from './shared/modals/terms-service/terms-service-modal.component';
import {BioUserRolesModalComponent} from './shared/modals/bio-user-roles/bio-user-roles-modal.component';
import {GuardianRequestListComponent} from './shared/components/guardian-request-list/guardian-request-list.component';
import {GuardianAuthorityModalComponent} from './shared/modals/guardian-authority/guardian-authority-modal.component';
import {WardFormModalComponent} from './shared/modals/ward-form/ward-form-modal.component';
import {MemoryDefaultsComponent} from './feature/admin/memory-defaults/memory-defaults.component';
import {MemoryDefaultModalComponent} from './shared/modals/admin/memory-default/memory-default-modal.component';
import {ExpandableTextComponent} from './shared/components/expandable-text/expandable-text.component';
import {ThingDefaultsComponent} from './feature/admin/thing-defaults/thing-defaults.component';
import {ThingDefaultModalComponent} from './shared/modals/admin/thing-default/thing-default-modal.component';
import {TribesterListComponent} from './shared/components/bio-list/tribster-list/tribester-list.component';
import {BioListComponent} from './shared/components/bio-list/bio-list/bio-list.component';
import {LegacyTransitionModalComponent} from './shared/modals/legacy-transition/legacy-transition-modal.component';
import {IconsModule} from './shared/icons/icons.module';
import {GuardianListComponent} from './shared/components/bio-list/guardian-list/guardian-list.component';
import {UserRoleTextComponent} from './shared/components/user-role-text/user-role-text.component';
import {RestingWallComponent} from './feature/secured/bios/resting-wall/resting-wall.component';
import {LegacyBioListComponent} from './shared/components/bio-list/legacy-bio-list/legacy-bio-list.component';
import {LegacyViewComponent} from './feature/secured/bios/view/legacy/legacy-view.component';
import {SecuredBioPrintModule} from './feature/_layout/secured-bio-print/secured-bio-print.module';
import {FileUploaderModule} from './shared/components/file-uploader/file-uploader.module';
import {AppPipesModule} from './shared/pipes/app-pipes.module';
import {ContentLoaderModule} from './shared/components/content-loader/content-loader.module';
import {TutorialModalComponent} from './shared/modals/tutorial-popup/tutorial-modal.component';

import {NgxStripeModule, STRIPE_PUBLISHABLE_KEY} from 'ngx-stripe';
import {PlanUpgradeModalComponent} from './shared/modals/plan-upgrade/plan-upgrade-modal.component';
import {LoaderInterceptor} from './core/interceptors/loader.interceptor';
import {UserPlanStatsBoxComponent} from './shared/components/user-plan-stats-box/user-plan-stats-box.component';
import {DatePipe, isPlatformBrowser} from '@angular/common';
import {PublicLegacyViewComponent} from './feature/public/bios/legacy/legacy-view.component';
import {StoreModule} from '@ngrx/store';
import {metaReducers, reducers} from './store';
import {EffectsModule} from '@ngrx/effects';
import {BiosEffects} from './store/bios/bios.effects';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {UserBiosEffects} from './store/user-bios/user-bios.effects';
import {TribesEffects} from './store/tribe/tribe.effects';
import {OptionsEffects} from './store/options/options.effects';
import {BioUsersEffects} from './store/bio-users/bio-users.effects';
import {JarsEffects} from './store/jars/jars.effects';
import {UsersEffects} from './store/users/users.effects';
import {MemoriesEffects} from './store/memories/memories.effects';
import {BioLegacyViewComponent} from './shared/components/bio-legacy/bio-legacy-view.component';
import {PasswordJarsComponent} from './feature/secured/jars/password-jars/password-jars.component';
import {PasswordDefaultsComponent} from './feature/admin/password-defaults/password-defaults.component';
import {PasswordViewModalComponent} from './shared/modals/password/view/password-view-modal.component';
import {PasswordDefaultModalComponent} from './shared/modals/admin/password-default/password-default-modal.component';
import {PasswordEditModalComponent} from './shared/modals/password/edit/password-edit-modal.component';
import {PasswordsEffects} from './store/passwords/passwords.effects';
import {TagDefaultsComponent} from './feature/admin/tag-defaults/tag-defaults.component';
import {BioShareLinkComponent} from './shared/components/bio-share-link/bio-share-link.component';
import {CopyClipboardDirective} from './shared/directives/copy-clipboard.directive';
import {PlanChangeWarningModalComponent} from './shared/modals/plan-change-warning/plan-change-warning-modal.component';
import {CommentListModelComponent} from './shared/modals/comment-list/comment-list-modal.component';
import {CommentsEffects} from './store/comments/comments.effects';
import {PasswordStrengthMeterModule} from 'angular-password-strength-meter';
import {GuardiansComponent} from './feature/secured/bios/guardians/guardians.component';
import {WardsComponent} from './feature/secured/bios/wards/wards.component';
import {WardListComponent} from './shared/components/bio-list/ward-list/ward-list.component';
import {FileEditModalComponent} from './shared/modals/file-edit/file-edit-modal.component';
import {DashboardCardComponent} from './feature/secured/dashboard/dashboard-card/dashboard-card.component';
import {DocumentationModalComponent} from './shared/modals/documentation-popup/documentation-modal.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {TribeRelationshipModalComponent} from './shared/modals/tribe-relationship/tribe-relationship-modal.component';
import {CharacterCountdownComponent} from './shared/components/character-countdown/character-countdown.component';
import {EmailChangeModalComponent} from './shared/modals/email-change/email-change-modal.component';
import {EmailChangeComponent} from './feature/public/email-change/email-change.component';
import {DefaultMemoriesEffects} from './store/memories/default-memories.effects';
import {BioTypeDirective} from './shared/directives/bio-type.directive';
import {BioEditPetComponent} from './feature/secured/bios/edit/edit-pet/bio-edit-pet.component';
import {BioTypeComponent} from './feature/secured/bios/edit/bio-type.component';
import {AppConfig} from './core/services/app-config.service';
import {JarPickerComponent} from './shared/modals/pickers/jar-picker/jar-picker.component';
import {DeleteAccountModalComponent} from './shared/modals/delete-account/delete-account-modal.component';
import {TribeRelationshipComponent} from './shared/components/tribe-relationship/tribe-relationship.component';
import {MemoryJarDefaultsComponent} from './feature/admin/memory-jar-defaults/memory-jar-defaults.component';
import {JarDefaultModalComponent} from './shared/modals/admin/jar-default/jar-default-modal.component';
import {ThingJarDefaultsComponent} from './feature/admin/thing-jar-defaults/thing-jar-defaults.component';
import {PasswordJarDefaultsComponent} from './feature/admin/password-jar-defaults/password-jar-defaults.component';
import {LOADER_ROUTES} from './shared/components/content-loader/loader.service';
import {loaderRoutes} from './shared/components/content-loader/loader.routes';
import {PageHeaderComponent} from './shared/components/page-header/page-header.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {SitewideSearchComponent} from './shared/components/sitewide-search/sitewide-search.component';
import {JunkFolderAlertComponent} from './shared/components/junk-folder-alert/junk-folder-alert.component';


export function initializeApp(appConfig: AppConfig) {
  return () => appConfig.load();
}

export function initializeMaps() {
  return {
    apiKey: AppConfig.settings.mapsApiKey,
    libraries: ['places']
  };
}

export function initializeStripe() {
  return AppConfig.settings.stripePublicKey;
}

// export function initializeGtm() {
//   return AppConfig.settings.tagManagerId;
// }

@NgModule({
  declarations: [
    AppComponent,
    AlertComponent,
    BreadcrumbComponent,
    PageHeaderComponent,
    JunkFolderAlertComponent,
    PaginatorComponent,
    ExpandableTextComponent,
    CharacterCountdownComponent,
    TribeRelationshipComponent,
    UserRoleTextComponent,
    UserPlanStatsBoxComponent,
    SitewideSearchComponent,

    ControlValidationComponent,
    FormValidationComponent,
    TribeActivityListComponent,
    GuardianRequestListComponent,
    BioShareLinkComponent,
    BioListComponent,
    WardListComponent,
    LegacyBioListComponent,
    TribesterListComponent,
    GuardianListComponent,
    BioLegacyViewComponent,

    CopyClipboardDirective,
    BioTypeDirective,

    PublicLayoutComponent,
    SecuredLayoutComponent,
    SecuredHeaderComponent,
    SecuredSidebarComponent,
    SecuredBannerComponent,

    LoginComponent,
    LoginCodeComponent,
    RegisterComponent,
    PasswordResetComponent,
    PasswordChangeComponent,
    EmailChangeComponent,

    PublicLegacyViewComponent,

    DashboardComponent,
    LifeWallComponent,
    RestingWallComponent,
    BioTypeComponent,
    BioEditPersonComponent,
    BioEditPetComponent,
    BioViewComponent,
    LegacyViewComponent,
    GuardiansComponent,
    WardsComponent,
    MemoryJarsComponent,
    ThingJarsComponent,
    PasswordJarsComponent,
    WishesComponent,
    WisdomComponent,
    SupportComponent,
    CommonAddressComponent,
    SettingsComponent,
    FeaturesComponent,

    JarEditModalComponent,
    MemoryEditModalComponent,
    MemoryViewModalComponent,
    PasswordEditModalComponent,
    PasswordViewModalComponent,

    LightboxModalComponent,
    TutorialModalComponent,
    DocumentationModalComponent,
    ThingEditModalComponent,
    FileEditModalComponent,
    BioSearchModalComponent,
    FriendInviteModalComponent,
    TribeRelationshipModalComponent,
    TribesterPickerComponent,
    LocationPickerComponent,
    ProfilePickerComponent,
    JarPickerComponent,
    EmailChangeModalComponent,
    DeleteAccountModalComponent,
    AlertPopupComponent,
    ConfirmPopupComponent,
    PrivacyPolicyModalComponent,
    TermsServiceModalComponent,
    PlanUpgradeModalComponent,
    ConfirmSubscriptionPopupComponent,
    BioUserRolesModalComponent,
    GuardianAuthorityModalComponent,
    WardFormModalComponent,
    LegacyTransitionModalComponent,
    PlanChangeWarningModalComponent,
    CommentListModelComponent,
    DashboardCardComponent,

    // Admin Components
    MemoryJarDefaultsComponent,
    MemoryDefaultsComponent,
    ThingJarDefaultsComponent,
    ThingDefaultsComponent,
    PasswordJarDefaultsComponent,
    PasswordDefaultsComponent,
    TagDefaultsComponent,
    MemoryDefaultModalComponent,
    JarDefaultModalComponent,
    ThingDefaultModalComponent,
    PasswordDefaultModalComponent
  ],
  exports: [
    CopyClipboardDirective,
    BioTypeDirective
  ],
    imports: [
        AppRoutingModule,
        HttpClientXsrfModule.withOptions({
            cookieName: 'XSRF-TOKEN',
            headerName: 'X-XSRF-TOKEN'
        }),
        BrowserModule.withServerTransition({
            appId: 'ng-cli-universal'
        }),
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        AutosizeModule,
        FontAwesomeModule,
        SortablejsModule,
        PasswordStrengthMeterModule,
        NgSelectModule,
        IconsModule,
        ContentLoaderModule,
        FileUploaderModule,
        SecuredBioPrintModule,
        AppPipesModule,

        AgmCoreModule.forRoot(),
        NgxStripeModule.forRoot('NULL'),

        StoreModule.forRoot(reducers, {
            metaReducers,
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: true
            }
        }),

        EffectsModule.forRoot([
            OptionsEffects,
            UsersEffects,
            UserBiosEffects,
            BiosEffects,
            BioUsersEffects,
            JarsEffects,
            TribesEffects,
            MemoriesEffects,
            DefaultMemoriesEffects,
            PasswordsEffects,
            CommentsEffects
        ]),

        // Instrumentation must be imported after importing StoreModule (config is optional)
        StoreDevtoolsModule.instrument({
            maxAge: 25, // Retains last 25 states
            logOnly: environment.production // Restrict extension to log-only mode
        }),
        DragDropModule
    ],
  providers: [
    AppConfig,
    {provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AppConfig], multi: true},
    {provide: LAZY_MAPS_API_CONFIG, useFactory: initializeMaps, deps: [AppConfig]},
    {provide: STRIPE_PUBLISHABLE_KEY, useFactory: initializeStripe, deps: [AppConfig]},
    // {provide: 'googleTagManagerId', useFactory: initializeGtm, deps: [AppConfig]},
    { provide: LOADER_ROUTES, useValue: loaderRoutes },

    // { provide: APP_BASE_HREF, useValue: window['base-href'] },
    // { provide: HTTP_INTERCEPTORS, useClass: DateInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true},
    {provide: NgbDateParserFormatter, useClass: AppDateFormatter},
    {provide: NgbModalConfig, useClass: BioModalConfigProvider},
  ],
  entryComponents: [
    JarEditModalComponent,
    MemoryEditModalComponent,
    MemoryViewModalComponent,
    PasswordEditModalComponent,
    PasswordViewModalComponent,
    LightboxModalComponent,
    TutorialModalComponent,
    ThingEditModalComponent,
    BioSearchModalComponent,
    FriendInviteModalComponent,
    EmailChangeModalComponent,
    DeleteAccountModalComponent,
    TribeRelationshipModalComponent,
    PrivacyPolicyModalComponent,
    TermsServiceModalComponent,
    PlanUpgradeModalComponent,
    ProfilePickerComponent,
    JarPickerComponent,
    TribesterPickerComponent,
    LocationPickerComponent,
    AlertPopupComponent,
    ConfirmPopupComponent,
    ConfirmSubscriptionPopupComponent,
    BioUserRolesModalComponent,
    GuardianAuthorityModalComponent,
    WardFormModalComponent,
    LegacyTransitionModalComponent,
    PlanChangeWarningModalComponent,
    CommentListModelComponent,

    // Admin
    JarDefaultModalComponent,
    MemoryDefaultModalComponent,
    ThingDefaultModalComponent,
    PasswordDefaultModalComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(APP_ID) private appId: string
  ) {
    const platform = isPlatformBrowser(platformId)
      ? 'in the browser'
      : 'on the server';
    console.log(`Running ${platform} with appId=${appId}`);
  }
}
