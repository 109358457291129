import {AfterViewInit, Component, ElementRef, HostBinding, Input, OnInit, ViewChild} from '@angular/core';
import {Bio} from '../../../core/models/bio.model';
import {JarModel} from '../../../core/models/jar.model';
import {IMemory, MemoryModel} from '../../../core/models/memory.model';
import {FileModel} from '../../../core/models/file.model';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FileUploaderService} from '../../components/file-uploader/file-uploader.service';
import {FileParams} from '../../components/file-uploader/file-lazy-loader/file-lazy-loader.component';

@Component({
  templateUrl: 'lightbox-modal.component.html'
})
export class LightboxModalComponent implements OnInit, AfterViewInit {
  @ViewChild('lightbox') lightboxElement: ElementRef;
  @HostBinding('style.width') width: number;
  @HostBinding('style.height') height: number;

  @Input() public bio: Bio;
  @Input() public memory: IMemory;
  @Input() public file: FileModel;
  @Input() public fileList: FileModel[];
  public clientWidth: number;

  private index: number;

  constructor(
    public activeModal: NgbActiveModal,
    protected modalService: NgbModal,
    protected fileUploader: FileUploaderService
  ) {
    this.bio = null;
    this.memory = null;
    this.file = null;
    this.fileList = [];
    this.index = 0;
  }

  public ngOnInit(): void {
  }

  public ngAfterViewInit(): void {

    // Find the Active Index
    this.index = 0;
    if (this.fileList && this.fileList.length) {
      for (let i = 0, l = this.fileList.length; i < l; i++) {
        if (this.fileList[i].src === this.file.src) {
          this.index = i;
          break;
        }
      }
    }

  }

  public onLoadComplete(params: FileParams) {

    // hack: there is a bug with the resize
    // starting before the container has finished loading/sizing.
    setTimeout(() => {

      this.clientWidth = this.lightboxElement.nativeElement.getBoundingClientRect().width;

      if (this.fileUploader.isImage(this.file) || this.fileUploader.isVideo(this.file)) {
        const ratio = params.height / params.width;
        this.height = this.clientWidth * ratio;

        // Audio has no height
      } else if (this.fileUploader.isAudio(this.file)) {
        this.height = 320;

        // Documents have no height
      } else {
        this.height = 600;
      }

    }, 250);
  }

  public next() {
    if (++this.index >= this.fileList.length) {
      this.index = 0;
    }
    this.file = this.fileList[this.index];
  }

  public previous() {
    if (--this.index < 0) {
      this.index = this.fileList.length - 1;
    }
    this.file = this.fileList[this.index];
  }

}
