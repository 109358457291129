
<div class="bg bg-cover"></div>

<div class="card card-bio card-bio-login">

  <div class="card-body">

    <div class="card-body-title text-center">
      <h2 class="text-gold font-weight-bold pb-3 mb-3">Create a My LifeJars Account</h2>
      <p>It's Free forever!</p>
    </div>

    <form-validation [control]="registerForm" [submitted]="submitted"></form-validation>

    <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">

      <div class="form-group">
        <label class="control-label sr-only">First Name</label>
        <input type="text" formControlName="firstName" class="form-control form-control-lg input-bio"
               [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" placeholder="First name" />
        <app-control-validation [control]="f.firstName" [submitted]="submitted"></app-control-validation>
      </div>

      <div class="form-group">
        <label class="control-label sr-only">Last Name</label>
        <input type="text" formControlName="lastName" class="form-control form-control-lg input-bio"
               [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" placeholder="Last Name" />
        <app-control-validation [control]="f.lastName" [submitted]="submitted"></app-control-validation>
      </div>

      <div class="form-group">
        <label class="control-label sr-only">Email</label>
        <input type="email" formControlName="email" class="form-control form-control-lg input-bio"
               [ngClass]="{ 'is-invalid': submitted && f.email.errors }" placeholder="Email" />
        <app-control-validation [control]="f.email" [submitted]="submitted"></app-control-validation>
      </div>

      <div class="form-group">
        <label class="control-label sr-only">Password</label>
        <input type="password" formControlName="password" class="form-control form-control-lg input-bio"
               [ngClass]="{ 'is-invalid': submitted && f.password.errors }" placeholder="New Password" />
        <app-control-validation [control]="f.password" [submitted]="submitted"></app-control-validation>
      </div>

      <password-strength-meter [password]="f.password.value"></password-strength-meter>

      <div class="form-group">
        <label class="control-label sr-only">Confirm Password</label>
        <input type="password" formControlName="passwordConfirm" class="form-control form-control-lg input-bio"
               [ngClass]="{ 'is-invalid': submitted && f.passwordConfirm.errors }" placeholder="Confirm New Password" />
        <app-control-validation [control]="f.passwordConfirm" [submitted]="submitted"></app-control-validation>
      </div>

      <div class="form-group">
        <button class="btn btn-lg btn-block btn-gold">
          Create Account Now
        </button>
      </div>

      <app-junk-folder-alert [showAlert]="showJunkMailAlert"></app-junk-folder-alert>

      <div class="form-group small x-small">
        <div class="row">
          <div class="col-12">
            By submitting this form, you agree to our
            <a href="{{showTerms()}}" target="_blank"><strong>Terms</strong></a> and that you have read
            our <a href="{{showPrivacy()}}" target="_blank"><strong>Privacy Policy</strong></a>.
          </div>
        </div>
      </div>

      <div class="form-group small">
        <div class="row">
          <div class="col-12 text-center">
            Already have a My LifeJars account? <a class="text-gold font-weight-bold" routerLink="/login">Sign in here &raquo;</a>
          </div>
        </div>
      </div>

    </form>

    <app-content-loader></app-content-loader>
  </div>
</div>
