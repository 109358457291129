import {Component} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  templateUrl: 'terms-service-modal.component.html'
})
export class TermsServiceModalComponent {
  constructor(
    public activeModal: NgbActiveModal,
    protected modalService: NgbModal
  ) {}
}
