import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, concatMap,  map, mergeMap, tap} from 'rxjs/operators';
import {UserActions} from './index';
import {EMPTY, of, } from 'rxjs';
import {UserService} from '../../core/services/user.service';
import {User} from '../../core/models/user.model';
import {ResponseMessage} from '../../core/models/response-message.model';
import {UserSubscription} from '../../core/models/user-subscription.model';
import {BioActions} from '../bios';
import {ConfirmSubscriptionPopupComponent} from '../../shared/modals/subscription/confirm-popup/confirm-subscription-popup.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AlertService} from '../../shared/components/alert/alert.service';
import {OptionActions} from '../options';
import {UserBioActions} from '../user-bios';
import {SubscriptionsService} from '../../core/services/subscriptions.service';
import {AuthService} from '../../core/services/auth.service';
import {BioDeleteResponse} from '../../core/models/bio-delete-response.model';
import {Router} from '@angular/router';
import {LoaderService} from '../../shared/components/content-loader/loader.service';

@Injectable()
export class UsersEffects {

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private userService: UserService,
    private subscriptionService: SubscriptionsService,
    private loaderService: LoaderService,
    private alertService: AlertService,
    private modalService: NgbModal,
    private router: Router,
  ) {
  }

  setCurrentUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.setCurrentUser),
      concatMap(action => {
        if (action.user == null) {
          return EMPTY;
        }
        return [
          // Side-effect: Load Options
          OptionActions.getOptions(),

          // Side-effect: Load User Current Bio (also loads Users other Bios)
          BioActions.switchBioRequest({slugOrId: null}),

          // Side-effect: Update User Subscription
          UserActions.updateUserSubscriptionRequest({id: null /*action.user.id*/}),
        ];
      })
    )
  );

  getUserRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getUserRequest),
      mergeMap(action =>
        this.userService.getUser(action.id).pipe(
          concatMap((user: User) => {
              if (user == null) {
                return EMPTY;
              }
              return [
                UserActions.getUserRequestSuccess({user}),

                // Side-effect: Load User Bios (aka wards)
                UserBioActions.getUserBiosRequest({id: user.id})
              ];
            }
          ),
          catchError(error => {
            return of(UserActions.effectError({error}));
          })
        )
      )
    )
  );

  saveUserRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.saveUserRequest),
      mergeMap(action =>
        this.userService.saveUser(action.user).pipe(
          tap((responseMessage: ResponseMessage) => {
            this.alertService.info(responseMessage.message);
          }),

          map((responseMessage: ResponseMessage) =>
            UserActions.saveUserRequestSuccess({
              responseMessage
            })
          ),
          catchError(error => {
            return of(UserActions.effectError({error}));
          })
        )
      )
    )
  );


  changePasswordRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.changePasswordRequest),
      mergeMap(action =>
        this.userService.passwordChange(action.oldPassword, action.password, action.passwordConfirm).pipe(
          tap((responseMessage: ResponseMessage) => {
            this.alertService.info(responseMessage.message);
          }),

          map((responseMessage: ResponseMessage) =>
            UserActions.changePasswordRequestSuccess({
              responseMessage
            })
          ),
          catchError(error => {
            return of(UserActions.effectError({error}));
          })
        )
      )
    )
  );

  changeEmailRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.changeEmailRequest),
      mergeMap(action =>
        this.userService.emailChange(action.email, action.emailConfirm).pipe(
          tap((responseMessage: ResponseMessage) => {
            this.alertService.info(responseMessage.message);
          }),

          map((responseMessage: ResponseMessage) =>
            UserActions.changeEmailRequestSuccess({
              responseMessage
            })
          ),

          catchError(error => {
            return of(UserActions.effectError({error}));
          })
        )
      )
    )
  );

  updateLoginSettingsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.updateLoginSettingsRequest),
      mergeMap(action =>
        this.userService.updateLoginSettings(action.enable2Fa, action.rememberMe).pipe(
          tap((responseMessage: ResponseMessage) => {
            this.alertService.info(responseMessage.message);
          }),

          map((responseMessage: ResponseMessage) =>
            UserActions.updateLoginSettingsSuccess({
              responseMessage
            })
          ),
          catchError(error => {
            return of(UserActions.effectError({error}));
          })
        )
      )
    )
  );


  saveUserMetaDataRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.saveUserMetaDataRequest),
      mergeMap(action =>
        this.userService.saveUserMetaData(action.metaData).pipe(
          map((responseMessage: ResponseMessage) =>
            UserActions.saveUserMetaDataRequestSuccess({
              responseMessage
            })
          ),
          catchError(error => {
            return of(UserActions.effectError({error}));
          })
        )
      )
    )
  );

  resetTutorialsDataRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.resetTutorialsRequest),
      mergeMap(action =>
        this.userService.saveUserMetaData(action.metaData).pipe(
          tap((responseMessage: ResponseMessage) => {
            this.alertService.info('Settings update complete.');
          }),

          map((responseMessage: ResponseMessage) =>
            UserActions.saveUserMetaDataRequestSuccess({
              responseMessage
            })
          ),
          catchError(error => {
            return of(UserActions.effectError({error}));
          })
        )
      )
    )
  );

  getUserSubscriptionRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getUserSubscriptionRequest),
      mergeMap(action =>
        this.subscriptionService.getSubscription(action.id).pipe(
          map((userSubscription: UserSubscription) =>
            UserActions.getUserSubscriptionRequestSuccess({
              userSubscription
            })
          ),
          catchError(error => {
            return of(UserActions.effectError({error}));
          })
        )
      )
    )
  );

  updateUserSubscriptionRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.updateUserSubscriptionRequest),
      mergeMap(action =>
        this.subscriptionService.updateSubscriptionStatus(action.id).pipe(
          tap((userSubscription: UserSubscription) => {
            if (action.alertChange) {
              const title = (userSubscription.title + '').replace(/(<([^>]+)>)/gi, '');
              this.confirmSubscription('Thank you. You have ' +
                'successfully subscribed to the [' + title + '] subscription.');
            }
            if (action.alertCancelled) {
              this.renewalCancelled();
            }
          }),

          concatMap((userSubscription: UserSubscription) => [
            UserActions.updateUserSubscriptionRequestSuccess({
              userSubscription
            }),
            UserActions.getUserSubscriptionRequestSuccess({
              userSubscription
            })
          ]),
          catchError(error => {
            return of(UserActions.effectError({error}));
          })
        )
      )
    )
  );

  getOwnedBiosRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getOwnedBiosRequest),
      mergeMap(action =>
        this.userService.getOwnedBios().pipe(
          map((responseMessage: ResponseMessage) =>
            UserActions.getOwnedBiosSuccess({
              bios: responseMessage.data
            })
          ),
          catchError(error => {
            return of(UserActions.effectError({error}));
          })
        )
      )
    )
  );


  deleteUserRequest$ = createEffect(() => {
    // This is a batched process and the endpoint must be called repeatedly until complete.
    return this.actions$.pipe(
      ofType(UserActions.deleteUserRequest),
      mergeMap(action =>
        this.userService.deleteUser().pipe(
          concatMap((responseMessage) => {
            const deleteResponse = responseMessage.data as BioDeleteResponse;
            if (deleteResponse.error === true) {
              this.alertService.error(deleteResponse.message);
              return of(UserActions.effectError({
                error: deleteResponse.message
              }));
            } else if (deleteResponse.complete === true) {
              this.alertService.warning(deleteResponse.message);
              return [
                UserActions.logout()
              ];
            }
            return of(UserActions.deleteUserRequest());
          }),
          catchError(error => {
            return of(UserActions.effectError({error}));
          })
        )
      )
    );
  });

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.logout),
      mergeMap(action =>
        this.authService.logout().pipe(
          tap(() => {
            this.loaderService.hideAll();
            this.modalService.dismissAll('Logout');
            this.router.navigate(['/login']);
          }),
          map((responseMessage: ResponseMessage) =>
            UserActions.logoutSuccess()
          ),
          catchError(error => {
            return of(UserActions.effectError({error}));
          })
        )
      )
    )
  );

  // todo: This feels ugly... Move somewhere else
  private confirmSubscription(message) {
    const modal = this.modalService.open(ConfirmSubscriptionPopupComponent, {size: 'lg', backdrop: 'static'});
    modal.componentInstance.title = 'Successfully subscribed';
    modal.componentInstance.message = message;
    modal.componentInstance.classes = 'success';
  }

  private renewalCancelled() {
    const modal = this.modalService.open(ConfirmSubscriptionPopupComponent, {size: 'lg', backdrop: 'static'});
    modal.componentInstance.title = 'Current Subscription Cancelled';
    modal.componentInstance.message = 'Your current subscription will not be renewed and you\'ll ' +
      'return to "My LifeJars Basic" at the end of the current period.';
    modal.componentInstance.classes = 'success';
  }

}
