import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {AuthService} from '../services/auth.service';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {AuthGuard} from './auth.guard';
import {User} from '../models/user.model';

@Injectable({ providedIn: 'root' })
export class AdminAuthGuard extends AuthGuard implements CanActivate {

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>|boolean {

    if ( this.authService.hasAdminAuthenticated() ) {
      return true;
    }

    // Complex remote server test
    return this.authService.hasOpenServerSession()
      .pipe(map((user: User) => {
      if ( user != null && user.isAdmin ) {
        return true;
      }
    }),
      catchError((err: HttpErrorResponse) => {
        this.router.navigate(['/login']);
        return of(false);
      }) );
  }
}
