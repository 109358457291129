import {Component, OnDestroy, OnInit, Inject, ViewChild, ElementRef, PLATFORM_ID} from '@angular/core';
import {Router} from '@angular/router';
import {faSearch} from '@fortawesome/free-solid-svg-icons';
import {User} from '../../../core/models/user.model';
import {UserSubscription} from '../../../core/models/user-subscription.model';
import {Subscription} from 'rxjs';
import {SubscriptionsService} from '../../../core/services/subscriptions.service';
import {first} from 'rxjs/operators';
import {FormBuilder, FormGroup, NgForm, Validators} from '@angular/forms';
import {ResponseMessage} from '../../../core/models/response-message.model';
import {NewUserSubscription} from '../../../core/models/new-user-subscription.model';

import {DOCUMENT, isPlatformBrowser} from '@angular/common';
import {LayoutService} from '../../../core/services/layout.service';

import {StripeService, StripeCardComponent} from 'ngx-stripe';
import {
  StripeCardElementOptions,
  StripeElementsOptions
} from '@stripe/stripe-js';

import {DonationPartner} from '../../../core/models/donation-partner.model';
import {AlertService} from '../../../shared/components/alert/alert.service';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../store';
import {selectCurrentUser, selectCurrentUserSubscription} from '../../../store/users/users.selectors';
import {UserActions} from '../../../store/users';
import {OptionSelectors} from '../../../store/options';
import {CouponModel} from '../../../core/models/coupon.model';
import {HtmlElementService} from '../../../core/services/html-element.service';

// declare var Stripe: any;

@Component({
  templateUrl: 'features.component.html',
  styleUrls: ['features.component.scss']
})
export class FeaturesComponent implements OnInit, OnDestroy {
  private readonly subscription: Subscription;

  @ViewChild('formSubscription')
  public ngForm: NgForm;

  @ViewChild(StripeCardComponent)
  card: StripeCardComponent;

  @ViewChild('anchorElement')
  public anchorElement: ElementRef;

  public cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        lineHeight: '40px',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    }
  };

  public elementsOptions: StripeElementsOptions = {
    locale: 'en'
  };


  public faSearch = faSearch;
  public submittedShowErrors = false;

  public userSubscription: UserSubscription;
  public subscriptionPackages: UserSubscription[];

  public user: User;

  public subscriptionForm: FormGroup;

  public donationPartners: DonationPartner[];
  public requiresPartnerDonation = false;

  public selectedPlan: UserSubscription;

  public gatewayKey: string;
  public stripeSourceId;

  public coupon: CouponModel;
  public finalAmount: number;


  // convenience getter for easy access to form fields
  public get f() {
    return this.subscriptionForm.controls;
  }

  constructor(
    @Inject(PLATFORM_ID) protected platformId,
    protected formBuilder: FormBuilder,
    protected router: Router,
    protected store: Store<AppState>,
    protected subscriptionsService: SubscriptionsService,
    protected layoutService: LayoutService,
    private htmlService: HtmlElementService,
    private alertService: AlertService,
    private stripeService: StripeService,
    @Inject(DOCUMENT) private document: Document
  ) {
    // Default Plan
    this.finalAmount = 0;
    this.selectedPlan = null;
    this.stripeSourceId = null;
    this.gatewayKey = '';

    this.layoutService.setTitle(`My Plan - My LifeJars`);

    this.subscriptionForm = this.formBuilder.group({
      subscriptionId: [0],
      stripeSourceId: [''],
      couponCode: [''],
      donationPartner: [{value: null, disabled: this.requiresPartnerDonation === false}],
      acceptLegals: [false, Validators.requiredTrue],
      timeFrameFilter: [12] // 12-months
    });

    this.subscription = new Subscription();

    this.subscription.add(this.store.pipe(select(selectCurrentUser))
      .subscribe((user: User) => {
        this.user = user;
      })
    );

    this.subscription.add(this.store.pipe(select(selectCurrentUserSubscription))
      .subscribe((userSub: UserSubscription) => {
        this.userSubscription = userSub;
      })
    );

    this.subscription.add(this.store
      .pipe(select(OptionSelectors.selectSubscriptions))
      .subscribe((subscriptions: UserSubscription[]) => {
        this.subscriptionPackages = subscriptions;
      })
    );

    this.subscription.add(this.store
      .pipe(select(OptionSelectors.selectDonationPartners))
      .subscribe((donationPartners: DonationPartner[]) => {
        this.donationPartners = donationPartners;
      })
    );
  }

  public ngOnInit() {
  }

  public updateFinalAmount(): number {
    this.finalAmount = 0;
    if (this.selectedPlan != null) {
      this.finalAmount = this.coupon && this.coupon.percent > 0
        ? this.selectedPlan.price - (this.selectedPlan.price * (this.coupon.percent / 100))
        : this.coupon && this.coupon.amount > 0
          ? this.selectedPlan.price - this.coupon.amount
          : this.selectedPlan.price;

      // Ensure the final amount is not greater than the original price or less than zero
      this.finalAmount = Math.max(0, Math.min(this.selectedPlan.price, this.finalAmount));
    }

    // Also check Partner Donation requirement
    this.requiresPartnerDonation = false;
    if (this.selectedPlan != null && this.selectedPlan.months >= 12 && this.finalAmount > 0) {
      this.requiresPartnerDonation = true;
    }

    return this.finalAmount;
  }


  public couponCodeVerify() {
    const couponCode = this.subscriptionForm.get('couponCode').value;
    if (!couponCode) {
      this.coupon = null;
      this.updateFinalAmount();
      return;
    }

    this.coupon = null;
    this.subscriptionsService.verifyCoupon(couponCode)
      .pipe(first())
      .subscribe((response: ResponseMessage) => {
        this.coupon = CouponModel.make(response.data);
        this.updateFinalAmount();
      }, error => {
        this.updateFinalAmount();
      });
  }

  public changePlan(clickedPlan: UserSubscription) {
    this.selectedPlan = clickedPlan;

    this.updateFinalAmount();

    // Upsell, Cross-sell, warn about lost services, etc
    this.layoutService.showPlanSuggestions(this.userSubscription, this.selectedPlan);

    if (this.anchorElement && this.anchorElement.nativeElement) {
      this.anchorElement.nativeElement.scrollIntoView({behavior: 'smooth'});
    }

    return false;
  }

  public filterByTimeFrame(item: UserSubscription, timeFrameFilter: number) {
    return item != null && (item.months === timeFrameFilter || item.sticky === true);
  }

  private preValidation(subscriptionPackage: NewUserSubscription): boolean {

    if (this.selectedPlan == null) {
      this.alertService.error(['Please select a plan.']);
      return false;
    }

    // Capture the Plan ID
    subscriptionPackage.subscriptionId = this.selectedPlan.id;

    // Only require organization partner selection when selecting annual plan.
    if (subscriptionPackage.donationPartner == null && this.requiresPartnerDonation === true) {
      this.alertService.error(['Please select a partner organization.']);
      this.submittedShowErrors = true;
      return false;

    } else if (this.requiresPartnerDonation === false) {
      // make sure to reset organization partner selection if annual plan is not selected.
      subscriptionPackage.donationPartner = null;
    }

    // stop here if form is invalid
    this.submittedShowErrors = true;
    if (this.subscriptionForm.invalid) {
      return false;
    }

    return true;
  }

  public onFreePlanSubmit() {
    this.ngForm.ngSubmit.emit();
  }

  public onPayPalSubmit() {
    this.gatewayKey = 'paypal';
    this.ngForm.ngSubmit.emit();
  }

  public onStripeSubmit() {

    const subscriptionPackage = NewUserSubscription.make(this.subscriptionForm.getRawValue());
    if (this.preValidation(subscriptionPackage) === false) {
      return;
    }

    this.stripeService
      .createSource(this.card.element, {})
      .subscribe(result => {
        if (result.source) {
          this.stripeSourceId = result.source.id;
          this.gatewayKey = 'stripe';
          this.ngForm.ngSubmit.emit();
        } else if (result.error) {
          this.alertService.error([result.error.message]);
        }
      });
  }

  private executeGtm(){
    const isBrowser = isPlatformBrowser(this.platformId);
    if (isBrowser === true) {
      // @ts-ignore
      window.dataLayer = window.dataLayer || [];
      const gtmTag = {
        event: 'Account Upgraded',
        properties: {
          object: 'User',
          action: 'Upgraded',
          user_id: '0',
          email: this.user.email,
          first_name: this.user.firstName,
          last_name: this.user.lastName,
        },
      };
      // @ts-ignore
      window.dataLayer.push(gtmTag);
      console.log('GTM: Account Upgraded Complete');
    }
  }

  public onSubmit() {

    const subscriptionPackage = NewUserSubscription.make(this.subscriptionForm.getRawValue());
    if (this.preValidation(subscriptionPackage) === false) {
      return;
    }

    if (this.gatewayKey) {
      subscriptionPackage.gatewayKey = this.gatewayKey;
    }

    if (this.stripeSourceId) {
      subscriptionPackage.stripeSourceId = this.stripeSourceId;
    }

    this.subscriptionsService.createSubscription(subscriptionPackage)
      .pipe(first())
      .subscribe((response: ResponseMessage) => {
        this.executeGtm();

        // Refresh the subscription
        this.store.dispatch(
          UserActions.updateUserSubscriptionRequest({
            id: null // Current User
          })
        );

        // Redirect to paypal or back to /account-settings page here...
        // const nextUrl = response.data['nextUrl'];
        const nextUrl = response.data.nextUrl;
        if (nextUrl.startsWith('http')) {

          // Redirect to External Payment Service
          this.document.location.href = nextUrl;

        } else {

          // Redirect Internally
          this.router.navigate([nextUrl]);
        }
      });
  }

  public showTerms(): string {
    return this.layoutService.showTerms();
  }

  public showPrivacy(): string {
    return this.layoutService.showPrivacy();
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
