import {Component} from '@angular/core';
import { NodeType } from 'src/app/core/common/enums';
import {AbstractMemoryDefaultsComponent} from '../abstract-memory-defaults/abstract-memory-defaults.component';
import {IMemory, MemoryModel} from '../../../core/models/memory.model';
import {MemoryActions} from '../../../store/memories';
import {MemoryDefaultModalComponent} from '../../../shared/modals/admin/memory-default/memory-default-modal.component';

@Component({templateUrl: '../abstract-memory-defaults/abstract-memory-defaults.component.html'})
export class MemoryDefaultsComponent extends AbstractMemoryDefaultsComponent {
  public PageTitle = 'Memory Defaults';
  public NodeType: NodeType = NodeType.MemoryDefault;

  public edit(memory?: IMemory) {
    this.store.dispatch(
      MemoryActions.setCurrentMemoryId({ id: memory?.id })
    );

    const modal = this.modalService.open(MemoryDefaultModalComponent, {size: 'lg', backdrop: 'static'});
    modal.componentInstance.memory = memory || new MemoryModel();
    modal.result.then((editedMemory: IMemory) => {
      this.search();
    }, (reason) => {
      console.log(reason);
    });
  }

}
