import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';


@Component({
  templateUrl: 'confirm-subscription-popup.component.html'
})
export class ConfirmSubscriptionPopupComponent implements OnInit {
  @Input() public title: string;
  @Input() public message: string;
  @Input() public okText: string;
  @Input() public classes: string;

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal
  ) {
    this.title = 'Confirm';
    this.message = 'Successful';
    this.okText = 'Ok';
  }

  public ngOnInit() {

  }

  public ok( data?: any ) {
    this.activeModal.close(true);
  }

}
