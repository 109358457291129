import {WardType} from '../common/enums';

export class UserBio {
  public wardType: WardType;
  public firstName: string;
  public lastName: string;
  public email: string;
  public phone: string;
  public gender: string;
  public birthday: any;
  public deathday: any;

  public relationshipType: number;
  public relationshipSubType: number;
  public relationshipOther: string;

  constructor() {
    this.wardType = WardType.PersonLife;
    this.firstName = '';
    this.lastName = '';
    this.email = '';
    this.phone = '';
    this.gender = '';
    this.birthday = null;
    this.deathday = null;

    this.relationshipType = -1;
    this.relationshipSubType = -1;
    this.relationshipOther = '';
  }

  public static make(obj: object): UserBio {
    if (!obj || (Object.keys(obj).length === 0)) {
      return null; // Object is null or empty
    }
    return {...new UserBio(), ...obj};
  }

}
