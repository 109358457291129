import {Injectable, OnDestroy} from '@angular/core';
import {from, Observable} from 'rxjs';
import {last, map, mergeMap, reduce, takeWhile} from 'rxjs/operators';
import {HttpClient, HttpEventType, HttpRequest, HttpResponse} from '@angular/common/http';
import {FileModel} from '../../../core/models/file.model';
import {IMemory} from '../../../core/models/memory.model';
import {Bio} from '../../../core/models/bio.model';
import {BioService} from '../../../core/services/bio.service';
import {PublicBioService} from '../../../core/services/public-bio.service';
import {MemoryService} from '../../../core/services/memory.service';

export class FileUpload {
  public file: File;
  public type: string;
  public size: number;
  public preview: string | ArrayBuffer;
  public progress: number;
  public complete: boolean;
  public abort: boolean;

  constructor(file?: File) {
    this.file = file;
    this.type = '';
    this.size = file ? file.size : 0;
    this.preview = '';
    this.progress = 0;
    this.complete = false;
    this.abort = false;
  }
}

export class FileCacheEntry {
  public src: string;
  public expiry: number;

  constructor() {
    this.src = '';
    this.expiry = 0;
  }
}

@Injectable({providedIn: 'root'})
export class FileUploaderService implements OnDestroy {

  public static readonly Image = [
    'image', // Simple type
    'image/png',
    'image/jpg',
    'image/jpeg',
    'image/gif',
    'image/bmp',
    'image/tiff'
  ];

  public static readonly Document = [
    'document',  // Simple type
    'application/msword',
    'application/vnd.ms-excel',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/pdf',
    'text/plain',
    'application/rtf',
    'application/vnd.oasis.opendocument.text',
    'application/vnd.oasis.opendocument.spreadsheet'
  ];

  public static readonly Video = [
    'video', // Simple type
    'video/x-flv',
    'video/mp4',
    'video/mpeg',
    'video/ogg',
    'video/webm',
    'application/x-mpegURL',
    'video/MP2T',
    'video/3gpp',
    'video/quicktime',
    'video/x-msvideo',
    'video/x-ms-wmv',
  ];

  public static readonly Audio = [
    'audio', // Simple type
    'audio/basic',
    'audio/vnd.wav',
    'audio/midi',
    'audio/x-midi',
    'audio/webm',
    'audio/wav',
    'audio/ogg',
    'audio/mpeg',
    'audio/mp3',
    'audio/mp4',
    'audio/m4a',
    'audio/x-m4a',
    'audio/aac',
    'audio/x-aiff'
  ];

  private fileCache = {};

  public setCache(key: string, fileEntry: FileCacheEntry) {
    return this.fileCache[key] = fileEntry;
  }

  public getCache(key: string): FileCacheEntry | void {
    const fileEntry = this.fileCache[key] || null;
    if (fileEntry != null) {
      const dt = (new Date()).getTime();
      if (fileEntry.expiry === 0 || fileEntry.expiry > dt) {
        return fileEntry;
      }
      this.fileCache[key] = null;
    }
    return null;
  }

  constructor(
    protected http: HttpClient,
    protected bioService: BioService,
    protected publicBioService: PublicBioService,
    protected memoryService: MemoryService
  ) {
  }

  public getType(file, restrictType?: string[]): string | boolean {

    if (!file || (!file.mime && !file.type)) {
      return false;
    }

    // Use the provided 'restrictType' or use all known types
    const allowedTypes: string[] = restrictType || (FileUploaderService.Image.concat(
      FileUploaderService.Document,
      FileUploaderService.Video,
      FileUploaderService.Audio
    ));

    // Pre-process the file type
    const fileType = ('' + (file.mime || file.type)) // Coerce into a string
      .toLowerCase() // Lowercase all for consistency
      .replace(/^\s*|\s*$/g, ''); // Remove white spaces

    // Loop through and validate each type
    for (let i = 0, l = allowedTypes.length; i < l; i++) {
      if (fileType.match(allowedTypes[i].toLowerCase())) {

        // Valid type
        return allowedTypes[i];
      }
    }

    // Invalid type
    return false;
  }

  public getSimpleType(file): string {

    if (this.isImage(file)) {
      return 'Image';
    }

    if (this.isDocument(file)) {
      return 'Document';
    }

    if (this.isVideo(file)) {
      return 'Video';
    }

    if (this.isAudio(file)) {
      return 'Audio';
    }

    return '';
  }

  public isValidType(file, types): boolean {
    return !!this.getType(file, types);
  }

  public isImage(file): boolean {
    return !!this.getType(file, FileUploaderService.Image);
  }

  public isDocument(file): boolean {
    return !!this.getType(file, FileUploaderService.Document);
  }

  public isVideo(file): boolean {
    return !!this.getType(file, FileUploaderService.Video);
  }

  public isAudio(file): boolean {
    return !!this.getType(file, FileUploaderService.Audio);
  }

  public isUnknown(file): boolean {
    return this.getType(file) === false;
  }

  public getSize(file, decimals: number = 2): string {
    if (!file || file.size === 0) {
      return '0 Bytes';
    }
    const bytes: number = file.size;
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  public getMegabytes(file, decimals: number = 2): number {
    if (!file || file.size === 0) {
      return 0;
    }
    const dm = decimals < 0 ? 0 : decimals;
    const mb = file.size / 1024 / 1024;
    return parseFloat(mb.toFixed(dm));
  }

  public uploadFiles(url: string, files: FileUpload[]): Observable<FileUpload[]> {

    // This will Chain each file Upload as an individual request
    // We do this so we can track individual progress
    return from(files)
      .pipe(
        mergeMap((file) => {
          const myFormData: FormData = new FormData();
          myFormData.append('file', file.file, file.file.name);

          const config = new HttpRequest('POST', url, myFormData, {
            reportProgress: true
          });

          return this.http.request(config)
            .pipe(
              takeWhile(event => !file.abort),
              map(event => {
                if (event.type === HttpEventType.UploadProgress) {
                  // Update progress Indicator
                  file.progress = Math.round((event.loaded / event.total) * 100);
                } else if (event.type === HttpEventType.Response) {
                  // Complete
                  file.complete = true;
                  file.progress = 100;
                }
                return event;
              }),
              last()
            );
        }),

        reduce<HttpResponse<any[]>, any>((results, response) => {

          // Multiple Files
          if (Array.isArray(response.body)) {
            response.body.forEach((file) => {
              results.push(file);
            });

          } else {

            // Single File
            results.push(response.body);
          }

          return results;
        }, [])
      );
  }

  public downloadFile(bio: Bio, memory: IMemory, file: FileModel,
                      isPublic: boolean = false, getPreview: boolean = false ): Observable<FileModel>{

    let observable$: Observable<FileModel> = null;
    if (bio) {
      if (isPublic) {
        observable$ = this.publicBioService.getBioFile(bio, file, getPreview);
      } else {
        observable$ = this.bioService.getFile(bio, file, getPreview);
      }
    } else if (memory) {
      if (memory.isDefault) {
        // Default (Admin)
        observable$ = this.memoryService.getDefaultFile(memory, file, getPreview);
      } else {
        // Standard (Members)
        if (isPublic) {
          observable$ = this.publicBioService.getMemoryFile(memory, file, getPreview);
        } else {
          observable$ = this.memoryService.getFile(memory, file, getPreview);
        }
      }
    }
    return observable$;
  }

  ngOnDestroy(): void {
  }

}
