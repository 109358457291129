import {AppState} from '../index';
import {createSelector} from '@ngrx/store';

export const selectBiosState = (state: AppState) => state.bios;

export const selectDisplayBio = createSelector(
  selectBiosState,
  state => state.displayBio
);

export const selectDisplayBioTribeRelationship = createSelector(
  selectBiosState,
  state => state.displayBio && state.displayBio.tribeRelationship.length
    ? state.displayBio.tribeRelationship[0]
    : null
);

export const selectCurrentBio = createSelector(
  selectBiosState,
  state => state.currentBio
);

export const selectBios = createSelector(
  selectBiosState,
  state => Object.keys(state.biosById).map(key => state.biosById[key])
);

export const selectBioById = createSelector(
  selectBiosState,
  state => (id: number) => {
    return state.biosById[id] || null;
  }
);

export const selectBioBySlug = createSelector(
  selectBiosState,
  state => (slug: string) => {
    return state.biosBySlug[slug] || null;
  }
);
