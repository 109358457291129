export class CommonAddress {

  public type: number;
  public streetAddress: string;
  public streetAddress2: string;
  public suburb: string;
  public postcode: string;
  public state: string;
  public country: string;

  constructor() {
    this.type = 0; // 0: Primary
    this.streetAddress = '';
    this.streetAddress2 = '';
    this.suburb = '';
    this.postcode = '';
    this.state = '';
    this.country = '';
  }

  public static make(obj: object): CommonAddress {
    if (!obj || (Object.keys(obj).length === 0)) {
      return null; // Object is null or empty
    }
    return {...new CommonAddress(), ...obj};
  }

}
