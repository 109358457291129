<div class="page-header">
  <div class="container pt-3 pb-3 mb-3">

    <div class="row">
      <div [ngClass]="{'col-12 col-md-12': !controls, 'col-6 col-md-7': controls}">

        <div class="block block-title">
          <h1>{{title}}</h1>
          <ng-content></ng-content>
        </div><!-- /block-title -->

      </div>
      <div class="col-6 col-md-5" *ngIf="controls">
        <ng-container *ngTemplateOutlet="controls"></ng-container>
      </div>
    </div>

  </div>
</div><!-- /page-header -->
