
<div class="page page-landscape page-memory-standard">

  <table class="page-layout-table">
    <tbody>

    <tr *ngIf="(page%2==1)" class="page-layout-table-body odd">
      <td class="story" width="50%" align="right" valign="middle">
        <app-bio-print-story [title]="title" [text]="text" [date]="date" [location]="location" ></app-bio-print-story>
      </td>
      <td class="photos" width="50%" align="center" valign="middle">
        <app-bio-print-photos [memory]="memory" [photos]="photos"></app-bio-print-photos>
      </td>
    </tr>

    <tr *ngIf="(page%2==0)" class="page-layout-table-body even">
      <td class="photos" width="50%" align="center" valign="middle">
        <app-bio-print-photos [memory]="memory" [photos]="photos"></app-bio-print-photos>
      </td>
      <td class="story" width="50%" align="right" valign="middle">
        <app-bio-print-story [title]="title" [text]="text" [date]="date" [location]="location" ></app-bio-print-story>
      </td>
    </tr>

    <tr class="page-layout-table-footer">
      <td colspan="2" align="center" valign="middle">
        <app-bio-print-footer [page]="page"></app-bio-print-footer>
      </td>
    </tr>

    </tbody>
  </table>
</div><!-- /page-memory-standard -->
