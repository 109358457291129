import {Component, Input} from '@angular/core';
import {Observable, OperatorFunction} from 'rxjs';
import {debounceTime, distinctUntilChanged, switchMap} from 'rxjs/operators';
import {NgbTypeaheadSelectItemEvent} from '@ng-bootstrap/ng-bootstrap';
import {faSearch, faTag, faEye, faClock, faUser, faCube} from '@fortawesome/free-solid-svg-icons';
import {SearchService} from '../../../core/services/search.service';
import {SearchResult} from '../../../core/models/search-result.model';
import {Router} from '@angular/router';
import {NodeType} from '../../../core/common/enums';
import {JarActions} from '../../../store/jars';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store';

@Component({
  selector: 'app-sitewide-search',
  templateUrl: 'sitewide-search.component.html',
  styleUrls: ['sitewide-search.component.scss']
})
export class SitewideSearchComponent {
  public readonly faSearch = faSearch;
  public readonly faTag = faTag;
  public readonly faEye = faEye;
  public readonly faClock = faClock;
  public readonly faUser = faUser;
  public readonly faCube = faCube;

  public searchModel: any;

  constructor(private router: Router, private searchService: SearchService, private store: Store<AppState>) {
  }

  public formatter(item: SearchResult) {
    return item.label;
  }

  public search: OperatorFunction<string, readonly SearchResult[]> = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((term: string) => this.searchService.search({
          page: 1,
          results: 10,
          keywords: term,
        }),
      )
    );
  };

  public onSearchSelect(selectEvent: NgbTypeaheadSelectItemEvent) {

    // Clear the selected item from search bar
    selectEvent.preventDefault();
    this.searchModel = null;

    if (selectEvent.item.type === 'bio') {
      this.router.navigate([selectEvent.item.bio.isLegacy ? '/legacy' : '/profiles', selectEvent.item.bio.slug]);
    } else {

      this.store.dispatch(
        JarActions.setCurrentJarId({
          id: selectEvent.item.bioNode.parentNode
        })
      );

      switch (selectEvent.item.bioNode.nodeType) {
        case NodeType.MemoryJar:
        case NodeType.Memory:
          this.router.navigate(['/jars/memories', selectEvent.item.label]);
          break;

        case NodeType.ThingJar:
        case NodeType.Thing:
          this.router.navigate(['/jars/things', selectEvent.item.label]);
          break;

        case NodeType.PasswordJar:
        case NodeType.Password:
          this.router.navigate(['/jars/passwords', selectEvent.item.label]);
          break;
      }
    }
  }

}
