import {Component, OnDestroy, OnInit} from '@angular/core';
import {MemoryModel} from '../../../core/models/memory.model';
import {MemoryDefaultsComponent} from '../memory-defaults/memory-defaults.component';
import {ThingDefaultModalComponent} from '../../../shared/modals/admin/thing-default/thing-default-modal.component';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MemoryService} from '../../../core/services/memory.service';
import {LoaderService} from '../../../shared/components/content-loader/loader.service';

@Component({templateUrl: 'tag-defaults.component.html'})
export class TagDefaultsComponent extends MemoryDefaultsComponent {

  constructor(
    protected router: Router,
    protected store: Store<AppState>,
    protected modalService: NgbModal,
    protected memoryService: MemoryService,
    protected loadingService: LoaderService
  ) {
    super(router, store, modalService, memoryService, loadingService);
  }

  public search() {
    /*
    this.memoryService.searchDefaultPasswords(this.page, this.results, this.keywords)
      .pipe(first())
      .subscribe(
        data => {
          this.pagedMemories = data;
        });
     */
  }

  public edit(password?: MemoryModel) {
    const modal = this.modalService.open(ThingDefaultModalComponent, {size: 'lg', backdrop: 'static'});

    const memoryToEdit = password || new MemoryModel();
    modal.componentInstance.memory = memoryToEdit;
    modal.result.then((newThing: MemoryModel) => {
      this.search();
    }, (reason) => {
      console.log(reason);
    });

  }
}
