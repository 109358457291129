import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';

import {NewUserSubscription} from '../models/new-user-subscription.model';
import {UserSubscription} from '../models/user-subscription.model';
import {ResponseMessage} from '../models/response-message.model';
import {AppConfig} from './app-config.service';

@Injectable({providedIn: 'root'})
export class SubscriptionsService {
  constructor( private http: HttpClient ) { }

  public verifyCoupon(couponCode: string): Observable<any> {
    return this.http.get<any>(`${AppConfig.settings.apiBaseUrl}/gateway/verify-coupon/${couponCode}`, );
  }

  public getSubscription(id?: number): Observable<UserSubscription> {
    let url = `${AppConfig.settings.apiBaseUrl}/gateway/user/subscription`;
    if (id != null) {
      url = `${AppConfig.settings.apiBaseUrl}/gateway/user/${id}/subscription`;
    }
    return this.http.get<UserSubscription>(url);
  }

  public updateSubscriptionStatus(invoiceNumber?: string): Observable<UserSubscription> {
    let url = `${AppConfig.settings.apiBaseUrl}/gateway/subscription/status`;
    if (invoiceNumber != null) {
      url = `${AppConfig.settings.apiBaseUrl}/gateway/subscription/${invoiceNumber}/status`;
    }
    return this.http.get<UserSubscription>(url, );
  }

  public createSubscription(model: NewUserSubscription): Observable<ResponseMessage> {
    return this.http.post<ResponseMessage>(`${AppConfig.settings.apiBaseUrl}/gateway/purchase`, {
      product: model.subscriptionId,
      stripeSourceId: model.stripeSourceId,
      donationPartner: model.donationPartner,
      couponCode: model.couponCode,
      gatewayKey: model.gatewayKey
    });
  }

  public capturePayment( invoiceNumber: string ): Observable<ResponseMessage> {
    const url = `${AppConfig.settings.apiBaseUrl}/gateway/payment/${invoiceNumber}/capture`;
    return this.http.post<ResponseMessage>(url, {});
  }

  public cancelSubscription( invoiceNumber: string ): Observable<ResponseMessage> {
    const url = `${AppConfig.settings.apiBaseUrl}/gateway/subscription/${invoiceNumber}/cancel`;
    return this.http.delete<ResponseMessage>(url);
  }

}
