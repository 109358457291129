
<app-page-header title="Support"></app-page-header>

<div class="container component-support-package">

  <div class="row">
    <div class="col-12">

      <div class="card card-bio">
        <div class="card-body text-legal-doc">

          <div class="image-title-banner">
            <div class="slide">
              <div class="col-6 col-sm-7 col-md-9 col-lg-12">
                <div class="pb-3"><span class="banner-title">How can we help you?</span></div>
              </div>
            </div>
          </div><!-- /image-title-banner -->


          <div class="tutorials mt-4">
            <ul class="tutorials-list">
              <li class="tutorial">
                <a class="content" href="#" (click)="showTutorial($event,'dashboardTutorial')">
                  <span class="tutorial-thumb">
                    <img src="/assets/img/bj-tutorial-dashboard-thb.jpg" alt="My LifeJars Tour Tutorial">
                  </span>
                  <span class="tutorial-title">
                    Tour of My LifeJars
                  </span>
                </a>
              </li>

              <li class="tutorial">
                <a class="content" href="#" (click)="showTutorial($event,'memoryJarsTutorial')">
                  <span class="tutorial-thumb">
                    <img src="/assets/img/bj-tutorial-memory-thb.jpg" alt="My LifeJars Memory Tutorial">
                  </span>
                  <span class="tutorial-title">
                    How to use the Memory Jars
                  </span>
                </a>
              </li>

              <li class="tutorial">
                <a class="content" href="#" (click)="showTutorial($event,'thingsJarsTutorial')">
                  <span class="tutorial-thumb">
                    <img src="/assets/img/bj-tutorial-thing-thb.jpg" alt="My LifeJars Things Tutorial">
                  </span>
                  <span class="tutorial-title">
                    How to use the Things Jars
                  </span>
                </a>
              </li>

              <li class="tutorial">
                <a class="content" href="#" (click)="showTutorial($event,'passwordJarsTutorial')">
                  <span class="tutorial-thumb">
                    <img src="/assets/img/bj-tutorial-password-thb.jpg" alt="My LifeJars Password Tutorial">
                  </span>
                  <span class="tutorial-title">
                    How to use the Password Jars
                  </span>
                </a>
              </li>

              <li class="tutorial">
                <a class="content" href="#" (click)="showTutorial($event,'onlineMemorialTutorial')">
                  <span class="tutorial-thumb">
                    <img src="/assets/img/bj-tutorial-memorial-thb.jpg" alt="My LifeJars Memorial Tutorial">
                  </span>
                  <span class="tutorial-title">
                    How to create an Online Memorial
                  </span>
                </a>
              </li>


            </ul>
          </div>

          <h1 class="mt-4">Frequently Asked Questions</h1>

          <h2>General</h2>
          <ngb-accordion [closeOthers]="true" [destroyOnHide]="false" activeIds="faq-general-1">
            <ngb-panel id="faq-general-1" title="How secure is my personal data?">
              <ng-template ngbPanelContent>
                <p>We take the security of your content seriously. All content uploaded onto our Platform
                  (unless uploaded with a public setting in the Secret Keeper) is encrypted on transit and
                  during storage so it is safe from man-in-the-middle attack. Refer to My LifeJars' Privacy Policy.</p>
              </ng-template>
            </ngb-panel>

            <ngb-panel id="faq-general-1b" title="How do change all my Public to Tribester settings in Secret Keeper?">
              <ng-template ngbPanelContent>
                <p>Identity theft is of primary importance that ‘s why My LifeJars offer an ‘override’ Privacy
                  setting. This is found at the bottom of the Edit Profile page. If you select Tribester Only
                  your profile image together with all memories and things you have given public
                  settings to are hidden from anyone searching for you in My LifeJars. So, you can be
                  incognito when you are alive. Any child Profile is automatically given his setting.</p>
              </ng-template>
            </ngb-panel>

            <ngb-panel id="faq-general-2" title="Is it free to register for a My LifeJars Account?">
              <ng-template ngbPanelContent>
                <p>When you create an Account, you will automatically be offered a free membership or you may
                  choose a paid membership (Membership). You may choose between different plans of paid Memberships
                  with different services and different membership periods (monthly or annual) as set out on our
                  Platform.</p>
              </ng-template>
            </ngb-panel>

            <ngb-panel id="faq-general-3" title="How old do I have to have a My LifeJars Account?">
              <ng-template ngbPanelContent>
                <p>You must be at least 13 years old to use the Platform. If you are under 13 your
                  parent or legal guardian may add content about you as an additional Profile to their
                  account but you may not have your own account.</p>
              </ng-template>
            </ngb-panel>

            <ngb-panel id="faq-general-4" title="Can I have more than 1 My LifeJars Account?">
              <ng-template ngbPanelContent>
                <p>You may only have 1 Account and you may use that account as a Tribester and as a Guardian and have
                  several Profiles (Life Profiles and Legacy Profiles) attached to that account.</p>
              </ng-template>
            </ngb-panel>

            <ngb-panel id="faq-general-5" title="What happens if I lose my password?">
              <ng-template ngbPanelContent>
                <p>When you log in you can click on reset password and a temporary password will be sent to your email
                  account. To maintain the security to your content, is important that no other person has access to
                  this email account. </p>
              </ng-template>
            </ngb-panel>

            <ngb-panel id="faq-general-6" title="What happens if someone uses my login and password?">
              <ng-template ngbPanelContent>
                <p>You are responsible for keeping your Account details and your password confidential and you will
                  be liable for all activity on your Account, including purchases made using your Account details.
                  You agree to immediately notify us of any unauthorised use of your Account.</p>
              </ng-template>
            </ngb-panel>

            <ngb-panel id="faq-general-7" title="Do I need to nominate a Guardian if I am over 18?">
              <ng-template ngbPanelContent>
                <p>You may choose (1) not to appoint a Guardian; (2) appoint only one Guardian; or (3) appoint more
                  than one Guardian. Where you appoint more than one Guardian you may give each Guardian different
                  access permissions for your Account. Where you have multiple Guardians, you will need to choose
                  one Guardian to be your prime Guardian. This Guardian will be the administrator of all other Guardians
                  nominated for your Profile and this Guardian's actions will take precedence over the actions of any
                  other Guardian on your Profile. </p>
              </ng-template>
            </ngb-panel>

            <ngb-panel id="faq-general-8"
                       title="Do I need to nominate a Guardian for my Life Profile if I am between 13 and 18?">
              <ng-template ngbPanelContent>
                <p>If you are over 13 but under 18 years old or mentally incapacitated, you represent and warrant
                  that you have permission from your parent or legal guardian to access and use the Platform, and
                  they have accepted these Terms on your behalf. Where you are over 13 but under 18 years old, you
                  will be required to have a Guardian nominated for your Profile to be able to use certain functionalities
                  on the Platform.</p>
              </ng-template>
            </ngb-panel>

            <ngb-panel id="faq-general-9" title="What should I do if someone is being abusive?">
              <ng-template ngbPanelContent>
                <p>Notify My LifeJars with details of the abuse and your content will be decrypted by My LifeJars
                  so we can investigate and take the appropriate action in accordance with "Prohibited Conduct"
                  outlined in My LifeJars' Terms & Conditions.</p>
              </ng-template>
            </ngb-panel>

            <ngb-panel id="faq-general-10" title="Does My LifeJars draft legal documents?">
              <ng-template ngbPanelContent>
                <p>Our Platform is not intended to and should never replace the use of formal legal documents, such as a
                  will, advance care
                  directive or the appointment of an executor or legal guardian (or similar) in accordance with the
                  applicable laws in
                  your jurisdiction. </p>
              </ng-template>
            </ngb-panel>

            <ngb-panel id="faq-general-11"
                       title="Does My LifeJars promote or provide Legal. Health, Financial or Funeral services?">
              <ng-template ngbPanelContent>
                <p>My LifeJars does not provide legal advice, legal services, health advice, health services, financial
                  advice, financial services
                  or funeral services. My LifeJars has no affiliation with any such service. It is your responsibility to
                  ensure you
                  receive appropriate and tailored advice or services from a suitably qualified professional. You should
                  carefully
                  assess the suitability of any Content on our Platform to your individual situation and any reliance on
                  any Content
                  is at your own risk.</p>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>


          <h2>Profiles</h2>
          <ngb-accordion [closeOthers]="true" [destroyOnHide]="false" activeIds="faq-bios-1">
            <ngb-panel id="faq-bios-1" title="How do I create my Life Profile?">
              <ng-template ngbPanelContent>
                <p>A User may create a Life Profile by using the functionality on the Platform described as ‘Memory Jars'
                  to store memories and by using the functionality on the Platform described as ‘Things Jars' to store
                  information or things about a person's life. </p>
              </ng-template>
            </ngb-panel>

            <ngb-panel id="faq-bios-2"
                       title="How do I create a Life Profile for someone else who is over 18 but can't use a computer?">
              <ng-template ngbPanelContent>
                <p>A User may create an 'Assisted' Life Profile for a person who is over 18 but can't use a computer by
                  providing
                  details of the relationship to the person and accepting the Terms and Conditions for this type
                  of Account. Content can be added to create their Life Profile , however some functionality of the
                  Platform may be restricted. The person must have their own email address and you will be required to
                  create an
                  email account from a free service such as gmail for them before creating their
                  Assisted Life Profile.</p>
              </ng-template>
            </ngb-panel>

            <ngb-panel id="faq-bios-3" title="How do I create a Life Profile for a child under 13?">
              <ng-template ngbPanelContent>
                <p>A User that is a parent or legal guardian for a child may create a Life Profile connected to the parent
                  or
                  legal guardian's Account, about their child. This Life Profile of your ward can be created and accessed
                  from the top menu bar RHS dropdown.</p>
              </ng-template>
            </ngb-panel>

            <ngb-panel id="faq-bios-4" title="How do I create a Legacy Profile for someone that has already died?">
              <ng-template ngbPanelContent>
                <p>A User should go to their Remembrance Wall and search for a Legacy Profile to ensure that a Legacy Profile
                  for the person has not already been created in My LifeJars. A Legacy Profile for a person can then be
                  created from the top menu bar RHS dropdown. The User will become the Guardian of this Legacy Profile, and
                  can invite other Tribester's to
                  become part of this person's Tribe and view their Legacy Profile.</p>
              </ng-template>
            </ngb-panel>

            <ngb-panel id="faq-bios-5" title="How does my Life Profile get changed into my Legacy Profile when I die?">
              <ng-template ngbPanelContent>
                <p>On your death, your Guardian can transition Life Profile to your Legacy Profile.</p>
              </ng-template>
            </ngb-panel>

            <ngb-panel id="faq-bios-6" title="How do I change a Life Profile to a Legacy Profile?">
              <ng-template ngbPanelContent>
                <p>Go to the dropdown on the top menu RHS corner dropdown, select the person’s edit
                  Profile. You can select Transition to Legacy Profile from the Top RHS corner of this page.
                  You can only transition a Life to a Legacy Profile if the deceased person made you a
                  Guardian of their Profile and gave you access rights to do this.</p>
              </ng-template>
            </ngb-panel>

            <ngb-panel id="faq-bios-7" title="In the future how will others be able to view my Legacy Profile?">
              <ng-template ngbPanelContent>
                <p>Any content that you have given a public setting to in Secret Keeper and Legacy in
                  the Time Lock will be available for anyone to view. Only your Tribesters will be able
                  to see any content you have given other settings to in Secret Keeper. Your
                  Guardians will be able to invite and accept any of your family &amp; friends into your
                  Tribe.</p>
              </ng-template>
            </ngb-panel>

            <ngb-panel id="faq-bios-8" title="Who will add my Resting Pin to my Legacy Profile?">
              <ng-template ngbPanelContent>
                <p>A nominate Guardian of your Legacy Profile will be able to add your Resting Pin, that
                  indicates on a Google map the place where you were buried, or your ashes were
                  scattered of still held in an urn.</p>
              </ng-template>
            </ngb-panel>

            <ngb-panel id="faq-bios-9" title="Why can’t I see a Resting Pin on a Legacy Profile?">
              <ng-template ngbPanelContent>
                <p>This is because the Guardian of the Legacy Profile has not set and saved the deceased person’s Resting
                  Pin.</p>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>


          <h2>Jars</h2>
          <ngb-accordion [closeOthers]="true" [destroyOnHide]="false" activeIds="faq-jars-1">
            <ngb-panel id="faq-jars-1" title="How much data can I upload into my My LifeJars Account?">
              <ng-template ngbPanelContent>
                <p>A Free account you can store up to 10GB of content across the platform. For a paid Annual
                  subscription, up to 100GB.</p>
              </ng-template>
            </ngb-panel>

            <ngb-panel id="faq-jars-2" title="How many Memories can I add?">
              <ng-template ngbPanelContent>
                <p>The free plan allows storage of 24 memories on each Profile as long as your total data does not exceed 10
                  GB. 5 Sample Memories are suggested for each of 5 Memory
                  Jars, that represent the different time periods of your life. You may have more or less Memories in
                  each Jar as long as the total does not exceed 24. You may add
                  unlimited Memories to each Memory Jar on the monthly or annual subscription to Memory Jars or My LifeJars
                  Gold.</p>
              </ng-template>
            </ngb-panel>

            <ngb-panel id="faq-jars-3" title="How many Images can I add to each Memory?">
              <ng-template ngbPanelContent>
                <p>There is a limit to 12 files and/or images you may add to each Memory. This is to promote the
                  curation of your memories so that you select the very best
                  images or supporting files to curate your life story. We suggest you limit the file size to 2MB and
                  save after every 3 images to allow for encryption processing time.</p>
              </ng-template>
            </ngb-panel>

            <ngb-panel id="faq-jars-3b" title="Can I upload video to my Memory?">
              <ng-template ngbPanelContent>
                <p>You can upload a video to the paid plans as long as the upload size does not exceed 500 MB (about 2
                  minutes). It is important you curate your memory and chose only
                  the significant parts of your life to upload.</p>
              </ng-template>
            </ngb-panel>

            <ngb-panel id="faq-jars-4" title="How many Things can I add?">
              <ng-template ngbPanelContent>
                <p>The free plan allows storage of 24 Things on each Profile as long as your total data does not exceed 10
                  GB. 4 Sample Things are suggested for each of 5 Things Jars.
                  A sixth Jar for Passwords will be coming soon. You may have more or less Things in each Jar as long as
                  the total does not exceed 24. You may add unlimited Things to
                  each Things Jar on the monthly or annual subscription to Memory Jars or My LifeJars Gold.</p>
              </ng-template>
            </ngb-panel>

            <ngb-panel id="faq-jars-5" title="How many Images can I add to each Thing?">
              <ng-template ngbPanelContent>
                <p>There is a limit to 12 files and/or images you may add to each Thing. This is to promote the curation
                  of your things so that you select only the images or supporting
                  documents that are necessary for you to organize your affairs now and for you Guardian’s to administer
                  your Estate later. We suggest you limit the file size to 2MB
                  and save after every 3 images to allow for encryption processing time.</p>
              </ng-template>
            </ngb-panel>

            <ngb-panel id="faq-jars-6" title="How many Legacy Profiles can I add to my Resting Wall?">
              <ng-template ngbPanelContent>
                <p>You may add as many Legacy Profiles to your Resting Wall for free.</p>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>


          <h2>Tribe</h2>
          <ngb-accordion [closeOthers]="true" [destroyOnHide]="false" activeIds="faq-tribe-1">
            <ngb-panel id="faq-tribe-1" title="What is my Tribe?">
              <ng-template ngbPanelContent>
                <p>Users may add family and friend to their network on the Platform, known as their Tribe. A User will
                  only be added to another User's Tribe
                  where they provide their consent.</p>
              </ng-template>
            </ngb-panel>

            <ngb-panel id="faq-tribe-2" title="What is a Tribester?">
              <ng-template ngbPanelContent>
                <p>Users that become part of another User's Tribe are known as the User's Tribesters. A Tribester can be
                  alive or deceased.</p>
              </ng-template>
            </ngb-panel>

            <ngb-panel id="faq-tribe-3" title="How do I invite my family and friends to become part of my Tribe?">
              <ng-template ngbPanelContent>
                <p>In your My LifeJars Dashboard click "Add Profile" to invite a person to your Tribe. A person's Life Profile will
                  be
                  added to your Life Wall is they accept your request. A dead person's Legacy Profile will be added to your
                  Resting Wall if their Guardian accepts your request.</p>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>

          <h2>Plans</h2>
          <ngb-accordion [closeOthers]="true" activeIds="faq-wisdom-1">
            <ngb-panel id="faq-wisdom-4" title="What happens to my content if I cancel or cannot pay my Paid Plan?">
              <ng-template ngbPanelContent>
                <p>If your Plan was a paid Plan, we will provide you with a copy of your User Content in a portable
                  format and
                  you may downgrade to a free Plan with limits to the amount of information that can be stored in your
                  Account
                  in accordance with the free Plan plan.</p>
              </ng-template>
            </ngb-panel>

            <ngb-panel id="faq-wisdom-5" title="Can I cancel my Free Account?">
              <ng-template ngbPanelContent>
                <p>You may cancel your Free Account at any time in the Account page / manage Plan page (or similar) of
                  your Account
                  and your content will be deleted.</p>
              </ng-template>
            </ngb-panel>

            <ngb-panel id="faq-wisdom-6" title="What happens to my Paid Plan when I die?">
              <ng-template ngbPanelContent>
                <p>Your Paid Plan will be immediately cancelled when your Guardian transitions your Life Profile to your
                  Legacy Profile.</p>
              </ng-template>
            </ngb-panel>

            <ngb-panel id="faq-wisdom-7" title="Can I pause my Paid Plan?">
              <ng-template ngbPanelContent>
                <p>If you have had a continuous paid Plan for more than 3 years you may choose to pause your Plan. A
                  paused
                  Plan will allow you to log in and view the content in your Account but you will not be able to edit
                  any content
                  in your Account unless you resubscribe to a paid Plan. </p>
              </ng-template>
            </ngb-panel>

            <ngb-panel id="faq-wisdom-8" title="How do I pause my Paid Plan?">
              <ng-template ngbPanelContent>
                <p>You can pause your Plan by selecting the pause account request in your Account settings.</p>
              </ng-template>
            </ngb-panel>

            <ngb-panel id="faq-wisdom-9" title="How do I reactivate a paused Plan?">
              <ng-template ngbPanelContent>
                <p>You can resubscribe via the functionality in your paused Account by selecting an appropriate plan.
                  Your Account must
                  be re-activated at least once every 3 years to stay active. </p>
              </ng-template>
            </ngb-panel>

            <ngb-panel id="faq-wisdom-10" title="How do I claim a promotional Discount?">
              <ng-template ngbPanelContent>
                <p>To claim a promotional discount, you must enter the promotional discount code at the time of
                  subscribing to a
                  Plan or upgrading a Plan, through the Platform. The conditions of use relating to promotional discount
                  codes will be specified on the Platform at the time they are issued. </p>

                <p>
                  For any questions or notices, please contact us at:
                  <br/>My LifeJars Pty Ltd ACN 632 790 526
                  <br/>Email: hello@mylifejars.com
                  <br/>Last update: 15 October 2019
                  <br/>&copy; My LifeJars Pty Ltd
                </p>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>

        </div>
      </div><!--/card -->

    </div>
  </div>

</div><!--/container-->
