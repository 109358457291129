import {AppState} from '../index';
import {createSelector} from '@ngrx/store';

export const selectUsersState = (state: AppState) => state.users;

export const selectAllUsers = createSelector(
  selectUsersState,
  state => Object.values(state.byId)
);

export const selectCurrentUser = createSelector(
  selectUsersState,
  state => state.currentUser
);

export const selectCurrentUserSubscription = createSelector(
  selectUsersState,
  state => state.currentUserSubscription
);

export const selectUsersById = createSelector(
  selectUsersState,
  state => state.byId
);

export const selectUserById = createSelector(
  selectUsersById,
  byId => (id: number) => {
    return byId[id] || null;
  }
);

export const selectUsersByIds = createSelector(
  selectUsersById,
  byId => (ids: string[]) => {
    return Object.keys(byId)
      .filter(key => {
        return ids.indexOf(key) >= 0;
      })
      .reduce((list, key) => {
        list.push(byId[key]);
        return list;
      }, []);
  }
);

export const selectOwnedBios = createSelector(
  selectUsersState,
  state => state.ownedBios
);
