<div *ngIf="isLoading" class="loader loader-content"
     [ngClass]="['loader-content--' + (scope||'empty')]"
     (click)="onClick($event)">
  <div class="loader-content-body">
    <div class="loader-content-body__spinner"></div>
    <div class="loader-content-body__text">
      <ng-content></ng-content>
    </div>
  </div>
</div>
