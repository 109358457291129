import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, concatMap, map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {UserBioActions} from './index';
import {of} from 'rxjs';
import {UserService} from '../../core/services/user.service';
import {Bio} from '../../core/models/bio.model';
import {BioActions} from '../bios';
import {ResponseMessage} from '../../core/models/response-message.model';
import {UserActions} from '../users';
import {AlertService} from '../../shared/components/alert/alert.service';

@Injectable()
export class UserBiosEffects {

  constructor(
    private actions$: Actions,
    private userService: UserService,
    private alertService: AlertService
  ) {
  }

  getUserBiosRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserBioActions.getUserBiosRequest),
      mergeMap(action =>
        this.userService.getUserBios(action.id).pipe(
          map((bios: Bio[]) =>
            UserBioActions.getUserBiosRequestSuccess({
              bios
            })
          ),
          catchError(error => {
            return of(UserBioActions.effectError({error}));
          })
        )
      )
    )
  );

  createUserBioRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserBioActions.createUserBioRequest),
      mergeMap(action =>
        this.userService.createUserBio(action.userBio).pipe(

          tap((responseMessage: ResponseMessage) => {
            this.alertService.info(responseMessage.message);
          }),

          concatMap((responseMessage: ResponseMessage) => [
            UserBioActions.addUserBio({
              bio: responseMessage.data
            }),

            UserActions.incrementCurrentUserBioCounter({
              bio: responseMessage.data
            }),

            BioActions.switchBioRequest({
              slugOrId: responseMessage.data.slug
            }),

            UserBioActions.createUserBioRequestSuccess({
              responseMessage
            })

          ]),
          catchError(error => {
            return of(UserBioActions.effectError({error}));
          })
        )
      )
    )
  );
}
