import {Component, OnInit} from '@angular/core';
import {MemoryEditModalComponent} from '../../memory/edit/memory-edit-modal.component';
import {JarModel} from '../../../../core/models/jar.model';
import {IListOption} from '../../../../core/models/option.model';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder} from '@angular/forms';
import {MemoryService} from '../../../../core/services/memory.service';
import {AppDateFormatter} from '../../../../core/format/date.format';
import {LayoutService} from '../../../../core/services/layout.service';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../store';
import {selectJarTags} from '../../../../store/jars/jars.selectors';
import {first} from 'rxjs/operators';
import {AlertService} from '../../../components/alert/alert.service';

@Component({
  templateUrl: 'thing-edit-modal.component.html'
})
export class ThingEditModalComponent extends MemoryEditModalComponent implements OnInit {

  public get ThingJarTypes(): JarModel[] {
    return this.bio.thingJars;
  }

  // Thing Tags filtered by Jar
  public thingTagsFiltered: IListOption[];

  constructor(
    public activeModal: NgbActiveModal,
    protected formBuilder: FormBuilder,
    protected modalService: NgbModal,
    protected store: Store<AppState>,
    protected memoryService: MemoryService,
    protected dateFormatter: AppDateFormatter,
    protected layoutService: LayoutService,
    protected alertService: AlertService
  ) {
    super(activeModal,
      formBuilder,
      modalService,
      store,
      memoryService,
      dateFormatter,
      layoutService,
      alertService
    );
    this.CONCEPT_KEYWORD = 'Thing';
  }

  public onJarChange(event) {
    const jarId = this.editForm.get('parentNode').value;
    this.thingTagsFiltered = [];
    this.store
      .pipe(
        select(selectJarTags(jarId)),
        first()
      )
      .subscribe((options: IListOption[]) => {
        this.thingTagsFiltered = options;
      });
  }

}
