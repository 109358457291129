<ul *ngIf="list?.length" class="list-tribe list-guardians list-tribe-active">
  <li *ngFor="let item of list" class="list-tribe-item">
    <div class="inset">
      <div class="row">
        <div class="col-9" [routerLink]="[ item.userBio.isLegacy ? '/legacy' : '/profiles', item.userBio.slug]">

          <div class="list-tribe-item-preview mr-3" [appBioTypeClass]="item.userBio.type">
            <app-file-lazy-loader [bio]="item.userBio" [file]="item.userBio.profile"></app-file-lazy-loader>
          </div>

          <div class="list-tribe-item-text">
            <strong>{{ item.userBio.firstName }} {{ item.userBio.lastName }}</strong>
            <app-ico-candle *ngIf="item.bio.isLegacy"></app-ico-candle>
            <br/>

            <small *ngIf="item.status !== 1">
              <fa-icon [icon]="faExclamationCircle"></fa-icon>
              Hasn't accepted yet
              <button class="btn btn-gold btn-sm" type="button" (click)="onResend($event,item)">Resend</button>
            </small>

            <small *ngIf="item.isOwner">
              <fa-icon [icon]="faUserLock"></fa-icon>
              Profile Owner
            </small>

            <small *ngIf="!item.isOwner && item.status === 1">
              <fa-icon [icon]="faUserShield"></fa-icon>
              Memories: <strong><app-user-role-text [role]="item.roles.memoryJars"></app-user-role-text></strong>,
              Things: <strong><app-user-role-text [role]="item.roles.thingsJars"></app-user-role-text></strong>,
              Password: <strong><app-user-role-text [role]="item.roles.passwordJars"></app-user-role-text></strong>
              Life: <strong><app-user-role-text [role]="item.roles.lifeBio"></app-user-role-text></strong>,
              Legacy: <strong><app-user-role-text [role]="item.roles.legacyBio"></app-user-role-text></strong>
            </small>
          </div>
        </div>
        <div class="col-3 text-right">

          <button *ngIf="selectable" type="button" class="btn btn-sm" (click)="onSelect($event, item)">
            <fa-icon [icon]="faPlusCircle"></fa-icon>
          </button>

          <button *ngIf="!item.isOwner && editable" type="button" class="btn btn-sm" (click)="onEdit($event, item)">
            <fa-icon [icon]="faPencilAlt"></fa-icon>
          </button>

          <button *ngIf="!item.isOwner && removable" type="button" class="btn btn-sm" (click)="onRemove($event, item)">
            <fa-icon [icon]="faMinusCircle"></fa-icon>
          </button>

        </div>
      </div>
    </div>
  </li>
</ul>
