import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {AlertService} from '../../../../shared/components/alert/alert.service';
import {BreadcrumbNavItem} from '../../../../shared/components/breadcrumb/breadcrumb.component';

import {
  faSearch,
  faPlusCircle,
  faPencilAlt,
  faCaretRight,
  faLock,
  faEye,
  faClock,
  faTag,
  faCalendar,
  faShare
} from '@fortawesome/free-solid-svg-icons';
import {ThingEditModalComponent} from '../../../../shared/modals/thing/edit/thing-edit-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {IMemory, MemoryModel} from '../../../../core/models/memory.model';
import {IListOption} from '../../../../core/models/option.model';
import {MemoryFilterModel} from '../../../../core/models/memory-filter.model';
import {filter} from 'rxjs/operators';
import {MemorySearchResult} from '../../../../core/models/memory-search-result.model';
import {Bio} from '../../../../core/models/bio.model';
import {JarModel} from '../../../../core/models/jar.model';
import {Subscription} from 'rxjs';
import {FileModel} from '../../../../core/models/file.model';
import {MemoryViewModalComponent} from '../../../../shared/modals/memory/view/memory-view-modal.component';
import {LayoutService} from '../../../../core/services/layout.service';
import {UserSubscription} from '../../../../core/models/user-subscription.model';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../store';
import {OptionsState} from '../../../../store/options/options.state';
import {AppStoreUtils} from '../../../../store/app-store.utils';
import {JarActions, JarSelectors} from '../../../../store/jars';
import {OptionSelectors} from '../../../../store/options';
import {UserSelectors} from '../../../../store/users';
import {BioActions, BioSelectors} from '../../../../store/bios';
import {MemoryActions, MemorySelectors} from '../../../../store/memories';
import {BioUserRole, ModerationStatus, NodeType} from '../../../../core/common/enums';
import {ConfirmPopupComponent} from '../../../../shared/modals/confirm-popup/confirm-popup.component';

@Component({templateUrl: 'thing-jars.component.html'})
export class ThingJarsComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly SEARCH_RESULTS_DEFAULT = 4;
  private readonly subscription: Subscription = new Subscription();

  private debounceTimer;

  public bio: Bio;
  public bioJars: JarModel[];
  public userSubscription: UserSubscription;
  public optionsData: OptionsState;

  public previewThing: MemoryModel;
  public previewThingFile: FileModel;

  public faSearch = faSearch;
  public faPlusCircle = faPlusCircle;
  public faPencilAlt = faPencilAlt;
  public faCaretRight = faCaretRight;
  public faLock = faLock;
  public faEye = faEye;
  public faClock = faClock;
  public faTag = faTag;
  public faCalendar = faCalendar;
  public faShare = faShare;

  public get ThingJarTypes(): JarModel[] {
    return this.bioJars;
  }

  public get ThingTagTypes() {
    return this.optionsData.thingTagTypes;
  }

  public get SecretKeeperTypes() {
    return this.optionsData.secretKeeperTypes;
  }

  public get TimeLockTypes() {
    return this.optionsData.timeLockTypes;
  }

  // Form Values
  public keywords: string;
  public bioJar: JarModel;
  public thingTagTypeFilters: any[] = [];
  public timeLockTypeFilters: any[] = [];
  public secretKeeperTypeFilters: any[] = [];

  // Pagination fields
  public page = 1;
  public results = this.SEARCH_RESULTS_DEFAULT;
  public pageCount = 1;

  // Data from API
  public filters: MemoryFilterModel = new MemoryFilterModel();
  public pagedThings: MemorySearchResult = new MemorySearchResult();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private modalService: NgbModal,
    private alertService: AlertService,
    private layoutService: LayoutService
  ) {

    this.layoutService.setTitle(`Thing Jars - My LifeJars`);

    this.optionsData = new OptionsState();
    this.subscription.add(this.store
      .pipe(
        select(OptionSelectors.selectOptions)
      )
      .subscribe((options: OptionsState) => {
        this.optionsData = options;
      })
    );

    this.subscription.add(this.store
      .pipe(
        select(UserSelectors.selectCurrentUserSubscription)
      )
      .subscribe((userSub: UserSubscription) => {
        this.userSubscription = userSub;
      })
    );

    this.subscription.add(this.store
      .pipe(
        select(BioSelectors.selectCurrentBio)
      )
      .subscribe((bio: Bio) => {
        this.bio = bio;
        this.store.dispatch(
          BioActions.setDisplayBioRequest({bio})
        );
      })
    );

    this.subscription.add(this.store
      .pipe(
        select(JarSelectors.selectCurrentJar),
        filter((jar: JarModel) => jar != null && jar.nodeType === NodeType.ThingJar)
      )
      .subscribe((jar: JarModel) => {
        this.bioJar = jar;
        this.store.dispatch(MemoryActions.resetSearchMemoriesRequest());
        this.resetFilters();
      })
    );

    this.bioJars = [];
    this.subscription.add(this.store
      .pipe(
        select(JarSelectors.selectThingJars),
        filter((jars: JarModel[]) => jars.length > 0)
      )
      .subscribe((jars: JarModel[]) => {
        this.bioJars = jars;

        if (this.bioJar == null || this.bioJar.nodeType !== NodeType.ThingJar ||  this.bioJar.bio !== this.bio.id) {
          this.store.dispatch(
            JarActions.setCurrentJarId({
              id: this.bioJars[0]?.id
            })
          );
        }
      })
    );

    this.subscription.add(this.store
      .pipe(select(MemorySelectors.selectMemorySearchResult))
      .subscribe((searchResults: MemorySearchResult) => {
        this.pagedThings = searchResults;
      })
    );

    this.subscription.add(this.store
      .pipe(
        select(MemorySelectors.selectCurrentMemory),
        filter((thing: MemoryModel) => thing != null)
      )
      .subscribe((thing: MemoryModel) => {
        this.previewThing = thing;
        this.previewThingFile = thing
          ? thing.files[0]
          : null;
      })
    );

  }

  public ngOnInit(): void {
    this.subscription.add(
      this.route.params.subscribe(params => {
        this.keywords = params.keywords || '';
        if (this.keywords) {
          this.page = 1;
          this.search();
        }
      })
    );
  }

  ngAfterViewInit(): void {
    this.layoutService.showTutorial('thingsJarsTutorial');
  }

  public setPreviewThing(thing: MemoryModel) {
    this.store.dispatch(
      MemoryActions.setCurrentMemoryId({
        id: thing.id
      })
    );
  }

  public setPreviewThingFile(file: FileModel) {
    this.previewThingFile = file;
  }

  // ThingJar Change Callback
  public onBioJarChange() {
    this.store.dispatch(
      JarActions.setCurrentJarId({
        id: this.bioJar.id
      })
    );
  }

  // Pagination callback
  public onPageChange(page: number) {
    this.page = page;
    this.search();
  }

  // Ensure that the API isn't hammered when checkboxes are being selected
  public queueSearch() {
    if (typeof window !== 'undefined') {
      window.clearTimeout(this.debounceTimer);
      this.debounceTimer = window.setTimeout(() => {
        this.page = 1;
        this.search();
      }, 1000);
    }
  }

  public resetFilters() {

    // Pagination
    this.keywords = '';
    this.page = 1;
    this.results = this.SEARCH_RESULTS_DEFAULT;
    this.pageCount = 1;

    // Build Filter Models
    this.thingTagTypeFilters = [];
    const thingTagsFiltered = AppStoreUtils.filterSubTypes(this.ThingTagTypes, this.bioJar.jarTag);
    thingTagsFiltered.forEach((item: IListOption) => {
      this.thingTagTypeFilters.push({
        item, selected: false
      });
    });

    this.timeLockTypeFilters = [];
    this.TimeLockTypes.forEach((item: IListOption) => {
      this.timeLockTypeFilters.push({
        item, selected: false
      });
    });

    this.secretKeeperTypeFilters = [];
    this.SecretKeeperTypes.forEach((item: IListOption) => {
      this.secretKeeperTypeFilters.push({
        item, selected: false
      });
    });

    // Clear the Preview Thing
    this.previewThing = null;
    this.previewThingFile = null;

    // Obviously we should also update the search
    this.search();
  }

  public search() {

    this.filters = new MemoryFilterModel();
    this.filters.keywords = this.keywords;
    this.filters.page = this.page;
    this.filters.results = this.results;

    // Set the Bio ID
    this.filters.bio = this.bio.id;

    // Set the Memory Jar ID
    this.filters.parentNodes = [this.bioJar.id];

    // Convert Selected Form Values to ID array
    this.thingTagTypeFilters.forEach((item: any) => {
      if (item.selected) {
        this.filters.memoryTagTypes.push(item.item.id);
      }
    });

    this.timeLockTypeFilters.forEach((item: any) => {
      if (item.selected) {
        this.filters.timeLockTypes.push(item.item.id);
      }
    });

    this.secretKeeperTypeFilters.forEach((item: any) => {
      if (item.selected) {
        this.filters.secretKeeperTypes.push(item.item.id);
      }
    });

    this.store.dispatch(
      MemoryActions.searchMemoriesRequest({
        filters: this.filters,
        setFirstAsCurrent: true
      })
    );

  }

  public openThingModal(thing?: IMemory) {

    // Prevent adding Things that are past the limit
    // 0: Unlimited
    if (thing == null && this.userSubscription.things > 0 && this.userSubscription.things <= this.bio.things) {
      return this.layoutService.showPlanUpgrade();
    }

    // Prepare the Memory
    if (thing != null) {

      // Edit existing Memory So prepare the store
      this.store.dispatch(
        MemoryActions.setCurrentMemoryId({id: thing.id})
      );
    } else {

      // Create a new Memory
      thing = new MemoryModel();
      thing.bio = this.bio.id;
      thing.parentNode = this.bioJar.id;
    }

    const modal = this.modalService.open(ThingEditModalComponent, {size: 'lg', backdrop: 'static'});
    modal.componentInstance.bio = this.bio;
    modal.componentInstance.memory = thing;
    modal.result.then((editedThing: MemoryModel) => {

      if (editedThing) {

        this.store.dispatch(
          MemoryActions.setCurrentMemoryId({id: editedThing.id})
        );

        // Set the Jar to match the new Thing
        if (this.bioJar.id !== editedThing.parentNode) {
          this.bioJar = Bio.getJarById(editedThing.parentNode, this.bioJars);
          this.onBioJarChange();
        }
      }

    }, (reason) => {
      console.log(reason);
    });

  }

  public viewThing(thing: IMemory) {
    this.store.dispatch(
      MemoryActions.setCurrentMemoryId({id: thing.id})
    );
    const modal = this.modalService.open(MemoryViewModalComponent, {size: 'lg'});
    modal.componentInstance.bio = this.bio;
  }

  public showLightbox(memoryOrJar: IMemory, file: FileModel) {
    this.layoutService.showLightbox(memoryOrJar, file);
  }

  public canEdit() {
    return this.bio && (this.bio.permissions.thingsJars === BioUserRole.FullAccess || this.bio.isOwner === true);
  }


  public removeShared(thing: IMemory) {
    const modal = this.modalService.open(ConfirmPopupComponent, {size: 'sm', backdrop: 'static'});
    modal.componentInstance.title = 'Remove shared thing';
    modal.componentInstance.message = 'Are you sure you want to remove this shared thing?';
    modal.componentInstance.classes = 'danger';
    modal.result.then((result) => {
      this.store.dispatch(
        MemoryActions.deleteMemoryRequest({
          memory: thing
        })
      );
    }, (reason) => {
    });
  }


  public ngOnDestroy() {
    this.subscription.unsubscribe();
    this.store.dispatch(MemoryActions.resetSearchMemoriesRequest());
  }

}
