import {Component, Input, OnInit} from '@angular/core';

import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store';
import {UserActions} from '../../../store/users';

@Component({
  templateUrl: 'tutorial-modal.component.html'
})
export class TutorialModalComponent implements OnInit {
  @Input()
  public title: string;

  @Input()
  public videoSrc: string;

  @Input()
  public tutorialRef: string;

  // Form Field
  public dontShowAgain: boolean;

  constructor(
    public activeModal: NgbActiveModal,
    protected store: Store<AppState>,
    protected modalService: NgbModal
  ) {
    this.title = '';
    this.videoSrc = '';
    this.tutorialRef = '';
    this.dontShowAgain = false;
  }

  public ngOnInit(): void {

  }

  public dismiss(event) {
    if (this.dontShowAgain && this.tutorialRef) {
      const metaData = {};
      metaData[this.tutorialRef] = true;

      this.store.dispatch(
        UserActions.saveUserMetaDataRequest({
          metaData
        })
      );

    }
    this.activeModal.dismiss(false);
  }

}
