
<div class="bg bg-cover"></div>

<div class="card card-bio card-bio-login">

  <div class="card-body">

    <div class="card-body-title text-center">
      <h2 class="text-gold font-weight-bold pb-3 mb-3">Welcome</h2>
      <p>Sign in to get started.</p>
    </div>

    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">

      <div class="form-group">
        <label class="control-label sr-only">Username</label>
        <input autocomplete="on" type="text" formControlName="username" class="form-control form-control-lg input-bio"
               [ngClass]="{ 'is-invalid': submitted && f.username.errors }" placeholder="Email" aria-label="Email" />
        <app-control-validation [control]="f.username" [submitted]="submitted"></app-control-validation>
      </div>

      <div class="form-group">
        <label class="control-label sr-only">Password</label>
        <input autocomplete="off" type="password" formControlName="password" class="form-control form-control-lg input-bio"
               [ngClass]="{ 'is-invalid': submitted && f.password.errors }" placeholder="Password" aria-label="Password"/>
        <app-control-validation [control]="f.password" [submitted]="submitted"></app-control-validation>
      </div>

      <div class="form-group small">
        <div class="row">
          <div class="col-4 text-left">

          </div>
          <div class="col-8 text-right">
            <a routerLink="/password-reset">Forgotten password?</a>
          </div>
        </div>
      </div>

      <div class="form-group">
        <button [disabled]="loading" class="btn btn-lg btn-block btn-gold">
          Sign in
        </button>
      </div>

      <div class="form-group small x-small">
        <div class="row">
          <div class="col-12">
            By submitting this form, you agree to our
            <a href="{{showTerms()}}" target="_blank"><strong>Terms</strong></a> and that you have read
            our <a href="{{showPrivacy()}}" target="_blank"><strong>Privacy Policy</strong></a>.
          </div>
        </div>
      </div>

      <div class="form-group small">
        <div class="row">
          <div class="col-12 text-center">
            New to My LifeJars? <a class="text-gold font-weight-bold" routerLink="/create-account">Sign up &raquo;</a>
          </div>
        </div>
      </div>

    </form>

    <app-content-loader></app-content-loader>
  </div>
</div>
