import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Bio} from '../../../core/models/bio.model';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ControlValidationService} from '../../components/control-validation/control-validation.service';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../store';
import {TribeActions} from '../../../store/tribe';
import {OptionsState} from '../../../store/options/options.state';
import {selectOptions, selectRelationshipSubTypeByType, selectRelationshipTypes} from '../../../store/options/options.selectors';
import {Subscription} from 'rxjs';
import {first} from 'rxjs/operators';
import {IListOption} from '../../../core/models/option.model';

@Component({
  templateUrl: 'friend-invite-modal.component.html'
})
export class FriendInviteModalComponent implements OnInit, OnDestroy {
  private readonly subscription: Subscription;

  @Input() public bio: Bio;

  public editForm: FormGroup;
  public submitted = false;

  public relationshipTypes: IListOption[];
  public relationshipSubTypesFiltered: IListOption[];

  // convenience getter for easy access to form fields
  public get f() { return this.editForm.controls; }

  constructor(
    public activeModal: NgbActiveModal,
    protected modalService: NgbModal,
    protected formBuilder: FormBuilder,
    protected store: Store<AppState>,
  ) {
    this.subscription = new Subscription();

    this.relationshipTypes = [];
    this.subscription.add(this.store.pipe(select(selectRelationshipTypes))
      .subscribe((options: IListOption[]) => {
        this.relationshipTypes = options;
      })
    );
  }

  public ngOnInit() {
    this.editForm = this.formBuilder.group({
      email: ['', [Validators.required, ControlValidationService.emailValidator] ],
      firstName: ['', Validators.required ],
      lastName: ['', Validators.required ],
      relationshipType: [null, Validators.required],
      relationshipSubType: [null, Validators.required],
    });
  }

  public onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.editForm.invalid) {
      return;
    }

    const model = this.editForm.getRawValue();
    this.store.dispatch(
      TribeActions.inviteFriendRequest({model, fromBio: this.bio})
    );

    this.activeModal.close(model);
  }

  public onRelationshipChange(event) {
    const relationshipType = this.editForm.get('relationshipType').value;
    this.relationshipSubTypesFiltered = [];
    this.store
      .pipe(
        select(selectRelationshipSubTypeByType(relationshipType)),
        first()
      )
      .subscribe((options: IListOption[]) => {
        this.relationshipSubTypesFiltered = options;
      });
  }

  public onRelationshipSubChange(event) {
    const id = this.editForm.get('relationshipSubType').value;
    if (id === -1) {
      this.editForm.addControl('relationshipOther', new FormControl('', Validators.required));
    } else {
      this.editForm.removeControl('relationshipOther');
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
