
export class PasswordModel {
  public id: number;
  public url: string;
  public username: string;
  public password: string;
  public email: string;
  public mobile: string;
  public accountId: string;

  constructor() {
    this.id = null;
    this.url = '';
    this.username = '';
    this.password = '';
    this.email = '';
    this.mobile = '';
    this.accountId = '';
  }

  public static make(obj: object): PasswordModel {
    if ( !obj || (Object.keys(obj).length === 0) ) {
      return null; // Object is null or empty
    }
    return {...new PasswordModel(), ...obj};
  }

}
