import {createAction, props} from '@ngrx/store';
import {Bio} from '../../core/models/bio.model';
import {UserBio} from '../../core/models/bio-ward.model';
import {ResponseMessage} from '../../core/models/response-message.model';

export const getUserBiosRequest = createAction(
  '[UserBios] get user bios request',
  props<{ id: number }>()
);

export const getUserBiosRequestSuccess = createAction(
  '[UserBios] get user bios request success',
  props<{ bios: Bio[] }>()
);

export const createUserBioRequest = createAction(
  '[UserBios] create user bio request',
  props<{ userBio: UserBio }>()
);

export const createUserBioRequestSuccess = createAction(
  '[UserBios] create user bio request success',
  props<{ responseMessage: ResponseMessage }>()
);

export const addUserBio = createAction(
  '[UserBios] add user bio',
  props<{ bio: Bio }>()
);

export const deleteUserBio = createAction(
  '[UserBios] delete user bio',
  props<{ bio: Bio }>()
);

export const effectError = createAction(
  '[UserBios] effect error',
  props<{ error: any }>()
);
