
<ng-template #headerControls>
  <div class="block block-btns text-right mt-sm-2">
    <button class="btn btn-gold" type="button" (click)="openGuardianSearchModal($event)">
      <fa-icon [icon]="faPlusCircle"></fa-icon>
      Invite <span class="d-none d-sm-inline">a Guardian</span>
    </button>
  </div>
</ng-template>

<app-page-header title="Guardians" [controls]="headerControls"></app-page-header>

<div class="container">
  <div class="row">
    <div class="col-12">
      <div *ngIf="bioUsers" class="tribe-active-container mt-4">
        <app-guardian-list [list]="bioUsers"
                           [editable]="true"
                           (edit)="openGuardianPermissionsModal($event)"
                           [removable]="true"
                           (remove)="removeGuardian($event)"></app-guardian-list>
      </div>
    </div>

    


    <div class="col-12">
      <h2 class="mt-5">Message to Guardians</h2>
      <hr/>
      <div class="form-group mt-4">
        
        <textarea class="form-control" id="guardianNotes" rows="3" [(ngModel)]="guardianNotes"></textarea>
      </div>
      <div class="block block-btns text-right mt-sm-2">
          <button class="btn btn-gold" type="button" (click)="updateGuardianNotes()">
            Update Notes
          </button>
      </div>
     
    </div>
  
  </div>

</div><!--/container-->
