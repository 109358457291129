import {Bio} from '../../core/models/bio.model';

export class BiosState {
  displayBio: Bio;
  currentBio: Bio;
  biosBySlug: BiosBySlug;
  biosById: BiosById;
}

export const initialBioState: BiosState = {
  displayBio: null,
  currentBio: null,
  biosBySlug: {},
  biosById: {},
};

export class BiosBySlug {
  [slug: string]: Bio;
}

export class BiosById {
  [id: number]: Bio;
}
