
export class MemoryFilterModel {
  public static readonly SortDate = 'sort_date_asc';
  public static readonly SortDateDesc = 'sort_date_desc';
  public static readonly SortTitle = 'sort_name';
  public static readonly SortTitleDesc = 'sort_name_desc';

  // Bio ID
  public bio = 0;

  // Memory Jar ID
  public parentNodes: number[] = [];
  public includeParents = false;

  public memoryTagTypes: number[] = [];
  public secretKeeperTypes: number[] = [];
  public timeLockTypes: number[] = [];
  public fileTypes: string[] = [];

  public page = 1;
  public results = 10;
  public keywords = '';

  public orderBy = '';
}
