import {FileModel} from './file.model';
import {IMemory} from './memory.model';
import {NodeType} from '../common/enums';

export class JarModel implements IMemory {
  public id: number;
  public nodeType: NodeType;
  public title: string;
  public placeholder: string;
  public jarTag: number;
  public memoryTag: number;
  public secretKeeper: number;
  public timeLock: number;
  public isReference: boolean;
  public isDefault: boolean;
  public files: FileModel[];

  public sortIndex: number;
  public bio: number;
  public description: string;

  // Indicates a pre-loaded memory/Thing
  public isSample: boolean;

  // Jar Icon and Banner
  public icon: FileModel;
  public banner: FileModel;

  constructor() {
    this.id = null;
    this.sortIndex = 0;
    this.nodeType = 0;
    this.jarTag = 0;
    this.bio = null;
    this.title = '';
    this.description = '';
    this.placeholder = '';
    this.files = [];
    this.isDefault = false;
    this.isSample = false;

    this.icon = null;
    this.banner = null;
  }

  public static make(obj: object): JarModel {
    if ( !obj || (Object.keys(obj).length === 0) ) {
      return null; // Object is null or empty
    }
    return {...new JarModel(), ...obj};
  }
}
