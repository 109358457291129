import {initialJarState, JarsState} from './jars.state';
import {Action, createReducer, on} from '@ngrx/store';
import {JarActions} from './index';
import {JarModel} from '../../core/models/jar.model';
import {AppStoreUtils} from '../app-store.utils';

const mergeJarsWithState = (state: JarsState, jars: JarModel[]) => {
  const byId = {};
  for (const jar of jars) {
    byId[jar.id] = jar;
  }
  return {
    ...state,
    byId: {
      ...state.byId,
      ...byId
    }
  };
};

const reducer = createReducer<JarsState>(
  initialJarState,

  on(JarActions.setCurrentJarId, (state, {id}) => {
    return {
      ...state,
      currentJarId: id
    };
  }),

  on(JarActions.setJarsRequest, (state, {jars}) => {
    return mergeJarsWithState({...state, byId: {}}, jars);
  }),

  on(JarActions.getJarRequestSuccess, (state, {jar}) => {
    return mergeJarsWithState(state, [jar]);
  }),

  on(JarActions.getJarRequestSuccess, (state, {jar}) => {
    return mergeJarsWithState(state, [jar]);
  }),

  on(JarActions.saveJarRequestSuccess, (state, {responseMessage}) => {
    const jar: JarModel = responseMessage.data;
    return mergeJarsWithState(state, [jar]);
  }),

  on(JarActions.deleteJarFileRequest, (state, {jar, file}) => {
    const idx = jar.files.map(j => j.id).indexOf(file.id);
    return {
      ...state,
      byId: {
        ...state.byId,
        ...{
          [jar.id]: {
            ...state.byId[jar.id],
            ...{
              files: [
                ...jar.files.slice(0, idx),
                ...jar.files.slice(idx + 1),
              ]
            }
          }
        }
      }
    };
  }),

  on(JarActions.saveJarFileRequestSuccess, (state, {jar, responseMessage}) => {
    const file = responseMessage.data || null;
    if ( file == null ){
      return state;
    }
    const idx = jar.files.map(j => j.id).indexOf(file.id);
    return {
      ...state,
      byId: {
        ...state.byId,
        ...{
          [jar.id]: {
            ...state.byId[jar.id],
            ...{
              files: [
                ...jar.files.slice(0, idx),
                {
                  ...jar.files[idx],
                  ...file
                },
                ...jar.files.slice(idx + 1),
              ]
            }
          }
        }
      }
    };
  }),

  on(JarActions.sortJarFilesRequest, (state, {jar, fileIds}) => {
    const fileList = AppStoreUtils.sortFileListByIds(jar.files, fileIds);
    return {
      ...state,
      byId: {
        ...state.byId,
        ...{
          [jar.id]: {
            ...state.byId[jar.id],
            ...{
              files: fileList
            }
          }
        }
      }
    };
  }),

  on(JarActions.uploadJarFilesRequestSuccess, (state, {jar, files}) => {
    if (jar == null || state.byId[jar.id] == null) {
      throw new Error('Jar doesn\'t exist in the store.');
    }
    return {
      ...state,
      byId: {
        ...state.byId,
        ...{
          [jar.id]: {
            ...state.byId[jar.id],
            ...{
              files: [
                ...state.byId[jar.id].files,
                ...files
              ]
            }
          }
        }
      }
    };
  })
);

export function JarsReducer(state: JarsState, action: Action) {
  return reducer(state, action);
}
