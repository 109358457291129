import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable, Subscription} from 'rxjs';

import {Bio} from '../models/bio.model';
import {ResponseMessage} from '../models/response-message.model';
import {AppDateFormatter} from '../format/date.format';
import {BioUser} from '../models/bio-user.model';
import {AppConfig} from './app-config.service';


@Injectable({providedIn: 'root'})
export class BioUserService implements OnDestroy {
  private readonly subscription: Subscription;

  constructor(
    protected http: HttpClient,
    protected dateFormatter: AppDateFormatter
  ) {
    this.subscription = new Subscription();
  }

  public getBioUsers(slugOrId?: string | number): Observable<BioUser[]> {

    let url = `${AppConfig.settings.apiBaseUrl}/bio/users`;
    if (slugOrId != null) {
      url = `${AppConfig.settings.apiBaseUrl}/bio/${slugOrId}/users`;
    }
    return this.http.get<BioUser[]>(url);
  }

  public getBioUser(id: number): Observable<BioUser> {
    return this.http.get<BioUser>(`${AppConfig.settings.apiBaseUrl}/bio/user/${id}`);
  }

  public saveBioUser(bioUser: BioUser): Observable<ResponseMessage> {
    return this.http.post<ResponseMessage>(`${AppConfig.settings.apiBaseUrl}/bio/${bioUser.bio.id}/user/${bioUser.user.id}/save`, {
      bio: bioUser.bio.id,
      user: bioUser.user.id,
      memoryJars: bioUser.roles.memoryJars,
      thingsJars: bioUser.roles.thingsJars,
      passwordJars: bioUser.roles.passwordJars,
      lifeBio: bioUser.roles.lifeBio,
      legacyBio: bioUser.roles.legacyBio,
    });
  }

  public resendBioUserInvite(bioUser: BioUser) {
    return this.http.post<ResponseMessage>(
      `${AppConfig.settings.apiBaseUrl}/bio/${bioUser.bio.id}/user/${bioUser.user.id}/resend-invite`,
      {}
    );
  }

  public deleteBioUser(bioUser: BioUser): Observable<ResponseMessage> {
    return this.http.delete<ResponseMessage>(`${AppConfig.settings.apiBaseUrl}/bio/${bioUser.bio.id}/user/${bioUser.user.id}/remove`);
  }

  public transferBioOwnership(bio: Bio, bioUser: BioUser): Observable<ResponseMessage> {
    return this.http.patch<ResponseMessage>(`${AppConfig.settings.apiBaseUrl}/bio/${bio.id}/user/${bioUser.user.id}/transfer`, {
      user: bioUser.user.id
    });
  }

  public acceptGuardianRequest(bioId: number): Observable<ResponseMessage> {
    return this.http.post<ResponseMessage>(`${AppConfig.settings.apiBaseUrl}/bio/user/accept`, {
      id: bioId
    });
  }

  public cancelGuardianRequest(bioId: number): Observable<ResponseMessage> {
    return this.http.post<ResponseMessage>(`${AppConfig.settings.apiBaseUrl}/bio/user/cancel`, {
      id: bioId
    });
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
