import {User} from './user.model';
import {Bio} from './bio.model';

export class BioUser {
  public id: number;
  public bio: Bio;
  public user: User;
  public userBio: Bio;
  public roles: any;
  public status: number;
  public statusLabel: string;
  public message: string;
  public isOwner: boolean;

  constructor() {
    this.id = null;
    this.bio = null;
    this.user = null;
    this.userBio = null;
    this.status = null;
    this.statusLabel = '';
    this.message = '';
    this.isOwner = false;

    this.roles = {
      memoryJars: 0, // 0 = NoAccess, 1 = ReadOnly, 2 = FullAccess
      thingsJars: 0,
      passwordJars: 0,
      lifeBio: 0,
      legacyBio: 0
    };
  }

  public static make(obj: object): BioUser {
    if (!obj || (Object.keys(obj).length === 0)) {
      return null; // Object is null or empty
    }
    return {...new BioUser(), ...obj};
  }

}
