import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {
  faSearch,
  faPlusCircle,
  faMinusCircle,
  faMapMarkerAlt,
  faBook
} from '@fortawesome/free-solid-svg-icons';

import {Bio} from '../../../../core/models/bio.model';
import {filter, map} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../store';
import {BioActions, BioSelectors} from '../../../../store/bios';
import {LayoutService} from '../../../../core/services/layout.service';

@Component({
  templateUrl: 'legacy-view.component.html',
  styleUrls: ['legacy-view.component.css']
})
export class PublicLegacyViewComponent implements OnInit, OnDestroy {
  private readonly subscription: Subscription;

  public bio: Bio;

  public faPlusCircle = faPlusCircle;
  public faMinusCircle = faMinusCircle;
  public faSearch = faSearch;
  public faMapMarkerAlt = faMapMarkerAlt;
  public faBook = faBook;

  // Active Bio Slug
  private slug: string;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected store: Store<AppState>,
    protected layoutService: LayoutService
  ) {
    this.bio = null;

    this.subscription = new Subscription();

    this.subscription.add(this.store
      .pipe(
        select(BioSelectors.selectDisplayBio),
        filter((bio: Bio) => bio != null)
      )
      .subscribe((bio: Bio) => {
        this.bio = bio;
        this.layoutService.setTitle(`The legacy of ${this.bio.firstName} ${this.bio.lastName} - My LifeJars`);
      })
    );
  }

  public ngOnInit() {
    this.subscription.add(
      this.route.params.subscribe(params => {
        this.slug = params.slug || null;

        this.store.dispatch(
          BioActions.getPublicBioRequest({
            slugOrId: this.slug,
            setAsDisplay: true
          })
        );

      })
    );
  }


  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
