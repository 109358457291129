import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Bio} from '../../../core/models/bio.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ControlValidationService} from '../../components/control-validation/control-validation.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store';
import {TribeActions} from '../../../store/tribe';
import {MemoryModel} from '../../../core/models/memory.model';
import {FileModel} from '../../../core/models/file.model';
import {MemoryActions} from '../../../store/memories';
import {JarModel} from '../../../core/models/jar.model';
import {JarActions} from '../../../store/jars';

@Component({
  templateUrl: 'file-edit-modal.component.html'
})
export class FileEditModalComponent implements OnInit {
  @Input() public bio: Bio;
  @Input() public memory: MemoryModel;
  @Input() public jar: JarModel;
  @Input() public file: FileModel;

  public editForm: FormGroup;
  public submitted = false;

  // convenience getter for easy access to form fields
  public get f() { return this.editForm.controls; }

  constructor(
    public activeModal: NgbActiveModal,
    protected modalService: NgbModal,
    protected formBuilder: FormBuilder,
    protected store: Store<AppState>,
  ) {

  }

  public ngOnInit() {
    if ( this.jar != null && this.memory != null ){
      throw new Error('File Edit Modal only supports editing a single Jar or Memory/Thing.');
    }

    this.editForm = this.formBuilder.group({
      title: ['', [Validators.required] ]
    });
    this.editForm.patchValue({title : this.file && this.file.title ? this.file.title : ''});
  }

  public onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.editForm.invalid) {
      return;
    }

    const model = { ...this.file, ...this.editForm.getRawValue() };

    if ( this.jar != null ){
      this.store.dispatch(
        JarActions.saveJarFileRequest({jar: this.jar, file: model})
      );
    } else {
      this.store.dispatch(
        MemoryActions.saveMemoryFileMetaRequest({memory: this.memory, file: model})
      );
    }

    this.activeModal.close(model);
  }

}
