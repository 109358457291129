import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Bio} from '../../../core/models/bio.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {OptionsService} from '../../../core/services/options.service';
import {BioUser} from '../../../core/models/bio-user.model';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store';
import {BioUserActions} from '../../../store/bio-users';

@Component({
  templateUrl: 'bio-user-roles-modal.component.html'
})
export class BioUserRolesModalComponent implements OnInit {
  @Input() public bioUser: BioUser;
  @Input() public bio: Bio;
  public editForm: FormGroup;
  public submitted = false;

  // convenience getter for easy access to form fields
  public get f() {
    return this.editForm.controls;
  }

  public get UserPermissions() {
    return this.optionsService.BioUserRoles;
  }

  constructor(
    public activeModal: NgbActiveModal,
    protected modalService: NgbModal,
    protected formBuilder: FormBuilder,
    protected store: Store<AppState>,
    protected optionsService: OptionsService
  ) {
  }

  public ngOnInit() {

    this.editForm = this.formBuilder.group({
      memoryJars: [0, Validators.required],
      thingsJars: [0, Validators.required],
      passwordJars: [0, Validators.required],
      lifeBio: [0, Validators.required],
      legacyBio: [0, Validators.required]
    });

    this.editForm.patchValue(this.bioUser.roles);
  }

  public onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.editForm.invalid) {
      return;
    }

    // Build Final BioUser Model
    const model = {...this.bioUser, ...{ roles: this.editForm.getRawValue()}};
    this.store.dispatch(
      BioUserActions.saveBioUserRequest({bioUser: model})
    );
    this.activeModal.close( this.bioUser );
  }

}
