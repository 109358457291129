import {Component, Input, OnInit} from '@angular/core';
import {MemoryModel} from '../../../../core/models/memory.model';

@Component({
  selector: 'app-bio-print-page-text',
  templateUrl: 'bio-print-page-text.component.html'
})
export class BioPrintPageTextComponent implements OnInit {

  @Input()
  public page: number;

  @Input()
  public textLeft: string;

  @Input()
  public textRight: string;

  @Input()
  public memory: MemoryModel;

  constructor() {
    this.memory = new MemoryModel();
  }

  ngOnInit(): void {
  }

}
