import {AppState} from '../index';
import {createSelector} from '@ngrx/store';

export const selectBioUsersState = (state: AppState) => state.bioUsers;

export const selectCurrentBioUserId = createSelector(
  selectBioUsersState,
  state => state.currentBioUserId
);

export const selectCurrentBioUser = createSelector(
  selectBioUsersState,
  selectCurrentBioUserId,
  (state, id) => state.byId[id] || null
);

export const selectBioUsers = createSelector(
  selectBioUsersState,
  state => Object.keys(state.byId).map(key => state.byId[key])
    .filter( (bu) => bu != null )
);

export const selectBioUserById = (id: number) => createSelector(
  selectBioUsersState,
  state => {
    return state.byId[id] || null;
  }
);
