import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {AuthService} from '../services/auth.service';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

  constructor(
    protected router: Router,
    protected authService: AuthService
  ) {

  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>|boolean {
    // Simple local test
    if ( this.authService.hasAuthenticated() ) {
      return true;
    }

    // Complex remote server test
    return this.authService.hasOpenServerSession().pipe(map(user => {
      if ( user ) {
        return true;
      }
    }),
      catchError((err: HttpErrorResponse) => {
        this.router.navigate(['/login']);
        return of(false);
      }) );
  }
}
