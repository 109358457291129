import {Component, OnInit} from '@angular/core';
import { Validators} from '@angular/forms';

import {MemoryDefaultModalComponent} from '../memory-default/memory-default-modal.component';

@Component({
  templateUrl: '../memory-default/memory-default-modal.component.html'
})
export class PasswordDefaultModalComponent extends MemoryDefaultModalComponent implements OnInit{
  public CONCEPT_KEYWORD = 'Password';

  public get JarTypes() {
    return this.optionsData.passwordJarTypes;
  }

  public ngOnInit() {
    this.editForm = this.formBuilder.group({
      title: ['', Validators.required],
      jarTag: [null, Validators.required],
      secretKeeper: [null, Validators.required],
      timeLock: [null, Validators.required],
      placeholder: ['']
    });
    this.editForm.patchValue(this.memory);
  }
}
