import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BioFilterModel} from '../models/bio-filter.model';
import {AppConfig} from './app-config.service';
import {SearchResult} from '../models/search-result.model';
import {ResponseMessage} from '../models/response-message.model';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class SearchService {

  constructor(private http: HttpClient) {}

  public search(filters: BioFilterModel): Observable<SearchResult[]> {
    const params = new HttpParams()
      .append('keywords', filters.keywords || '');
    return this.http.get<ResponseMessage>(`${AppConfig.settings.apiBaseUrl}/search`, {params})
      .pipe(map((response: ResponseMessage) => response.data as SearchResult[]));
  }

}
