import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {AlertService} from '../../../../shared/components/alert/alert.service';
import {BreadcrumbNavItem} from '../../../../shared/components/breadcrumb/breadcrumb.component';

import {faSearch, faPlusCircle, faPencilAlt, faCaretRight} from '@fortawesome/free-solid-svg-icons';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {BioService} from '../../../../core/services/bio.service';
import {Bio} from '../../../../core/models/bio.model';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {selectCurrentBio} from '../../../../store/bios/bios.selectors';
import {AppState} from '../../../../store';
import {LayoutService} from '../../../../core/services/layout.service';
import {selectUserBios} from '../../../../store/user-bios/user-bios.selectors';
import {BioActions} from '../../../../store/bios';
import {Actions, ofType} from '@ngrx/effects';
import {WardFormModalComponent} from '../../../../shared/modals/ward-form/ward-form-modal.component';

@Component({templateUrl: 'wards.component.html'})
export class WardsComponent implements AfterViewInit, OnDestroy {
  private readonly subscription: Subscription;

  public bio: Bio;
  public userBios: Bio[];

  public faSearch = faSearch;
  public faPlusCircle = faPlusCircle;
  public faPencilAlt = faPencilAlt;
  public faCaretRight = faCaretRight;

  constructor(
    protected router: Router,
    protected store: Store<AppState>,
    private actions: Actions,
    protected modalService: NgbModal,
    protected alertService: AlertService,
    protected bioService: BioService,
    protected layoutService: LayoutService
  ) {

    this.layoutService.setTitle( `Guardians - My LifeJars`  );

    this.subscription = new Subscription();

    this.subscription.add(this.store
      .pipe(
        select(selectCurrentBio),
        filter( (bio: Bio) => bio != null )
      )
      .subscribe((bio: Bio) => {
        this.bio = bio;
      })
    );

    this.userBios = []; //  aka Wards
    this.subscription.add(this.store.pipe(select(selectUserBios), filter( b => b != null))
      .subscribe((bios: Bio[]) => {
        this.userBios = bios.filter(b => b.isDefault === false); // Exclude the Default Bio
      })
    );

  }

  public ngAfterViewInit(): void {
    //this.layoutService.showTutorial('guardiansTutorial');
  }

  public showWardModal(event) {
    const modal = this.modalService.open(WardFormModalComponent, {backdrop: 'static'});
  }

  public switchBio(bio: Bio) {
    this.subscription.add( this.actions
      .pipe(
        ofType(BioActions.switchBioRequestSuccess),
        select( result => result.bio )
      )
      .subscribe((data) => {
        this.router.navigate(['/profiles/edit']);
      })
    );

    this.store.dispatch(
      BioActions.switchBioRequest({
        slugOrId: bio.slug // Current Bio
      })
    );
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
