import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AppPipesModule} from '../../pipes/app-pipes.module';
import {FileLazyLoaderComponent} from '../../../shared/components/file-uploader/file-lazy-loader/file-lazy-loader.component';
import {FileUploaderComponent} from './file-uploader.component';
import {FileUploaderThumbComponent} from './file-uploader-thumb/file-uploader-thumb.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {SortablejsModule} from 'ngx-sortablejs';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    imports: [
        CommonModule,
        FontAwesomeModule,
        SortablejsModule,
        AppPipesModule,
        NgbTooltipModule,
    ],
  entryComponents: [],
  declarations: [
    FileUploaderComponent,
    FileUploaderThumbComponent,
    FileLazyLoaderComponent,
  ],
  exports: [
    FileUploaderComponent,
    FileUploaderThumbComponent,
    FileLazyLoaderComponent,
  ],
  providers: []
})
export class FileUploaderModule { }
