import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'listFilter'

  // If you plan on modifying objects within the array, you may have to enable 'pure' mode.
  // ,pure: false
})
export class ListFilterPipe implements PipeTransform {
  transform(
    items: any[],
    callback: (item: any, ...filters) => boolean,
    ...filters: any[]
  ): any {
    if (!items || !callback) {
      return items;
    }
    return items.filter(item => callback(item, ...filters));
  }
}
