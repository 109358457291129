import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {BioUserActions} from './index';
import { of} from 'rxjs';
import {BioUser} from '../../core/models/bio-user.model';
import {ResponseMessage} from '../../core/models/response-message.model';
import {BioUserService} from '../../core/services/bio-user.service';
import {AlertService} from '../../shared/components/alert/alert.service';

@Injectable()
export class BioUsersEffects {

  constructor(
    private actions$: Actions,
    private bioUserService: BioUserService,
    private alertService: AlertService
  ) {
  }

  getBioUserRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BioUserActions.getBioUserRequest),
      mergeMap(action =>
        this.bioUserService.getBioUser(action.id).pipe(
          map((bioUser: BioUser) =>
            BioUserActions.getBioUserSuccess({
              bioUser
            })
          ),
          catchError(error => {
            return of( BioUserActions.effectError({error}) );
          })
        )
      )
    )
  );

  saveBioUserRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BioUserActions.saveBioUserRequest),
      mergeMap(action =>
        this.bioUserService.saveBioUser(action.bioUser).pipe(

          tap((responseMessage: ResponseMessage) => {
            this.alertService.info(responseMessage.message);
          }),

          map((responseMessage: ResponseMessage) =>
            BioUserActions.saveBioUserSuccess({
              bioUser: responseMessage.data
            })
          ),
          catchError(error => {
            return of( BioUserActions.effectError({error}) );
          })
        )
      )
    )
  );

  resendBioUserInviteRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BioUserActions.resendBioUserInviteRequest),
      mergeMap(action =>
        this.bioUserService.resendBioUserInvite(action.bioUser).pipe(

          tap((responseMessage: ResponseMessage) => {
            this.alertService.info(responseMessage.message);
          }),

          map((responseMessage: ResponseMessage) =>
            BioUserActions.saveBioUserSuccess({
              bioUser: responseMessage.data
            })
          ),

          catchError(error => {
            return of( BioUserActions.effectError({error}) );
          })
        )
      )
    )
  );

  getBioUsersRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BioUserActions.getBioUsersRequest),
      mergeMap(action =>
        this.bioUserService.getBioUsers(action.slugOrId).pipe(
          map((bioUsers: BioUser[]) =>
            BioUserActions.getBioUsersSuccess({
              bioUsers
            })
          ),
          catchError(error => {
            return of( BioUserActions.effectError({error}) );
          })
        )
      )
    )
  );

  deleteBioUserRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BioUserActions.deleteBioUserRequest),
      mergeMap(action =>
        this.bioUserService.deleteBioUser(action.bioUser).pipe(
          tap((responseMessage: ResponseMessage) => {
            this.alertService.warning(responseMessage.message);
          }),

          map(responseMessage =>
            BioUserActions.deleteBioUserSuccess({ responseMessage, bioUser: action.bioUser})
          ),

          catchError(error => {
            return of( BioUserActions.effectError({error}) );
          })
        )
      )
    )
  );

}
