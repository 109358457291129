import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {faSearch} from '@fortawesome/free-solid-svg-icons';
import {Subscription} from 'rxjs';
import {LoaderService} from '../../../shared/components/content-loader/loader.service';
import {MemorySearchResult} from '../../../core/models/memory-search-result.model';
import {MemoryService} from '../../../core/services/memory.service';
import {IMemory, MemoryModel} from '../../../core/models/memory.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {select, Store} from '@ngrx/store';
import {DefaultMemoryActions, MemoryActions, MemorySelectors} from '../../../store/memories';
import {AppState} from '../../../store';
import {MemoryFilterModel} from '../../../core/models/memory-filter.model';
import {NodeType} from '../../../core/common/enums';
import {JarDefaultModalComponent} from '../../../shared/modals/admin/jar-default/jar-default-modal.component';
import {JarModel} from '../../../core/models/jar.model';
import {AbstractMemoryDefaultsComponent} from '../abstract-memory-defaults/abstract-memory-defaults.component';

@Component({templateUrl: '../abstract-memory-defaults/abstract-memory-defaults.component.html'})
export class ThingJarDefaultsComponent extends AbstractMemoryDefaultsComponent{

  public PageTitle = 'Thing Jar Defaults';
  public NodeType: NodeType = NodeType.ThingJarDefault;

  public edit(memory?: IMemory) {
    this.store.dispatch(
      MemoryActions.setCurrentMemoryId({ id: memory?.id })
    );

    const modal = this.modalService.open(JarDefaultModalComponent, {size: 'lg', backdrop: 'static'});
    modal.componentInstance.jar = memory || new JarModel();
    modal.result.then((editedMemory: IMemory) => {
      this.search();
    }, (reason) => {
      console.log(reason);
    });
  }
}
