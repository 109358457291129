<form [formGroup]="editForm" (ngSubmit)="onSubmit()">
<div class="modal-header danger">
  <h4 class="modal-title">Delete account</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss(false)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body danger">

  <form-validation [control]="editForm" [submitted]="submitted"></form-validation>

  <h2 class="text-centered text-uppercase">
    <strong>Warning</strong>
  </h2>
  <h4 class="text-centered">
    This process can not be reversed.
  </h4>

  <p class="mt-4">
    Are you sure? We don't want to see you go!
  </p>

  <p>
    Please note that once your account has been deleted all Profiles, Memories, Things and Passwords will be deleted.
  </p>

  <p>
    Delete account and all Profiles, Memories, Things and Passwords.
  </p>

  <p>
    If you do not want your Ward Profiles deleted, please transfer ownership to another Guardian before continuing.
  </p>

  <ul>
    <li *ngFor="let bio of ownedBios">
      {{bio.firstName}} {{bio.lastName}}
    </li>
  </ul>

  <p>
    <a class="btn bnt-sm btn-dark text-white" routerLink="/profiles/wards">Transfer Profiles</a>
  </p>

  <p>
    If you are on a paid plan, once your account has been deleted you wont be billed again.
    Our <a target="_blank" href="https://mylifejars.com/pricing-features/">refund policy is here</a>.
  </p>

  <div class="form-group mt-4">
    <label class="control-label">Type DELETE to confirm this action</label>
    <input type="email" formControlName="deleteConfirm" class="form-control form-control-lg input-bio"
           [ngClass]="{ 'is-invalid': submitted && f.deleteConfirm.errors }" placeholder="DELETE" />
    <app-control-validation [control]="f.deleteConfirm" [submitted]="submitted"></app-control-validation>
  </div>

  <div class="form-group text-center">
    <button type="submit" class="btn btn-danger">
      Delete
    </button>

    <button type="button" class="btn btn-gold ml-md-3" (click)="activeModal.dismiss(false)">
      Continue with My LifeJars
    </button>
  </div>

</div>
</form>
<app-content-loader></app-content-loader>
