import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {FileModel} from '../models/file.model';
import {LightboxModalComponent} from '../../shared/modals/lightbox/lightbox-modal.component';
import {IMemory, MemoryModel} from '../models/memory.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {JarModel} from '../models/jar.model';
import {TutorialModalComponent} from '../../shared/modals/tutorial-popup/tutorial-modal.component';
import {User} from '../models/user.model';
import {PlanUpgradeModalComponent} from '../../shared/modals/plan-upgrade/plan-upgrade-modal.component';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../store';
import {selectCurrentUser} from '../../store/users/users.selectors';
import {Title} from '@angular/platform-browser';
import {UserSubscription} from '../models/user-subscription.model';
import {PlanChangeWarningModalComponent} from '../../shared/modals/plan-change-warning/plan-change-warning-modal.component';
import {ModerationStatus} from '../common/enums';
import {FileUploaderService} from '../../shared/components/file-uploader/file-uploader.service';
import {filter, first} from 'rxjs/operators';
import {LoaderService} from '../../shared/components/content-loader/loader.service';

@Injectable({providedIn: 'root'})
export class LayoutService implements OnDestroy {
  private readonly subscription: Subscription;
  private readonly sidebarSubject: BehaviorSubject<boolean>;
  private sidebarShow: boolean;
  private user: User;

  public get SidebarStatus() {
    return this.sidebarSubject;
  }

  constructor(
    protected titleService: Title,
    protected store: Store<AppState>,
    protected modalService: NgbModal,
    protected fileUploader: FileUploaderService,
    protected loaderService: LoaderService
  ) {
    this.sidebarShow = false;
    this.sidebarSubject = new BehaviorSubject<boolean>(this.sidebarShow);

    this.subscription = new Subscription();
    this.subscription.add(this.store.pipe(select(selectCurrentUser))
      .subscribe((user: User) => {
        this.user = user;
      })
    );
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  public toggleSidebar(): boolean {
    this.sidebarShow = !this.sidebarShow;
    this.sidebarSubject.next(this.sidebarShow);
    return this.sidebarShow;
  }

  public showSidebar(): boolean {
    this.sidebarShow = true;
    this.sidebarSubject.next(this.sidebarShow);
    return this.sidebarShow;
  }

  public hideSidebar(): boolean {
    this.sidebarShow = false;
    this.sidebarSubject.next(this.sidebarShow);
    return this.sidebarShow;
  }

  public showLightbox(memory: IMemory, firstFile: FileModel) {
    if ( !firstFile || firstFile.moderation !== ModerationStatus.Approved) {
      return;
    }

    if (this.fileUploader.isDocument(firstFile) === true && typeof window !== 'undefined') {
      const observable$ = this.fileUploader.downloadFile(null, memory, firstFile, false);
      if (observable$ != null) {
        this.loaderService.showLoader();
        observable$
          .pipe(first(), filter((f) => f != null))
          .subscribe((file: FileModel) => {
            if (file) {
              window.open(file.src, '_blank');
            } else {
              console.log('LayoutService File Loader Exception: ', memory, firstFile);
            }
            this.loaderService.hideLoader();
          }, (error) => {
            console.log('LayoutService File Loader Server Exception: ', memory, firstFile);
            this.loaderService.hideLoader();
          });
        return;
      }
    }

    const modal = this.modalService.open(LightboxModalComponent, {
      size: 'xl',
      backdropClass: 'modal-lightbox',
      windowClass: 'bio modal-lightbox'
    });
    modal.componentInstance.memory = memory;
    modal.componentInstance.fileList = (memory.files || []).filter(f => f.moderation === ModerationStatus.Approved);
    modal.componentInstance.file = firstFile;
  }

  public showTerms() {
    // const modal = this.modalService.open(TermsServiceModalComponent, {size: 'lg'});
    return 'https://mylifejars.com/terms-conditions/';
  }

  public showPrivacy() {
    // const modal = this.modalService.open(PrivacyPolicyModalComponent, {size: 'lg'});
    return 'https://mylifejars.com/privacy/';
  }

  public showPlanUpgrade() {
    const modal = this.modalService.open(PlanUpgradeModalComponent);
  }

  public showPlanSuggestions(currentPlan: UserSubscription, newPlan: UserSubscription) {

    if (currentPlan.tier === 2 && newPlan.tier === currentPlan.tier || newPlan.tier > currentPlan.tier) {
      const modal = this.modalService.open(PlanChangeWarningModalComponent, {size: 'lg'});
      modal.componentInstance.newPlan = newPlan;
      modal.componentInstance.currentPlan = currentPlan;

      return false;
    }

    return true;
  }


  // todo: this should probably be it's own service, maybe?
  public showTutorial(tutorialRef: string, forceDisplay: boolean = false) {

    // Tutorial Factory
    const tutorialModel = {
      title: '',
      videoSrc: '',
      tutorialRef: ''
    };

    switch (tutorialRef) {
      case 'memoryJarsTutorial':
        tutorialModel.title = 'How to use the Memory Jars';
        tutorialModel.videoSrc = 'https://player.vimeo.com/video/506663171?color=c5af80&title=0&byline=0&portrait=0';
        tutorialModel.tutorialRef = tutorialRef;
        break;

      case 'thingsJarsTutorial':
        tutorialModel.title = 'How to use the Things Jars';
        tutorialModel.videoSrc = 'https://player.vimeo.com/video/506663684?color=c5af80&title=0&byline=0&portrait=0';
        tutorialModel.tutorialRef = tutorialRef;
        break;

      case 'passwordJarsTutorial':
        tutorialModel.title = 'How to use the Password Jars';
        tutorialModel.videoSrc = 'https://player.vimeo.com/video/506663936?color=c5af80&title=0&byline=0&portrait=0';
        tutorialModel.tutorialRef = tutorialRef;
        break;

      case 'onlineMemorialTutorial':
        tutorialModel.title = 'How to create an Online Memorial';
        tutorialModel.videoSrc = 'https://player.vimeo.com/video/506666007?color=c5af80&title=0&byline=0&portrait=0';
        tutorialModel.tutorialRef = tutorialRef;
        break;

      case 'tribeTutorial':
        tutorialModel.title = 'How to build your Tribe';
        tutorialModel.videoSrc = 'https://player.vimeo.com/video/506664964?color=c5af80&title=0&byline=0&portrait=0';
        tutorialModel.tutorialRef = tutorialRef;
        break;

      case 'guardiansTutorial':
        tutorialModel.title = 'How to add your Guardians';
        tutorialModel.videoSrc = 'https://player.vimeo.com/video/506665536?color=c5af80&title=0&byline=0&portrait=0';
        tutorialModel.tutorialRef = tutorialRef;
        break;

      case 'digitalLegacyTutorial':
        tutorialModel.title = 'View a sample Digital Legacy';
        tutorialModel.videoSrc = 'https://player.vimeo.com/video/528084999?color=c5af80&title=0&byline=0&portrait=0';
        tutorialModel.tutorialRef = tutorialRef;
        break;

      case 'lifeJournalTutorial':
        tutorialModel.title = 'View a sample Life Journal';
        tutorialModel.videoSrc = 'https://player.vimeo.com/video/528103963?color=c5af80&title=0&byline=0&portrait=0';
        tutorialModel.tutorialRef = tutorialRef;
        break;

      case 'editBioTutorial':
        tutorialModel.title = 'How to edit a Profile';
        tutorialModel.videoSrc = 'https://player.vimeo.com/video/525360500?color=c5af80&title=0&byline=0&portrait=0';
        tutorialModel.tutorialRef = tutorialRef;
        break;

      case 'dashboardTutorial':
      default:
        tutorialModel.title = 'Tour of My LifeJars';
        tutorialModel.videoSrc = 'https://player.vimeo.com/video/506630154?color=c5af80&title=0&byline=0&portrait=0';
        tutorialModel.tutorialRef = tutorialRef;
        break;
    }

    // Only Show Tutorials to Users who haven't flagged the "Don't show again" condition.
    // We store the condition in the users metaData field
    if (forceDisplay || (this.user && (this.user.metaData == null || this.user.metaData[tutorialRef] == null))) {

      // More Issues with ngBootstrap modals... Hopeless
      setTimeout(() => {
        const modal = this.modalService.open(TutorialModalComponent, {size: 'lg'});
        modal.componentInstance.title = tutorialModel.title;
        modal.componentInstance.videoSrc = tutorialModel.videoSrc; // Remember: ?rel=0
        modal.componentInstance.tutorialRef = tutorialModel.tutorialRef;
      }, 500);

    }
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
