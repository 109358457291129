import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpHeaders
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthService} from '../services/auth.service';
import {environment} from '../../../environments/environment';
import {AppConfig} from '../services/app-config.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(public auth: AuthService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    request = request.clone({
      withCredentials: true
    });

    const token = this.auth.getToken();
    if (token != null) {
      const headers = new HttpHeaders()
        .set('Authorization', `Bearer ${token}`)
        .set(`${AppConfig.settings.XsrfFieldName}`, token);

      request = request.clone({
        headers
      });

      /*
      if (request.method.toUpperCase() === 'GET') {
        request = request.clone({
          params: request.params.set(`${AppConfig.settings.XsrfFieldName}`, token)
        });
      } else {
        if (request.body instanceof FormData) {
          request = request.clone({
            body: request.body.append(`${AppConfig.settings.XsrfFieldName}`, token)
          });
        } else {
          request = request.clone({
            body: {...request.body, [`${AppConfig.settings.XsrfFieldName}`]: token}
          });
        }
      }
      */

    }

    return next.handle(request);
  }

}
