import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable, Subscription} from 'rxjs';

import {Bio} from '../models/bio.model';
import {ResponseMessage} from '../models/response-message.model';
import {AppDateFormatter} from '../format/date.format';
import {BioFilterModel} from '../models/bio-filter.model';
import {FileModel} from '../models/file.model';
import {BioSearchResult} from '../models/bio-search-result.model';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {AppConfig} from './app-config.service';

@Injectable({providedIn: 'root'})
export class BioService {

  constructor(
    protected http: HttpClient,
    protected dateFormatter: AppDateFormatter
  ) {
  }

  public switchBio(slugOrId?: string | number): Observable<Bio> {
    let url = `${AppConfig.settings.apiBaseUrl}/bio/switch`;
    if (slugOrId != null) {
      url = `${AppConfig.settings.apiBaseUrl}/bio/switch/${slugOrId}`;
    }
    return this.http.get<Bio>(url);
  }

  public getBio(slugOrId?: string | number): Observable<Bio> {

    // Optional id param
    let url = `${AppConfig.settings.apiBaseUrl}/bio`;
    if (slugOrId != null) {
      url = `${AppConfig.settings.apiBaseUrl}/bio/${slugOrId}`;
    }
    return this.http.get<Bio>(url);
  }

  public save(model: Bio): Observable<ResponseMessage> {
    // Insert
    let url = `${AppConfig.settings.apiBaseUrl}/bio/save`;

    // Update
    if (model.id != null) {
      url = `${AppConfig.settings.apiBaseUrl}/bio/save/${model.id}`;
    }

    return this.http.post<ResponseMessage>(url, {
      privacy: model.privacy,
      firstName: model.firstName,
      lastName: model.lastName,
      deathday: model.deathday,
      allowComments: model.allowComments,
      metaData: {
        birthday: model.birthday,
        gender: model.gender,
        emails: model.emails,
        phones: model.phones,
        middleNames: model.middleNames,
        preferredNames: model.preferredNames,
        previousNames: model.previousNames,
        religion: model.religion,
        practicingReligion: model.practicingReligion,
        ethnicity: model.ethnicity,
        customTradition: model.customTradition,
        addresses: model.addresses,
        restingLocation: model.restingLocation
      }
    });
  }

  public deleteBio(bio: Bio): Observable<ResponseMessage> {
    return this.http.delete<ResponseMessage>(`${AppConfig.settings.apiBaseUrl}/bio/${bio.slug}/delete`);
  }

  public getFile(bio: Bio, file: FileModel, getPreview: boolean = false): Observable<FileModel> {
    return this.http.get<FileModel>(`${AppConfig.settings.apiBaseUrl}/bio/${bio.slug}/file/${file.id}?preview=${getPreview}`);
  }

  public saveProfileFile(bio: Bio, file: FileModel): Observable<ResponseMessage> {
    return this.http.patch<ResponseMessage>(`${AppConfig.settings.apiBaseUrl}/bio/${bio.slug}/profile/save`, {file: file.id});
  }

  public saveFilesUrl(bio: Bio) {
    return `${AppConfig.settings.apiBaseUrl}/bio/${bio.slug}/file/save`;
  }

  public deleteFile(bio: Bio, file: FileModel): Observable<ResponseMessage> {
    return this.http.delete<ResponseMessage>(`${AppConfig.settings.apiBaseUrl}/bio/${bio.slug}/file/${file.id}/remove`);
  }


  public legacyTransition(id: number, deathday: NgbDateStruct): Observable<ResponseMessage> {
    return this.http.patch<ResponseMessage>(`${AppConfig.settings.apiBaseUrl}/bio/${id}/legacy`, {
      deathday: this.dateFormatter.format(deathday, 'YYYY-MM-DD'),
    });
  }

  public search(filters: BioFilterModel): Observable<BioSearchResult> {

    const params = new HttpParams()
      .append('page', filters.page.toString())
      .append('results', filters.results.toString())
      .append('keywords', filters.keywords || '');

    return this.http.get<BioSearchResult>(`${AppConfig.settings.apiBaseUrl}/bio/search`, {params});
  }

}
