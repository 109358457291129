<form [formGroup]="editForm" (ngSubmit)="onSubmit()">
<div class="modal-header">
  <h4 class="modal-title">Guardian Authority</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="text-legal-doc">

    <p>
      <b>{{ bio?.firstName }} {{ bio?.lastName }}</b> has invited you to become their <b>Guardian</b> on My LifeJars.
    </p>

    <p>
      Depending on your role in real life and the permission {{ bio?.firstName }} has granted you,
      you will be able to manage their My LifeJars account, when they are alive or when they die or both in life and death.
    </p>
    <p>
      {{ bio?.firstName }} may grant you access to their Memory Jars, Things Jars or Passwords Jars
      individually or any combination of these.
    </p>
    <p>
      If {{ bio?.firstName }} dies you may transition their online Life Profile to their Legacy Profile to
      create an online memorial and accept any new requests from family and friends to belong to their Tribe.
    </p>
    <p>
      You may be able to moderate any further Memories, Recollections Tributes &amp; Things about {{ bio?.firstName }}.
    </p>
    <p>
      You may be granted access to documentation to assist you (and other Guardians) managing
      {{ bio?.firstName }}'s physical and digital estate.
    </p>
    <p>
      In some cases, it may be necessary to provide a death certificate to My LifeJars to
      administer {{ bio?.firstName }}’s legacy.
    </p>

    <div class="form-group">
      <div class="custom-control custom-checkbox">
        <input type="checkbox" formControlName="acceptTerms" id="acceptTermsId"
               class="input-bio custom-control-input"
               [ngClass]="{ 'is-invalid': submitted && f.acceptTerms.errors }"/>
        <label class="custom-control-label" for="acceptTermsId">
          I acknowledge that I will only act in accordance with {{ bio?.firstName }}'s  wishes either
          noted in My LifeJars and/or recorded legally in his Last Will &amp; Testament.
        </label>
        <app-control-validation [control]="f.acceptTerms" [submitted]="submitted"></app-control-validation>
      </div>
    </div>

    <div class="form-group mt-4">

      <button  type="submit" class="btn btn-gold pl-4 pr-4">
        Accept
      </button>

      <button type="button" class="btn btn-outline-dark ml-3 pl-4 pr-4" (click)="activeModal.close(false)">
        Decline
      </button>

      <form-validation [control]="editForm" [submitted]="submitted"></form-validation>

    </div>

  </div>
</div>
</form>
<app-content-loader></app-content-loader>
