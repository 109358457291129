import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {faCalendar, faPlusCircle, faPencilAlt, faSearch} from '@fortawesome/free-solid-svg-icons';
import {WardType} from '../../../core/common/enums';
import {IListOption} from '../../../core/models/option.model';
import {first, map} from 'rxjs/operators';
import {BioService} from '../../../core/services/bio.service';
import {ControlValidationService} from '../../components/control-validation/control-validation.service';
import {Router} from '@angular/router';
import {UserBio} from '../../../core/models/bio-ward.model';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../store';
import {Subscription} from 'rxjs';
import {selectOptions, selectRelationshipSubTypeByType, selectRelationshipTypes} from '../../../store/options/options.selectors';
import {OptionsState} from '../../../store/options/options.state';
import {UserBioActions} from '../../../store/user-bios';
import {LayoutService} from '../../../core/services/layout.service';
import {selectCurrentUser, selectCurrentUserSubscription} from '../../../store/users/users.selectors';
import {UserSubscription} from '../../../core/models/user-subscription.model';
import {User} from '../../../core/models/user.model';

@Component({
  templateUrl: 'ward-form-modal.component.html'
})
export class WardFormModalComponent implements OnInit, OnDestroy {
  private readonly subscription: Subscription;

  @Input()
  public isLegacy: boolean;

  private user: User;
  private userSubscription: UserSubscription;

  public editForm: FormGroup;
  public submittedShowErrors = false;

  public faCalendar = faCalendar;
  public faSearch = faSearch;
  public faPlusCircle = faPlusCircle;
  public faPencilAlt = faPencilAlt;

  public optionalAccountSetup: boolean;
  public showAccountSetup: boolean;
  public showLegacySetup: boolean;
  public disableSave: boolean;

  public optionsData: OptionsState;

  public relationshipTypes: IListOption[];
  public relationshipSubTypesFiltered: IListOption[];

  public get GenderTypes() {
    return this.optionsData.genderTypes;
  }

  // convenience getter for easy access to form fields
  public get f() {
    return this.editForm.controls;
  }

  constructor(
    public activeModal: NgbActiveModal,
    protected router: Router,
    protected store: Store<AppState>,
    protected modalService: NgbModal,
    protected formBuilder: FormBuilder,
    protected bioService: BioService,
    protected layoutService: LayoutService,
  ) {
    this.optionalAccountSetup = true;
    this.showAccountSetup = false;
    this.showLegacySetup = false;
    this.disableSave = false;

    this.subscription = new Subscription();
    this.optionsData = new OptionsState();
    this.subscription.add(this.store.pipe(select(selectOptions))
      .subscribe((options: OptionsState) => {
        this.optionsData = options;
      })
    );

    this.relationshipTypes = [];
    this.subscription.add(this.store.pipe(select(selectRelationshipTypes))
      .subscribe((options: IListOption[]) => {
        this.relationshipTypes = options;
      })
    );

    this.subscription.add(this.store.pipe(select(selectCurrentUser))
      .subscribe((user: User) => {
        this.user = user;
      })
    );

    this.subscription.add(this.store.pipe(select(selectCurrentUserSubscription))
      .subscribe((userSub: UserSubscription) => {
        this.userSubscription = userSub;
      })
    );

  }

  public ngOnInit() {

    this.editForm = this.formBuilder.group({
      wardTypeA: [1, Validators.required],
      wardTypeB: [1, Validators.required],
      createAccount: [0, Validators.required],

      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      gender: [null, Validators.required],
      birthday: [null],

      relationshipType: [null, Validators.required],
      relationshipSubType: [null, Validators.required],

      agreeToConditions: [null, Validators.requiredTrue]
    });

    if (this.isLegacy === true) {
      this.editForm.patchValue({wardTypeB: 2});
      this.onWardTypeChange(null);
    }

  }

  private mapWardType() {
    const wardTypeA = this.editForm.get('wardTypeA').value;
    const wardTypeB = this.editForm.get('wardTypeB').value;
    if (wardTypeA === 1) {

      // Person
      if (wardTypeB === 1) {
        return WardType.PersonLife;
      } else {
        return WardType.PersonLegacy;
      }
    } else {

      // Pet
      if (wardTypeB === 1) {
        return WardType.PetLife;
      } else {
        return WardType.PetLegacy;
      }
    }
  }

  private addAccountSetup() {
    this.editForm.addControl('email', new FormControl('', [Validators.required, ControlValidationService.emailValidator]));
    this.showAccountSetup = true;
  }

  private removeAccountSetup() {
    this.showAccountSetup = false;
    this.editForm.removeControl('email');
  }

  public onWardTypeChange(event) {
    const wardType = this.mapWardType();

    // Prevent adding Bios that are past the limit
    const legacyStop = (wardType === WardType.PersonLegacy || wardType === WardType.PetLegacy)
      && this.userSubscription.legacyBios > 0 // 0: Unlimited
      && this.userSubscription.legacyBios <= this.user.ownedLegacyBios;

    const lifeStop = (wardType === WardType.PersonLife || wardType === WardType.PetLife)
      && this.userSubscription.legacyBios > 0 // 0: Unlimited
      && this.userSubscription.bios <= this.user.ownedBios;

    this.disableSave = false;
    if (legacyStop || lifeStop) {
      this.layoutService.showPlanUpgrade();
      this.disableSave = true;
      return;
    }

    if (wardType === WardType.PersonLife) {
      this.optionalAccountSetup = true;
    } else {
      this.optionalAccountSetup = false;
      this.removeAccountSetup();
      this.editForm.patchValue({createAccount: 0});
    }

    if (wardType === WardType.PersonLegacy || wardType === WardType.PetLegacy) {
      this.editForm.addControl('deathday', new FormControl(null, Validators.required));
      this.showLegacySetup = true;
    } else {
      this.showLegacySetup = false;
      this.editForm.removeControl('deathday');
    }
  }

  public onCreateAccountChange(event) {
    const createAccount = this.editForm.get('createAccount').value;
    if (createAccount === 1) {
      this.addAccountSetup();
    } else {
      this.removeAccountSetup();
    }
  }

  public onRelationshipChange(event) {
    const relationshipType = this.editForm.get('relationshipType').value;
    this.relationshipSubTypesFiltered = [];
    this.store
      .pipe(
        select(selectRelationshipSubTypeByType(relationshipType)),
        first()
      )
      .subscribe((options: IListOption[]) => {
        this.relationshipSubTypesFiltered = options;
      });
  }

  public onRelationshipSubChange(event) {
    const id = this.editForm.get('relationshipSubType').value;
    if (id === -1) {
      this.editForm.addControl('relationshipOther', new FormControl('', Validators.required));
    } else {
      this.editForm.removeControl('relationshipOther');
    }
  }

  public onSubmit() {
    this.submittedShowErrors = true;

    // stop here if form is invalid
    if (this.editForm.invalid || this.disableSave === true) {
      return;
    }

    const formData = this.editForm.getRawValue();
    formData.wardType = this.mapWardType();

    // These fields are not required for the API (client only)
    formData.wardTypeA = undefined;
    formData.wardTypeB = undefined;

    const wardModel = UserBio.make(formData);
    this.store.dispatch(
      UserBioActions.createUserBioRequest({
        userBio: wardModel
      })
    );

    // Redirect to the Bio Edit Page
    this.router.navigate(['/profiles/edit']);

    // Close the Modal
    this.activeModal.close(true);
  }

  public showTerms(): string {
    return this.layoutService.showTerms();
  }

  public showPrivacy(): string {
    return this.layoutService.showPrivacy();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
