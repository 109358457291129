<form [formGroup]="editForm" (ngSubmit)="onSubmit()">
  <div class="modal-header">
    <h4 class="modal-title">Memory Jar Default</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body modal-body-memory-jar-edit">

    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label class="control-label text-center d-block">
            Add Memory Jar Icon
          </label>
          <app-file-uploader #icon [fileTypes]="'image/*'" [memory]="jar" [fileList]="[jar?.icon]"
                             preview="true"
                             [sortable]="false"
                             [removable]="true"
                             [editable]="true"
                             [maxCount]="1"
                             (fileRemove)="removeIcon($event)"
                             (fileEdit)="editFile($event)"
                             (fileClick)="showLightbox($event)"
                             (fileError)="showError($event)"></app-file-uploader>
        </div>
      </div>

      <div class="col-6" *ngIf="jar.nodeType == NodeType.ThingJarDefault ||jar.nodeType == NodeType.PasswordJarDefault">
        <div class="form-group">
          <label class="control-label text-center d-block">
            Add Thing/Password Banner
          </label>
          <app-file-uploader #banner [fileTypes]="'image/*'" [memory]="jar" [fileList]="[jar?.banner]"
                             preview="true"
                             [sortable]="false"
                             [removable]="true"
                             [editable]="true"
                             [maxCount]="1"
                             (fileRemove)="removeBanner($event)"
                             (fileEdit)="editFile($event)"
                             (fileClick)="showLightbox($event)"
                             (fileError)="showError($event)"></app-file-uploader>
        </div>
      </div>
    </div>

    <div class="form-group" *ngIf="jar.nodeType == NodeType.MemoryJarDefault">
      <label class="control-label text-center d-block">Add Images, Documents, Audio &amp; Video Files</label>
      <app-file-uploader #files [memory]="jar" [fileList]="jar?.files"
                         [editable]="true"
                         [sortable]="true"
                         [removable]="true"
                         [maxSize]="10"
                         [maxCount]="10"
                         (fileSort)="sortFiles($event)"
                         (fileRemove)="removeFile($event)"
                         (fileClick)="showLightbox($event)"
                         (fileError)="showError($event)"></app-file-uploader>
    </div>

    <div class="form-group">
      <label class="control-label">Jar</label>
      <select formControlName="jarTag" class="form-control input-bio"
              [ngClass]="{ 'is-invalid': submitted && f.jarTag.errors }" aria-label="Jar">
        <option [ngValue]="null" disabled>Select one...</option>
        <option *ngFor="let item of JarTypes" [ngValue]="item.id">{{ item.label }}</option>
      </select>
      <app-control-validation [control]="f.jarTag" [submitted]="submitted"></app-control-validation>
    </div>

    <div class="form-group">
      <label class="control-label">Memory Title</label>
      <input ngbAutofocus type="text" formControlName="title" class="form-control form-control-lg input-bio"
             [ngClass]="{ 'is-invalid': submitted && f.title.errors }" placeholder="Memory Title"
             aria-label="Memory Title"/>
      <app-control-validation [control]="f.title" [submitted]="submitted"></app-control-validation>
    </div>

    <div class="form-group">
      <label class="control-label">Placeholder</label>
      <textarea autosize formControlName="placeholder" class="form-control input-bio"
                [ngClass]="{ 'is-invalid': submitted && f.placeholder.errors }"
                placeholder="Add a placeholder description"
                aria-label="Placeholder" rows="6"></textarea>
      <app-control-validation [control]="f.placeholder" [submitted]="submitted"></app-control-validation>
    </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark mr-auto" (click)="activeModal.dismiss()">
      Cancel
    </button>
    <button type="submit" class="btn btn-gold ml-3">
      Save
    </button>

    <button *ngIf="jar.id" type="button" class="btn btn-success ml-2" title="Publish to all members Profiles"
            (click)="this.afterSubmit(jar)">
      Publish
    </button>
  </div>
</form>
<app-content-loader></app-content-loader>
