import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {IListOption} from '../models/option.model';
import {AppConfig} from './app-config.service';


@Injectable({providedIn: 'root'})
export class OptionsService {

  public readonly BioUserRoles = [
    {id: 0, label: 'No Access'},
    {id: 1, label: 'Read Only'},
    {id: 2, label: 'Full Access'},
  ];

  constructor( private http: HttpClient ) {
  }

  public getOptions(): Observable<any> {
    return this.http.get<any>(`${AppConfig.settings.apiBaseUrl}/options`);
  }

}
