
<table class="footer">
  <tbody>
  <tr height="40">
    <td width="30%" align="left" valign="middle">
      <div class="bio-watermark">
        <img src="/assets/img/mylifejars-print-watermark.png" alt="My LifeJars">
      </div>
    </td>
    <td width="40%" align="center" valign="middle">
      <span class="footer-page">{{ page }}</span>
    </td>
    <td width="30%" align="right" valign="middle">
      <span class="footer-url">www.mylifejars.com</span>
    </td>
  </tr>
  </tbody>
</table>
