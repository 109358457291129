import {AppState} from '../index';
import {createSelector} from '@ngrx/store';
import {MemoryModel} from '../../core/models/memory.model';
import {selectMemoryTags} from '../options/options.selectors';
import {JarModel} from '../../core/models/jar.model';

const sortBySortIndex = (a: JarModel, b: JarModel) => {
  if (b.sortIndex > a.sortIndex) {
    return -1;
  } else {
    return 1;
  }
};

export const selectJarsState = (state: AppState) => state.jars;

export const selectCurrentJar = createSelector(
  selectJarsState,
  state => state.byId[state.currentJarId]
);

// export const selectCurrentJarTags = createSelector(
//   selectMemoryTags,
//   (jarTags, jar) => {
//     if (jar == null) {
//       return [];
//     }
//     return AppStoreUtils.filterSubTypes(jarTags, jar.jarType);
//   }
// );

export const selectJarTags = (jarId: number) => createSelector(
  selectMemoryTags,
  selectJarById(jarId),
  (jarTags, jar) => {
    if (jar == null) {
      return [];
    }
    return  jarTags.filter( x => x.groupId === jar.jarTag);
  }
);

export const selectJars = createSelector(
  selectJarsState,
  state => Object.keys(state.byId).map(key => state.byId[key])
);

export const selectMemoryJars = createSelector(
  selectJars,
  state => state.filter(jar => MemoryModel.isMemory(jar)).sort(sortBySortIndex)
);

export const selectThingJars = createSelector(
  selectJars,
  state => state.filter(jar => MemoryModel.isThing(jar)).sort(sortBySortIndex)
);

export const selectPasswordJars = createSelector(
  selectJars,
  state => state.filter(jar => MemoryModel.isPassword(jar)).sort(sortBySortIndex)
);

export const selectJarById = (id: number) => createSelector(
  selectJarsState,
  state => state.byId[id] || null
);
