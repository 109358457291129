import {Component, Input, OnInit} from '@angular/core';

import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store';
import {UserActions} from '../../../store/users';

@Component({
  templateUrl: 'documentation-modal.component.html'
})
export class DocumentationModalComponent implements OnInit {
  @Input()
  public title: string;

  @Input()
  public ref: string;

  // Form Field
  public dontShowAgain: boolean;

  constructor(
    public activeModal: NgbActiveModal,
    protected modalService: NgbModal
  ) {
    this.title = '';
    this.ref = '';
  }

  public ngOnInit(): void {
  }

  public dismiss(event) {
    this.activeModal.dismiss(false);
  }

}
