import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import {BioService} from '../../../../core/services/bio.service';
import {filter, first} from 'rxjs/operators';
import {Bio} from '../../../../core/models/bio.model';
import {Router} from '@angular/router';
import {BioSearchResult} from '../../../../core/models/bio-search-result.model';
import {TribeService} from '../../../../core/services/tribe.service';
import {TribeFilterModel} from '../../../../core/models/tribe-filter.model';


@Component({
  templateUrl: 'tribester-picker.component.html'
})
export class TribesterPickerComponent implements OnInit {
  @Input() public bio: Bio;
  @Input() public tribesters: Bio[];
  private debounceTimer;
  public faSearch = faSearch;

  // Form Values
  public keywords: string;

  // Pagination fields
  public page = 1;
  public results = 10;
  public pageCount = 1;

  // Data from API
  public pagedBios: BioSearchResult = new BioSearchResult();

  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,
    private bioService: BioService,
    private tribeService: TribeService
  ) {

  }

  public ngOnInit() {
    this.search();
  }

  public addTribester(tribester: Bio) {
    this.pagedBios.list.splice(this.pagedBios.list.indexOf(tribester), 1);
    this.tribesters.push(tribester);
  }

  public removeTribester(tribester: Bio) {
    this.tribesters.splice(this.tribesters.indexOf(tribester), 1);
    this.pagedBios.list.unshift(tribester);
  }

  public onPageChange(page: number) {
    this.page = page;
    this.search();
  }

  // Ensure that the API isn't hammered when checkboxes are being selected
  public queueSearch() {
    if (typeof window !== 'undefined') {
      window.clearTimeout(this.debounceTimer);
      this.debounceTimer = window.setTimeout(() => {
        //this.page = 1;
        this.search();
      }, 1000);
    }
  }

  public search() {
    const filters = new TribeFilterModel();
    filters.keywords = this.keywords;
    filters.page = this.page;
    filters.results = this.results;
    filters.bio = this.bio.id;
    filters.excludeBios = this.tribesters.map((bio: Bio) => {
      return bio.id;
    });

    this.tribeService.search( filters )
      .pipe(first())
      .subscribe(
        data => {
          this.pagedBios = data;
        });
  }

  public onSubmit() {
    this.activeModal.close( this.tribesters );
  }

}
