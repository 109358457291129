<nav class="breadcrumb-container" aria-label="breadcrumb">
  <div class="container">
    <ol class="breadcrumb">
      <li class="ml-auto"></li>
      <li *ngFor="let item of list; let index = index, let last = last" class="breadcrumb-item" routerLinkActive="active">
        <a [routerLink]="[item.route]" routerLinkActive="active">{{ item.label }}</a>
      </li>
    </ol>
  </div>
</nav>
