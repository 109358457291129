import {JarModel} from './jar.model';
import {FileModel} from './file.model';
import {BioPermissions} from './bio-permissions.model';
import {CommonAddress} from './common-address.model';
import {BioType} from '../common/enums';
import {Tribe} from './tribe.model';

export class Bio {

  /*
  * 1 = Private
  * 2 = Members
  * 3 = Public
  */
  public privacy: number;

  /*
  * 1 = Person
  * 2 = Pet
  */
  public type: number;

  public id: number;
  public slug: string;

  public age: number;
  public deathday: any;
  public location: string;

  public profile: FileModel;
  public birthday: any;
  public firstName: string;
  public lastName: string;
  public gender: string;
  public about: string;

  public middleNames: string[];
  public preferredNames: string[];
  public previousNames: string[];
  public emails: string[];
  public phones: string[];
  public addresses: CommonAddress[];

  public practicingReligion: string;
  public religion: number;
  public ethnicity: number;
  public customTradition: number;
  public restingLocation: string;

  public mutual: number; // Number of mutual tribesters
  public tribeMembers: number;
  public memories: number;
  public things: number;
  public passwords: number;

  public memoryJars: JarModel[];
  public thingJars: JarModel[];
  public passwordJars: JarModel[];
  public files: FileModel[];
  public tribe: Bio[];
  public tribeRelationship: Tribe[];

  public isOwner: boolean;
  public isDefault: boolean;
  public isLegacy: boolean;

  public allowComments: boolean;
  public commentCount: number;

  // This is the current Authenticated Users Bio Permissions/Roles
  public permissions: BioPermissions;

  // This is the User Account that has this Bio as the "defaultBio"
  // We don't always include this value. Only when necessary.
  public user: any;

  constructor() {
    this.id = null;
    this.type = BioType.Person;
    this.slug = '';
    this.privacy = 2;
    this.age = 18;
    this.location = '';
    this.deathday = null;

    this.profile = null;
    this.birthday = null;
    this.firstName = '';
    this.lastName = '';
    this.gender = '';
    this.about = '';

    this.middleNames = [];
    this.preferredNames = [];
    this.previousNames = [];
    this.emails = [];
    this.phones = [];
    this.addresses = [];

    this.religion = null;
    this.practicingReligion = null;
    this.ethnicity = null;
    this.customTradition = null;
    this.restingLocation = '';

    this.mutual = 0;
    this.tribeMembers = 0;
    this.memories = 0;
    this.things = 0;
    this.passwords = 0;

    this.allowComments = true;
    this.commentCount = 0;

    this.isOwner = false;
    this.isDefault = false;
    this.isLegacy = false;

    this.memoryJars = [];
    this.thingJars = [];
    this.passwordJars = [];

    this.files = [];
    this.tribe = [];
    this.tribeRelationship = [];

    this.user = null;

    this.permissions = new BioPermissions();
  }

  public static make(obj: object): Bio {
    if (!obj || (Object.keys(obj).length === 0)) {
      return null; // Object is null or empty
    }
    return {...new Bio(), ...obj};
  }

  public static isPerson(bio: Bio) {
    return bio.type === BioType.Person;
  }

  public static isPet(bio: Bio) {
    return bio.type === BioType.Pet;
  }

  public static isLifeBio(bio: Bio) {
    return bio.isLegacy === false;
  }

  public static isLegacyBio(bio: Bio) {
    return bio.isLegacy === true;
  }

  public static getJarById(id: number, list: JarModel[]): any {
    for (let i = 0, l = list.length; i < l; i++) {
      if (list[i].id === id) {
        return list[i];
      }
    }
    return null;
  }

  public static getJarIds(list: JarModel[]): number[] {
    const ids = [];
    for (let i = 0, l = list.length; i < l; i++) {
      ids.push(list[i].id);
    }
    return ids;
  }


}
