import {createAction, props} from '@ngrx/store';
import {Bio} from '../../core/models/bio.model';
import {ResponseMessage} from '../../core/models/response-message.model';
import {IMemory, MemoryModel} from '../../core/models/memory.model';
import {FileModel} from '../../core/models/file.model';
import {Tribe} from '../../core/models/tribe.model';

export const setDisplayBioRequest = createAction(
  '[Bios] set display Bio request ',
  props<{ bio: Bio }>()
);

export const updateDisplayBioTribeRelationship = createAction(
  '[Bios] update display Bio Tribe relationship ',
  props<{ tribeRelationship: Tribe }>()
);

export const setCurrentBioRequest = createAction(
  '[Bios] set current bio request',
  props<{ bio: Bio }>()
);

export const switchBioRequest = createAction(
  '[Bios] switch bio request',
  props<{ slugOrId: string|number }>()
);

export const switchBioRequestSuccess = createAction(
  '[Bios] switch bio request success',
  props<{ bio: Bio  }>()
);

export const incrementCurrentBioMemoryCounter = createAction(
  '[Bios] increment current bio memory counter',
  props<{ memory: IMemory }>()
);

export const decrementCurrentBioMemoryCounter = createAction(
  '[Bios] decrement current bio memory counter',
  props<{ memory: IMemory }>()
);

export const incrementCommentCounter = createAction(
  '[Bios] increment bio comment counter',
  props<{ bio: Bio }>()
);

export const decrementCommentCounter = createAction(
  '[Bios] decrement bio comment counter',
  props<{ bio: Bio }>()
);

export const getPublicBioRequest = createAction(
  '[Bios] get public bio request',
  props<{ slugOrId: string|number, setAsDisplay?: boolean }>()
);

export const getBioRequest = createAction(
  '[Bios] get bio request',
  props<{ slugOrId: string|number, setAsDisplay?: boolean }>()
);

export const getBioRequestSuccess = createAction(
  '[Bios] get bio request success',
  props<{ bio: Bio, setAsDisplay?: boolean }>()
);

export const saveBioRequest = createAction(
  '[Bios] save bio request',
  props<{ bio: Bio }>()
);

export const saveBioRequestSuccess = createAction(
  '[Bios] save bio request success',
  props<{ responseMessage: ResponseMessage }>()
);

export const saveBioProfileRequest = createAction(
  '[Bios] save bio profile request',
  props<{ bio: Bio, file: FileModel }>()
);

export const saveBioProfileRequestSuccess = createAction(
  '[Bios] save bio profile request success',
  props<{ bio: Bio, file: FileModel }>()
);

export const deleteBioRequest = createAction(
  '[Bios] delete bio request',
  props<{ bio: Bio }>()
);

export const deleteBioRequestSuccess = createAction(
  '[Bios] delete bio request success',
  props<{ responseMessage: ResponseMessage }>()
);

export const effectError = createAction(
  '[Bios] effect error',
  props<{ error: any }>()
);
