import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable, Subscription, throwError} from 'rxjs';
import {catchError, first, map} from 'rxjs/operators';

import {User} from '../models/user.model';
import {RegisterModel} from '../models/register.model';
import {ResponseMessage} from '../models/response-message.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AppState} from '../../store';
import {select, Store} from '@ngrx/store';
import {UserActions} from '../../store/users';
import {selectCurrentUser} from '../../store/users/users.selectors';
import {AppConfig} from './app-config.service';

@Injectable({providedIn: 'root'})
export class AuthService implements OnDestroy {
  private readonly subscription: Subscription;
  private user: User;
  private token: string;

  // private tokenRefreshTimer;

  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
    private modalService: NgbModal
  ) {
    this.subscription = new Subscription();
    this.subscription.add(this.store.pipe(select(selectCurrentUser))
      .subscribe((user: User) => {
        this.user = user;
      })
    );

    /*
    if (typeof window !== 'undefined') {
      window.clearInterval(this.tokenRefreshTimer);
      this.tokenRefreshTimer = window.setInterval(() => {
        this.refreshXsrfToken().pipe(first()).subscribe(() => {
          // nothing required
        });
      }, (1000 * 60 * 5) ); // 5-minutes
    }
    */
  }

  private setCurrentUser(user: any) {
    this.store.dispatch(UserActions.setCurrentUser({user}));
  }

  private unsetCurrentUser() {
    this.store.dispatch(UserActions.logout());
  }

  public getToken() {
    return this.token;
  }

  public hasAuthenticated(): boolean {
    return (this.user != null);
  }

  public hasAdminAuthenticated(): boolean {
    return (this.user != null) && this.user.isAdmin;
  }

  public hasOpenServerSession(): Observable<User> {
    return this.http.get<User>(`${AppConfig.settings.apiBaseUrl}/auth/active`, {observe: 'response'})
      .pipe(
        map((resp: HttpResponse<User>) => {
          this.token = resp.headers.get(`${AppConfig.settings.XsrfFieldName}`);
          this.setCurrentUser(resp.body);
          return resp.body;
        }),
        catchError((err: HttpErrorResponse) => {
          this.unsetCurrentUser();
          return throwError(err);
        })
      );
  }

  public refreshXsrfToken(): Observable<string> {
    return this.http.get<string>(`${AppConfig.settings.apiBaseUrl}/auth/active`, {observe: 'response'})
      .pipe(
        map((resp: HttpResponse<string>) => {
          this.token = resp.headers.get(`${AppConfig.settings.XsrfFieldName}`);
          return this.token;
        })
      );
  }

  public login(username: string, password: string, rememberMe: boolean): Observable<User> {
    return this.http.post<User>(`${AppConfig.settings.apiBaseUrl}/auth/login`, {
      username: username.trim(),
      password,
      _remember_me: rememberMe
    }, {observe: 'response'})
      .pipe(
        map((resp: HttpResponse<User>) => {
          this.token = resp.headers.get(`${AppConfig.settings.XsrfFieldName}`);
          this.setCurrentUser(resp.body);
          return resp.body;
        }),
      );
  }

  public loginStep1(username: string, password: string): Observable<ResponseMessage> {
    return this.http.post<ResponseMessage>(`${AppConfig.settings.apiBaseUrl}/auth/login`, {
      username: username.trim(),
      password,
      _remember_me: '1', // Accepted values: 'true', 'on', '1', 'yes', true
    });
  }

  public loginStep2(code: string): Observable<User> {
    return this.http.post<User>(`${AppConfig.settings.apiBaseUrl}/auth/2fa`, {
      code: code.trim(),
      _remember_me: '1', // Accepted values: 'true', 'on', '1', 'yes', true
    }, {observe: 'response'})
      .pipe(
        map((resp: HttpResponse<User>) => {
          this.token = resp.headers.get(`${AppConfig.settings.XsrfFieldName}`);
          this.setCurrentUser(resp.body);
          return resp.body;
        }),
      );
  }

  public loginStep2Resend(): Observable<ResponseMessage> {
    return this.http.post<ResponseMessage>(`${AppConfig.settings.apiBaseUrl}/auth/2fa/resend`, {});
  }

  public register(model: RegisterModel): Observable<ResponseMessage> {

    // Remove extra white spaces
    model.email = model.email.trim();

    // Symfony requires the passwords to be sent as an Object for comparison
    model.password = {
      password: model.password,
      passwordConfirm: model.passwordConfirm
    };

    // Hacky: Server will reject invalid fields, so we need to remove this one.
    model.passwordConfirm = undefined;

    return this.http.post<ResponseMessage>(`${AppConfig.settings.apiBaseUrl}/auth/register`, model);
  }

  public verify(hash: string): Observable<ResponseMessage> {
    return this.http.post<ResponseMessage>(`${AppConfig.settings.apiBaseUrl}/auth/verify`, {hash});
  }

  public verifyEmail(hash: string): Observable<ResponseMessage> {
    return this.http.post<ResponseMessage>(`${AppConfig.settings.apiBaseUrl}/auth/verify-email`, {hash});
  }

  public passwordReset(username: string): Observable<ResponseMessage> {
    return this.http.post<ResponseMessage>(`${AppConfig.settings.apiBaseUrl}/auth/password-reset`, {
      username: username.trim()
    });
  }

  public passwordChange(hash: string, password1: string, password2: string): Observable<ResponseMessage> {

    // Symfony requires the passwords to be sent as an Object for comparison
    const password = {
      password: password1,
      passwordConfirm: password2
    };
    return this.http.post<ResponseMessage>(`${AppConfig.settings.apiBaseUrl}/auth/password-change`, {hash, password});
  }

  public logout() {
    return this.http.post(`${AppConfig.settings.apiBaseUrl}/auth/logout`, {});
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
    /*
    if (this.tokenRefreshTimer != null && typeof window !== 'undefined') {
      window.clearInterval(this.tokenRefreshTimer);
    }
    */
  }
}
