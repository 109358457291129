import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {LoaderService} from '../../shared/components/content-loader/loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  constructor(private loaderService: LoaderService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = request && request.urlWithParams != null ? request.urlWithParams : '';
    this.loaderService.showLoader(url);
    return next.handle(request).pipe(
      finalize(() => {
        this.loaderService.hideLoader(url);
      })
    );
  }

}
