<div *ngIf="file" class="file-uploader-thumb"
     placement="bottom" [ngbTooltip]="tooltip" [disableTooltip]="!tooltip">
  <div class="file-uploader-list-item-preview">
    <div *ngIf="file.preview" class="ico-preview">
      <div class="file-uploader-list-item-preview" [ngStyle]="{'background-image': 'url('+file.preview+')' }"></div>
    </div>
    <div *ngIf="!file.preview" class="ico-preview" (click)="onClick($event, file)">
      <app-file-lazy-loader *ngIf="isImage(file)"
                            [file]="file"
                            [bio]="bio"
                            [memory]="memory"
                            [public]="public"
                            [preview]="preview"> </app-file-lazy-loader>

      <div *ngIf="!isImage(file)" class="ico-preview" [ngClass]="{
          'is-audio': isAudio(file),
          'is-video': isVideo(file),
          'is-doc': isDocument(file),
          'is-pending': file && file.moderation === ModerationStatus.Pending,
          'is-rejected': file && file.moderation === ModerationStatus.Rejected
          }">

        <div *ngIf="file && file.moderation === ModerationStatus.Rejected" role="status"
          class="ico-preview-rejected text-danger">
          <fa-icon [icon]="faExclamationTriangle"></fa-icon>
          <div class="text-decrypting">Inappropriate Content</div>
        </div>

        <div *ngIf="file && file.moderation === ModerationStatus.Pending" role="status"
             class="ico-preview-pending text-black-50">
          <div class="spinner-grow spinner-grow-lg"></div>
          <div class="text-decrypting">Processing</div>
        </div>

        <div *ngIf="file.title" class="ico-preview-title">{{ file.title }}</div>
      </div>
    </div>
  </div>

  <button *ngIf="sortable" class="sort-handle file-uploader-list-item-move" type="button"><fa-icon [icon]="faArrowsAlt"></fa-icon></button>
  <button *ngIf="removable" type="button" class="file-uploader-list-item-cancel" (click)="onRemove($event, file)">X</button>
  <button *ngIf="editable" type="button" class="file-uploader-list-item-edit" (click)="onEdit($event, file)">
    <span class="title">{{ file.title }}</span> <fa-icon [icon]="faPencilAlt"></fa-icon>
  </button>
</div>
