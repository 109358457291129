import {BiosState, initialBioState} from './bios.state';
import {Action, createReducer, on} from '@ngrx/store';
import {BioActions} from './index';
import {Bio} from '../../core/models/bio.model';
import {MemoryModel} from '../../core/models/memory.model';
import {JarActions} from '../jars';
import {JarModel} from '../../core/models/jar.model';

const getJarPropKey = (jar: JarModel): string => {
  if (MemoryModel.isMemory(jar) === true) {
    return 'memoryJars';
  } else if (MemoryModel.isThing(jar) === true) {
    return 'thingJars';
  } else if (MemoryModel.isPassword(jar) === true) {
    return 'passwordJars';
  }
  return null;
};

const mergeBiosWithState = (state: BiosState, bios: Bio[]) => {
  const byId = {};
  const bySlug = {};

  let displayBio = state.displayBio;
  let currentBio = state.currentBio;

  for (const bio of bios) {
    if (bio != null) {
      byId[bio.id] = bio;

      // Reference the same object
      bySlug[bio.slug] = byId[bio.id];

      if (displayBio != null && displayBio.id === bio.id) {
        displayBio = byId[bio.id];
      }

      if (currentBio != null && currentBio.id === bio.id) {
        currentBio = byId[bio.id];
      }
    }
  }

  return {
    ...state,
    displayBio,
    currentBio,
    biosById: {
      ...state.biosById,
      ...byId
    },
    biosBySlug: {
      ...state.biosBySlug,
      ...bySlug
    }
  };
};

const reducer = createReducer<BiosState>(
  initialBioState,

  on(BioActions.setDisplayBioRequest, (state, {bio}) => {
    if (bio == null) {
      return {...state, displayBio: null};
    }
    return mergeBiosWithState({...state, displayBio: bio}, [bio]);
  }),

  on(BioActions.updateDisplayBioTribeRelationship, (state, {tribeRelationship}) => {

    return {
      ...state,
      displayBio: {
        ...state.displayBio,
        ...{
          tribeRelationship: [
            tribeRelationship
          ]
        }
      }
    };
  }),

  on(BioActions.setCurrentBioRequest, (state, {bio}) => {
    if (bio == null) {
      return {...state, currentBio: null};
    }
    return mergeBiosWithState({...state, currentBio: bio}, [bio]);
  }),

  on(BioActions.getBioRequestSuccess, (state, {bio, setAsDisplay}) => {
    if (setAsDisplay === true) {
      return mergeBiosWithState({...state, displayBio: bio}, [bio]);
    }
    return mergeBiosWithState(state, [bio]);
  }),

  on(BioActions.incrementCurrentBioMemoryCounter, (state, {memory}) => {
    if (state.currentBio != null) {
      let memories = state.currentBio.memories;
      let things = state.currentBio.things;
      let passwords = state.currentBio.passwords;

      if (MemoryModel.isMemory(memory)) {
        memories = memories + 1;

      } else if (MemoryModel.isThing(memory)) {
        things = things + 1;

      } else if (MemoryModel.isPassword(memory)) {
        passwords = passwords + 1;
      }

      return {...state, currentBio: {...state.currentBio, ...{memories, things, passwords}}};
    }

    // No change;
    return state;
  }),

  on(BioActions.decrementCurrentBioMemoryCounter, (state, {memory}) => {
    if (state.currentBio != null) {
      let memories = state.currentBio.memories;
      let things = state.currentBio.things;
      let passwords = state.currentBio.passwords;

      if (MemoryModel.isMemory(memory)) {
        memories = memories - 1;

      } else if (MemoryModel.isThing(memory)) {
        things = things - 1;

      } else if (MemoryModel.isPassword(memory)) {
        passwords = passwords - 1;
      }

      return {...state, currentBio: {...state.currentBio, ...{memories, things, passwords}}};
    }

    // No change;
    return state;
  }),

  on(BioActions.incrementCommentCounter, (state, {bio}) => {
    if (bio == null) {
      return state;
    }
    const commentCount = bio.commentCount + 1;
    return mergeBiosWithState(state, [{...bio, ...{commentCount}}]);
  }),

  on(BioActions.decrementCommentCounter, (state, {bio}) => {
    if (bio == null) {
      return state;
    }
    const commentCount = bio.commentCount - 1;
    return mergeBiosWithState(state, [{...bio, ...{commentCount}}]);
  }),

  on(BioActions.saveBioRequest, (state, {bio}) => {
    return mergeBiosWithState(state, [bio]);
  }),

  on(BioActions.saveBioProfileRequestSuccess, (state, {bio, file}) => {
    const newBio = {...bio, ...{profile: file}};
    return mergeBiosWithState(state, [newBio]);
  }),

  on(JarActions.saveJarRequestSuccess, (state, {responseMessage}) => {
    const jar: JarModel = responseMessage.data;
    if (jar == null || state.biosById[jar.bio] == null) {
      throw new Error('Profile doesn\'t exist in the store.');
    }
    let bio = state.biosById[jar.bio];
    const propKey = getJarPropKey(jar);
    const idx = bio[propKey].map(j => j.id).indexOf(jar.id);
    bio = {
      ...bio,
      ...{
        [propKey]: [
          ...bio[propKey].slice(0, idx),
          jar,
          ...bio[propKey].slice(idx + 1),
        ]
      }
    };
    return mergeBiosWithState(state, [bio]);
  }),

  on(JarActions.uploadJarFilesRequestSuccess, (state, {jar, files}) => {
    if (jar == null || state.biosById[jar.bio] == null) {
      throw new Error('Profile doesn\'t exist in the store.');
    }
    let bio = state.biosById[jar.bio];
    const propKey = getJarPropKey(jar);
    const idx = bio[propKey].map(j => j.id).indexOf(jar.id);
    bio = {
      ...bio,
      ...{
        [propKey]: [
          ...bio[propKey].slice(0, idx),
          {
            ...bio[propKey][idx],
            ...{
              files: [
                ...bio[propKey][idx].files,
                ...files
              ]
            }
          },
          ...bio[propKey].slice(idx + 1),
        ]
      }
    };
    return mergeBiosWithState(state, [bio]);
  }),

  on(JarActions.deleteJarFileRequest, (state, {jar, file}) => {
    if (jar == null || state.biosById[jar.bio] == null) {
      throw new Error('Profile doesn\'t exist in the store.');
    }
    let bio = state.biosById[jar.bio];
    const propKey = getJarPropKey(jar);
    const idx = bio[propKey].map(j => j.id).indexOf(jar.id);
    const fileIdx = jar.files.map(j => j.id).indexOf(file.id);
    bio = {
      ...bio,
      ...{
        [propKey]: [
          ...bio[propKey].slice(0, idx),
          {
            ...bio[propKey][idx],
            ...{
              files: [
                ...jar.files.slice(0, fileIdx),
                ...jar.files.slice(fileIdx + 1),
              ]
            }
          },
          ...bio[propKey].slice(idx + 1),
        ]
      }
    };
    return mergeBiosWithState(state, [bio]);
  }),

);

export function BiosReducer(state: BiosState, action: Action) {
  return reducer(state, action);
}
