import {AppState} from '../index';
import {createSelector} from '@ngrx/store';

export const selectTribesState = (state: AppState) => state.tribes;

export const selectTribeActivity = createSelector(
  selectTribesState,
  state => state.activity
);

export const selectTribeMemberActivity = createSelector(
  selectTribesState,
  state => state.activity.pending
);

export const selectTribeGuardianActivity = createSelector(
  selectTribesState,
  state => state.activity.guardians
);

export const selectTribeSearchResult = createSelector(
  selectTribesState,
  state => state.tribeSearchResult
);
