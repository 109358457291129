import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {AlertService} from '../../../../shared/components/alert/alert.service';
import {BreadcrumbNavItem} from '../../../../shared/components/breadcrumb/breadcrumb.component';

import {faSearch, faPlusCircle, faPencilAlt, faCaretRight, faEnvelope} from '@fortawesome/free-solid-svg-icons';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {BioService} from '../../../../core/services/bio.service';
import {Bio} from '../../../../core/models/bio.model';
import {Subscription} from 'rxjs';
import {TribeService} from '../../../../core/services/tribe.service';
import {filter} from 'rxjs/operators';
import {BioSearchModalComponent} from '../../../../shared/modals/bio-search/bio-search-modal.component';
import {FriendInviteModalComponent} from '../../../../shared/modals/friend-invite/friend-invite-modal.component';
import {TribeFilterModel} from '../../../../core/models/tribe-filter.model';
import {BioSearchResult} from '../../../../core/models/bio-search-result.model';
import {select, Store} from '@ngrx/store';
import {selectCurrentBio} from '../../../../store/bios/bios.selectors';
import {AppState} from '../../../../store';
import {TribeActivityResult} from '../../../../core/models/tribe-activity-result.model';
import {selectTribeActivity, selectTribeSearchResult} from '../../../../store/tribe/tribe.selectors';
import {TribeActions} from '../../../../store/tribe';
import {LayoutService} from '../../../../core/services/layout.service';

@Component({templateUrl: 'life-wall.component.html'})
export class LifeWallComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly subscription: Subscription;

  private debounceTimer;
  public bio: Bio;

  public faSearch = faSearch;
  public faPlusCircle = faPlusCircle;
  public faPencilAlt = faPencilAlt;
  public faCaretRight = faCaretRight;
  public faEnvelope = faEnvelope;

  // Form Values
  public keywords: string;

  // Pagination fields
  public page = 1;
  public results = 10;
  public pageCount = 1;

  public tribeActivity: TribeActivityResult;
  public tribeSearchResult: BioSearchResult;
  private searchFilters: TribeFilterModel;

  constructor(
    protected router: Router,
    protected store: Store<AppState>,
    protected modalService: NgbModal,
    protected alertService: AlertService,
    protected bioService: BioService,
    protected tribeService: TribeService,
    protected layoutService: LayoutService
  ) {
    this.layoutService.setTitle( `Life wall - My LifeJars`  );

    this.subscription = new Subscription();

    this.subscription.add(this.store
      .pipe(
        select(selectCurrentBio),
        filter( (bio: Bio) => bio != null )
      )
      .subscribe((bio: Bio) => {
        this.bio = bio;
        this.search();
      })
    );

    this.tribeActivity = new TribeActivityResult();
    this.subscription.add(this.store.pipe(select(selectTribeActivity))
      .subscribe((tribeActivity: TribeActivityResult) => {
        this.tribeActivity = tribeActivity;
      })
    );

    this.tribeSearchResult = new BioSearchResult();
    this.searchFilters = new TribeFilterModel();
    this.subscription.add(this.store.pipe(select(selectTribeSearchResult))
      .subscribe((tribeSearchResult: BioSearchResult) => {
        this.tribeSearchResult = tribeSearchResult;
      })
    );
  }

  public ngOnInit() {
  }

  public ngAfterViewInit(): void {
    this.layoutService.showTutorial('tribeTutorial');
  }

  // Pagination callback
  public onPageChange(page: number) {
    this.page = page;
    this.search();
  }

  // Ensure that the API isn't hammered when checkboxes are being selected
  public queueSearch() {
    if (typeof window !== 'undefined') {
      window.clearTimeout(this.debounceTimer);
      this.debounceTimer = window.setTimeout(() => {
        this.search();
      }, 1000);
    }
  }

  public search() {

    this.searchFilters = new TribeFilterModel();
    this.searchFilters.includeLegacy = false;
    this.searchFilters.includeLife = true;

    this.searchFilters.bio = this.bio.id;
    this.searchFilters.keywords = this.keywords;
    this.searchFilters.page = this.page;
    this.searchFilters.results = this.results;

    this.store.dispatch(
      TribeActions.searchTribeRequest({
        filters: this.searchFilters
      })
    );

  }

  public openAddBioModal() {
    const modal = this.modalService.open(BioSearchModalComponent, {});
  }

  public friendInvitation() {
    const modal = this.modalService.open(FriendInviteModalComponent, {});
    modal.componentInstance.bio = this.bio;
  }

  public requestResponse(response: boolean) {
    if (response === true) {
      this.search();
    }
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
    this.store.dispatch(TribeActions.resetTribeSearchRequest());
  }
}
