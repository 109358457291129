
export class NewUserSubscription {

  public subscriptionId: number;
  public stripeSourceId: string;
  public donationPartner: number;
  public couponCode: string;
  public gatewayKey: string;

  constructor() {
    this.subscriptionId = null;
    this.stripeSourceId = '';
    this.donationPartner = null;
    this.couponCode = '';
    this.gatewayKey = '';
  }

  public static make(obj: object): NewUserSubscription {
    if ( !obj || (Object.keys(obj).length === 0) ) {
      return null; // Object is null or empty
    }
    return {...new NewUserSubscription(), ...obj};
  }

}
