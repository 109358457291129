import {Component,  Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {faQuestionCircle} from '@fortawesome/free-solid-svg-icons';
import {AlertService} from '../../../components/alert/alert.service';
import {filter, first, map} from 'rxjs/operators';
import {MemoryService} from '../../../../core/services/memory.service';
import {FileUploaderComponent} from '../../../components/file-uploader/file-uploader.component';
import {Bio} from '../../../../core/models/bio.model';
import {JarModel} from '../../../../core/models/jar.model';
import {FileModel} from '../../../../core/models/file.model';
import {LayoutService} from '../../../../core/services/layout.service';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../store';
import {Subscription} from 'rxjs';
import {selectCurrentJar} from '../../../../store/jars/jars.selectors';
import {JarActions} from '../../../../store/jars';
import {ResponseMessage} from '../../../../core/models/response-message.model';
import {UserSubscription} from '../../../../core/models/user-subscription.model';
import {selectCurrentUserSubscription} from '../../../../store/users/users.selectors';
import {FileEditModalComponent} from '../../file-edit/file-edit-modal.component';
import { CommonText } from 'src/app/core/common/common-text';

@Component({
  templateUrl: 'jar-edit-modal.component.html'
})
export class JarEditModalComponent implements OnInit, OnDestroy {
  private readonly subscription: Subscription;

  public readonly CommonText = CommonText;

  public readonly DESCRIPTION_LENGTH = 10000;

  @ViewChild(FileUploaderComponent) fileUploader: FileUploaderComponent;
  @Input() public bio: Bio;
  @Input() public bioJar: JarModel;
  public editForm: FormGroup;
  public submitted = false;
  private debounceTimer;

  public faQuestionCircle = faQuestionCircle;

  public userSubscription: UserSubscription;

  // convenience getter for easy access to form fields
  public get f() {
    return this.editForm.controls;
  }

  constructor(
    public activeModal: NgbActiveModal,
    protected modalService: NgbModal,
    protected router: Router,
    protected formBuilder: FormBuilder,
    protected store: Store<AppState>,
    protected memoryService: MemoryService,
    protected alertService: AlertService,
    protected layoutService: LayoutService
  ) {
    this.bioJar = new JarModel();

    this.subscription = new Subscription();
    this.subscription.add(this.store
      .pipe(
        select(selectCurrentJar),
        filter((jar: JarModel) => jar != null)
      )
      .subscribe((jar: JarModel) => {
        this.bioJar = jar;
      })
    );

    this.subscription.add(this.store.pipe(select(selectCurrentUserSubscription))
      .subscribe((userSub: UserSubscription) => {
        this.userSubscription = userSub;
      })
    );

  }

  public ngOnInit() {

    this.editForm = this.formBuilder.group({
      description: ['', [Validators.maxLength(this.DESCRIPTION_LENGTH)]]
    });

    this.editForm.patchValue(this.bioJar);
  }

  public sortFiles(files: FileModel[]) {
    window.clearTimeout(this.debounceTimer);
    this.debounceTimer = window.setTimeout(() => {
      const fileIds = files.filter(f => f != null).map(f => f.id);
      this.store.dispatch(
        JarActions.sortJarFilesRequest({
          jar: this.bioJar,
          fileIds
        })
      );
    }, 1000);
  }

  public removeFile(file: any) {
    this.store.dispatch(
      JarActions.deleteJarFileRequest({
        jar: this.bioJar,
        file
      })
    );
  }

  public editFile(file: FileModel){
    const modal = this.modalService.open(FileEditModalComponent, {size: 'md', backdrop: 'static'});
    modal.componentInstance.bio = this.bio;
    modal.componentInstance.jar = this.bioJar;
    modal.componentInstance.file = file;
  }

  public showLightbox(file: FileModel) {
      this.layoutService.showLightbox(this.bioJar, file);
  }

  public showError(error: Error) {
    this.alertService.warning([error.message]);
  }

  public onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.editForm.invalid) {
      return;
    }

    const jarModel = {...this.bioJar, ...this.editForm.getRawValue()};

    this.memoryService.saveMemoryJar(jarModel)
      .pipe(first())
      .subscribe((responseMessage: ResponseMessage) => {
        const jar = responseMessage.data;

        this.store.dispatch(
          JarActions.saveJarRequestSuccess({
            responseMessage
          })
        );

        if (this.fileUploader.hasPendingUploads()) {
          this.fileUploader.uploadFiles(this.memoryService.saveFilesUrl(jar))
            .pipe(first())
            .subscribe((files: FileModel[]) => {
              this.store.dispatch(
                JarActions.uploadJarFilesRequestSuccess({
                  jar,
                  files
                })
              );
              this.afterSubmit(jarModel);
            });
        } else {
          this.afterSubmit(jarModel);
        }

      });
  }

  public afterSubmit(data: any) {
    this.activeModal.close(data);
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
