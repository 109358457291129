import {createAction, props} from '@ngrx/store';
import {PasswordModel} from '../../core/models/password.model';

export const getCurrentPassword = createAction(
  '[Passwords] get current password request',
  props<{ id: number }>()
);

export const setCurrentPassword = createAction(
  '[Passwords] set current password request ',
  props<{ password: PasswordModel }>()
);

export const effectError = createAction(
  '[Passwords] effect error',
  props<{ error: any }>()
);
