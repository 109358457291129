<div class="app-sitewide-search">
  <ng-template #rt let-result="result" let-term="term">
    <div class="app-sitewide-search__result" [ngSwitch]="result.type">

      <ng-container *ngSwitchCase="'bio'">
        <div class="search-result__icon">
          <fa-icon [icon]="faUser"></fa-icon>
          Profile
        </div>
        <div class="search-result__label">
          <ngb-highlight [result]="result.label" [term]="term"></ngb-highlight>
          <div class="description small">
            {{result.bio.location}}
          </div>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'bioNode'">
        <div class="search-result__icon">
          <fa-icon [icon]="faTag"></fa-icon>
          {{ (result.bioNode.nodeType|options: 'nodeType')?.label }}
        </div>
        <div class="search-result__label">
          <ngb-highlight [result]="result.label" [term]="term"></ngb-highlight>
          <div class="description small">
          </div>
        </div>
      </ng-container>

    </div>
  </ng-template>

  <div class="input-group">
    <input type="text" class="form-control" placeholder="Search..." aria-label="Search Profiles"
           [(ngModel)]="searchModel"
           [ngbTypeahead]="search"
           [editable]="false"
           [inputFormatter]="formatter"
           [resultTemplate]="rt"
           (selectItem)="onSearchSelect($event)">

    <div class="input-group-append">
      <span class="input-group-text">
        <span class="sr-only">Search</span>
        <fa-icon [icon]="faSearch"></fa-icon>
      </span>
<!--      <button class="btn btn-outline-secondary" type="button">-->
<!--        <span class="sr-only">Search</span>-->
<!--        <fa-icon [icon]="faSearch"></fa-icon>-->
<!--      </button>-->
    </div>
  </div>
</div>
