<div class="file-uploader-container">
  <ul class="file-uploader-list" [sortablejs]="fileList" [sortablejsOptions]="sortOptions">

    <li class="file-uploader-list-item" *ngFor="let item of fileList">
      <app-file-uploader-thumb
        [file]="item"
        [removable]="removable"
        [sortable]="sortable"
        [editable]="editable"
        (fileClick)="onThumbClick($event)"
        (fileRemove)="onThumbRemove($event)"
        (fileEdit)="onThumbEdit($event)"
        [bio]="bio"
        [memory]="memory"
        [preview]="preview"
        [public]="public"></app-file-uploader-thumb>
    </li>

    <li class="file-uploader-list-item" *ngFor="let item of queue">

      <app-file-uploader-thumb [file]="item"
                               [removable]="removable"
                               [sortable]="false"
                               [preview]="preview"
                               (fileRemove)="fileCancel($event)"
      ></app-file-uploader-thumb>

      <div class="file-uploader-list-item-progress" *ngIf="item.progress > 0">
        <div class="progress">
          <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': item.progress + '%' }"></div>
        </div>
      </div>

    </li>

    <li *ngIf="!disableUploads" class="file-uploader-list-item add-file" [draggable]="false">
      <div class="file-uploader-list-item-preview">
        <div class="file-uploader-list-item-text d-none d-sm-block">
          Drag &amp; Drop
          <br/>or Click to add
        </div>
        <input #fileUploadInput *ngIf="multiple" multiple type="file" (change)="fileDrop($event)" [accept]="fileTypes">
        <input #fileUploadInput *ngIf="!multiple" type="file" (change)="fileDrop($event)" [accept]="fileTypes">
      </div>
    </li>

  </ul>

</div><!-- /file-uploader-container -->
