import {Component} from '@angular/core';
import {IMemory} from '../../../core/models/memory.model';
import {MemoryActions} from '../../../store/memories';
import {NodeType} from '../../../core/common/enums';
import {JarDefaultModalComponent} from '../../../shared/modals/admin/jar-default/jar-default-modal.component';
import {JarModel} from '../../../core/models/jar.model';
import {AbstractMemoryDefaultsComponent} from '../abstract-memory-defaults/abstract-memory-defaults.component';

@Component({templateUrl: '../abstract-memory-defaults/abstract-memory-defaults.component.html'})
export class MemoryJarDefaultsComponent extends AbstractMemoryDefaultsComponent{

  public PageTitle = 'Memory Jar Defaults';
  public NodeType: NodeType = NodeType.MemoryJarDefault;

  public edit(jar?: IMemory) {
    this.store.dispatch(
      MemoryActions.setCurrentMemoryId({ id: jar?.id })
    );

    const modal = this.modalService.open(JarDefaultModalComponent, {size: 'lg', backdrop: 'static'});
    modal.componentInstance.jar = jar || new JarModel();
    modal.result.then((editedMemory: IMemory) => {
      this.search();
    }, (reason) => {
      console.log(reason);
    });
  }
}
