import {createAction, props} from '@ngrx/store';
import {IMemory} from '../../core/models/memory.model';
import {ResponseMessage} from '../../core/models/response-message.model';
import {FileModel} from '../../core/models/file.model';
import {MemoryFilterModel} from '../../core/models/memory-filter.model';
import {MemorySearchResult} from '../../core/models/memory-search-result.model';
import {NodeType} from '../../core/common/enums';

export const searchDefaultMemoriesRequest = createAction(
  '[Memories] search default memories request',
  props<{ filters?: MemoryFilterModel, nodeType: NodeType }>()
);

export const searchDefaultMemoriesSuccess = createAction(
  '[Memories] search default memories request success',
  props<{ memorySearchResult: MemorySearchResult, setFirstAsCurrent?: boolean, appendResults?: boolean }>()
);

export const saveDefaultMemoryRequest = createAction(
  '[Memories] save default memory request',
  props<{ memory: IMemory, files: FileModel[] }>()
);

export const saveDefaultMemorySuccess = createAction(
  '[Memories] save default memory success',
  props<{ responseMessage: ResponseMessage }>()
);

export const publishDefaultMemoryRequest = createAction(
  '[Memories] publish default memory request',
  props<{ memory: IMemory }>()
);

export const publishDefaultMemorySuccess = createAction(
  '[Memories] publish default memory success',
  props<{ responseMessage: ResponseMessage }>()
);

export const deleteDefaultMemoryRequest = createAction(
  '[Memories] delete default memory request',
  props<{ memory: IMemory }>()
);

export const deleteDefaultMemorySuccess = createAction(
  '[Memories] delete default memory success',
  props<{ memory: IMemory, responseMessage: ResponseMessage }>()
);

export const saveDefaultMemoryFileMetaRequest = createAction(
  '[Memories] save default memory file meta request',
  props<{ memory: IMemory, file: FileModel }>()
);

export const saveDefaultMemoryFileMetaSuccess = createAction(
  '[Memories] save default memory file meta success',
  props<{ memory: IMemory, responseMessage: ResponseMessage }>()
);

export const deleteDefaultMemoryFileRequest = createAction(
  '[Memories] delete default memory file request',
  props<{ memory: IMemory, file: FileModel }>()
);

export const deleteDefaultBannerRequest = createAction(
  '[Memories] delete default banner request',
  props<{ memory: IMemory, file: FileModel }>()
);

export const deleteDefaultIconRequest = createAction(
  '[Memories] delete default icon request',
  props<{ memory: IMemory, file: FileModel }>()
);

export const deleteDefaultMemoryFileSuccess = createAction(
  '[Memories] delete default memory file success',
  props<{ responseMessage: ResponseMessage }>()
);

export const deleteDefaultBannerSuccess = createAction(
  '[Memories] delete default banner success',
  props<{ responseMessage: ResponseMessage }>()
);

export const deleteDefaultIconSuccess = createAction(
  '[Memories] delete default icon success',
  props<{ responseMessage: ResponseMessage }>()
);


export const sortDefaultMemoryFilesRequest = createAction(
  '[Memories] sort default memory files request',
  props<{ memory: IMemory, fileIds: number[] }>()
);

export const sortDefaultMemoryFilesSuccess = createAction(
  '[Memories] sort default memory files success',
  props<{ responseMessage: ResponseMessage }>()
);

export const uploadDefaultMemoryFilesSuccess = createAction(
  '[Memories] upload default memory files success',
  props<{ memory: IMemory, files: FileModel[] }>()
);
