import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, concatMap, map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {DefaultMemoryActions, MemoryActions} from './index';
import {of} from 'rxjs';
import {ResponseMessage} from '../../core/models/response-message.model';
import {AlertService} from '../../shared/components/alert/alert.service';
import {MemoryService} from '../../core/services/memory.service';
import {MemorySearchResult} from '../../core/models/memory-search-result.model';
import {FileUploaderService} from '../../shared/components/file-uploader/file-uploader.service';
import {NodeType} from '../../core/common/enums';

@Injectable()
export class DefaultMemoriesEffects {

  constructor(
    private actions$: Actions,
    private memoryService: MemoryService,
    private fileUploader: FileUploaderService,
    private alertService: AlertService
  ) {
  }

  searchDefaultMemoryRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DefaultMemoryActions.searchDefaultMemoriesRequest),
      concatMap(action => this.memoryService.searchDefaultMemories(action.filters, action.nodeType).pipe(
        map((memorySearchResult: MemorySearchResult) =>
          MemoryActions.searchMemoriesRequestSuccess({
            memorySearchResult
          })
        ))
      ),
      catchError(error => {
        return of(MemoryActions.effectError({error}));
      })
    )
  );

  saveDefaultMemoryRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DefaultMemoryActions.saveDefaultMemoryRequest),
      mergeMap(action =>
        this.memoryService.saveDefaultMemory(action.memory).pipe(
          tap((responseMessage: ResponseMessage) => {
            this.alertService.info(responseMessage.message);
          }),

          switchMap((responseMessage: ResponseMessage) => [
            DefaultMemoryActions.saveDefaultMemorySuccess({
              responseMessage
            }),
          ]),

          catchError(error => {
            return of(MemoryActions.effectError({error}));
          })
        )
      )
    )
  );


  publishDefaultMemoryRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DefaultMemoryActions.publishDefaultMemoryRequest),
      mergeMap(action =>
        this.memoryService.publishDefaultMemory(action.memory).pipe(
          tap((responseMessage: ResponseMessage) => {
            this.alertService.info(responseMessage.message);
          }),

          switchMap((responseMessage: ResponseMessage) => [
            DefaultMemoryActions.publishDefaultMemorySuccess({
              responseMessage
            }),
          ]),

          catchError(error => {
            return of(MemoryActions.effectError({error}));
          })
        )
      )
    )
  );

  deleteDefaultMemoryRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DefaultMemoryActions.deleteDefaultMemoryRequest),
      mergeMap(action =>
        this.memoryService.deleteDefaultMemory(action.memory).pipe(
          tap((responseMessage: ResponseMessage) => {
            this.alertService.warning(responseMessage.message);
          }),

          concatMap((responseMessage: ResponseMessage) => [
            DefaultMemoryActions.deleteDefaultMemorySuccess({memory: action.memory, responseMessage}),
          ]),

          catchError(error => {
            return of(MemoryActions.effectError({error}));
          })
        )
      )
    )
  );

  sortDefaultMemoryFilesRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DefaultMemoryActions.sortDefaultMemoryFilesRequest),
      mergeMap(action =>
        this.memoryService.sortDefaultFiles(action.memory, action.fileIds).pipe(
          tap((responseMessage: ResponseMessage) => {
            this.alertService.warning(responseMessage.message);
          }),

          map(responseMessage =>
            DefaultMemoryActions.sortDefaultMemoryFilesSuccess({responseMessage})
          ),

          catchError(error => {
            return of(MemoryActions.effectError({error}));
          })
        )
      )
    )
  );

  deleteDefaultMemoryFileRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DefaultMemoryActions.deleteDefaultMemoryFileRequest),
      mergeMap(action =>
        this.memoryService.removeDefaultFile(action.memory, action.file).pipe(
          tap((responseMessage: ResponseMessage) => {
            this.alertService.warning(responseMessage.message);
          }),

          map(responseMessage =>
            DefaultMemoryActions.deleteDefaultMemoryFileSuccess({responseMessage})
          ),

          catchError(error => {
            return of(MemoryActions.effectError({error}));
          })
        )
      )
    )
  );

  deleteDefaultBannerRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DefaultMemoryActions.deleteDefaultBannerRequest),
      mergeMap(action =>
        this.memoryService.removeDefaultBanner(action.memory, action.file).pipe(
          tap((responseMessage: ResponseMessage) => {
            this.alertService.warning(responseMessage.message);
          }),

          map(responseMessage =>
            DefaultMemoryActions.deleteDefaultBannerSuccess({responseMessage})
          ),

          catchError(error => {
            return of(MemoryActions.effectError({error}));
          })
        )
      )
    )
  );

  deleteDefaultIconRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DefaultMemoryActions.deleteDefaultIconRequest),
      mergeMap(action =>
        this.memoryService.removeDefaultIcon(action.memory, action.file).pipe(
          tap((responseMessage: ResponseMessage) => {
            this.alertService.warning(responseMessage.message);
          }),

          map(responseMessage =>
            DefaultMemoryActions.deleteDefaultIconSuccess({responseMessage})
          ),

          catchError(error => {
            return of(MemoryActions.effectError({error}));
          })
        )
      )
    )
  );

  saveDefaultMemoryFileMetaRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DefaultMemoryActions.saveDefaultMemoryFileMetaRequest),
      mergeMap(action =>
        this.memoryService.patchDefaultFile(action.memory, action.file).pipe(
          tap((responseMessage: ResponseMessage) => {
            this.alertService.info(responseMessage.message);
          }),

          switchMap((responseMessage: ResponseMessage) => [
            DefaultMemoryActions.saveDefaultMemoryFileMetaSuccess({
              memory: action.memory,
              responseMessage
            }),
          ]),

          catchError(error => {
            return of(MemoryActions.effectError({error}));
          })
        )
      )
    )
  );

}



