import {initialBioUserState, BioUsersState} from './bio-users.state';
import {Action, createReducer, on} from '@ngrx/store';
import {BioUserActions} from './index';
import {BioUser} from '../../core/models/bio-user.model';

const mergeBioUsersWithState = (state: BioUsersState, bioUsers: BioUser[]) => {
  const byId = {};
  for (const bioUser of bioUsers) {
    byId[bioUser.id] = bioUser;
  }

  return {
    ...state,
    byId: {
      ...state.byId,
      ...byId
    }
  };
};

const reducer = createReducer<BioUsersState>(
  initialBioUserState,

  on(BioUserActions.setCurrentBioUserId, (state, {id}) => {
    return {
      ...state,
      currentBioUserId: id
    };
  }),

  on(BioUserActions.setBioUsers, (state, {bioUsers}) => {
    return mergeBioUsersWithState({...state, byId: {}}, bioUsers);
  }),

  on(BioUserActions.getBioUsersSuccess, (state, {bioUsers}) => {
    return mergeBioUsersWithState({...state, byId: {}}, bioUsers);
  }),

  on(BioUserActions.getBioUserSuccess, (state, {bioUser}) => {
    return mergeBioUsersWithState(state, [bioUser]);
  }),

  on(BioUserActions.saveBioUserSuccess, (state, {bioUser}) => {
    return mergeBioUsersWithState(state, [bioUser]);
  }),

  on(BioUserActions.deleteBioUserSuccess, (state, {
    responseMessage,
    bioUser
  }) => {
    return {
      ...state,
      byId: {
        ...state.byId,
        ...{
          [bioUser.id]: undefined
        }
      }
    };
  }),
);

export function BioUsersReducer(state: BioUsersState, action: Action) {
  return reducer(state, action);
}
