import {createAction, props} from '@ngrx/store';
import {CommentModel} from '../../core/models/comment.model';
import {ResponseMessage} from '../../core/models/response-message.model';
import {FileModel} from '../../core/models/file.model';
import {Bio} from '../../core/models/bio.model';
import {MemoryModel} from '../../core/models/memory.model';

export const setCurrentCommentId = createAction(
  '[Comments] set current comment id request ',
  props<{ id: number }>()
);

export const resetSearchCommentsRequest = createAction(
  '[Comments] reset search comments request'
);

export const searchCommentsRequest = createAction(
  '[Comments] search comments request',
  props<{ bio: Bio, setFirstAsCurrent?: boolean }>()
);

export const searchMemoryCommentsRequest = createAction(
  '[Comments] search memory comments request',
  props<{ bioNode?: MemoryModel, setFirstAsCurrent?: boolean }>()
);

export const searchCommentsRequestSuccess = createAction(
  '[Comments] search comments request success',
  props<{ comments: CommentModel[], setFirstAsCurrent?: boolean }>()
);

export const saveCommentRequest = createAction(
  '[Comments] save comment request',
  props<{ comment: CommentModel}>()
);

export const saveCommentRequestSuccess = createAction(
  '[Comments] save comment request success',
  props<{ responseMessage: ResponseMessage }>()
);

export const deleteCommentRequest = createAction(
  '[Comments] delete comment request',
  props<{ comment: CommentModel }>()
);

export const deleteCommentRequestSuccess = createAction(
  '[Comments] delete comment request success',
  props<{ comment: CommentModel, responseMessage: ResponseMessage }>()
);


export const effectError = createAction(
  '[Comments] effect error',
  props<{ error: any }>()
);
