<div class="modal-body modal-body-lightbox">
  <div #lightbox class="lightbox-container" [style.width]="width + 'px'" [style.height]="height + 'px'">
    <app-file-lazy-loader
      [memory]="memory"
      [bio]="bio"
      [file]="file" (loadingComplete)="onLoadComplete($event)"
      [public]="true" [preview]="false"></app-file-lazy-loader>

    <div class="file-title" *ngIf="file && file.title">
      {{file.title}}
    </div>

  </div>

  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>

  <button *ngIf="fileList && fileList?.length > 1" type="button" class="btn-prev" aria-label="Previous" (click)="previous()">
    <span aria-hidden="true"> &lt; </span>
  </button>

  <button *ngIf="fileList && fileList.length > 1" type="button" class="btn-next" aria-label="Next" (click)="next()">
    <span aria-hidden="true"> &gt; </span>
  </button>

</div>
