import {Component, Input, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../store';
import {OptionSelectors} from '../../../store/options';
import {filter, first} from 'rxjs/operators';
import {Tribe} from '../../../core/models/tribe.model';
import {TribeRelationshipModalComponent} from '../../modals/tribe-relationship/tribe-relationship-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-tribe-relationship',
  templateUrl: 'tribe-relationship.component.html',
  styleUrls: ['tribe-relationship.component.scss']
})
export class TribeRelationshipComponent implements OnInit {
  @Input() tribe: Tribe;
  public text: string;

  constructor(
    private store: Store<AppState>,
    private modalService: NgbModal
  ) {
    this.text = '';
  }

  public ngOnInit(): void {
    if (this.tribe) {
      let subType = -1;
      let other = '';
      if (this.tribe.sender) {
        subType = this.tribe.relationshipSubType;
        other = this.tribe.relationshipOther;
      } else {
        subType = this.tribe.inverseSubType;
        other = this.tribe.inverseOther;
      }
      if (subType === -1) {
        this.text = other;
      } else {
        this.store.pipe(first(), select(OptionSelectors.selectRelationshipType(subType)))
          .pipe(filter( option => option != null ))
          .subscribe((option) => {
            this.text = option.label;
          });
      }
    }
  }

  public changeRelationship(event){
    event.stopPropagation();
    event.preventDefault();
    const modal = this.modalService.open(TribeRelationshipModalComponent, {});
    modal.componentInstance.tribe = this.tribe;
  }

}
