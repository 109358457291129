
<div *ngIf="photos?.length == 1">
  <div class="img-container" style="width:127mm;height:153mm">
    <app-file-lazy-loader [memory]="memory" [file]="photos[0]"></app-file-lazy-loader>
  </div>
</div>

<div *ngIf="photos?.length == 2">
  <div class="img-container" style="width:127mm;height:76mm">
    <app-file-lazy-loader [memory]="memory" [file]="photos[0]"></app-file-lazy-loader>
  </div>
  <div class="img-container" style="width:127mm;height:76mm">
    <app-file-lazy-loader [memory]="memory" [file]="photos[1]"></app-file-lazy-loader>
  </div>
</div>

<div *ngIf="photos?.length == 3">
  <div class="img-container" style="width:127mm;height:92mm">
    <app-file-lazy-loader [memory]="memory" [file]="photos[0]"></app-file-lazy-loader>
  </div>

  <div class="img-container" style="width:63mm;height:61mm"
       *ngFor="let item of photos | slice:1; let index = index;" >
    <app-file-lazy-loader [memory]="memory" [file]="item"></app-file-lazy-loader>
  </div>

</div>

<div *ngIf="photos?.length >= 4">

  <div class="img-container" style="width:127mm;height:92mm">
    <app-file-lazy-loader [memory]="memory" [file]="photos[0]"></app-file-lazy-loader>
  </div>
  <div class="img-container" style="width:42mm;height:54mm"
       *ngFor="let item of photos | slice:1:4; let index = index;" >
    <app-file-lazy-loader [memory]="memory" [file]="item"></app-file-lazy-loader>
  </div>
</div>
