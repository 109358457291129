import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {PasswordsActions} from './index';
import {PasswordService} from '../../core/services/password.service';
import {PasswordModel} from '../../core/models/password.model';

@Injectable()
export class PasswordsEffects {

  constructor(
    private actions$: Actions,
    private passwordService: PasswordService
  ) {
  }

  getCurrentPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PasswordsActions.getCurrentPassword),
      mergeMap(action =>
        this.passwordService.getPassword(action.id).pipe(
          map((password: PasswordModel) =>
            PasswordsActions.setCurrentPassword({
              password
            })
          ),
          catchError(error => {
            return of(PasswordsActions.effectError({error}));
          })
        )
      )
    )
  );

}



