import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {PasswordModel} from '../models/password.model';
import {AppConfig} from './app-config.service';

@Injectable({providedIn: 'root'})
export class PasswordService {
  constructor(
    private http: HttpClient
  ) {
  }

  public getPassword(id: number): Observable<PasswordModel> {
    return this.http.get<PasswordModel>(`${AppConfig.settings.apiBaseUrl}/password/${id}`);
  }

}
