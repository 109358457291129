import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../core/services/auth.service';
import {AlertService} from '../../../shared/components/alert/alert.service';
import {ControlValidationService} from '../../../shared/components/control-validation/control-validation.service';
import {first} from 'rxjs/operators';
import {LoaderService} from '../../../shared/components/content-loader/loader.service';
import {LayoutService} from '../../../core/services/layout.service';

@Component({templateUrl: 'password-reset.component.html'})
export class PasswordResetComponent implements OnInit {
  public passwordResetForm: FormGroup;
  public loading = false;
  public submitted = false;

  public showJunkMailAlert = false;

  // convenience getter for easy access to form fields
  public get f() {
    return this.passwordResetForm.controls;
  }

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private alertService: AlertService,
    private loaderService: LoaderService,
    private layoutService: LayoutService
  ) {
    this.layoutService.setTitle(`Password Reset - My LifeJars`);
  }

  public ngOnInit() {
    this.passwordResetForm = this.formBuilder.group({
      username: ['', [Validators.required, ControlValidationService.emailValidator]]
    });
  }

  public onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.passwordResetForm.invalid) {
      return;
    }

    this.loading = true;
    this.loaderService.showLoader();
    this.authService.passwordReset(this.f.username.value)
      .pipe(first())
      .subscribe(
        data => {
          this.loading = false;
          this.loaderService.hideLoader();
          this.alertService.error(data.message);
          this.showJunkMailAlert = true;
        },
        error => {
          this.loading = false;
          this.loaderService.hideLoader();
        });
  }

}
