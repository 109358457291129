import {Component, Input} from '@angular/core';
import {Bio} from '../../../../core/models/bio.model';
import {AbstractListDirective} from '../abstract-list.directive';

@Component({
    selector: 'app-legacy-bio-list',
    templateUrl: 'legacy-bio-list.component.html'
})
export class LegacyBioListComponent extends AbstractListDirective {
    @Input() public list: Bio[] = [];
}
