import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges, OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {FileUploaderService} from '../file-uploader.service';
import {FileModel} from '../../../../core/models/file.model';
import {Bio} from '../../../../core/models/bio.model';
import {IMemory} from '../../../../core/models/memory.model';
import {filter, first, takeUntil} from 'rxjs/operators';
import {isPlatformBrowser} from '@angular/common';
import {ModerationStatus} from '../../../../core/common/enums';
import {faDownload} from '@fortawesome/free-solid-svg-icons';
import {Subject} from 'rxjs';

export class FileParams {
  public width = 0;
  public height = 0;
  public duration = 0;
}

@Component({
  selector: 'app-file-lazy-loader',
  templateUrl: 'file-lazy-loader.component.html',
  styleUrls: ['file-lazy-loader.component.css']
})
export class FileLazyLoaderComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('image') imgElement: ElementRef;
  @ViewChild('document') objectElement: ElementRef;
  @ViewChild('video') videoElement: ElementRef;
  @ViewChild('audio') audioElement: ElementRef;

  @Input()
  public file: FileModel;

  @Output()
  public loadingComplete: EventEmitter<FileParams>;

  // Application specific
  @Input() public bio: Bio;
  @Input() public memory: IMemory;
  @Input() public downloadable: boolean;

  // This indicates that the Public API endpoints should be used
  @Input() public public: boolean;

  // Request the preview or thumbnail from server
  @Input() public preview: boolean;

  public loading: boolean;
  public faDownload = faDownload;

  public ModerationStatus = ModerationStatus;

  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    @Inject(PLATFORM_ID) protected platformId,
    protected fileUploader: FileUploaderService,
  ) {
    this.file = new FileModel();
    this.loadingComplete = new EventEmitter<FileParams>();
    this.loading = true;
    this.downloadable = false;
    this.public = false;
    this.preview = false;
  }

  public ngOnInit(): void {
  }

  public ngOnChanges(changes: SimpleChanges) {
    this.loading = true;

    if ((!this.bio && !this.memory) || !this.file) {
      this.loading = false;
      return;
    }

    // Server-side rendering. Don't load the remote file data.
    const isBrowser = isPlatformBrowser(this.platformId);
    if (isBrowser === false) {
      this.file = {
        ...this.file, ...{
          src: null
        }
      };
      this.loading = false;
      return;
    }

    if ( this.file.moderation !== ModerationStatus.Approved){
      this.loading = false;
      return;
    }

    // Check Cache
    const cacheKey = this.bio
      ? 'bio_' + this.bio.id + '_' + this.file.id + (this.preview ? '_preview' : '')
      : 'memory_' + this.memory.id + '_' + this.file.id + (this.preview ? '_preview' : '');

    const cacheFile = this.fileUploader.getCache(cacheKey);
    if (cacheFile != null) {
      this.file = {...this.file, ...cacheFile};
      this.loading = false;
      return;
    }

    // Observable reference
    const observable$ = this.fileUploader
      .downloadFile(this.bio, this.memory, this.file, this.public, this.preview )
      // Need to be able to abort the http request when the file loaded is destroyed
      .pipe( takeUntil(this.ngUnsubscribe) );

    if (observable$ != null) {
      this.loading = true;
      observable$
        .pipe(first(), filter((f) => f != null))
        .subscribe((file: FileModel) => {
          if (file && this.file) {
            this.file = {...this.file, ...file};
            this.fileUploader.setCache(cacheKey, {
              src: this.file.src,
              expiry: (new Date()).getTime() + (120 * 1000) // Expire in 120-seconds
            });
          } else {
            console.log('File Loader Exception: ', this.bio, this.memory, this.file);
          }
          this.loading = false;

        }, (error) => {
          this.file = null;
          this.loading = false;
          console.log('File Loader Server Exception: ', this.bio, this.memory, this.file);
        });
    }
  }

  public onLoadingComplete(event: any) {
    const params = new FileParams();
    if (this.isImage() && this.imgElement) {
      const element = (this.imgElement.nativeElement as HTMLImageElement);
      params.width = element.width;
      params.height = element.height;

    } else if (this.isVideo() && this.videoElement) {
      const element = (this.videoElement.nativeElement as HTMLVideoElement);
      params.width = element.videoWidth;
      params.height = element.videoHeight;
      params.duration = element.duration;

    } else if (this.isAudio() && this.audioElement) {
      const element = (this.audioElement.nativeElement as HTMLAudioElement);
      params.duration = element.duration;

    } else if (this.isDocument() && this.objectElement) {
      const element = (this.objectElement.nativeElement as HTMLObjectElement);
    }

    this.loadingComplete.emit(params);
    this.loading = false;
  }

  public isImage() {
    return this.fileUploader.isImage(this.file);
  }

  public isDocument() {
    return this.fileUploader.isDocument(this.file);
  }

  public isVideo() {
    return this.fileUploader.isVideo(this.file);
  }

  public isAudio() {
    return this.fileUploader.isAudio(this.file);
  }

  public isUnknown() {
    return this.fileUploader.isUnknown(this.file);
  }

  ngOnDestroy(): void {
    // Aborts all HTTP requests.
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
