import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {faCalendar, faClock, faEye, faLock, faPencilAlt, faPlusCircle, faSearch, faShare, faTag} from '@fortawesome/free-solid-svg-icons';
import {MemoryEditModalComponent} from '../../../../shared/modals/memory/edit/memory-edit-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {IMemory, MemoryModel} from '../../../../core/models/memory.model';
import {IListOption} from '../../../../core/models/option.model';
import {MemoryFilterModel} from '../../../../core/models/memory-filter.model';
import {filter} from 'rxjs/operators';
import {MemorySearchResult} from '../../../../core/models/memory-search-result.model';
import {Bio} from '../../../../core/models/bio.model';
import {JarModel} from '../../../../core/models/jar.model';
import {JarEditModalComponent} from '../../../../shared/modals/memory-jar/edit/jar-edit-modal.component';
import {Subscription} from 'rxjs';
import {FileModel} from '../../../../core/models/file.model';
import {MemoryViewModalComponent} from '../../../../shared/modals/memory/view/memory-view-modal.component';
import {LayoutService} from '../../../../core/services/layout.service';
import {UserSubscription} from '../../../../core/models/user-subscription.model';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../store';
import {OptionsState} from '../../../../store/options/options.state';
import {selectCurrentJar} from '../../../../store/jars/jars.selectors';
import {JarActions, JarSelectors} from '../../../../store/jars';
import {MemoryActions, MemorySelectors} from '../../../../store/memories';
import {BioActions, BioSelectors} from '../../../../store/bios';
import {OptionSelectors} from '../../../../store/options';
import {UserSelectors} from '../../../../store/users';
import {BioUserRole, NodeType} from '../../../../core/common/enums';
import {ConfirmPopupComponent} from '../../../../shared/modals/confirm-popup/confirm-popup.component';

@Component({templateUrl: 'memory-jars.component.html'})
export class MemoryJarsComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly JAR_FILES_LIMIT = 12;
  private readonly SEARCH_RESULTS_DEFAULT = 4;
  private readonly subscription: Subscription = new Subscription();

  private debounceTimer;

  public bio: Bio;
  public bioJars: JarModel[];
  public userSubscription: UserSubscription;
  public optionsData: OptionsState;

  public fakeMemoryJarFiles: FileModel[] = [];
  public faSearch = faSearch;
  public faPlusCircle = faPlusCircle;
  public faPencilAlt = faPencilAlt;
  public faLock = faLock;
  public faEye = faEye;
  public faClock = faClock;
  public faTag = faTag;
  public faCalendar = faCalendar;
  public faShare = faShare;

  public get MemoryJarTypes(): JarModel[] {
    return this.bioJars;
  }

  public get MemoryTagTypes() {
    return this.optionsData.memoryTagTypes;
  }

  public get SecretKeeperTypes() {
    return this.optionsData.secretKeeperTypes;
  }

  public get TimeLockTypes() {
    return this.optionsData.timeLockTypes;
  }

  // Form Values
  public keywords: string;
  public bioJar: JarModel;
  public memoryTagTypeFilters: any[] = [];
  public timeLockTypeFilters: any[] = [];
  public secretKeeperTypeFilters: any[] = [];

  // Pagination fields
  public page = 1;
  public results = this.SEARCH_RESULTS_DEFAULT;
  public pageCount = 1;

  // Data from API
  public filters: MemoryFilterModel = new MemoryFilterModel();
  public pagedMemories: MemorySearchResult = new MemorySearchResult();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private modalService: NgbModal,
    private layoutService: LayoutService
  ) {

    this.layoutService.setTitle( `Memory Jars - My LifeJars`  );

    this.optionsData = new OptionsState();
    this.subscription.add(this.store
      .pipe(
        select(OptionSelectors.selectOptions)
      )
      .subscribe((options: OptionsState) => {
        this.optionsData = options;
      })
    );

    this.subscription.add(this.store
      .pipe(
        select(UserSelectors.selectCurrentUserSubscription)
      )
      .subscribe((userSub: UserSubscription) => {
        this.userSubscription = userSub;
      })
    );

    this.subscription.add(this.store
      .pipe(
        select(BioSelectors.selectCurrentBio),
        filter((bio: Bio) => bio != null)
      )
      .subscribe((bio: Bio) => {
        this.bio = bio;
        this.store.dispatch(
          BioActions.setDisplayBioRequest({bio})
        );
      })
    );

    this.subscription.add(this.store
      .pipe(
        select(selectCurrentJar),
        filter((jar: JarModel) => jar != null && jar.nodeType === NodeType.MemoryJar)
      )
      .subscribe((jar: JarModel) => {
        this.bioJar = jar;
        this.store.dispatch( MemoryActions.resetSearchMemoriesRequest() );
        this.loadFakeJarFiles();
        this.resetFilters();
      })
    );

    this.bioJars = [];
    this.subscription.add(this.store
      .pipe(
        select(JarSelectors.selectMemoryJars),
        filter((jars: JarModel[]) => jars.length > 0)
      )
      .subscribe((jars: JarModel[]) => {
        this.bioJars = jars;

        if (this.bioJar == null || this.bioJar.nodeType !== NodeType.MemoryJar || this.bioJar.bio !== this.bio.id) {
          this.store.dispatch(
            JarActions.setCurrentJarId({
              id: this.bioJars[0]?.id
            })
          );
        }
      })
    );

    this.subscription.add(this.store
      .pipe(select(MemorySelectors.selectMemorySearchResult))
      .subscribe((searchResults: MemorySearchResult) => {
        this.pagedMemories = searchResults;
      })
    );

  }

  private loadFakeJarFiles() {
    this.fakeMemoryJarFiles = [];
    const jarFilesDelta = (this.JAR_FILES_LIMIT - this.bioJar.files.length);
    if (jarFilesDelta > 0) {
      for (let i = 0, l = jarFilesDelta; i < l; i++) {
        this.fakeMemoryJarFiles.push(null);
      }
    }
  }

  public ngOnInit(): void {
    this.subscription.add(
      this.route.params.subscribe(params => {
        this.keywords = params.keywords || '';
        if (this.keywords) {
          this.page = 1;
          this.search();
        }
      })
    );
  }

  ngAfterViewInit(): void {
    this.layoutService.showTutorial('memoryJarsTutorial');
  }

  // MemoryJar Change Callback
  public onBioJarChange() {
    this.store.dispatch(
      JarActions.setCurrentJarId({
        id: this.bioJar.id
      })
    );
  }

  // Pagination callback
  public onPageChange(page: number) {
    this.page = page;
    this.search();
  }

  // Ensure that the API isn't hammered when checkboxes are being selected
  public queueSearch() {
    if (typeof window !== 'undefined') {
      window.clearTimeout(this.debounceTimer);
      this.debounceTimer = window.setTimeout(() => {
        this.page = 1;
        this.search();
      }, 1000);
    }
  }

  public resetFilters() {

    // Pagination
    this.keywords = '';
    this.page = 1;
    this.results = this.SEARCH_RESULTS_DEFAULT;
    this.pageCount = 1;

    this.memoryTagTypeFilters = [];
    this.MemoryTagTypes.forEach((item: IListOption) => {
      this.memoryTagTypeFilters.push({
        item, selected: false
      });
    });

    this.timeLockTypeFilters = [];
    this.TimeLockTypes.forEach((item: IListOption) => {
      this.timeLockTypeFilters.push({
        item, selected: false
      });
    });

    this.secretKeeperTypeFilters = [];
    this.SecretKeeperTypes.forEach((item: IListOption) => {
      this.secretKeeperTypeFilters.push({
        item, selected: false
      });
    });

    // Obviously we should also update the search
    this.search();
  }

  public search() {

    this.filters = new MemoryFilterModel();
    this.filters.keywords = this.keywords;
    this.filters.page = this.page;
    this.filters.results = this.results;

    // Set the Bio ID
    this.filters.bio = this.bio.id;

    // Set the Memory Jar ID
    this.filters.parentNodes = [this.bioJar.id];

    // Convert Selected Form Values to ID array
    this.memoryTagTypeFilters.forEach((item: any) => {
      if (item.selected) {
        this.filters.memoryTagTypes.push(item.item.id);
      }
    });

    this.timeLockTypeFilters.forEach((item: any) => {
      if (item.selected) {
        this.filters.timeLockTypes.push(item.item.id);
      }
    });

    this.secretKeeperTypeFilters.forEach((item: any) => {
      if (item.selected) {
        this.filters.secretKeeperTypes.push(item.item.id);
      }
    });

    this.store.dispatch(
      MemoryActions.searchMemoriesRequest({
        filters: this.filters
      })
    );
  }


  public openMemoryJarModal() {
    const modal = this.modalService.open(JarEditModalComponent, {size: 'lg', backdrop: 'static'});
    modal.componentInstance.bio = this.bio;
    modal.componentInstance.memoryJar = this.bioJar;
  }

  public openMemoryModal(memory?: IMemory) {

    // Prevent adding Memories that are past the limit
    // 0: Unlimited
    if (memory == null && this.userSubscription.memories > 0 && this.userSubscription.memories <= this.bio.memories) {
      return this.layoutService.showPlanUpgrade();
    }

    // Prepare the Memory
    if (memory != null) {

      // Edit existing Memory So prepare the store
      this.store.dispatch(
        MemoryActions.setCurrentMemoryId({ id: memory.id })
      );

    } else {

      // Create a new Memory
      memory = new MemoryModel();
      memory.bio = this.bio.id;
      memory.parentNode = this.bioJar.id;
    }

    const modal = this.modalService.open(MemoryEditModalComponent, {size: 'lg', backdrop: 'static'});
    modal.componentInstance.bio = this.bio;
    modal.componentInstance.memory = memory;
    modal.result.then((editedMemory: MemoryModel) => {

      if (editedMemory) {

        // Set the Jar to match the new Memory
        if (this.bioJar.id !== editedMemory.parentNode) {
          this.bioJar = Bio.getJarById(editedMemory.parentNode, this.bioJars);
          this.onBioJarChange();
        }

      }
    }, (reason) => {
      console.log(reason);
    });
  }

  public viewMemory(memory: IMemory) {

    this.store.dispatch(
      MemoryActions.setCurrentMemoryId({ id: memory.id })
    );

    const modal = this.modalService.open(MemoryViewModalComponent, {size: 'lg'});
    modal.componentInstance.bio = this.bio;
    // modal.componentInstance.memory = memory;
  }

  public showLightbox(file: FileModel, memoryOrJar: IMemory) {
      this.layoutService.showLightbox(memoryOrJar, file);
  }

  public canEdit(){
    return  this.bio && (this.bio.permissions.memoryJars === BioUserRole.FullAccess || this.bio.isOwner === true);
  }

  public removeShared(memory: IMemory) {
    const modal = this.modalService.open(ConfirmPopupComponent, {size: 'sm', backdrop: 'static'});
    modal.componentInstance.title = 'Remove shared memory';
    modal.componentInstance.message = 'Are you sure you want to remove this shared memory?';
    modal.componentInstance.classes = 'danger';
    modal.result.then((result) => {
      this.store.dispatch(
        MemoryActions.deleteMemoryRequest({
          memory
        })
      );
    }, (reason) => {});
  }


  public ngOnDestroy() {
    this.subscription.unsubscribe();
    this.store.dispatch( MemoryActions.resetSearchMemoriesRequest() );
  }
}
