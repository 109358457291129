import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Subscription} from 'rxjs';
import {User} from '../../../core/models/user.model';
import {Bio} from '../../../core/models/bio.model';
import {UserSubscription} from '../../../core/models/user-subscription.model';
import {select, Store} from '@ngrx/store';
import {selectCurrentUser, selectCurrentUserSubscription} from '../../../store/users/users.selectors';
import {AppState} from '../../../store';
import {selectCurrentBio} from '../../../store/bios/bios.selectors';

@Component({
  templateUrl: 'plan-change-warning-modal.component.html'
})
export class PlanChangeWarningModalComponent implements OnDestroy  {
  public currentPlan: UserSubscription;
  public newPlan: UserSubscription;

  constructor(
    public activeModal: NgbActiveModal,
    protected modalService: NgbModal
  ) {

  }

  public ngOnDestroy() {
  }
}
