<form [formGroup]="editForm" (ngSubmit)="onSubmit()">
  <div class="modal-header">
    <h4 class="modal-title">Memory</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body modal-body-memory-edit">

    <div class="form-group">
      <label class="control-label text-center d-block">
        Add Images, Documents, Audio &amp; Video Files

        <button class="btn" type="button"
                [ngbPopover]="CommonText.Popover.FileUpload"
                popoverTitle="Images">
          <fa-icon [icon]="faQuestionCircle" class="ico-gold"></fa-icon>
          <span class="sr-only">Help</span>
        </button>

      </label>
      <app-file-uploader ngbAutofocus [memory]="memory" [fileList]="memory?.files"
                         preview="true"
                         [sortable]="true"
                         [removable]="true"
                         [editable]="true"
                         [maxSize]="userSubscription.fileSize"
                         [maxCount]="10"
                         [multiple]="true"
                         (fileSort)="sortFiles($event)"
                         (fileClick)="showLightbox($event)"
                         (fileRemove)="removeFile($event)"
                         (fileEdit)="editFile($event)"
                         (fileError)="showError($event)"></app-file-uploader>
    </div>

    <div class="form-group">
      <label class="control-label">Memory Title</label>
      <input type="text" formControlName="title" class="form-control form-control-lg input-bio"
             [ngClass]="{ 'is-invalid': submitted && f.title.errors }" placeholder="Memory Title"
             aria-label="Memory Title"/>
      <app-control-validation [control]="f.title" [submitted]="submitted"></app-control-validation>
    </div>

    <div class="form-group row">
      <div class="col-6 col-sm-3">
        <label class="control-label">Jar</label>
        <select formControlName="parentNode" class="form-control input-bio" (change)="onJarChange($event)"
                [ngClass]="{ 'is-invalid': submitted && f.parentNode.errors }" aria-label="Jar">
          <option [ngValue]="null" disabled>Select one...</option>
          <option *ngFor="let item of MemoryJarTypes" [ngValue]="item.id">{{ item.title }}</option>
        </select>
        <app-control-validation [control]="f.parentNode" [submitted]="submitted"></app-control-validation>
      </div>

      <div class="col-6 col-sm-3">
        <label class="control-label">Tag</label>
        <select formControlName="memoryTag" class="form-control input-bio"
                [ngClass]="{ 'is-invalid': submitted && f.memoryTag.errors }" aria-label="Tag">
          <option [ngValue]="null">Select one...</option>
          <option *ngFor="let item of MemoryTagTypes" [ngValue]="item.id">{{ item.label }}</option>
        </select>
        <app-control-validation [control]="f.memoryTag" [submitted]="submitted"></app-control-validation>
      </div>

      <div class="col-6 col-sm-3">
        <label class="control-label">Secret Keeper</label>
        <select #secretKeeper formControlName="secretKeeper" class="form-control input-bio"
                (change)="onSecretKeeperChange($event)"

                [ngClass]="{ 'is-invalid': submitted && f.secretKeeper.errors }" aria-label="Secret Keeper">
          <option [ngValue]="null">Select one...</option>
          <option *ngFor="let item of SecretKeeperTypes" [ngValue]="item.id">{{ item.label }}</option>
        </select>
        <button type="button" class="btn btn-sm"
                *ngIf="enableTribeEditor()"
                (click)="showTribeEditor($event)">
          <fa-icon [icon]="faUsers" class="ico-gold"></fa-icon> Select Tribesters
        </button>
        <app-control-validation [control]="f.secretKeeper" [submitted]="submitted"></app-control-validation>
      </div>

      <div class="col-6 col-sm-3">
        <label class="control-label">Time Lock</label>
        <select #timeLock formControlName="timeLock" class="form-control input-bio"
                (change)="onTimeLockChange($event)"
                [ngClass]="{ 'is-invalid': submitted && f.timeLock.errors }" aria-label="Time Lock">
          <option [ngValue]="null">Select one...</option>
          <option *ngFor="let item of TimeLockTypes" [ngValue]="item.id">{{ item.label }}</option>
        </select>
        <app-control-validation [control]="f.timeLock" [submitted]="submitted"></app-control-validation>
      </div>

    </div>

    <div class="form-group row">
      <div class="col-6">
        <label class="control-label">Location</label>

        <div class="input-group">
          <input (click)="onLocationChange($event)" type="text" formControlName="location"
                 class="form-control input-bio"
                 [ngClass]="{ 'is-invalid': submitted && f.location.errors }" placeholder="Location"
                 aria-label="Location"/>
          <div class="input-group-append btn-outline-secondary">
            <button class="btn" type="button" (click)="onLocationChange($event)">
              <span class="sr-only">Location</span>
              <fa-icon [icon]="faMapMarker"></fa-icon>
            </button>
          </div>
        </div>
        <app-control-validation [control]="f.location" [submitted]="submitted"></app-control-validation>

      </div>

      <div class="col-6">
        <label class="control-label">Date</label>

        <div class="input-group">
          <input ngbDatepicker type="text" formControlName="date" class="form-control input-bio"
                 [ngClass]="{ 'is-invalid': submitted && f.date.errors }" placeholder="DD/MM/YYYY"
                 aria-label="DD/MM/YYYY"
                 #d="ngbDatepicker" [placement]="['bottom-right','top-right']"/>
          <div class="input-group-append btn-outline-secondary">
            <button class="btn" type="button" (click)="d.toggle()">
              <span class="sr-only">Calendar</span>
              <fa-icon [icon]="faCalendar"></fa-icon>
            </button>
          </div>
        </div>

        <app-control-validation [control]="f.date" [submitted]="submitted"></app-control-validation>
      </div>

    </div>

    <div class="form-group">
      <label class="control-label">
        Description
        <button class="btn" type="button"
                ngbPopover="Descriptions are limited to {{DESCRIPTION_LENGTH | number:'1.0-0'}} characters." popoverTitle="Description">
          <fa-icon [icon]="faQuestionCircle" class="ico-gold"></fa-icon>
          <span class="sr-only">Help</span>
        </button>
      </label>
      <textarea maxlength="{{DESCRIPTION_LENGTH}}" autosize formControlName="description" class="form-control input-bio"
                [ngClass]="{ 'is-invalid': submitted && f.description.errors }" placeholder="Add a detailed description"
                aria-label="Description" rows="5" [placeholder]="memory.placeholder"></textarea>

      <app-character-countdown [text]="f.description.value" [maxLength]="DESCRIPTION_LENGTH" ></app-character-countdown>

      <app-control-validation [control]="f.description" [submitted]="submitted"></app-control-validation>
    </div>


    <div class="form-group d-flex justify-content-center">

      <div class="custom-control custom-checkbox form-control-lg">
        <input  id="allowComments" class="custom-control-input" type="checkbox" formControlName="allowComments"
               [ngClass]="{ 'is-invalid': submitted && f.allowComments.errors }"/>
        <label for="allowComments" class="custom-control-label">
          Allow Recollections
        </label>
      </div>

      <div class="custom-control custom-checkbox form-control-lg">
        <input  id="allowShare" class="custom-control-input" type="checkbox" formControlName="allowShare"
                [ngClass]="{ 'is-invalid': submitted && f.allowShare.errors }"/>
        <label for="allowShare" class="custom-control-label">
          Allow Sharing
        </label>
      </div>

    </div>

  </div>

  <div class="modal-footer">
    <button *ngIf="memory.id" type="button" class="btn btn-outline-dark mr-auto"
            (click)="delete($event)">
      Delete
    </button>

    <button type="button" class="btn btn-light" (click)="activeModal.dismiss()">
      Cancel
    </button>

    <button type="submit" class="btn btn-gold">
      Save
    </button>
  </div>

</form>
<app-content-loader>Please wait... Encrypting files</app-content-loader>
