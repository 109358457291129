<div class="modal-header">
    <h4 class="modal-title">Location</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <div class="form-group row">
      <div class="col-12">

        <div class="block block-search d-block">
          <div class="input-group">
            <input id="address" #search  type="text" class="form-control" placeholder="Address search..." aria-label="Search Address"
                   autocorrect="off" autocapitalize="off" spellcheck="off" [ngModel]="address">
            <div class="input-group-append">
              <button class="btn" type="button">
                <span class="sr-only">Search</span>
                <fa-icon [icon]="faSearch"></fa-icon>
              </button>
            </div>
          </div>
        </div><!-- /block-search -->


      </div>
    </div>

    <div class="form-group row">
      <div class="col-12">

        <agm-map [latitude]="mapCoords?.lat" [longitude]="mapCoords?.lng" [zoom]="zoom" (mapClick)="onMapClick($event)">
          <agm-marker [latitude]="markerCoords?.lat" [longitude]="markerCoords?.lng" [markerDraggable]="true" (dragEnd)="onMapClick($event)"></agm-marker>
        </agm-map>

      </div>
    </div>

  </div>
  <div class="modal-footer">

    <button *ngIf="!readonly" type="button" class="btn btn-outline-dark" (click)="onSubmit()">
      Save
    </button>

    <button *ngIf="readonly"  type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('Close')">
      Close
    </button>

  </div>
<app-content-loader></app-content-loader>
