import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Bio} from '../../../core/models/bio.model';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../store';
import {TribeActions} from '../../../store/tribe';
import {OptionsState} from '../../../store/options/options.state';
import {selectOptions, selectRelationshipSubTypeByType, selectRelationshipTypes} from '../../../store/options/options.selectors';
import {Subscription} from 'rxjs';
import {first} from 'rxjs/operators';
import {IListOption} from '../../../core/models/option.model';
import {Tribe} from '../../../core/models/tribe.model';

@Component({
  templateUrl: 'tribe-relationship-modal.component.html'
})
export class TribeRelationshipModalComponent implements OnInit, OnDestroy {
  private readonly subscription: Subscription;

  @Input() public tribe: Tribe;
  @Input() public fromBio: Bio;
  @Input() public toBio: Bio;

  public editForm: FormGroup;
  public submitted = false;

  public relationshipTypes: IListOption[];
  public relationshipSubTypesFiltered: IListOption[];

  // convenience getter for easy access to form fields
  public get f() { return this.editForm.controls; }

  constructor(
    public activeModal: NgbActiveModal,
    protected modalService: NgbModal,
    protected formBuilder: FormBuilder,
    protected store: Store<AppState>,
  ) {
    this.subscription = new Subscription();

    this.relationshipTypes = [];
    this.subscription.add(this.store.pipe(select(selectRelationshipTypes))
      .subscribe((options: IListOption[]) => {
        this.relationshipTypes = options;
      })
    );
  }

  public ngOnInit() {
    this.editForm = this.formBuilder.group({
      relationshipType: [null, Validators.required],
      relationshipSubType: [null, Validators.required],
    });
  }

  public onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.editForm.invalid) {
      return;
    }

    const model = this.editForm.getRawValue();

    this.store.dispatch(
      this.tribe
        ? TribeActions.updateTribeRequest({model, tribe: this.tribe})
        : TribeActions.addTribeRequest({model, toBio: this.toBio, fromBio: this.fromBio})
    );
    this.activeModal.close(model);
  }

  public onRelationshipChange(event) {
    const relationshipType = this.editForm.get('relationshipType').value;
    this.relationshipSubTypesFiltered = [];
    this.store
      .pipe(
        select(selectRelationshipSubTypeByType(relationshipType)),
        first()
      )
      .subscribe((options: IListOption[]) => {
        this.relationshipSubTypesFiltered = options;
      });
  }

  public onRelationshipSubChange(event) {
    const id = this.editForm.get('relationshipSubType').value;
    if (id === -1) {
      this.editForm.addControl('relationshipOther', new FormControl('', Validators.required));
    } else {
      this.editForm.removeControl('relationshipOther');
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
