import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {faCalendar, faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons';
import {AlertService} from '../../../components/alert/alert.service';
import {IMemory, MemoryModel} from '../../../../core/models/memory.model';
import {filter, first} from 'rxjs/operators';
import {MemoryService} from '../../../../core/services/memory.service';
import {FileUploaderComponent} from '../../../components/file-uploader/file-uploader.component';
import {AuthService} from '../../../../core/services/auth.service';
import {ConfirmPopupComponent} from '../../confirm-popup/confirm-popup.component';
import {ResponseMessage} from '../../../../core/models/response-message.model';
import {LoaderService} from '../../../components/content-loader/loader.service';
import {FileModel} from '../../../../core/models/file.model';
import {LayoutService} from '../../../../core/services/layout.service';
import {IListOption} from '../../../../core/models/option.model';
import {Subscription} from 'rxjs';
import {OptionsState} from '../../../../store/options/options.state';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../store';
import {selectOptions} from '../../../../store/options/options.selectors';
import {DefaultMemoryActions, MemorySelectors} from '../../../../store/memories';
import {FileEditModalComponent} from '../../file-edit/file-edit-modal.component';
import {NodeType} from '../../../../core/common/enums';

@Component({
  templateUrl: 'memory-default-modal.component.html'
})
export class MemoryDefaultModalComponent implements OnInit, OnDestroy {
  private readonly subscription: Subscription;

  public readonly NodeType = NodeType;

  public CONCEPT_KEYWORD = 'Memory';

  @ViewChild(FileUploaderComponent)
  fileUploader: FileUploaderComponent;

  @Input()
  public memory: IMemory;

  public editForm: FormGroup;
  public submitted = false;
  public faCalendar = faCalendar;
  public faMapMarker = faMapMarkerAlt;
  private debounceTimer;

  public optionsData: OptionsState;

  // Tags filtered by Jar
  public tagsFiltered: IListOption[];

  // convenience getter for easy access to form fields
  public get f() {
    return this.editForm.controls;
  }

  public get JarTypes() {
    return this.optionsData.memoryJarTypes;
  }

  public get TagTypes() {
    return this.optionsData.memoryTagTypes;
  }

  public get SecretKeeperTypes() {
    return this.optionsData.secretKeeperTypes;
  }

  public get TimeLockTypes() {
    return this.optionsData.timeLockTypes;
  }

  constructor(
    public activeModal: NgbActiveModal,
    protected formBuilder: FormBuilder,
    protected modalService: NgbModal,
    protected store: Store<AppState>,
    protected authService: AuthService,
    protected memoryService: MemoryService,
    protected alertService: AlertService,
    protected layoutService: LayoutService,
    protected loaderService: LoaderService
  ) {
    this.CONCEPT_KEYWORD = 'Memory';

    this.memory = new MemoryModel();

    this.subscription = new Subscription();
    this.optionsData = new OptionsState();
    this.subscription.add(this.store.pipe(select(selectOptions))
      .subscribe((options: OptionsState) => {
        this.optionsData = options;
      })
    );

  }

  public ngOnInit() {

    // Existing Memory so Subscribe to the store
    if (this.memory.id) {
      this.subscription.add(this.store
        .pipe(
          select(MemorySelectors.selectCurrentMemory),
          filter((memory: MemoryModel) => memory != null)
        )
        .subscribe((memory: MemoryModel) => {
          this.memory = memory;
        })
      );
    }

    this.editForm = this.formBuilder.group({
      title: ['', Validators.required],
      jarTag: [null, Validators.required],
      memoryTag: [null, Validators.required],
      secretKeeper: [null, Validators.required],
      timeLock: [null, Validators.required],
      placeholder: ['']
    });

    this.editForm.patchValue(this.memory);

    this.onJarChange(null);
  }

  public onJarChange(event) {
    this.tagsFiltered = this.TagTypes;
  }

  public sortFiles(files: FileModel[]) {
    if (typeof window !== 'undefined') {
      window.clearTimeout(this.debounceTimer);
      this.debounceTimer = window.setTimeout(() => {
        const fileIds = files.filter(f => f != null).map(f => f.id);
        this.store.dispatch(
          DefaultMemoryActions.sortDefaultMemoryFilesRequest({
            memory: this.memory,
            fileIds
          })
        );
      }, 1000);
    }
  }

  public removeFile(file: any) {
    this.store.dispatch(
      DefaultMemoryActions.deleteDefaultMemoryFileRequest({
        memory: this.memory,
        file
      })
    );
  }

  public editFile(file: FileModel){
    const modal = this.modalService.open(FileEditModalComponent, {size: 'md', backdrop: 'static'});
    modal.componentInstance.memory = this.memory;
    modal.componentInstance.file = file;
  }

  public delete(event: any) {
    event.preventDefault();

    const modal = this.modalService.open(ConfirmPopupComponent, {size: 'sm', backdrop: 'static'});
    modal.componentInstance.title = 'Delete ' + this.CONCEPT_KEYWORD;
    modal.componentInstance.message = 'Are you sure you want to delete this ' + this.CONCEPT_KEYWORD + '?';
    modal.componentInstance.classes = 'danger';
    modal.result.then((result) => {

      this.store.dispatch(
        DefaultMemoryActions.deleteDefaultMemoryRequest({
          memory: this.memory
        })
      );
      this.activeModal.close(null);

    }, (reason) => {});
  }

  public showLightbox(file: FileModel) {
      this.layoutService.showLightbox(this.memory, file);
  }

  public showError(error: Error) {
    this.alertService.warning([error.message]);
  }

  public onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.editForm.invalid) {
      return;
    }

    const model = Object.assign({}, this.memory, this.editForm.getRawValue());

    this.memoryService.saveDefaultMemory(model)
      .pipe(first())
      .subscribe(
        (responseMessage: ResponseMessage) => {
          const memory = responseMessage.data;

          this.store.dispatch(
            DefaultMemoryActions.saveDefaultMemorySuccess({
              responseMessage
            })
          );

          if ( this.fileUploader && this.fileUploader.hasPendingUploads() ) {
            this.fileUploader.uploadFiles(this.memoryService.saveDefaultFilesUrl(memory))
              .pipe(first())
              .subscribe(files => {
                this.store.dispatch(
                  DefaultMemoryActions.uploadDefaultMemoryFilesSuccess({
                    memory,
                    files
                  })
                );
                this.afterSubmit(memory);
              });
          } else {
            this.afterSubmit(memory);
          }
        });
  }

  public afterSubmit(data: any) {
    const modal = this.modalService.open(ConfirmPopupComponent, {size: 'md', backdrop: 'static'});
    modal.componentInstance.title = 'Publish';
    modal.componentInstance.message = 'Do you want to publish this default to all un-edited member Profiles?';
    modal.componentInstance.classes = 'warning';
    modal.result.then((result) => {
      this.store.dispatch(
        DefaultMemoryActions.publishDefaultMemoryRequest({
          memory: this.memory,
        })
      );
      this.activeModal.close(data);
    }, (reason) => {
      this.activeModal.close(data);
    });
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
