import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../core/services/auth.service';
import {AlertService} from '../../../shared/components/alert/alert.service';
import {Subscription} from 'rxjs';
import {first} from 'rxjs/operators';
import {LayoutService} from '../../../core/services/layout.service';
import {User} from '../../../core/models/user.model';
import {ResponseMessage} from '../../../core/models/response-message.model';

@Component({templateUrl: 'login-code.component.html'})
export class LoginCodeComponent implements OnInit, OnDestroy {
  private readonly subscription: Subscription;
  public loginForm: FormGroup;
  public loading = false;
  public submitted = false;
  private returnUrl: string;

  // convenience getter for easy access to form fields
  public get f() {
    return this.loginForm.controls;
  }

  constructor(
    protected formBuilder: FormBuilder,
    protected route: ActivatedRoute,
    protected router: Router,
    protected authService: AuthService,
    protected alertService: AlertService,
    protected layoutService: LayoutService
  ) {
    this.subscription = new Subscription();

    // redirect to dashboard if already logged in
    if (this.authService.hasAuthenticated()) {
      this.router.navigate(['/']);
    }

    this.layoutService.setTitle(`Verify sign in - My LifeJars`);
  }

  public ngOnInit() {

    this.loginForm = this.formBuilder.group({
      code: ['', [Validators.required]]
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
  }

  public onResendCode(event){
    event.preventDefault();
    this.authService.loginStep2Resend()
      .pipe(first())
      .subscribe(
        (data: ResponseMessage) => {
          this.alertService.info(data.message);
        });
  }

  public onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.authService.loginStep2(this.f.code.value)
      .pipe(first())
      .subscribe(
        (data: User) => {
          this.router.navigate([this.returnUrl]);
        });
  }

  public showTerms(): string {
    return this.layoutService.showTerms();
  }

  public showPrivacy(): string {
    return this.layoutService.showPrivacy();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
