<nav *ngIf="pages > 1" class="mt-4" aria-label="Memories Pagination">
  <ul class="pagination justify-content-center">

    <li *ngIf="!isFirst()" class="page-item">
      <a class="page-link" aria-label="Previous" (click)="previous()">
        <span aria-hidden="true">&laquo;</span>
        <span class="sr-only">Previous</span>
      </a>
    </li>

    <li *ngFor="let pg of pageList;" class="page-item" [ngClass]="{'active':(pg==page)}">
      <a class="page-link" (click)="goto(pg)">{{pg}}</a>
    </li>

    <li *ngIf="!isLast()" class="page-item">
      <a class="page-link" aria-label="Next" (click)="next()">
        <span aria-hidden="true">&raquo;</span>
        <span class="sr-only">Next</span>
      </a>
    </li>

  </ul>
</nav>
