import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export const LOADER_ROUTES = new InjectionToken<LoaderRoute[]>('LOADER_ROUTES');

export interface LoaderRoute {
  scope?: string | string[];
  path: RegExp;
  ignore?: boolean;
}

export class LoaderParams {
  public message = '';
  public isLoading = false;

  constructor(message: string, isLoading: boolean) {
    this.message = message;
    this.isLoading = isLoading;
  }
}

export class ScopedLoader {
  private scopeName: string;
  private counter: number;
  private readonly subject: BehaviorSubject<LoaderParams>;

  constructor(scopeName: string) {
    this.scopeName = scopeName || '';
    this.counter = 0;
    this.subject = new BehaviorSubject<LoaderParams>(new LoaderParams(null, false));
  }

  public show(message = null) {
    ++this.counter;
    this.subject.next(new LoaderParams(message, true));
    return this;
  }

  public hide() {
    if (--this.counter <= 0) {
      this.counter = 0;
      this.subject.next(new LoaderParams(null, false));
    }
    return this;
  }

  public reset() {
    this.counter = 0;
    this.subject.next(new LoaderParams(null, false));
    return this;
  }

  public watch(): BehaviorSubject<LoaderParams> {
    return this.subject;
  }

  public isLoading(): boolean {
    return this.counter > 0;
  }
}

@Injectable({ providedIn: 'root' })
export class LoaderService {
  private readonly DEFAULT_SCOPE: string = '__default';
  private readonly loaders: any;
  private routes: LoaderRoute[];

  constructor(@Optional() @Inject(LOADER_ROUTES) routes: LoaderRoute[] = []) {
    this.loaders = {};
    this.routes = routes || [];
  }

  public getLoaderFilter(url: string): LoaderRoute[] {
    url = url || '';
    return this.routes.filter(f => {
      const matches = url.match(f.path) || [];
      if (matches.length > 0) {
        return true;
      }
      return false;
    });
  }

  public getScopedLoader(scopeName?: string): ScopedLoader {
    scopeName = scopeName || this.DEFAULT_SCOPE;
    if (!this.loaders[scopeName]) {
      this.loaders[scopeName] = new ScopedLoader(scopeName);
    }
    return this.loaders[scopeName];
  }

  public watchLoader(scopeName?: string): BehaviorSubject<LoaderParams> {
    const scope = this.getScopedLoader(scopeName);
    return scope.watch();
  }

  public showLoader(url?: string): ScopedLoader[] {
    const loadedScopes: ScopedLoader[] = [];
    const filters = this.getLoaderFilter(url);
    if (filters.length) {
      for (let i = 0, l = filters.length; i < l; i++) {
        const filter = filters[i];
        if (filter && filter.ignore) {
          continue;
        }

        let scopes = filter.scope || [''];
        if (filter && filter.scope && Array.isArray(filter.scope) === false) {
          scopes = [filter.scope as string];
        }

        for (let i2 = 0, l2 = scopes.length; i2 < l2; i2++) {
          const scopeName = scopes[i2];
          const scope = this.getScopedLoader(scopeName);
          scope.show();
          loadedScopes.push(scope);
        }
      }
    } else {
      const scope = this.getScopedLoader();
      scope.show();
      loadedScopes.push(scope);
    }
    return loadedScopes;
  }

  public hideLoader(url?: string): ScopedLoader[] {
    const hiddenScopes: ScopedLoader[] = [];
    const filters = this.getLoaderFilter(url);
    if (filters.length) {
      for (let i = 0, l = filters.length; i < l; i++) {
        const filter = filters[i];

        let scopes = filter.scope || [''];
        if (filter && filter.scope && Array.isArray(filter.scope) === false) {
          scopes = [filter.scope as string];
        }

        for (let i2 = 0, l2 = scopes.length; i2 < l2; i2++) {
          const scopeName = scopes[i2];
          const scope = this.getScopedLoader(scopeName);
          scope.hide();
          hiddenScopes.push(scope);
        }
      }
    } else {
      const scope = this.getScopedLoader();
      scope.hide();
      hiddenScopes.push(scope);
    }
    return hiddenScopes;
  }

  public showScopedLoader(scopeName?: string): ScopedLoader {
    const scope = this.getScopedLoader(scopeName);
    return scope.show();
  }

  public hideScopedLoader(scopeName?: string): ScopedLoader {
    const scope = this.getScopedLoader(scopeName);
    return scope.hide();
  }

  public hideAll() {
    for (const key of Object.keys(this.loaders)) {
      const scope = this.loaders[key];
      scope.hide();
    }
  }

  public isLoading(scopeName?: string): boolean {
    const scope = this.getScopedLoader(scopeName);
    return scope.isLoading();
  }

  public reset(scopeName?: string): ScopedLoader {
    const scope = this.getScopedLoader(scopeName);
    return scope.reset();
  }
}
