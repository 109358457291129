<ng-template #headerControls>
  <div class="block block-btns text-right mt-sm-2">
    <button class="btn btn-gold" type="button" (click)="showWardModal($event)">
      <fa-icon [icon]="faPlusCircle" class="mr-1"></fa-icon>
      Create <span class="d-none d-sm-inline">Profile</span>
    </button>
  </div>
</ng-template>

<app-page-header title="Wards" [controls]="headerControls"></app-page-header>

<div class="container">
  <div class="row">
    <div class="col-12">

      <div *ngIf="userBios" class="tribe-active-container mt-4">
        <app-ward-list [list]="userBios | listSort: 'lastName'"
                           [selectable]="true"
                           (select)="switchBio($event)"></app-ward-list>
      </div>

    </div>
  </div>
</div><!--/container-->
