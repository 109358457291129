<form [formGroup]="editForm" (ngSubmit)="onSubmit()">
  <div class="modal-header">
    <h4 class="modal-title">{{CONCEPT_KEYWORD}} Default</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body modal-body-memory-edit">

    <div class="form-group" *ngIf="memory.nodeType != NodeType.PasswordDefault">
      <label class="control-label text-center d-block">Add Images, Documents, Audio &amp; Video Files</label>
      <app-file-uploader ngbAutofocus  [memory]="memory" [fileList]="memory?.files"
                         [editable]="true"
                         [sortable]="true"
                         [removable]="true"
                         [maxSize]="10"
                         [maxCount]="10"
                         (fileSort)="sortFiles($event)"
                         (fileRemove)="removeFile($event)"
                         (fileClick)="showLightbox($event)"
                         (fileError)="showError($event)"></app-file-uploader>
    </div>

    <div class="form-group">
      <label class="control-label">Memory Title</label>
      <input ngbAutofocus  type="text" formControlName="title" class="form-control form-control-lg input-bio"
             [ngClass]="{ 'is-invalid': submitted && f.title.errors }" placeholder="Memory Title" aria-label="Memory Title" />
      <app-control-validation [control]="f.title" [submitted]="submitted"></app-control-validation>
    </div>

    <div class="form-group row">
      <div class="col-6 col-sm-3">
        <label class="control-label">Jar</label>
        <select formControlName="jarTag" class="form-control input-bio" (change)="onJarChange($event)"
               [ngClass]="{ 'is-invalid': submitted && f.jarTag.errors }" aria-label="Jar">
          <option [ngValue]="null" disabled>Select one...</option>
          <option *ngFor="let item of JarTypes" [ngValue]="item.id">{{ item.label }}</option>
        </select>
        <app-control-validation [control]="f.jarTag" [submitted]="submitted"></app-control-validation>
      </div>

      <div class="col-6 col-sm-3" *ngIf="memory.nodeType != NodeType.PasswordDefault">
        <label class="control-label">Tag</label>
        <select formControlName="memoryTag" class="form-control input-bio"
                [ngClass]="{ 'is-invalid': submitted && f.memoryTag.errors }" aria-label="Tag">
          <option [ngValue]="null" disabled>Select one...</option>
          <option *ngFor="let item of tagsFiltered" [ngValue]="item.id">{{ item.label }}</option>
        </select>
        <app-control-validation [control]="f.memoryTag" [submitted]="submitted"></app-control-validation>
      </div>

      <div class="col-6 col-sm-3">
        <label class="control-label">Secret Keeper</label>
        <select #secretKeeper formControlName="secretKeeper" class="form-control input-bio"
                [ngClass]="{ 'is-invalid': submitted && f.secretKeeper.errors }" aria-label="Secret Keeper">
          <option [ngValue]="null" disabled>Select one...</option>
          <option *ngFor="let item of SecretKeeperTypes" [ngValue]="item.id">{{ item.label }}</option>
        </select>
        <app-control-validation [control]="f.secretKeeper" [submitted]="submitted"></app-control-validation>
      </div>

      <div class="col-6 col-sm-3">
        <label class="control-label">Time Lock</label>
        <select #timeLock formControlName="timeLock" class="form-control input-bio"
                [ngClass]="{ 'is-invalid': submitted && f.timeLock.errors }" aria-label="Time Lock">
          <option [ngValue]="null" disabled>Select one...</option>
          <option *ngFor="let item of TimeLockTypes" [ngValue]="item.id">{{ item.label }}</option>
        </select>
        <app-control-validation [control]="f.timeLock" [submitted]="submitted"></app-control-validation>
      </div>

    </div>

    <div class="form-group">
      <label class="control-label">Placeholder</label>
      <textarea autosize formControlName="placeholder" class="form-control input-bio"
                 [ngClass]="{ 'is-invalid': submitted && f.placeholder.errors }" placeholder="Add a placeholder description"
                aria-label="Placeholder" rows="6"></textarea>
      <app-control-validation [control]="f.placeholder" [submitted]="submitted"></app-control-validation>
    </div>
  </div>

  <div class="modal-footer">
    <button *ngIf="memory.id" type="button" class="btn btn-danger mr-auto"
            (click)="delete($event)">
      Delete
    </button>

    <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss()">
      Cancel
    </button>

    <button type="submit" class="btn btn-gold ml-2">
      Save
    </button>

    <button *ngIf="memory.id" type="button" class="btn btn-success ml-2" title="Publish to all members Profiles"
      (click)="this.afterSubmit(memory)">
      Publish
    </button>
  </div>
</form>
<app-content-loader></app-content-loader>
