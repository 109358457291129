import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-secured-banner',
  templateUrl: 'secured-banner.component.html',
  styleUrls: ['secured-banner.component.css']
})
export class SecuredBannerComponent {
  constructor() {}
}
