<form [formGroup]="editForm" (ngSubmit)="onSubmit()">
<div class="modal-header">
  <h4 class="modal-title">File edit</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss(false)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <form-validation [control]="editForm" [submitted]="submitted"></form-validation>

  <div class="form-group">
    <label class="control-label sr-only">Title</label>
    <input type="text" formControlName="title" class="form-control form-control-lg input-bio"
           [ngClass]="{ 'is-invalid': submitted && f.title.errors }" placeholder="Title" />
    <app-control-validation [control]="f.title" [submitted]="submitted"></app-control-validation>
  </div>

  <div class="form-group text-center text-sm-right">
    <button type="submit" class="btn btn-gold">
      Save
    </button>
    <button type="button" class="btn btn-outline-dark ml-3" (click)="activeModal.dismiss()">
      Cancel
    </button>
  </div>

</div>
</form>
<app-content-loader></app-content-loader>
