import {CommentModel} from '../../core/models/comment.model';

export class CommentsState {
  currentCommentId: number;
  comments: CommentModel[];
}

export const initialCommentState: CommentsState = {
  currentCommentId: 0,
  comments: [],
};
