import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, concatMap, map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {MemoryActions} from './index';
import {concat, EMPTY, of} from 'rxjs';
import {ResponseMessage} from '../../core/models/response-message.model';
import {AlertService} from '../../shared/components/alert/alert.service';
import {MemoryService} from '../../core/services/memory.service';
import {MemorySearchResult} from '../../core/models/memory-search-result.model';
import {BioActions} from '../bios';
import {PublicBioService} from '../../core/services/public-bio.service';

@Injectable()
export class MemoriesEffects {

  constructor(
    private actions$: Actions,
    private publicBioService: PublicBioService,
    private memoryService: MemoryService,
    private alertService: AlertService
  ) {
  }


  searchMemoryRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemoryActions.searchMemoriesRequest),
      concatMap(action => this.memoryService.search(action.filters).pipe(
          map((memorySearchResult: MemorySearchResult) =>
            MemoryActions.searchMemoriesRequestSuccess({
              memorySearchResult,
              setFirstAsCurrent: action.setFirstAsCurrent,
              appendResults: action.appendResults
            })
          )
        )
      ),
      catchError(error => {
        return of(MemoryActions.effectError({error}));
      })
    )
  );

  searchPublicMemoryRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemoryActions.searchPublicMemoriesRequest),
      concatMap(action => this.publicBioService.searchMemories(action.bio,  action.filters).pipe(
        map((memorySearchResult: MemorySearchResult) =>
          MemoryActions.searchMemoriesRequestSuccess({
            memorySearchResult,
            setFirstAsCurrent: action.setFirstAsCurrent,
            appendResults: action.appendResults
          })
        )
        )
      ),
      catchError(error => {
        return of(MemoryActions.effectError({error}));
      })
    )
  );


  saveMemoryRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemoryActions.saveMemoryRequest),
      mergeMap(action =>
        this.memoryService.saveMemory(action.memory).pipe(
          tap((responseMessage: ResponseMessage) => {
            this.alertService.info(responseMessage.message);
          }),

          switchMap((responseMessage: ResponseMessage) => [
            MemoryActions.saveMemoryRequestSuccess({
              responseMessage
            }),
          ]),

          catchError(error => {
            return of(MemoryActions.effectError({error}));
          })
        )
      )
    )
  );

  deleteMemoryRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemoryActions.deleteMemoryRequest),
      mergeMap(action =>
        this.memoryService.deleteMemory(action.memory).pipe(
          tap((responseMessage: ResponseMessage) => {
            this.alertService.warning(responseMessage.message);
          }),

          concatMap((responseMessage: ResponseMessage) => [
            MemoryActions.deleteMemoryRequestSuccess({memory: action.memory, responseMessage}),
            BioActions.decrementCurrentBioMemoryCounter({memory: action.memory}),
          ]),

          catchError(error => {
            return of(MemoryActions.effectError({error}));
          })
        )
      )
    )
  );

  sortMemoryFilesRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemoryActions.sortMemoryFilesRequest),
      mergeMap(action =>
        this.memoryService.sortFiles(action.memory, action.fileIds).pipe(
          tap((responseMessage: ResponseMessage) => {
            this.alertService.warning(responseMessage.message);
          }),

          map(responseMessage =>
            MemoryActions.sortMemoryFilesRequestSuccess({responseMessage})
          ),

          catchError(error => {
            return of(MemoryActions.effectError({error}));
          })
        )
      )
    )
  );

  deleteMemoryFileRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemoryActions.deleteMemoryFileRequest),
      mergeMap(action =>
        this.memoryService.removeFile(action.memory, action.file).pipe(
          tap((responseMessage: ResponseMessage) => {
            this.alertService.warning(responseMessage.message);
          }),

          map(responseMessage =>
            MemoryActions.deleteMemoryFileRequestSuccess({responseMessage})
          ),

          catchError(error => {
            return of(MemoryActions.effectError({error}));
          })
        )
      )
    )
  );

  saveMemoryFileMetaRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemoryActions.saveMemoryFileMetaRequest),
      mergeMap(action =>
        this.memoryService.patchFile(action.memory, action.file).pipe(
          tap((responseMessage: ResponseMessage) => {
            this.alertService.info(responseMessage.message);
          }),

          switchMap((responseMessage: ResponseMessage) => [
            MemoryActions.saveMemoryFileMetaRequestSuccess({
              memory: action.memory,
              responseMessage
            }),
          ]),

          catchError(error => {
            return of(MemoryActions.effectError({error}));
          })
        )
      )
    )
  );

  shareMemoryRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemoryActions.shareMemoryRequest),
      mergeMap(action =>
        this.memoryService.shareMemory(action.jar, action.memory).pipe(
          tap((responseMessage: ResponseMessage) => {
            this.alertService.info(responseMessage.message);
          }),
          concatMap((responseMessage: ResponseMessage) => EMPTY),
          catchError(error => {
            return of(MemoryActions.effectError({error}));
          })
        )
      )
    )
  );

}



