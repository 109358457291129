import {User} from '../../core/models/user.model';
import {UserSubscription} from '../../core/models/user-subscription.model';
import {Bio} from '../../core/models/bio.model';

export class UsersState {
  currentUser: User;
  currentUserSubscription: UserSubscription;
  byId: UsersById;
  ownedBios: Bio[];
}

export const initialUserState: UsersState = {
  currentUser: null,
  currentUserSubscription: null,
  byId: {},
  ownedBios: []
};

export class UsersById {
  [id: number]: User;
}
