import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {catchError, first, timeout} from 'rxjs/operators';
import {AlertService} from '../../shared/components/alert/alert.service';
import {AuthService} from '../services/auth.service';
import {Router} from '@angular/router';
import {LoaderService} from '../../shared/components/content-loader/loader.service';
import {environment} from '../../../environments/environment';
import {UserActions} from '../../store/users';
import {Store} from '@ngrx/store';
import {AppState} from '../../store';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private alertService: AlertService,
    private loaderService: LoaderService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)

      // Catch other errors
      .pipe(timeout(60000), catchError(err => {

        // Capture Server Error
        if (err.status === 500) {
          this.loaderService.hideAll();

          // This is VERY ugly in production so only do in debug
          if (environment.production === false) {
            this.alertService.error(['Service error. Please try again.']);
          }
        }

        // API returns 415 Unsupported File Upload
        else if (err.status === 415 ) {
          this.alertService.error('Upload failed. Unsupported file type.');
          //this.alertService.error(err.error.message);
        }

        // API Returns 404's when missing data, or data is unauthorised
        // API returns 412's when form fields are invalid
        else if (err.status === 404 || err.status === 412) {
          this.loaderService.hideAll();
          this.alertService.error(err.error.message);

        }

        else if (err.status === 401 || err.status === 403) {
          this.store.dispatch(UserActions.logout());
        }

        return throwError(err);
      }));
  }

}
