<div class="expandable-text">
  <div [ngClass]="{'expand':expand}" class="expand-description">{{ text }}</div>
  <div class="expand-button" *ngIf="showButton">
    <button class="btn btn-expand-text" type="button" (click)="toggle()">
      <span *ngIf="!expand"><fa-icon [icon]="faChevronDown"></fa-icon> More </span>
      <span *ngIf="expand"><fa-icon [icon]="faChevronUp"></fa-icon> Less </span>
    </button>
  </div>
</div><!-- /expandable-text -->

