import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TruncatePipe} from './truncate.pipe';
import {SafePipe} from './safe.pipe';
import {OptionsPipe} from './options.pipe';
import {ListFilterPipe} from './list-filter.pipe';
import {StripHtmlPipe} from './strip-html.pipe';
import {ListSortPipe} from './list-sort.pipe';

@NgModule({
  imports: [
    CommonModule,
  ],
  entryComponents: [],
  declarations: [
    SafePipe,
    TruncatePipe,
    OptionsPipe,
    ListFilterPipe,
    ListSortPipe,
    StripHtmlPipe
  ],
  exports: [
    SafePipe,
    TruncatePipe,
    OptionsPipe,
    ListFilterPipe,
    ListSortPipe,
    StripHtmlPipe
  ],
  providers: []
})
export class AppPipesModule { }
