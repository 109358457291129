import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TribeService} from '../../../core/services/tribe.service';
import {AlertService} from '../alert/alert.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store';
import {TribeActions} from '../../../store/tribe';
import {resendTribeRequest} from '../../../store/tribe/tribe.actions';

@Component({
  selector: 'app-tribe-activity-list',
  templateUrl: 'tribe-activity-list.component.html'
})
export class TribeActivityListComponent implements OnInit {

  @Input() public activityList: any[] = [];

  @Output() public requestResponse: EventEmitter<boolean>;

  constructor(
    protected store: Store<AppState>,
    protected alertService: AlertService,
    protected tribeService: TribeService
  ) {
    this.requestResponse = new EventEmitter<boolean>();
  }

  ngOnInit(): void {
  }

  public acceptTribeRequest(tribeRequest: any) {
    this.store.dispatch(
      TribeActions.acceptTribeRequest({toBio: tribeRequest.bio})
    );
    this.requestResponse.emit(true);
  }

  public resendTribeRequest(tribeRequest: any) {
    this.store.dispatch(
      TribeActions.resendTribeRequest({toBio: tribeRequest.bio})
    );
    this.requestResponse.emit(true);
  }

  public cancelTribeRequest(tribeRequest: any) {
    this.store.dispatch(
      TribeActions.removeTribeRequest({toBio: tribeRequest.bio})
    );
    this.requestResponse.emit(false);
  }

}
