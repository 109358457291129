import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, concatMap, delay, filter, map, mergeMap, repeatWhen, switchMap, tap} from 'rxjs/operators';
import {BioActions} from './index';
import {EMPTY, of, Subject} from 'rxjs';
import {Bio} from '../../core/models/bio.model';
import {ResponseMessage} from '../../core/models/response-message.model';
import {UserActions} from '../users';
import {BioService} from '../../core/services/bio.service';
import {TribeActions} from '../tribe';
import {AlertService} from '../../shared/components/alert/alert.service';
import {BioUserActions} from '../bio-users';
import {JarActions} from '../jars';
import {UserBioActions} from '../user-bios';
import {PublicBioService} from '../../core/services/public-bio.service';
import {BioDeleteResponse} from '../../core/models/bio-delete-response.model';

@Injectable()
export class BiosEffects {

  constructor(
    private actions$: Actions,
    private bioService: BioService,
    private publicBioService: PublicBioService,
    private alertService: AlertService
  ) {
  }

  setDisplayBioRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BioActions.setDisplayBioRequest),
      filter(action => action.bio != null),
      map(action => JarActions.setJarsRequest({
          jars: [
            ...action.bio.thingJars,
            ...action.bio.memoryJars,
            ...action.bio.passwordJars
          ]
        })
      )
    )
  );

  switchBioRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BioActions.switchBioRequest),
      mergeMap(action =>
        this.bioService.switchBio(action.slugOrId).pipe(
          concatMap((bio: Bio) => {
              if (bio == null) {
                return EMPTY;
              }
              return [
                BioActions.setCurrentBioRequest({bio}),

                // Side-effect: Set Bio Jars
                JarActions.setJarsRequest({
                  jars: [
                    ...bio.thingJars,
                    ...bio.memoryJars,
                    ...bio.passwordJars
                    // todo: add more jars
                  ]
                }),

                // Side-effect: Load Bio Users (aka Guardians)
                BioUserActions.getBioUsersRequest({slugOrId: bio.slug}),

                // Side-effect: Load Users Bios (aka Wards)
                UserBioActions.getUserBiosRequest({id: null}),

                // Side-effect: Load Tribe Activity
                TribeActions.getTribeActivityRequest(),

                BioActions.switchBioRequestSuccess({bio}),
              ];
            }
          ),
          catchError(error => {
            return of(BioActions.effectError({error}));
          })
        )
      )
    )
  );

  getBioRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BioActions.getBioRequest),
      mergeMap(action =>
        this.bioService.getBio(action.slugOrId).pipe(
          concatMap((bio: Bio) => [
            BioActions.getBioRequestSuccess({
              bio,
              setAsDisplay: action.setAsDisplay
            }),

            // Side-effect: Set Bio Jars
            JarActions.setJarsRequest({
              jars: [
                ...bio.thingJars,
                ...bio.memoryJars,
                ...bio.passwordJars
                // todo: add more jars
              ]
            })
          ]),
          catchError(error => {
            return of(BioActions.effectError({error}));
          })
        )
      )
    )
  );

  saveBioRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BioActions.saveBioRequest),
      mergeMap(action =>
        this.bioService.save(action.bio).pipe(
          tap((responseMessage: ResponseMessage) => {
            this.alertService.info(responseMessage.message);
          }),

          map((responseMessage: ResponseMessage) =>
            BioActions.saveBioRequestSuccess({
              responseMessage
            })
          ),

          catchError(error => {
            return of(BioActions.effectError({error}));
          })
        )
      )
    )
  );

  deleteBioRequest$ = createEffect(() => {
    // This is a batched process and the endpoint must be called repeatedly until complete.
    return this.actions$.pipe(
      ofType(BioActions.deleteBioRequest),
      mergeMap(action =>
        this.bioService.deleteBio(action.bio).pipe(
          concatMap((responseMessage) => {
            const deleteResponse = responseMessage.data as BioDeleteResponse;
            if (deleteResponse.error === true) {
              this.alertService.error(deleteResponse.message);
              return of(BioActions.effectError({
                error: deleteResponse.message
              }));
            } else if (deleteResponse.complete === true) {
              this.alertService.warning(deleteResponse.message);
              return [
                BioActions.deleteBioRequestSuccess({responseMessage}),
                BioActions.switchBioRequest({slugOrId: null}),
                UserBioActions.deleteUserBio({bio: action.bio}),
                UserActions.decrementCurrentUserBioCounter({bio: action.bio}),
              ];
            }
            return of(BioActions.deleteBioRequest({bio: action.bio}));
          }),
          catchError(error => {
            return of(BioActions.effectError({error}));
          })
        )
      )
    );
  });

  // deleteBioRequest$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(BioActions.deleteBioRequest),
  //     mergeMap(action =>
  //       this.bioService.deleteBio(action.bio).pipe(
  //         tap((responseMessage: ResponseMessage) => {
  //           this.alertService.warning(responseMessage.message);
  //         }),
  //
  //         concatMap((responseMessage: ResponseMessage) => [
  //           BioActions.deleteBioRequestSuccess({responseMessage}),
  //           BioActions.switchBioRequest({slugOrId: null}),
  //           UserActions.decrementCurrentUserBioCounter({bio: action.bio}),
  //         ]),
  //
  //         catchError(error => {
  //           return of(BioActions.effectError({error}));
  //         })
  //       )
  //     )
  //   );
  // });

  saveBioProfileRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BioActions.saveBioProfileRequest),
      mergeMap(action =>
        this.bioService.saveProfileFile(action.bio, action.file).pipe(
          tap((responseMessage: ResponseMessage) => {
            this.alertService.info(responseMessage.message);
          }),

          map((responseMessage: ResponseMessage) =>
            BioActions.saveBioProfileRequestSuccess({
              bio: action.bio,
              file: action.file
            })
          ),
          catchError(error => {
            return of(BioActions.effectError({error}));
          })
        )
      )
    )
  );


  getPublicBioRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BioActions.getPublicBioRequest),
      mergeMap(action =>
        this.publicBioService.getBio(action.slugOrId).pipe(
          concatMap((bio: Bio) => [
            BioActions.getBioRequestSuccess({
              bio,
              setAsDisplay: action.setAsDisplay
            }),

            // Side-effect: Set Bio Jars
            JarActions.setJarsRequest({
              jars: [
                ...bio.thingJars,
                ...bio.memoryJars,
                ...bio.passwordJars
                // todo: add more jars
              ]
            })
          ]),
          catchError(error => {
            return of(BioActions.effectError({error}));
          })
        )
      )
    )
  );


}



