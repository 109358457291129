import {createAction, props} from '@ngrx/store';
import {OptionsState} from './options.state';

export const getOptions = createAction(
  '[Options] get options request'
);

export const getOptionsSuccess = createAction(
  '[Options] get options request success',
  props<{ options: OptionsState }>()
);

export const effectError = createAction(
  '[Options] effect error',
  props<{ error: any }>()
);
