<div *ngIf="bio" class="app-bio-share-link small">

  <div class="d-inline-block" *ngIf="!bio.isLegacy">
    <a [routerLink]="getUrl(false)">
      {{getUrl()}}
    </a>
  </div>

  <div class="d-inline-block" *ngIf="bio.isLegacy && bio.privacy !== 3">
    <a [routerLink]="getUrl(false)">
      {{getUrl()}}
    </a>
  </div>

  <div class="d-inline-block" *ngIf="bio.isLegacy && bio.privacy === 3">
    <a [routerLink]="getUrl(false)">
      {{getUrl()}}
    </a>
  </div>

  <button class="btn btn-sm btn-gold ml-3" style="padding: 0px 16px;" type="button"
          [appCopyClipboard]="getUrl()" (copied)="onCopy($event)">Copy
  </button>

  <div *ngIf="bio.isLegacy && bio.privacy === 3">
    <em>Copy &amp; Paste link to share this public Legacy Profile</em>
  </div>

</div>
