import {NgbConfig, NgbModalConfig} from '@ng-bootstrap/ng-bootstrap';
import {Injectable} from '@angular/core';

@Injectable()
export class BioModalConfigProvider extends NgbModalConfig {
  public windowClass: string;

  constructor(ngbConfig: NgbConfig) {
    super(ngbConfig);
    this.windowClass = 'bio';
    this.container = '.bio-modal-container';
  }

}
