<div class="card card-bio card-dashboard">
  <a class="thumb" [routerLink]="route">
    <img [src]="thumbSrc" alt="{{title}}">
  </a>

  <h3 class="title"><a [routerLink]="route">{{title}}</a></h3>

  <div class="d-flex">
    <a *ngIf="tutorialRef" class="link-video mr-4" (click)="videoLinkClick($event)" href="#">
      <fa-icon [icon]="faPlayCircle"></fa-icon>
    </a>
    <a *ngIf="documentationRef" class="link-documentation mr-4" (click)="documentLinkClick($event)" href="#">
      <fa-icon [icon]="faQuestionCircle"></fa-icon>
    </a>
    <a [routerLink]="route" class="link-route ml-auto">
      <fa-icon [icon]="faLink"></fa-icon>
    </a>
  </div>

</div>
