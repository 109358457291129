import {Component} from '@angular/core';
import {IMemory, MemoryModel} from '../../../core/models/memory.model';
import {ThingDefaultModalComponent} from '../../../shared/modals/admin/thing-default/thing-default-modal.component';
import {MemoryActions} from '../../../store/memories';
import {NodeType} from '../../../core/common/enums';
import {AbstractMemoryDefaultsComponent} from '../abstract-memory-defaults/abstract-memory-defaults.component';

@Component({templateUrl: '../abstract-memory-defaults/abstract-memory-defaults.component.html'})
export class ThingDefaultsComponent extends AbstractMemoryDefaultsComponent{
  public PageTitle = 'Thing Defaults';
  public NodeType: NodeType = NodeType.ThingDefault;

  public edit(thing?: IMemory) {
    this.store.dispatch(
      MemoryActions.setCurrentMemoryId({ id: thing?.id })
    );
    const modal = this.modalService.open(ThingDefaultModalComponent, {size: 'lg', backdrop: 'static'});
    modal.componentInstance.memory = thing || new MemoryModel();
    modal.result.then((editedMemory: IMemory) => {
      this.search();
    }, (reason) => {
      console.log(reason);
    });
  }
}
