import {Component, Input, OnInit} from '@angular/core';
import {MemoryModel} from '../../../../core/models/memory.model';

@Component({
  selector: 'app-bio-print-footer',
  templateUrl: 'bio-print-footer.component.html'
})
export class BioPrintFooterComponent implements OnInit {
  @Input()
  public page: number;
  constructor() {}
  ngOnInit(): void {}
}
