
<ul *ngIf="list?.length" class="list-tribe list-tribe-active">
  <li *ngFor="let item of list" class="list-tribe-item">
    <div class="inset">
      <div class="row">
        <div [ngClass]="{'col-9':selectable||editable, 'col-12':!selectable && !editable}" [routerLink]="[ item.isLegacy ? '/legacy' : '/profiles',item.slug]">

          <div class="list-tribe-item-preview mr-3" [appBioTypeClass]="item.type">
            <app-file-lazy-loader [bio]="item" [file]="item.profile"></app-file-lazy-loader>
          </div>

          <div class="list-tribe-item-text">
            <strong>{{ item.firstName }} {{ item.lastName }}</strong><app-ico-candle *ngIf="item.isLegacy"></app-ico-candle>
            <sup *ngIf="item.tribeRelationship?.length" class="ml-1"><app-tribe-relationship
              [tribe]="item.tribeRelationship[0]"></app-tribe-relationship></sup>

            <br/>
            <small>
              {{ item.tribeMembers||0 }} Tribesters
              <span *ngIf="item.mutual">, {{ item.mutual }} Mutual</span>
            </small>
          </div>
        </div>
        <div class="text-right" [ngClass]="{'col-3':selectable||editable, 'd-none':!selectable && !editable}">

          <button *ngIf="selectable" type="button" class="btn btn-sm" (click)="onSelect($event, item)">
            <fa-icon [icon]="faPlusCircle"></fa-icon>
          </button>

          <button *ngIf="editable" type="button" class="btn btn-sm" (click)="onEdit($event, item)">
            <fa-icon [icon]="faPencilAlt"></fa-icon>
          </button>

          <button *ngIf="removable" type="button" class="btn btn-sm" (click)="onRemove($event, item)">
            <fa-icon [icon]="faMinusCircle"></fa-icon>
          </button>

        </div>
      </div>
    </div>
  </li>
</ul>
