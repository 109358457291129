import {AppState} from '../index';
import {createSelector} from '@ngrx/store';

export const selectMemoriesState = (state: AppState) => state.passwords;

export const selectCurrentPassword = createSelector(
  selectMemoriesState,
  state => state.currentPassword
);

