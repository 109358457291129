<div class="modal-header" [ngClass]="classes">
  <h4 class="modal-title">{{ title }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss(false)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" [ngClass]="classes">
  <div class="confirm-message" [innerHtml]="message | safe:'html'">
  </div>
</div>
<div class="modal-footer text-center" [ngClass]="classes">
  <button class="btn btn-gold pl-4 pr-4" type="button" (click)="yes()">{{yesText}}</button>
  <button ngbAutofocus class="btn btn-outline-dark ml-3 pl-4 pr-4" type="button" (click)="no()">{{noText}}</button>
</div>
