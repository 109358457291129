import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {faSearch} from '@fortawesome/free-solid-svg-icons';
import {Subscription} from 'rxjs';
import {LoaderService} from '../../../shared/components/content-loader/loader.service';
import {MemorySearchResult} from '../../../core/models/memory-search-result.model';
import {MemoryService} from '../../../core/services/memory.service';
import {IMemory} from '../../../core/models/memory.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {select, Store} from '@ngrx/store';
import {DefaultMemoryActions, MemoryActions, MemorySelectors} from '../../../store/memories';
import {AppState} from '../../../store';
import {MemoryFilterModel} from '../../../core/models/memory-filter.model';
import {NodeType} from '../../../core/common/enums';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
  template: ''
})
export abstract class AbstractMemoryDefaultsComponent implements OnInit, OnDestroy {
  private readonly subscription: Subscription;
  private readonly SEARCH_RESULTS_DEFAULT = 100;

  private debounceTimer;

  public faSearch = faSearch;

  // Pagination fields
  public page = 1;
  public results = this.SEARCH_RESULTS_DEFAULT;
  public keywords: string;
  public pagedMemories: MemorySearchResult = new MemorySearchResult();

  public PageTitle = 'Memory Defaults';
  public NodeType: NodeType = NodeType.MemoryDefault;

  constructor(
    protected router: Router,
    protected store: Store<AppState>,
    protected modalService: NgbModal,
    protected memoryService: MemoryService,
    protected loadingService: LoaderService
  ) {
    this.subscription = new Subscription();

    this.store.dispatch( MemoryActions.resetSearchMemoriesRequest() );
    this.subscription.add(this.store
      .pipe(select(MemorySelectors.selectMemorySearchResult))
      .subscribe((searchResults: MemorySearchResult) => {
        this.pagedMemories = searchResults;
      })
    );
  }

  public ngOnInit() {
    this.search();
  }

  public onSort(event: CdkDragDrop<string[]>) {
    //moveItemInArray(this.pagedMemories.list, event.previousIndex, event.currentIndex);
  }

  // Pagination callback
  public onPageChange(page: number) {
    this.page = page;
    this.search();
  }

  public queueSearch() {
    if (typeof window !== 'undefined') {
      window.clearTimeout(this.debounceTimer);
      this.debounceTimer = window.setTimeout(() => {
        this.page = 1;
        this.search();
      }, 1000);
    }
  }

  public search() {
    const filters = new MemoryFilterModel();
    filters.keywords = this.keywords;
    filters.page = this.page;
    filters.results = this.results;
    filters.orderBy = 'sort_jar_tag';
    this.store.dispatch(
      DefaultMemoryActions.searchDefaultMemoriesRequest({filters, nodeType: this.NodeType})
    );
  }

  public abstract edit(memory?: IMemory);

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.store.dispatch( MemoryActions.resetSearchMemoriesRequest() );
  }
}
