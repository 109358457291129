import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {Bio} from '../../../core/models/bio.model';
import {first} from 'rxjs/operators';
import {BioService} from '../../../core/services/bio.service';
import {MemoryService} from '../../../core/services/memory.service';
import {MemoryFilterModel} from '../../../core/models/memory-filter.model';
import {MemorySearchResult} from '../../../core/models/memory-search-result.model';
import {MemoryModel} from '../../../core/models/memory.model';
import {JarModel} from '../../../core/models/jar.model';

export class PageData {
  public template: string; // standard, photos, text
  public date: Date;
  public location: string;
  public photos: any[];
  public title: string;
  public textLeft: string;
  public textRight: string;
  public memory: MemoryModel;
}

@Component({
  templateUrl: 'secured-bio-print.component.html',
  styleUrls: ['secured-bio-print.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SecuredBioPrintComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  public bio: Bio;
  public pagedMemories: MemorySearchResult;

  public jarPhotos: any[];
  public pageList: PageData[];

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected bioService: BioService,
    protected memoryService: MemoryService,
  ) {
    this.pagedMemories = new MemorySearchResult();
    this.jarPhotos = [];
    this.pageList = [];
    this.subscription = new Subscription();
  }

  public ngOnInit() {
    this.subscription.add(this.route.params.subscribe(params => {

        const slug = params['slug'] || null;
        if (slug !== null) {

          // Load the Bio
          this.bioService.getBio(slug)
            .pipe(first())
            .subscribe(
              data => {
                this.bio = data;

                // Load all the Memories
                const filters = new MemoryFilterModel();
                filters.includeParents = true;
                filters.fileTypes = ['Image'];
                filters.results = 100000;

                // Set the Bio ID
                filters.bio = this.bio.id;

                // Set the Memory Jar ID
                filters.parentNodes = Bio.getJarIds(this.bio.memoryJars);

                this.memoryService.search(filters)
                  .pipe(first())
                  .subscribe(
                    (results: MemorySearchResult) => {
                      this.pagedMemories = results;
                      this.processPages();
                    });
              });

        }
      })
    );
  }


  public substrCount(haystack: string, needle: string, offset: number = 0, length: number = 0): number {
    let cnt = 0;

    if (needle.length === 0) {
      return 0;
    }

    while (offset !== -1) {
      offset = haystack.indexOf(needle, offset + 1);
      if (length > 0 && (offset + needle.length) > length) {
        return 0;
      }
      cnt++;
    }

    return cnt;
  }

  public wordwrap(str: string, width: number, strBreak: string = '\n', cut: boolean = false): string {
    // ref: https://locutus.io/php/strings/wordwrap/

    if (!str || width < 1) {
      return str;
    }

    const reLineBreaks = /\r\n|\n|\r/;
    const reBeginningUntilFirstWhitespace = /^\S*/;
    const reLastCharsWithOptionalTrailingWhitespace = /\S*(\s)?$/;

    const lines = str.split(reLineBreaks);
    const l = lines.length;

    // for each line of text
    let line;
    for (let i = 0; i < l; lines[i++] += line) {
      line = lines[i];
      lines[i] = '';

      while (line.length > width) {

        // get slice of length one char above limit
        const slice = line.slice(0, width + 1);

        const match = slice.match(reLastCharsWithOptionalTrailingWhitespace);

        // remove leading whitespace from rest of line to parse
        let ltrim = 0;

        // remove trailing whitespace from new line content
        let rtrim = 0;

        // if the slice ends with whitespace
        let j = 0;
        if (match[1]) {

          // then perfect moment to cut the line
          j = width;
          ltrim = 1;

        } else {

          // otherwise cut at previous whitespace
          j = slice.length - match[0].length;

          if (j) {
            rtrim = 1;
          }

          // but if there is no previous whitespace
          // and cut is forced
          // cut just at the defined limit
          if (!j && cut && width) {
            j = width;
          }

          // if cut wasn't forced
          // cut at next possible whitespace after the limit
          if (!j) {
            const charsUntilNextWhitespace = (line.slice(width).match(reBeginningUntilFirstWhitespace) || [''])[0];

            j = slice.length + charsUntilNextWhitespace.length;
          }
        }

        lines[i] += line.slice(0, j - rtrim);
        line = line.slice(j + ltrim);
        lines[i] += line.length ? strBreak : '';
      }
    }
    return lines.join('\n');
  }

  public splitAtCharBySize(text: string, char: string = '\n', size: number = 20): string[] {
    const arr = text.split(char);
    const count = arr.length;
    const groups = Math.ceil(count / size);

    const nArr: string[] = [];
    for (let i = 0; i < groups; i++) {
      const start = (i * size);
      const slice = arr.slice(start, start + size);
      nArr.push(slice.join(char).trim());
    }

    return nArr;
  }

  protected processPages() {

    this.jarPhotos = [];
    this.bio.memoryJars.forEach((jar: JarModel) => {
      if (jar.files.length > 0) {
        this.jarPhotos.push({
          jar,
          photo: jar.files[0]
        });
      }
    });

    this.pageList = [];
    this.pagedMemories.list.forEach((memory: MemoryModel) => {

      const title = this.wordwrap(memory.title, 30);
      const text = this.wordwrap(memory.description, 60);
      const columns = this.splitAtCharBySize(text, '\n', 20);
      const lineCount = this.substrCount(text, '\n');
      const photoCount = memory.files.length;

      // Figure out what templates are required
      if (lineCount <= 20) {
        if (photoCount <= 5) {

          // Create one standard page
          const page = new PageData();
          page.template = 'standard';
          page.date = memory.date;
          page.location = memory.location;
          page.photos = memory.files;
          page.title = title;
          page.textLeft = text;
          page.memory = memory;
          this.pageList.push(page);

        } else {

          // Create one standard page
          let page = new PageData();
          page.template = 'standard';
          page.date = memory.date;
          page.location = memory.location;
          page.photos = memory.files.slice(0, 4);
          page.title = title;
          page.textLeft = text;
          page.memory = memory;
          this.pageList.push(page);

          // Create Second Photo Page
          page = new PageData();
          page.template = 'photos';
          page.photos = memory.files.slice(5);
          page.memory = memory;
          this.pageList.push(page);
        }

      } else {

        // Multi-page text and Photos
        if (photoCount >= columns.length) {

          for (let i = 0, l = columns.length; i < l; i++) {

            const page = new PageData();
            page.template = 'standard';
            page.memory = memory;
            page.textLeft = columns[i];

            if (i < (l - 1)) {
              // If not last page, take one photo
              page.photos = memory.files.slice(i, i + 1);
            } else {
              // If last page, take remaining photos
              page.photos = memory.files.slice(i);
            }

            // First page (includes title, date, location)
            if (i === 0) {
              page.date = memory.date;
              page.location = memory.location;
              page.title = title;
            }

            this.pageList.push(page);
          }

        } else {

          // Create one standard page
          let page = new PageData();
          page.template = 'standard';
          page.date = memory.date;
          page.location = memory.location;
          page.photos = memory.files.slice(0, 4);
          page.title = title;
          page.textLeft = columns[0];
          page.memory = memory;
          this.pageList.push(page);

          // Create Second Text Only Page
          page = new PageData();
          page.template = 'text';
          page.textLeft = columns[1];
          page.textRight = columns[2];
          page.memory = memory;
          this.pageList.push(page);
        }
      }
    });
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
