import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {
  faSearch,
  faPlusCircle,
  faMinusCircle,
  faMapMarkerAlt,
  faBook
} from '@fortawesome/free-solid-svg-icons';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {BreadcrumbNavItem} from '../../../../../shared/components/breadcrumb/breadcrumb.component';
import {Bio} from '../../../../../core/models/bio.model';
import {filter, map} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {Tribe} from '../../../../../core/models/tribe.model';
import {LayoutService} from '../../../../../core/services/layout.service';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../store';
import {BioActions, BioSelectors} from '../../../../../store/bios';
import {TribeActions} from '../../../../../store/tribe';
import {TribeRelationshipModalComponent} from '../../../../../shared/modals/tribe-relationship/tribe-relationship-modal.component';

@Component({
  templateUrl: 'legacy-view.component.html',
  styleUrls: ['legacy-view.component.css']
})
export class LegacyViewComponent implements OnInit,  OnDestroy {
  private readonly subscription: Subscription;

  public currentBio: Bio;
  public bio: Bio;
  public tribeRelationship: Tribe;

  public faPlusCircle = faPlusCircle;
  public faMinusCircle = faMinusCircle;
  public faSearch = faSearch;
  public faMapMarkerAlt = faMapMarkerAlt;
  public faBook = faBook;

  // Active Bio Slug
  private slug: string;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected store: Store<AppState>,
    protected modalService: NgbModal,
    protected layoutService: LayoutService,
  ) {
    this.bio = null;
    this.currentBio = null;

    this.subscription = new Subscription();

    // User's Current Bio
    this.subscription.add(this.store
      .pipe(
        select(BioSelectors.selectCurrentBio)
      )
      .subscribe((bio: Bio) => {
        this.currentBio = bio;
      })
    );

    // Need to clear the display Bio here so that we don't load the previous one before checking the slug
    this.store.dispatch(
      BioActions.setDisplayBioRequest({
        bio: null
      })
    );

    // Now wait for the display Bio
    this.subscription.add(this.store
      .pipe(
        select(BioSelectors.selectDisplayBio),
        filter((bio: Bio) => bio != null)
      )
      .subscribe((bio: Bio) => {
        this.bio = bio;

        // redirect if Legacy Bio
        if (!this.bio.isLegacy) {
          this.router.navigate(['/profiles', this.bio.slug]);
        }

        this.layoutService.setTitle( `The legacy of ${this.bio.firstName} ${this.bio.lastName} - My LifeJars`  );
      })
    );

    this.subscription.add(this.store
      .pipe(select(BioSelectors.selectDisplayBioTribeRelationship))
      .subscribe((relationship: Tribe) => {
        this.tribeRelationship = relationship;
      })
    );

  }

  public ngOnInit() {
    this.subscription.add(
      this.route.params.subscribe(params => {
        this.slug = params.slug || null;
        this.store.dispatch(
          BioActions.getBioRequest({
            slugOrId: this.slug,
            setAsDisplay: true
          })
        );
      })
    );
  }

  public sendTribeRequest() {
    const modal = this.modalService.open(TribeRelationshipModalComponent, {});
    modal.componentInstance.fromBio = this.currentBio;
    modal.componentInstance.toBio = this.bio;
  }

  public resendTribeRequest() {
    this.store.dispatch(
      TribeActions.resendTribeRequest({toBio: this.bio})
    );
  }

  public cancelTribeRequest() {
    this.tribeRelationship = null;
    this.store.dispatch(
      TribeActions.removeTribeRequest({toBio: this.bio, fromBio: this.currentBio})
    );
  }

  public acceptTribeRequest() {
    this.tribeRelationship = Tribe.makeActive(this.bio, this.currentBio);
    this.store.dispatch(
      TribeActions.acceptTribeRequest({toBio: this.bio, fromBio: this.currentBio})
    );
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
