import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {BreadcrumbNavItem} from '../../../../../shared/components/breadcrumb/breadcrumb.component';

import {
  faCalendar,
  faCaretRight,
  faClock,
  faComment,
  faEye,
  faLock,
  faMinusCircle,
  faPencilAlt,
  faPlusCircle,
  faSearch,
  faShare,
  faTag
} from '@fortawesome/free-solid-svg-icons';

import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {Bio} from '../../../../../core/models/bio.model';
import {filter} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {MemorySearchResult} from '../../../../../core/models/memory-search-result.model';
import {MemoryFilterModel} from '../../../../../core/models/memory-filter.model';
import {MemoryService} from '../../../../../core/services/memory.service';
import {Tribe} from '../../../../../core/models/tribe.model';
import {IMemory, MemoryModel} from '../../../../../core/models/memory.model';
import {MemoryViewModalComponent} from '../../../../../shared/modals/memory/view/memory-view-modal.component';
import {LayoutService} from '../../../../../core/services/layout.service';
import {JarModel} from '../../../../../core/models/jar.model';
import {FileModel} from '../../../../../core/models/file.model';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../store';
import {BioActions, BioSelectors} from '../../../../../store/bios';
import {TribeActions} from '../../../../../store/tribe';
import {MemoryActions, MemorySelectors} from '../../../../../store/memories';
import {CommentListModelComponent} from '../../../../../shared/modals/comment-list/comment-list-modal.component';
import {PasswordViewModalComponent} from '../../../../../shared/modals/password/view/password-view-modal.component';
import {TribeRelationshipModalComponent} from '../../../../../shared/modals/tribe-relationship/tribe-relationship-modal.component';
import {JarPickerComponent} from '../../../../../shared/modals/pickers/jar-picker/jar-picker.component';
import {JarType} from '../../../../../core/common/enums';

@Component({templateUrl: 'bio-view.component.html'})
export class BioViewComponent implements OnInit, OnDestroy {
  private readonly subscription: Subscription;

  private debounceTimer;
  public bio: Bio;
  public currentBio: Bio;
  public tribeRelationship: Tribe;

  public memoryJars: JarModel[];
  public thingJars: JarModel[];
  public passwordJars: JarModel[];

  public faSearch = faSearch;
  public faPlusCircle = faPlusCircle;
  public faMinusCircle = faMinusCircle;
  public faPencilAlt = faPencilAlt;
  public faCaretRight = faCaretRight;
  public faLock = faLock;
  public faEye = faEye;
  public faTag = faTag;
  public faClock = faClock;
  public faCalendar = faCalendar;
  public faComment = faComment;
  public faShare = faShare;

  // Active Bio Slug
  private slug: string;

  // Form Values
  public keywords: string;

  public jarType: string;
  public readonly MemoryJarType = 'memory';
  public readonly ThingJarType = 'thing';
  public readonly PasswordJarType = 'password';

  public readonly PasswordNodeTypes = [30, 31, 32, 33];

  // Pagination fields
  public page = 1;
  public results = 5;
  public pageCount = 1;

  // Data from API
  public pagedMemories: MemorySearchResult = new MemorySearchResult();

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected store: Store<AppState>,
    protected modalService: NgbModal,
    protected memoryService: MemoryService,
    protected layoutService: LayoutService
  ) {

    // set the Default Jar Type
    this.jarType = this.MemoryJarType;

    this.subscription = new Subscription();

    // User's Current Bio
    this.subscription.add(this.store
      .pipe(
        select(BioSelectors.selectCurrentBio)
      )
      .subscribe((bio: Bio) => {
        this.currentBio = bio;
      })
    );

    // Need to clear the display Bio here so that we don't load the previous one before checking the slug
    this.store.dispatch(
      BioActions.setDisplayBioRequest({
        bio: null
      })
    );

    // Now wait for the display Bio
    this.subscription.add(this.store
      .pipe(
        select(BioSelectors.selectDisplayBio),
        filter((bio: Bio) => bio != null)
      )
      .subscribe((bio: Bio) => {
        this.bio = bio;

        // redirect if Legacy Bio
        if (this.bio.isLegacy) {
          this.router.navigate(['/legacy', this.bio.slug]);
        }
        this.search();

        this.layoutService.setTitle( `The life of ${this.bio.firstName} ${this.bio.lastName} - My LifeJars`  );
      })
    );

    this.subscription.add(this.store
      .pipe(select(BioSelectors.selectDisplayBioTribeRelationship))
      .subscribe((relationship: Tribe) => {
        this.tribeRelationship = relationship;
      })
    );

    this.subscription.add(this.store
      .pipe(select(MemorySelectors.selectMemorySearchResult))
      .subscribe((searchResults: MemorySearchResult) => {
        this.pagedMemories = searchResults;
      })
    );

    /*
    this.memoryJars = [];
    this.subscription.add(this.store
      .pipe(
        select(JarSelectors.selectMemoryJars),
        filter((jars: JarModel[]) => jars.length > 0)
      )
      .subscribe((jars: JarModel[]) => {
        this.memoryJars = jars;
      })
    );

    this.thingJars = [];
    this.subscription.add(this.store
      .pipe(
        select(JarSelectors.selectThingJars),
        filter((jars: JarModel[]) => jars.length > 0)
      )
      .subscribe((jars: JarModel[]) => {
        this.thingJars = jars;
      })
    );

    this.passwordJars = [];
    this.subscription.add(this.store
      .pipe(
        select(JarSelectors.selectPasswordJars),
        filter((jars: JarModel[]) => jars.length > 0)
      )
      .subscribe((jars: JarModel[]) => {
        this.passwordJars = jars;
      })
    );
    */
  }

  public ngOnInit() {
    this.subscription.add(
      this.route.params.subscribe(params => {
        this.slug = params.slug || null;
        this.store.dispatch(
          BioActions.getBioRequest({
            slugOrId: this.slug,
            setAsDisplay: true
          })
        );
      })
    );
  }

  // Pagination callback
  public onPageChange(page: number) {
    this.page = page;
    this.search();
  }

  // Ensure that the API isn't hammered when checkboxes are being selected
  public queueSearch() {
    if (typeof window !== 'undefined') {
      window.clearTimeout(this.debounceTimer);
      this.debounceTimer = window.setTimeout(() => {
        this.page = 1;
        this.search();
      }, 1000);
    }
  }

  public search() {

    const filters = new MemoryFilterModel();
    filters.keywords = this.keywords;
    filters.page = this.page;
    filters.results = this.results;

    // Set the Bio ID
    filters.bio = this.bio.id;

    // Set the Memory Jar ID
    switch (this.jarType) {
      case this.PasswordJarType:
        filters.parentNodes = Bio.getJarIds( this.bio.passwordJars );
        filters.includeParents = false;
        break;

      case this.ThingJarType:
        filters.parentNodes = Bio.getJarIds( this.bio.thingJars );
        filters.includeParents = false;
        break;

      case this.MemoryJarType:
      default:
        filters.parentNodes = Bio.getJarIds( this.bio.memoryJars );
        filters.includeParents = true;
        break;
    }

    this.store.dispatch(
      MemoryActions.searchMemoriesRequest({
        filters
      })
    );
  }

  public sendTribeRequest() {
    const modal = this.modalService.open(TribeRelationshipModalComponent, {});
    modal.componentInstance.fromBio = this.currentBio;
    modal.componentInstance.toBio = this.bio;
  }

  public resendTribeRequest() {
    this.store.dispatch(
      TribeActions.resendTribeRequest({toBio: this.bio})
    );
  }

  public cancelTribeRequest() {
    this.tribeRelationship = null;
    this.store.dispatch(
      TribeActions.removeTribeRequest({toBio: this.bio, fromBio: this.currentBio})
    );
  }

  public acceptTribeRequest() {
    this.tribeRelationship = Tribe.makeActive(this.bio, this.currentBio);
    this.store.dispatch(
      TribeActions.acceptTribeRequest({toBio: this.bio, fromBio: this.currentBio})
    );
  }

  public viewMemory(memory: IMemory) {
    this.store.dispatch(
      MemoryActions.setCurrentMemoryId({ id: memory.id })
    );
    const modal = this.modalService.open(MemoryViewModalComponent, {size: 'lg'});
    modal.componentInstance.bio = this.bio;
  }

  public viewPassword(memory: IMemory) {
    const modal = this.modalService.open(PasswordViewModalComponent, {size: 'lg'});
    modal.componentInstance.bio = this.bio;
    modal.componentInstance.memory = memory;
  }

  public showLightbox(memoryOrJar: IMemory, file: FileModel) {
      this.layoutService.showLightbox(memoryOrJar, file);
  }

  public showMemoryComments(event, memory: IMemory) {
    event.preventDefault();

    this.store.dispatch(MemoryActions.setCurrentMemoryId({
      id: memory.id
    }));

    const modal = this.modalService.open(CommentListModelComponent, {size: 'lg'});
    modal.componentInstance.type = 'memory';
    modal.componentInstance.conceptKeyword = 'Recollection';
  }

  public addToJarPicker(event: any, memory: IMemory) {
    event.preventDefault();
    const modal = this.modalService.open(JarPickerComponent, {size: 'lg', backdrop: 'static'});
    modal.componentInstance.bio = this.bio;
    modal.componentInstance.memory = memory;
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
    this.store.dispatch( MemoryActions.resetSearchMemoriesRequest() );
  }

}
