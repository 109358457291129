import {Optional} from '@angular/core';

export class RegisterModel {

  public email: string;
  public password: string|object;
  public passwordConfirm: string;
  public firstName: string;
  public lastName: string;
  public token: string;

  constructor() {}

  public static make(obj: object): RegisterModel {
    if ( !obj || (Object.keys(obj).length === 0) ) {
      return null; // Object is null or empty
    }
    const model = new RegisterModel();
    return Object.assign(model, obj);
  }

}
