import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {faCalendar, faMapMarkerAlt, faTag, faUserLock, faLock, faClock, faEye, faComment, faShare} from '@fortawesome/free-solid-svg-icons';
import {IMemory, MemoryModel} from '../../../../core/models/memory.model';
import {Bio} from '../../../../core/models/bio.model';
import {FileModel} from '../../../../core/models/file.model';
import {LayoutService} from '../../../../core/services/layout.service';
import {JarModel} from '../../../../core/models/jar.model';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../store';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {JarActions, JarSelectors} from '../../../../store/jars';
import {CommentListModelComponent} from '../../comment-list/comment-list-modal.component';
import {MemorySelectors} from '../../../../store/memories';
import {JarPickerComponent} from '../../pickers/jar-picker/jar-picker.component';

@Component({
  templateUrl: 'memory-view-modal.component.html'
})
export class MemoryViewModalComponent implements OnInit {
  protected readonly subscription: Subscription;

  @Input()
  public bio: Bio;

  @Input()
  public memory: MemoryModel;

  public jar: JarModel;

  public faCalendar = faCalendar;
  public faMapMarker = faMapMarkerAlt;
  public faTag = faTag;
  public faUserLock = faUserLock;
  public faLock = faLock;
  public faClock = faClock;
  public faEye = faEye;
  public faComment = faComment;
  public faShare = faShare;

  constructor(
    public activeModal: NgbActiveModal,
    protected modalService: NgbModal,
    protected store: Store<AppState>,
    protected layoutService: LayoutService
  ) {
    this.jar = new JarModel();
    this.memory = new MemoryModel();

    this.subscription = new Subscription();
    this.subscription.add(this.store
      .pipe(
        select(JarSelectors.selectCurrentJar),
        filter((jar: JarModel) => jar != null)
      )
      .subscribe((jar: JarModel) => {
        this.jar = jar;
      })
    );

    this.subscription.add(this.store
      .pipe(
        select(MemorySelectors.selectCurrentMemory),
        filter((memory: MemoryModel) => memory != null)
      )
      .subscribe((memory: MemoryModel) => {
        this.memory = memory;
      })
    );

  }

  public ngOnInit() {

    // Existing Memory so Subscribe to the store
    if (this.memory.id) {

      // Do this first (So we don't end up with the wrong one)
      //this.store.dispatch(
      //  MemoryActions.setCurrentMemoryId({ id: this.memory.id })
      //);

      // Do this too so the Parent Node context is correct
      this.store.dispatch(
        JarActions.setCurrentJarId({id: this.memory.parentNode})
      );
    }
  }

  public addToJarPicker(event: any) {
    event.preventDefault();
    const modal = this.modalService.open(JarPickerComponent, {size: 'lg', backdrop: 'static'});
    modal.componentInstance.bio = this.bio;
    modal.componentInstance.memory = this.memory;
  }

  public showComments(event) {
    event.preventDefault();
    const modal = this.modalService.open(CommentListModelComponent, {size: 'lg'});
    modal.componentInstance.type = 'memory';
    modal.componentInstance.conceptKeyword = 'Recollection';
  }

  public showLightbox(file: FileModel) {
    this.layoutService.showLightbox(this.memory, file);
  }

}
