import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {faPencilAlt, faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import {Bio} from '../../../core/models/bio.model';
import {MemoryModel} from '../../../core/models/memory.model';
import {Subscription} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../store';

import {CommentActions, CommentSelectors} from '../../../store/comments';
import {CommentModel} from '../../../core/models/comment.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ConfirmPopupComponent} from '../confirm-popup/confirm-popup.component';
import {BioActions, BioSelectors} from '../../../store/bios';
import {filter} from 'rxjs/operators';
import {MemoryActions, MemorySelectors} from '../../../store/memories';


@Component({
  templateUrl: 'comment-list-modal.component.html',
  styleUrls: ['comment-list-modal.component.css']
})
export class CommentListModelComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly subscription: Subscription;

  public readonly COMMENT_LENGTH = 5000;

  @Input()
  public type: 'bio' | 'memory';

  @Input()
  public conceptKeyword: string;

  public currentBio: Bio;
  public displayBio: Bio;
  public memory: MemoryModel;

  public comments: CommentModel[];
  public comment: CommentModel;

  public commentForm: FormGroup;
  public submitted = false;

  public faPencilAlt = faPencilAlt;
  public faTimesCircle = faTimesCircle;

  // convenience getter for easy access to form fields
  public get f() {
    return this.commentForm.controls;
  }

  constructor(
    public activeModal: NgbActiveModal,
    protected modalService: NgbModal,
    protected formBuilder: FormBuilder,
    protected store: Store<AppState>
  ) {
    this.conceptKeyword = 'Recollection';

    this.comments = [];

    this.subscription = new Subscription();
    this.subscription.add(this.store.pipe(select(CommentSelectors.selectAllComments))
      .subscribe((comments: CommentModel[]) => {
        this.comments = comments;
      })
    );

    this.subscription.add(this.store
      .pipe(
        select(BioSelectors.selectCurrentBio),
        filter((bio: Bio) => bio != null)
      )
      .subscribe((bio: Bio) => {
        this.currentBio = bio;
      })
    );

  }

  public ngOnInit() {

    // Always reset the comments
    this.comments = [];

    if (this.type === 'memory') {

      this.subscription.add(this.store
        .pipe(
          select(MemorySelectors.selectCurrentMemory),
          filter((memory: MemoryModel) => memory != null)
        )
        .subscribe((memory: MemoryModel) => {
          this.memory = memory;
          if (this.comments.length === 0) {
            this.store.dispatch(
              CommentActions.searchMemoryCommentsRequest({bioNode: this.memory})
            );
          }
        })
      );

    } else {

      this.subscription.add(this.store
        .pipe(
          select(BioSelectors.selectDisplayBio),
          filter((bio: Bio) => bio != null)
        )
        .subscribe((bio: Bio) => {
          this.displayBio = bio;
          if (this.comments.length === 0) {
            this.store.dispatch(
              CommentActions.searchCommentsRequest({bio: this.displayBio})
            );
          }
        })
      );
    }

    this.commentForm = this.formBuilder.group({
      comment: ['', [Validators.maxLength(this.COMMENT_LENGTH)]]
    });

    this.onResetCommentEdit();
  }

  public ngAfterViewInit() {

  }

  public onEditComment(event, comment: CommentModel) {
    event.preventDefault();
    this.comment = comment;
    this.commentForm.patchValue(this.comment);
  }

  public onDeleteComment(event, comment: CommentModel) {
    event.preventDefault();
    const modal = this.modalService.open(ConfirmPopupComponent, {size: 'sm', backdrop: 'static'});
    modal.componentInstance.title = 'Remove ' + this.conceptKeyword;
    modal.componentInstance.message = 'Are you sure you want to remove this ' + this.conceptKeyword + '?';
    modal.componentInstance.classes = 'danger';
    modal.result.then((result) => {

      this.store.dispatch(
        CommentActions.deleteCommentRequest({comment})
      );

      if (this.type === 'memory') {
        this.store.dispatch(
          MemoryActions.decrementCommentCounter({memory: this.memory})
        );
      } else {
        this.store.dispatch(
          BioActions.decrementCommentCounter({bio: this.displayBio})
        );
      }

    }, (reason) => {
    });
  }

  public onResetCommentEdit() {
    this.comment = new CommentModel();

    this.comment.bio = this.memory
      ? this.memory.bio
      : this.displayBio.id;

    this.comment.bioNode = this.memory
      ? this.memory.id
      : null;

    this.comment.comment = '';
    this.commentForm.patchValue(this.comment);
  }

  public onSubmit() {
    this.submitted = true;

    if (this.commentForm.invalid) {
      return;
    }

    this.comment = {...this.comment, ...this.commentForm.getRawValue()};

    this.store.dispatch(
      CommentActions.saveCommentRequest({comment: this.comment})
    );

    if (this.comment.id == null) {
      if (this.type === 'memory') {
        this.store.dispatch(
          MemoryActions.incrementCommentCounter({memory: this.memory})
        );
      } else {
        this.store.dispatch(
          BioActions.incrementCommentCounter({bio: this.displayBio})
        );
      }
    }

    this.onResetCommentEdit();
  }


  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
