import {Component, Input, OnInit} from '@angular/core';
import {MemoryModel} from '../../../../core/models/memory.model';

@Component({
  selector: 'app-bio-print-story',
  templateUrl: 'bio-print-story.component.html'
})
export class BioPrintStoryComponent implements OnInit {

  @Input()
  public date: Date;

  @Input()
  public location: string;

  @Input()
  public title: string;

  @Input()
  public text: string;

  constructor() {}
  ngOnInit(): void {}
}
