
<app-page-header title="Wisdom"></app-page-header>

<div class="container">
  <div class="row">
    <div class="col-12">

      <div class="card card-bio h-100">
        <div class="card-body">

          </div>
        </div><!--/card -->

      </div>
    </div>

  </div><!--/container-->
