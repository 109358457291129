import {createAction, props} from '@ngrx/store';
import {Bio} from '../../core/models/bio.model';
import {TribeActivityResult} from '../../core/models/tribe-activity-result.model';
import {TribeFilterModel} from '../../core/models/tribe-filter.model';
import {BioSearchResult} from '../../core/models/bio-search-result.model';
import {ResponseMessage} from '../../core/models/response-message.model';
import {Tribe} from '../../core/models/tribe.model';

export const getTribeActivityRequest = createAction(
  '[Tribes] get tribe activity request'
);

export const getTribeActivityRequestSuccess = createAction(
  '[Tribes] get tribe activity request success',
  props<{ tribeActivityResult: TribeActivityResult }>()
);

export const searchTribeRequest = createAction(
  '[Tribes] search tribe request',
  props<{ filters: TribeFilterModel }>()
);

export const resetTribeSearchRequest = createAction(
  '[Tribes] reset search tribes request'
);

export const searchTribeRequestSuccess = createAction(
  '[Tribes] search tribe request success',
  props<{ tribeSearchResult: BioSearchResult }>()
);

export const addTribeRequest = createAction(
  '[Tribes] add tribe request',
  props<{ model: any, toBio: Bio, fromBio?: Bio }>()
);



export const updateTribeRequest = createAction(
  '[Tribes] update tribe request',
  props<{ model: any, tribe: Tribe }>()
);

export const updateTribeSuccess = createAction(
  '[Tribes] update tribe success',
  props<{ bio: Bio }>()
);

export const resendTribeRequest = createAction(
  '[Tribes] resend tribe request',
  props<{ toBio: Bio }>()
);

export const removeTribeRequest = createAction(
  '[Tribes] remove tribe request',
  props<{ toBio: Bio, fromBio?: Bio }>()
);

export const acceptTribeRequest = createAction(
  '[Tribes] accept tribe request',
  props<{ toBio: Bio, fromBio?: Bio }>()
);

export const inviteFriendRequest = createAction(
  '[Tribes] invite friend request',
  props<{ model: any, fromBio?: Bio }>()
);

export const inviteFriendRequestSuccess = createAction(
  '[Tribes] invite friend request success',
  props<{ responseMessage: ResponseMessage }>()
);

export const effectError = createAction(
  '[Tribes] effect error',
  props<{ error: any }>()
);
