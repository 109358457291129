<ul *ngIf="list?.length" class="list-tribe list-tribe-legacy">
  <li *ngFor="let item of list" class="list-tribe-item">
    <div class="inset">

      <div class="row">
        <div class="col-12" [routerLink]="['/legacy',item.slug]">

          <div class="list-tribe-item-preview" [appBioTypeClass]="item.type">
            <app-file-lazy-loader [bio]="item" [file]="item.profile"></app-file-lazy-loader>
          </div>

          <div class="list-tribe-item-text">
            <strong>{{ item.firstName }} {{ item.lastName }}</strong>
            <div class="date">
              <span>{{ item.birthday|date:'MMM d, y' }}</span>
              to
              <span>{{ item.deathday|date:'MMM d, y' }}</span>
            </div>
          </div>

        </div>
      </div>

      <div class="row">
        <div class="col-12 text-right">

          <button *ngIf="selectable" type="button" class="btn btn-sm" (click)="onSelect($event, item)">
            <fa-icon [icon]="faPlusCircle"></fa-icon>
          </button>

          <button *ngIf="editable" type="button" class="btn btn-sm" (click)="onEdit($event, item)">
            <fa-icon [icon]="faPencilAlt"></fa-icon>
          </button>

          <button *ngIf="removable" type="button" class="btn btn-sm" (click)="onRemove($event, item)">
            <fa-icon [icon]="faMinusCircle"></fa-icon>
          </button>

        </div>
      </div>
    </div>
  </li>
</ul>
