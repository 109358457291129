export enum BioType {
  Unknown = 0,
  Person = 1,
  Pet = 2,
}

export enum WardType {
  Unknown = 0,
  PersonLife = 1,
  PersonLegacy = 2,
  PetLife = 3,
  PetLegacy
}

export enum JarType {
  Memory = 1,
  Thing = 2,
  Password = 3
}

export enum NodeType {
  MemoryJarDefault = 10,
  MemoryJar = 11,
  MemoryDefault = 12,
  Memory = 13,

  ThingJarDefault = 20,
  ThingJar = 21,
  ThingDefault = 22,
  Thing = 23,

  PasswordJarDefault = 30,
  PasswordJar = 31,
  PasswordDefault = 32,
  Password = 33,
}

export enum ModerationStatus {
  Pending = 0,
  Approved = 1,
  Rejected = 2
}

export enum BioUserRole {
  NoAccess = 0,
  ReadOnly = 1,
  FullAccess = 2
}
