import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {LayoutService} from '../../../../core/services/layout.service';
import {faPlayCircle, faQuestionCircle , faLink} from '@fortawesome/free-solid-svg-icons';
import {TutorialModalComponent} from '../../../../shared/modals/tutorial-popup/tutorial-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DocumentationModalComponent} from '../../../../shared/modals/documentation-popup/documentation-modal.component';

@Component({
  selector: 'app-dashboard-card',
  templateUrl: 'dashboard-card.component.html',
  styleUrls: ['dashboard-card.component.scss']
})
export class DashboardCardComponent implements OnInit, OnDestroy {

  @Input()
  public thumbSrc: string;

  @Input()
  public title: string;

  @Input()
  public route: string;

  @Input()
  public tutorialRef: string;

  @Input()
  public documentationRef: string;

  public faPlayCircle = faPlayCircle;
  public faQuestionCircle = faQuestionCircle;
  public faLink = faLink;

  constructor( private modalService: NgbModal, private layoutService: LayoutService) {
  }

  public documentLinkClick(event: any){
    event.preventDefault();
    const modal = this.modalService.open(DocumentationModalComponent, {size: 'lg'});
    modal.componentInstance.title = this.title;
    modal.componentInstance.ref = this.documentationRef;
  }

  public videoLinkClick(event: any){
    event.preventDefault();
    this.layoutService.showTutorial(this.tutorialRef, true);
  }

  public ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }
}
