import {Component, OnDestroy, OnInit} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import {AuthService} from '../../../core/services/auth.service';
import {AlertService} from '../../../shared/components/alert/alert.service';
import {ControlValidationService} from '../../../shared/components/control-validation/control-validation.service';
import {Subscription} from 'rxjs';
import {first} from 'rxjs/operators';
import {LoaderService} from '../../../shared/components/content-loader/loader.service';
import {LayoutService} from '../../../core/services/layout.service';
import {UserActions} from '../../../store/users';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store';

@Component({ templateUrl: 'email-change.component.html' })
export class EmailChangeComponent implements OnInit, OnDestroy {
  private readonly subscription: Subscription;

  public loading = false;
  public hashError = false;
  private hash: string;

  constructor(
    public router: Router,
    private store: Store<AppState>,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private authService: AuthService,
    private alertService: AlertService,
    private loaderService: LoaderService,
    private layoutService: LayoutService
  ) {
    this.subscription = new Subscription();
    this.layoutService.setTitle( `Email Change - My LifeJars`  );
  }

  public ngOnInit() {

    this.subscription.add( this.route.params.subscribe(params => {
      this.hash = params['hash'];

      if ( this.hash ) {
        this.loaderService.showLoader();
        this.authService.verifyEmail(this.hash)
          .pipe(first())
          .subscribe(
            data => {
              this.alertService.info(data.message);

              setTimeout(() => {
                this.store.dispatch(UserActions.logout());
              }, 3000);

            },
            error => {
              this.hashError = true;
              this.loaderService.hideLoader();
            });
      }
    }));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }


}
