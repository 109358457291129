import {initialUserState, UserBiosState} from './user-bios.state';
import {Action, createReducer, on} from '@ngrx/store';
import {UserBioActions} from './index';
import {Bio} from '../../core/models/bio.model';

const mergeUserBiosWithState = (state: UserBiosState, bios: Bio[]) => {
  const byId = {};
  for (const bio of bios) {
    byId[bio.id] = bio;
  }

  return {
    ...state,
    byId: {
      ...state.byId,
      ...byId
    }
  };
};

const reducer = createReducer<UserBiosState>(
  initialUserState,

  on(UserBioActions.getUserBiosRequestSuccess, (state, {bios}) => {
    return mergeUserBiosWithState(state, bios);
  }),

  on(UserBioActions.addUserBio, (state, {bio}) => {
    return mergeUserBiosWithState(state, [bio]);
  }),

  on(UserBioActions.deleteUserBio, (state, {bio}) => {
    return {
      ...state,
      byId: {
        ...state.byId,
        [bio.id]: undefined
      }
    };
  })
);

export function UserBiosReducer(state: UserBiosState, action: Action) {
  return reducer(state, action);
}
