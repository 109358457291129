import {BioUserRole} from '../common/enums';

export class BioPermissions {
  /*
	* NoAccess = 0;
	* ReadOnly = 1;
	* FullAccess = 2;
  */
  public legacyBio: BioUserRole;
  public lifeBio: BioUserRole;
  public memoryJars: BioUserRole;
  public thingsJars: BioUserRole;
  public passwordJars: BioUserRole;

  constructor() {
    this.legacyBio = BioUserRole.NoAccess;
    this.lifeBio = BioUserRole.NoAccess;
    this.memoryJars = BioUserRole.NoAccess;
    this.thingsJars = BioUserRole.NoAccess;
    this.passwordJars = BioUserRole.NoAccess;
  }

  public static make(obj: object): BioPermissions {
    if (!obj || (Object.keys(obj).length === 0)) {
      return null; // Object is null or empty
    }
    return {...new BioPermissions(), ...obj};
  }
}
