import { LoaderRoute } from './loader.service';

export const loaderRoutes: LoaderRoute[] = [
  {
    path: /\/auth\/active/g,
    ignore: true,
  },
  {
    path: /\/api\/search\?keywords=/g,
    ignore: true,
  },
  {
    path: /\/memory\/[0-9]+\/file\/[0-9]+/g,
    ignore: true,
  },
  {
    path: /\/bio\/[0-9a-zA-z\-\_]+\/file\/[0-9]+/g,
    ignore: true,
  },
  {
    path: /\/public\/memory\/[0-9]+\/file\/[0-9]+/g,
    ignore: true,
  },
  {
    path: /\/public\/bio\/[0-9a-zA-z\-\_]+\/file\/[0-9]+/g,
    ignore: true,
  },
  {
    path: /\/tribe\/activity/g,
    ignore: true,
  },
  {
    scope: 'modal',
    path: /\/user\/search/g,
  },
  {
    scope: 'modal',
    path: /\/memory\/[0-9]+\/comments/g,
  },
  {
    scope: 'modal',
    path: /\/bio\/[0-9a-zA-z\-\_]+\/comments/g,
  },
  {
    scope: 'modal',
    path: /\/comments\/[0-9]+\/delete/g,
  },
  {
    scope: 'modal',
    path: /\/comments\/[0-9]+\/save/g,
  },
  {
    scope: 'lazy',
    path: /\/memory\/search/g,
  },
  {
    scope: 'lazy',
    path: /\/user\/meta\/update/g,
  },
];
