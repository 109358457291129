<form [formGroup]="editForm" (ngSubmit)="onSubmit()">
<div class="modal-header">
  <h4 class="modal-title">Transition to Legacy Profile</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

    <div class="form-group">
      <label class="control-label">Date of Passing</label>

      <div class="input-group">
        <input ngbDatepicker type="text" formControlName="deathday" class="form-control form-control-lg input-bio"
               [ngClass]="{ 'is-invalid': submittedShowErrors && f.deathday.errors }" placeholder="DD/MM/YYYY"
               aria-label="DD/MM/YYYY"
               #d2="ngbDatepicker" [placement]="['bottom-right','top-right']"/>
        <div class="input-group-append btn-outline-secondary">
          <button class="btn" type="button" (click)="d2.toggle()">
            <span class="sr-only">Calendar</span>
            <fa-icon [icon]="faCalendar"></fa-icon>
          </button>
        </div>
      </div>

      <app-control-validation [control]="f.deathday" [submitted]="submittedShowErrors"></app-control-validation>
    </div>

    <p>
      <strong>Important</strong>
    </p>

    <div class="form-group">
      <div class="custom-control custom-checkbox">
        <input type="checkbox" formControlName="agreeToConditions" id="agreeToConditions"
               class="input-bio custom-control-input"
               [ngClass]="{ 'is-invalid': submittedShowErrors && f.agreeToConditions.errors }"/>

        <label class="custom-control-label" for="agreeToConditions">
          I acknowledge that I am a Guardian of this Profile and I
          will provide only true and accurate information.
        </label>

        <app-control-validation [control]="f.agreeToConditions"
                                [submitted]="submittedShowErrors"></app-control-validation>
      </div>
    </div>

    <div class="form-group small">
      <div class="row">
        <div class="col-12">
          By confirming this transition, you agree to our
          <a href="{{showTerms()}}" target="_blank"><strong>Terms</strong></a> and confirm that you
          have read our
          <a href="{{showPrivacy()}}" target="_blank"><strong>Privacy Policy</strong></a>,
          including our Cookie Use.
        </div>
      </div>
    </div>

    <div class="form-group mt-4">

      <button  type="submit" class="btn btn-gold pl-4 pr-4">
        Complete
      </button>

      <button type="button" class="btn btn-outline-dark ml-3 pl-4 pr-4" (click)="activeModal.close(false)">
        Cancel
      </button>

      <form-validation [control]="editForm" [submitted]="submittedShowErrors"></form-validation>

    </div>

  </div>
</form>
<app-content-loader></app-content-loader>
