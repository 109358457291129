<div class="modal-header">
  <h4 class="modal-title">Select Tribesters</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <div class="row">
    <div class="col-12">

      <div class="block block-search d-block">
        <div class="input-group">
          <input ngbAutofocus type="text" class="form-control" placeholder="Search..." aria-label="Search Tribe" [(ngModel)]="keywords" (keyup)="queueSearch()">
          <div class="input-group-append">
            <button class="btn" type="button" (click)="search()">
              <span class="sr-only">Search</span>
              <fa-icon [icon]="faSearch"></fa-icon>
            </button>
          </div>
        </div>
      </div><!-- /block-search -->

    </div>
  </div>

  <div class="row">
    <div class="col-12">

      <div class="bios-list-container">

        <ul *ngIf="tribesters.length" class="list list-bios list-bios-selected mb-4">
          <li *ngFor="let item of tribesters" class="item item-bio">
            <div class="row">
              <div class="col-sm-3">
                <div class="thumb thumb-bio" [appBioTypeClass]="item.type">
                  <app-file-lazy-loader [bio]="item" [file]="item.profile"></app-file-lazy-loader>
                </div>
              </div>
              <div class="col-sm-6">
                <h3 class="title">{{ item.firstName }} {{ item.lastName }}</h3>
              </div>
              <div class="col-sm-3 text-sm-right">
                <button type="button" class="btn btn-sm btn-hollow" (click)="removeTribester(item)">
                  Remove
                </button>
              </div>
            </div>

          </li>
        </ul>

        <ul class="list list-bios">
          <li *ngFor="let item of pagedBios.list" class="item item-bio">
            <div class="row">
              <div class="col-sm-3">
                <div class="thumb thumb-bio" [appBioTypeClass]="item.type">
                  <app-file-lazy-loader [bio]="item" [file]="item.profile"></app-file-lazy-loader>
                </div>
              </div>
              <div class="col-sm-6">
                <h3 class="title">{{ item.firstName }} {{ item.lastName }}</h3>
              </div>
              <div class="col-sm-3 text-sm-right">
                <button type="button" class="btn btn-sm btn-hollow" (click)="addTribester(item)">
                  Add
                </button>
              </div>
            </div>

          </li>
        </ul>

        <app-paginator [page]="this.pagedBios.page" [pages]="this.pagedBios.pages"
                       (pageChange)="onPageChange($event)"></app-paginator>

      </div><!-- /bios-list-container -->

    </div>
  </div>

</div>

<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="onSubmit()">
    Save
  </button>
</div>
<app-content-loader [scope]="'modal'"></app-content-loader>
