import {TribeActivityResult} from '../../core/models/tribe-activity-result.model';
import {BioSearchResult} from '../../core/models/bio-search-result.model';

export class TribesState {
  activity: TribeActivityResult;
  tribeSearchResult: BioSearchResult;
}

export const initialTribeState: TribesState = {
  activity: new TribeActivityResult(),
  tribeSearchResult: new BioSearchResult(),
};
