<form [formGroup]="editForm" (ngSubmit)="onSubmit()">
<div class="modal-header">
  <h4 class="modal-title">
    {{ tribe ? 'Tribester Relationship' : 'Invite to Tribe' }}
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss(false)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <form-validation [control]="editForm" [submitted]="submitted"></form-validation>

  <div class="form-group row">
    <div class="col-6">

      <label class="control-label">Our relationship is:</label>
      <select formControlName="relationshipType" class="form-control form-control-lg input-bio"
              [ngClass]="{ 'is-invalid': submitted && f.relationshipType.errors }"
              aria-label="Relationship Type" (ngModelChange)="onRelationshipChange($event)">
        <option [ngValue]="null" selected>Select one...</option>
        <option *ngFor="let item of relationshipTypes" [ngValue]="item.id">{{ item.label }}</option>
      </select>
      <app-control-validation [control]="f.relationshipType" [submitted]="submitted"></app-control-validation>

    </div>
    <div class="col-6">

      <label class="control-label">They are:</label>
      <select formControlName="relationshipSubType" class="form-control form-control-lg input-bio"
              [ngClass]="{ 'is-invalid': submitted && f.relationshipSubType.errors }"
              aria-label="Relationship" (ngModelChange)="onRelationshipSubChange($event)">>
        <option [ngValue]="null" selected>Select one...</option>
        <option *ngFor="let item of relationshipSubTypesFiltered" [ngValue]="item.id">{{ item.label }}</option>
        <option [ngValue]="-1">Other</option>
      </select>
      <app-control-validation [control]="f.relationshipSubType" [submitted]="submitted"></app-control-validation>

    </div>
  </div>

  <div *ngIf="f.relationshipOther" class="form-group">
    <label class="control-label">Relationship Other</label>
    <input maxlength="250" type="text" formControlName="relationshipOther" class="form-control form-control-lg input-bio"
           [ngClass]="{ 'is-invalid': submitted && f.relationshipOther.errors }" placeholder="Describe..."/>
    <app-control-validation [control]="f.relationshipOther" [submitted]="submitted"></app-control-validation>
  </div>

  <div class="form-group text-center">
    <button type="submit" class="btn btn-gold">
       {{ tribe ? 'Save' : 'Send invitation' }}
    </button>
  </div>

</div>
</form>
<app-content-loader></app-content-loader>
