import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {first} from 'rxjs/operators';
import {AlertService} from '../alert/alert.service';
import {BioUser} from '../../../core/models/bio-user.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {GuardianAuthorityModalComponent} from '../../modals/guardian-authority/guardian-authority-modal.component';
import {BioUserService} from '../../../core/services/bio-user.service';
import {UserBioActions} from '../../../store/user-bios';
import {FormBuilder} from '@angular/forms';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store';

@Component({
  selector: 'app-guardian-request-list',
  templateUrl: 'guardian-request-list.component.html'
})
export class GuardianRequestListComponent implements OnInit {

  @Input()
  public activityList: BioUser[] = [];

  @Output()
  public requestResponse: EventEmitter<boolean>;

  constructor(
    protected modalService: NgbModal,
    protected alertService: AlertService,
    protected bioUserService: BioUserService,
    protected store: Store<AppState>
  ) {
    this.requestResponse = new EventEmitter<boolean>();
  }

  ngOnInit(): void {

  }

  public acceptRequest(guardianRequest: any) {

    // Show Modal with Auth information
    const modal = this.modalService.open(GuardianAuthorityModalComponent, {size: 'lg'});
    modal.componentInstance.bio = guardianRequest.bio;
    modal.result.then((accepted: boolean) => {

      // Accept Guardian request
      if (accepted) {

        // todo: Move to store
        this.bioUserService.acceptGuardianRequest(guardianRequest.bio.id)
          .pipe(first())
          .subscribe(
            (data) => {
              this.alertService.info(data.message);

              // Remove from pending list
              const idx = this.activityList.indexOf(guardianRequest);
              this.activityList.splice(idx, 1);

              // refresh the Users Wards
              this.store.dispatch(
                UserBioActions.getUserBiosRequest({
                  id: null
                })
              );

              this.requestResponse.emit(true);
            },
            error => {
            });

      } else {

        // Decline Guardian request
        this.cancelRequest(guardianRequest);
      }
    }, (reason) => {

    });
  }

  public cancelRequest(guardianRequest: any) {

    // todo: Move to store
    this.bioUserService.cancelGuardianRequest(guardianRequest.bio.id)
      .pipe(first())
      .subscribe(
        data => {
          this.alertService.info(data.message);

          // Remove from pending list
          const idx = this.activityList.indexOf(guardianRequest);
          this.activityList.splice(idx, 1);

          this.requestResponse.emit(false);
        },
        error => {
        });
  }

}
