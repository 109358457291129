

<ng-template #headerControls>
  <div class="block block-btns text-right mt-sm-2">

    <button *ngIf="!tribeRelationship && bio?.id != currentBio?.id" (click)="sendTribeRequest()"
            class="btn btn-gold ml-2" type="button">
      <fa-icon [icon]="faPlusCircle"></fa-icon>
      Invite <span class="d-none d-sm-inline">{{ bio?.firstName }} to Tribe</span>
    </button>

    <button *ngIf="tribeRelationship?.statusLabel == 'Pending' && tribeRelationship?.sender"
            (click)="resendTribeRequest()" class="btn btn-gold ml-2" type="button">
      <fa-icon [icon]="faMinusCircle"></fa-icon>
      Resend Invite
    </button>

    <button *ngIf="tribeRelationship?.statusLabel == 'Pending' && tribeRelationship?.sender"
            (click)="cancelTribeRequest()" class="btn btn-blue ml-2" type="button">
      <fa-icon [icon]="faMinusCircle"></fa-icon>
      Cancel Invite
    </button>

    <button *ngIf="tribeRelationship?.statusLabel == 'Pending' && tribeRelationship?.receiver"
            (click)="acceptTribeRequest()" class="btn btn-gold ml-2" type="button">
      <fa-icon [icon]="faPlusCircle"></fa-icon>
      Accept Invite
    </button>

    <button *ngIf="tribeRelationship?.statusLabel == 'Pending' && tribeRelationship?.receiver"
            (click)="cancelTribeRequest()" class="btn btn-blue ml-2" type="button">
      <fa-icon [icon]="faMinusCircle"></fa-icon>
      Decline Invite
    </button>

    <button *ngIf="tribeRelationship?.statusLabel == 'Active'" (click)="cancelTribeRequest()"
            class="btn btn-gold ml-2" type="button">
      <fa-icon [icon]="faMinusCircle"></fa-icon>
      Remove <span class="d-none d-sm-inline">from Tribe</span>
    </button>

  </div><!-- /block-btns -->
</ng-template>

<app-page-header title="Legacy Profile" [controls]="headerControls">
  <app-bio-share-link [bio]="bio"></app-bio-share-link>
</app-page-header>

<div class="container">
  <div class="row">
    <div class="col-12">

      <app-bio-legacy-view></app-bio-legacy-view>

    </div>
  </div>
</div><!--/container-->
