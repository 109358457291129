import {initialTribeState, TribesState} from './tribe.state';
import {Action, createReducer, on} from '@ngrx/store';
import {TribeActions} from './index';
import {BioSearchResult} from '../../core/models/bio-search-result.model';

const reducer = createReducer<TribesState>(
  initialTribeState,

  on(TribeActions.getTribeActivityRequestSuccess, (state, {tribeActivityResult}) => {
    return {
      ...state,
      activity: tribeActivityResult
    };
  }),

  on(TribeActions.resetTribeSearchRequest, (state) => {
    return {
      ...state,
      tribeSearchResult: new BioSearchResult()
    };
  }),

  on(TribeActions.searchTribeRequestSuccess, (state, {tribeSearchResult}) => {
    return {
      ...state,
      tribeSearchResult
    };
  }),

  on(TribeActions.updateTribeSuccess, (state, {bio}) => {
    const idx = state.tribeSearchResult.list.map(b => b.id).indexOf(bio.id);
    return {
      ...state,
      tribeSearchResult: {
        ...state.tribeSearchResult,
        list: [
          ...state.tribeSearchResult.list.slice(0, idx),
          bio,
          ...state.tribeSearchResult.list.slice(idx + 1),
        ]
      }
    };
  }),
);

export function TribesReducer(state: TribesState, action: Action) {
  return reducer(state, action);
}
