<ng-template #headerControls>
  <div class="block block-btns text-right mt-sm-2">

    <button *ngIf="!tribeRelationship && bio?.id != currentBio?.id" (click)="sendTribeRequest()"
            class="btn btn-gold" type="button">
      <fa-icon [icon]="faPlusCircle"></fa-icon>
      Invite <span class="d-none d-sm-inline">{{ bio?.firstName }}</span> to Tribe
    </button>

    <button *ngIf="tribeRelationship?.statusLabel == 'Pending' && tribeRelationship?.sender"
            (click)="resendTribeRequest()" class="btn btn-gold ml-2" type="button">
      <fa-icon [icon]="faPlusCircle"></fa-icon>
      Resend Invite
    </button>

    <button *ngIf="tribeRelationship?.statusLabel == 'Pending' && tribeRelationship?.sender"
            (click)="cancelTribeRequest()" class="btn btn-blue ml-2" type="button">
      <fa-icon [icon]="faMinusCircle"></fa-icon>
      Cancel Invite
    </button>

    <button *ngIf="tribeRelationship?.statusLabel == 'Pending' && tribeRelationship?.receiver"
            (click)="acceptTribeRequest()" class="btn btn-gold ml-2" type="button">
      <fa-icon [icon]="faPlusCircle"></fa-icon>
      Accept Invite
    </button>

    <button *ngIf="tribeRelationship?.statusLabel == 'Pending' && tribeRelationship?.receiver"
            (click)="cancelTribeRequest()" class="btn btn-blue ml-2" type="button">
      <fa-icon [icon]="faMinusCircle"></fa-icon>
      Decline Invite
    </button>

    <button *ngIf="tribeRelationship?.statusLabel == 'Active'" (click)="cancelTribeRequest()"
            class="btn btn-gold ml-2" type="button">
      <fa-icon [icon]="faMinusCircle"></fa-icon>
      Remove <span class="d-none d-sm-inline-block">from Tribe</span>
    </button>

  </div><!-- /block-btns -->
</ng-template>

<app-page-header title="Life Profile" [controls]="headerControls"></app-page-header>

<div class="container">
  <div class="row">
    <div class="col-12">

      <div class="card card-bio">
        <div class="card-body">

          <div *ngIf="bio" class="bio-container">
            <div class="row">
              <div class="col-sm-4 mb-4 mb-sm-0">

                <div class="bio-profile bg-lightgold" [appBioTypeClass]="bio.type">
                  <app-file-lazy-loader [bio]="bio" [file]="bio.profile"></app-file-lazy-loader>
                </div>

                <div *ngIf="bio.tribe.length" class="bio-tribe bg-lightgold">
                  <h3 class="bio-tribe-title p-2 m-0">Tribesters</h3>

                  <div class="tribe-active-container mt-3">
                    <app-bio-list [list]="bio.tribe"></app-bio-list>
                  </div>

                </div>

              </div>
              <div class="col-sm-8">
                <h1 class="bio-name">
                  {{ bio.firstName }} {{ bio.lastName }}
                  <app-ico-candle *ngIf="bio?.isLegacy"></app-ico-candle>
                </h1>
                <h2 *ngIf="bio.preferredNames?.length" class="bio-aka">aka {{ bio.preferredNames[0] }}</h2>

                <div *ngIf="bio.addresses?.length" class="bio-address">
                  {{ bio.addresses[0].suburb }} {{ bio.addresses[0].state }}
                </div>

                <div class="row row-page-btns row-page-btns-bio-view row-page-btns-things">
                  <div class="btn-group btn-group-toggle" ngbRadioGroup
                       [(ngModel)]="jarType" (ngModelChange)="onPageChange(1);search();">

                    <label class="btn" ngbButtonLabel *ngIf="bio.memoryJars.length > 0">
                      <input type="radio" ngbButton [value]="MemoryJarType">
                      <div class="sprite sprite-thing-jar"></div>
                      <div class="text">Memory Jars</div>
                    </label>

                    <label class="btn" ngbButtonLabel *ngIf="bio.thingJars.length > 0">
                      <input type="radio" ngbButton [value]="ThingJarType">
                      <div class="sprite sprite-thing-jar"></div>
                      <div class="text">Thing Jars</div>
                    </label>

                    <label class="btn" ngbButtonLabel *ngIf="bio.tribeRelationship?.length && bio.passwordJars.length > 0">
                      <input type="radio" ngbButton [value]="PasswordJarType">
                      <div class="sprite sprite-thing-jar">
                        <span></span>
                      </div>
                      <div class="text">Password Jars</div>
                    </label>

                  </div>
                </div><!-- /row-page-btns -->


                <div class="block block-search mt-2 mb-4">
                  <div class="input-group">
                    <input type="text" class="form-control" placeholder="Search..." aria-label="Search Tribe"
                           [(ngModel)]="keywords" (change)="queueSearch()">
                    <div class="input-group-append">
                      <button class="btn" type="button" (click)="search()">
                        <span class="sr-only">Search</span>
                        <fa-icon [icon]="faSearch"></fa-icon>
                      </button>
                    </div>
                  </div>
                </div><!-- /block-search -->


                <div class="memories-list-container">

                  <ul class="list list-memories">
                    <li *ngFor="let item of pagedMemories.list" class="item item-memory">

                      <ng-container *ngIf="PasswordNodeTypes.indexOf(item.nodeType) >= 0">
                        <div class="row">
                          <div class="col-sm-12">
                            <h4 class="tag clearfix">
                            <span class="font-weight-bold" *ngIf="item.parentNode">
                              <fa-icon [icon]="faTag"></fa-icon>
                              {{ (item.parentNode|options: 'biojar')?.title }}
                            </span>

                              <span class="float-right smaller ml-2"><fa-icon [icon]="faEye"></fa-icon>
                                {{ (item.secretKeeper|options: 'secretKeeper')?.label }}</span>

                              <span class="float-right smaller ml-2"><fa-icon [icon]="faClock"></fa-icon>
                                {{ (item.timeLock|options: 'timeLock')?.label}}</span>

                              <span *ngIf="item?.date" class="float-right smaller ml-2">
                            <fa-icon [icon]="faCalendar"></fa-icon>
                                {{ item.date | date: 'mediumDate' }}</span>

                            </h4>
                            <h3 class="title">
                              {{ item.title }}
                            </h3>
                            <div class="description">
                              {{ (item.description || item.placeholder) | truncate:['200', '...'] }}
                            </div>
                          </div>
                        </div>
                        <div class="row row-controls">
                          <div class="col-12 text-right">
                            <button (click)="viewPassword( item )" type="button" class="btn btn-sm btn-hollow ml-2">
                              View
                            </button>
                          </div>
                        </div>
                      </ng-container>

                      <ng-container *ngIf="PasswordNodeTypes.indexOf(item.nodeType) == -1">
                        <div class="row">
                          <div class="col-sm-3">
                            <div class="thumb thumb-memory mb-2">
                              <app-file-lazy-loader [memory]="item" [file]="item.files[0]"
                                                    (click)="showLightbox(item, item.files[0])"></app-file-lazy-loader>
                              <app-ico-image-count [count]="item?.files?.length"></app-ico-image-count>
                            </div>
                          </div>
                          <div class="col-sm-9">
                            <h4 class="tag clearfix">
                            <span class="font-weight-bold" *ngIf="item.parentNode">
                              <fa-icon [icon]="faTag"></fa-icon>
                              {{ (item.parentNode|options: 'biojar')?.title }}
                              -  {{ (item.memoryTag|options: 'memoryTag')?.label }}
                            </span>

                              <span class="float-right smaller ml-2"><fa-icon [icon]="faEye"></fa-icon>
                                {{ (item.secretKeeper|options: 'secretKeeper')?.label }}</span>

                              <span class="float-right smaller ml-2"><fa-icon [icon]="faClock"></fa-icon>
                                {{ (item.timeLock|options: 'timeLock')?.label}}</span>

                              <span *ngIf="item?.date" class="float-right smaller ml-2">
                            <fa-icon [icon]="faCalendar"></fa-icon>
                                {{ item.date | date: 'mediumDate' }}</span>

                            </h4>
                            <h3 class="title">
                              {{ item.title }}
                            </h3>
                            <div class="description">
                              {{ (item.description || item.placeholder) | truncate:['200', '...'] }}
                            </div>
                          </div>
                        </div>
                        <div class="row row-controls">
                          <div class="col-12 text-right">

                            <button *ngIf="item.allowShare" class="btn btn-sm btn-gold mr-2"
                                    (click)="addToJarPicker($event, item)" type="button">
                              <fa-icon [icon]="faShare"></fa-icon>
                              Add to my Jar
                            </button>

                            <button *ngIf="item.allowComments" class="btn btn-sm btn-gold mr-2"
                                    (click)="showMemoryComments($event, item)" type="button">
                              <fa-icon [icon]="faComment"></fa-icon>
                              Recollections ({{ item.commentCount }})
                            </button>

                            <button (click)="viewMemory( item )" type="button" class="btn btn-sm btn-hollow">
                              View
                            </button>
                          </div>
                        </div>
                      </ng-container>

                    </li>
                  </ul>

                  <app-paginator [page]="pagedMemories.page" [pages]="pagedMemories.pages"
                                 (pageChange)="onPageChange($event)"></app-paginator>

                </div><!-- /memories-list-container -->

              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>

</div><!--/container-->
