<div class="img-lazy-loader"
     [ngClass]="{
       'img-lazy-loader-img' : isImage(),
       'img-lazy-loader-document': isDocument(),
       'img-lazy-loader-video' : isVideo(),
       'img-lazy-loader-audio' : isAudio(),
       'img-lazy-loader-unknown' : isUnknown(),
       'img-lazy-loader-empty' : (!file || !file.src),
       'img-lazy-loader-pending': file && file.moderation === ModerationStatus.Pending,
       'img-lazy-loader-rejected': file && file.moderation === ModerationStatus.Rejected
      }">

  <div *ngIf="file && file.moderation === ModerationStatus.Pending" class="moderation-pending">
    Pending
  </div>

  <div *ngIf="file && file.moderation === ModerationStatus.Rejected" class="moderation-rejected">
    Rejected
  </div>

  <div *ngIf="( !loading && isImage() && file.src && file.moderation === ModerationStatus.Approved)"
       class="image" [ngStyle]="{'background-image':'url('+file.src+')'}">
    <img #image [src]="file.src | safe:'resourceUrl'" alt="{{ file.title }}" (load)="onLoadingComplete($event)">
  </div>

  <object *ngIf="(!loading && isDocument() && file.src && file.moderation === ModerationStatus.Approved)"
          [data]="file.src | safe:'resourceUrl'" type="{{ file.mime }}">
    <embed #document [src]="file.src | safe:'resourceUrl'" type="{{ file.mime }}"
           (load)="onLoadingComplete($event)" />
  </object>

  <video #video *ngIf="(!loading && isVideo() && file.src && file.moderation === ModerationStatus.Approved)"
         width="100%" height="100%" controls
         (loadedmetadata)="onLoadingComplete($event)">
    <source [src]="file.src | safe:'resourceUrl'" type="{{ file.mime }}" >
    Your browser does not support the video tag.
  </video>

  <audio #audio *ngIf="(!loading && isAudio() && file.src && file.moderation === ModerationStatus.Approved)"
         width="100%" height="100%" controls
         (loadedmetadata)="onLoadingComplete($event)">
    <source [src]="file.src | safe:'resourceUrl'" type="{{ file.mime }}" >
    Your browser does not support the audio tag.
  </audio>

  <a *ngIf="downloadable && file?.src && file.moderation === ModerationStatus.Approved" [href]="file.src"
     class="btn btn-sm btn-hollow btn-download" target="_blank" download>
    <fa-icon [icon]="faDownload"></fa-icon>
    <span class="sr-only">Download</span>
  </a>

  <span *ngIf="loading" class="spinner-border spinner-border-lg" role="status"></span>
</div><!-- /img-lazy-loader -->
