import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Bio} from '../../../../core/models/bio.model';
import {AlertService} from '../../../../shared/components/alert/alert.service';
import {BreadcrumbNavItem} from '../../../../shared/components/breadcrumb/breadcrumb.component';
import {Subscription} from 'rxjs';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {first} from 'rxjs/operators';
import {BioService} from '../../../../core/services/bio.service';
import {AppDateFormatter} from '../../../../core/format/date.format';
import {ProfilePickerComponent} from '../../../../shared/modals/pickers/profile-picker/profile-picker.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ControlValidationService} from '../../../../shared/components/control-validation/control-validation.service';
import {BioSearchModalComponent, SearchMode} from '../../../../shared/modals/bio-search/bio-search-modal.component';
import {BioUser} from '../../../../core/models/bio-user.model';
import {BioUserRolesModalComponent} from '../../../../shared/modals/bio-user-roles/bio-user-roles-modal.component';
import {Options} from 'sortablejs';
import {ConfirmPopupComponent} from '../../../../shared/modals/confirm-popup/confirm-popup.component';
import {ResponseMessage} from '../../../../core/models/response-message.model';
import {
  faCalendar, faPlusCircle, faPencilAlt, faSearch,
  faArrowsAlt, faExchangeAlt, faUserTimes, faMapMarker
} from '@fortawesome/free-solid-svg-icons';
import {CommonAddress} from '../../../../core/models/common-address.model';
import {LegacyTransitionModalComponent} from '../../../../shared/modals/legacy-transition/legacy-transition-modal.component';
import {LocationPickerComponent} from '../../../../shared/modals/pickers/location/location-picker.component';
import {select, Store} from '@ngrx/store';
import {selectCurrentBio} from '../../../../store/bios/bios.selectors';
import {AppState} from '../../../../store';
import {BioActions} from '../../../../store/bios';
import {selectEthnicityGroupOptions, selectOptions} from '../../../../store/options/options.selectors';
import {OptionsState} from '../../../../store/options/options.state';
import {selectBioUsers} from '../../../../store/bio-users/bio-users.selectors';
import {BioUserActions} from '../../../../store/bio-users';
import {BioUserService} from '../../../../core/services/bio-user.service';
import {LayoutService} from '../../../../core/services/layout.service';
import {IListOption} from '../../../../core/models/option.model';
import {IListOptionGroup} from '../../../../core/models/option-group.model';

@Component({
  template: ''
})
export abstract class AbstractBioEditComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly subscription: Subscription;

  public bioForm: FormGroup;
  public id: number;
  public bio: Bio;
  public bioUsers: BioUser[];
  public optionsData: OptionsState;
  public submittedShowErrors = false;

  public faCalendar = faCalendar;
  public faSearch = faSearch;
  public faPlusCircle = faPlusCircle;
  public faPencilAlt = faPencilAlt;
  public faArrowsAlt = faArrowsAlt;
  public faExchangeAlt = faExchangeAlt;
  public faUserTimes = faUserTimes;
  public faMapMarker = faMapMarker;

  public sortOptions: Options;

  public get Options() {
    return this.optionsData;
  }

  public get GenderTypes() {
    return this.optionsData.genderTypes;
  }

  public get Religions() {
    return this.optionsData.religions;
  }

  public get PracticingReligionStatuses() {
    return this.optionsData.practicingReligionStatuses;
  }

  public ethnicityChoices: IListOptionGroup[];
  // public get EthnicityChoices() {
  //   return this.optionsData.ethnicTypes;
  // }

  public get CustomTraditionChoices() {
    return this.optionsData.customTraditions;
  }

  public get PrivacyChoices() {
    return this.optionsData.privacyStatus;
  }

  // convenience getter for easy access to form fields
  public get f() {
    return this.bioForm.controls;
  }

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected modalService: NgbModal,
    protected formBuilder: FormBuilder,
    protected store: Store<AppState>,
    protected bioService: BioService,
    protected bioUserService: BioUserService,
    protected alertService: AlertService,
    protected dateFormatter: AppDateFormatter,
    protected layoutService: LayoutService
  ) {

    this.bioForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      middleNames: this.formBuilder.array([]),
      lastName: ['', Validators.required],
      gender: [null, Validators.required],
      birthday: [null, Validators.required],
      preferredNames: this.formBuilder.array([]),
      previousNames: this.formBuilder.array([]),
      addresses: this.formBuilder.array([]),
      ethnicity: [null],
      religion: [null],
      practicingReligion: [null],
      customTradition: [null],
      privacy: [2, Validators.required],
      allowComments: [true]
    });

    this.sortOptions = {
      sort: true,
      disabled: false,
      handle: '.sort-handle',
      direction: 'vertical'
    };

    this.subscription = new Subscription();

    this.subscription.add(this.store.pipe(select(selectCurrentBio))
      .subscribe((bio: Bio) => {
        this.setBio(bio);
        this.store.dispatch(
          BioActions.setDisplayBioRequest({bio})
        );
      })
    );

    this.bioUsers = [];
    this.subscription.add(this.store.pipe(select(selectBioUsers))
      .subscribe((bioUsers: BioUser[]) => {
        this.bioUsers = bioUsers; // aka Guardians
      })
    );

    this.optionsData = new OptionsState();
    this.subscription.add(this.store.pipe(select(selectOptions))
      .subscribe((options: OptionsState) => {
        this.optionsData = options;
      })
    );

    this.subscription.add(this.store.pipe(select(selectEthnicityGroupOptions))
      .subscribe((options: IListOptionGroup[]) => {
        this.ethnicityChoices = options;
      })
    );
  }

  public ngOnInit() {

  }

  public ngAfterViewInit(): void {
    this.layoutService.showTutorial('editBioTutorial');
  }

  get preferredNames() {
    return this.bioForm.get('preferredNames') as FormArray;
  }

  addPreferredName() {
    this.preferredNames.push(this.formBuilder.control(''));
  }

  removePreferredName(i) {
    this.preferredNames.removeAt(i);
  }

  get previousNames() {
    return this.bioForm.get('previousNames') as FormArray;
  }

  addPreviousName() {
    this.previousNames.push(this.formBuilder.control(''));
  }

  removePreviousName(i) {
    this.previousNames.removeAt(i);
  }

  get middleNames() {
    return this.bioForm.get('middleNames') as FormArray;
  }

  addMiddleName() {
    this.middleNames.push(this.formBuilder.control(''));
  }

  removeMiddleName(i) {
    this.middleNames.removeAt(i);
  }

  get emails() {
    return this.bioForm.get('emails') as FormArray;
  }

  addEmail() {
    this.emails.push(this.formBuilder.control('', [ControlValidationService.emailValidator]));
  }

  removeEmail(i) {
    this.emails.removeAt(i);
  }

  get phones() {
    return this.bioForm.get('phones') as FormArray;
  }

  addPhone() {
    this.phones.push(this.formBuilder.control(''));
  }

  removePhone(i) {
    this.phones.removeAt(i);
  }

  get addresses() {
    return this.bioForm.get('addresses') as FormArray;
  }

  addAddress() {
    this.addresses.push(this.formBuilder.control(new CommonAddress()));
  }

  removeAddress(i) {
    this.addresses.removeAt(i);
  }

  public initializeMultiValuedFormFields(fieldName) {
    const bioField = this.bio[fieldName];
    const formField = (this.bioForm.get(fieldName) as FormArray);
    if (bioField && formField) {
      formField.clear();

      const isAddress = (fieldName === 'addresses');

      if (bioField.length) {

        this.bio[fieldName].forEach(function(item) {
          if (isAddress === true) {
            formField.push(this.formBuilder.control({...new CommonAddress(), ...item}));
          } else {
            formField.push(this.formBuilder.control(item));
          }
        }.bind(this));

      } else {

        // Add an empty placeholder
        if (isAddress === true) {
          formField.push(this.formBuilder.control(new CommonAddress()));
        } else {
          formField.push(this.formBuilder.control(''));
        }
      }

    }

  }

  protected configureOptionalFormFields() {
    // Life Bio
    if (this.bio.isLegacy === false) {
      this.bioForm.removeControl('deathday');
      this.bioForm.removeControl('restingLocation');

      this.bioForm.addControl('emails', this.formBuilder.array([]));
      this.initializeMultiValuedFormFields('emails');

      this.bioForm.addControl('phones', this.formBuilder.array([]));
      this.initializeMultiValuedFormFields('phones');

      // Legacy Bio
    } else {
      this.bioForm.removeControl('emails');
      this.bioForm.removeControl('phones');

      this.bioForm.addControl('deathday', new FormControl('', [Validators.required]));
      this.bioForm.get('deathday').setValue(this.dateFormatter.parse(this.bio.deathday, 'YYYY-MM-DD'));

      this.bioForm.addControl('restingLocation', new FormControl(''));
      this.bioForm.get('restingLocation').setValue(this.bio.restingLocation);
    }
  }

  public setBio(bio: Bio) {
    this.bio = bio;
    if (this.bio == null) {
      return;
    }

    this.layoutService.setTitle(`${this.bio.firstName} ${this.bio.lastName} Edit - My LifeJars`);

    this.bioForm.patchValue(this.bio);

    // ngBootstrap Dates need to be in a 'stupid' format
    this.bioForm.get('birthday').setValue(this.dateFormatter.parse(this.bio.birthday, 'YYYY-MM-DD'));

    // initialize form fields with multiple options/choices.
    if (bio.religion) {
      this.bioForm.get('religion').setValue(this.bio.religion);
    }

    if (bio.practicingReligion) {
      this.bioForm.get('practicingReligion').setValue(this.bio.practicingReligion);
    }

    if (bio.ethnicity) {
      this.bioForm.get('ethnicity').setValue(this.bio.ethnicity);
    }

    if (bio.customTradition) {
      this.bioForm.get('customTradition').setValue(this.bio.customTradition);
    }

    // initialize multi valued form fields.
    this.initializeMultiValuedFormFields('middleNames');
    this.initializeMultiValuedFormFields('preferredNames');
    this.initializeMultiValuedFormFields('previousNames');
    this.initializeMultiValuedFormFields('addresses');

    // Configure Conditional Form Fields
    this.configureOptionalFormFields();
  }

  public onSubmit() {

    // stop here if form is invalid
    this.submittedShowErrors = true;
    if (this.bioForm.invalid) {
      return;
    }

    const formData = this.bioForm.getRawValue();
    if (formData.birthday) {
      formData.birthday = this.dateFormatter.format(formData.birthday, 'YYYY-MM-DD');
    }

    if (formData.deathday) {
      formData.deathday = this.dateFormatter.format(formData.deathday, 'YYYY-MM-DD');
    }

    const bioModel = {...this.bio, ...formData};
    this.store.dispatch(
      BioActions.saveBioRequest({bio: bioModel})
    );
  }


  public delete(event: any) {
    event.preventDefault();

    const modal = this.modalService.open(ConfirmPopupComponent, {size: 'md', backdrop: 'static'});
    modal.componentInstance.title = 'Permanently Delete Profile';
    modal.componentInstance.message = '<strong>Warning: You cannot recover a permanently deleted Profile. </strong>' +
      '<br/><br/> Are you sure you want to permanently delete this Profile including Memories, Things and related files?';
    modal.componentInstance.classes = 'danger';
    modal.result.then((result) => {
      this.store.dispatch(
        BioActions.deleteBioRequest({bio: this.bio})
      );
    }, (reason) => { });
  }

  public openProfilePicker(event: any) {
    event.preventDefault();
    const modal = this.modalService.open(ProfilePickerComponent, {size: 'lg', backdrop: 'static'});
    modal.componentInstance.bio = this.bio;
  }

  public openGuardianSearchModal(event: any) {
    event.preventDefault();

    const modal = this.modalService.open(BioSearchModalComponent, {});
    modal.componentInstance.searchMode = SearchMode.Users;
    modal.componentInstance.isPicker = true;
    modal.componentInstance.bio = this.bio;
    modal.result.then((bio: Bio) => {

      // Create new Bio User and Open Permissions
      const bioUser = new BioUser();
      bioUser.bio = this.bio;
      bioUser.user = bio.user;
      bioUser.userBio = bio;
      this.openGuardianPermissionsModal(bioUser);

    }, (reason) => {
      console.log(reason);
    });
  }

  public openGuardianPermissionsModal(bioUser: BioUser) {
    const modal = this.modalService.open(BioUserRolesModalComponent, {size: 'lg', backdrop: 'static'});
    modal.componentInstance.bio = this.bio;
    modal.componentInstance.bioUser = bioUser;
  }


  public removeGuardian(bioUser: BioUser) {

    const modal = this.modalService.open(ConfirmPopupComponent, {backdrop: 'static'});
    modal.componentInstance.title = 'Remove this Guardian ';
    modal.componentInstance.message = 'Are you sure you want to remove this Guardian?';
    modal.componentInstance.classes = 'danger';
    modal.result.then((result) => {
      this.store.dispatch(
        BioUserActions.deleteBioUserRequest({bioUser})
      );
    }, (reason) => {

    });
  }


  public transfer(event: any) {
    event.preventDefault();

    const modal = this.modalService.open(BioSearchModalComponent, {});
    modal.componentInstance.searchMode = SearchMode.Users;
    modal.componentInstance.isPicker = true;
    modal.componentInstance.bio = this.bio;
    modal.result.then((userBio: Bio) => {
      this.openConfirmTransferModal(userBio);
    }, (reason) => {
      console.log(reason);
    });
  }

  public openConfirmTransferModal(userBio: Bio) {

    // Create a New BioUser
    const bioUser: BioUser = new BioUser();
    bioUser.bio = userBio;
    bioUser.user = userBio.user;

    const modal = this.modalService.open(ConfirmPopupComponent, {size: 'md', backdrop: 'static'});
    modal.componentInstance.title = 'Transfer Profile Ownership';
    modal.componentInstance.message = `Are you sure you want to transfer ownership to ` +
      `<strong>${userBio.firstName} ${userBio.lastName}</strong>?`;
    modal.componentInstance.classes = 'warning';
    modal.result.then((result) => {

      this.bioUserService.transferBioOwnership(this.bio, bioUser)
        .pipe(first())
        .subscribe((msg: ResponseMessage) => {

          // This will revert us back to the default Bio
          this.bioService.switchBio().pipe(first()).subscribe();

          this.alertService.info(`The Profile has been transferred to ${userBio.firstName} ${userBio.lastName}.`);
        });

    }, (reason) => {

    });
  }


  public openLegacyTransitionModal(event: any) {
    event.preventDefault();

    const modal = this.modalService.open(LegacyTransitionModalComponent, {});
    modal.componentInstance.bio = this.bio;
    modal.result.then((userBio: Bio) => {
      // refresh the Current Bio
      this.bioService.switchBio().pipe(first()).subscribe();
    }, (reason) => {
      console.log(reason);
    });
  }

  public onLocationChange(event) {
    event.target.blur(); // Without this we get exception: ExpressionChangedAfterItHasBeenCheckedError
    event.preventDefault();
    const modal = this.modalService.open(LocationPickerComponent, {size: 'lg', backdrop: 'static'});
    modal.componentInstance.address = this.bio.restingLocation;
    modal.result.then((result) => {
      this.bioForm.get('restingLocation').setValue(result);
    }, (reason) => {
      console.log('Negative: ', reason);
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
