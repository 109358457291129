import {Component, Input, OnInit} from '@angular/core';
import {faChevronDown, faChevronUp} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-expandable-text',
  templateUrl: 'expandable-text.component.html',
  styleUrls: ['expandable-text.component.css']
})
export class ExpandableTextComponent implements OnInit {
  @Input() text: string;
  @Input() expand: boolean;
  @Input() minLength: number;

  public showButton: boolean;
  public faChevronDown = faChevronDown;
  public faChevronUp = faChevronUp;

  constructor() {
    this.text = '';
    this.expand = false;
    this.minLength = 400;
  }

  public ngOnInit(): void {
    if (this.text !== null) {
      const linebreaks = this.text.split(/\r?\n/g).length;
      const characters = this.text.length;
      this.showButton = characters > this.minLength || linebreaks > 4;
    }
  }

  public toggle() {
    this.expand = !this.expand;
  }

}
