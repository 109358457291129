import {Component, Input, OnInit} from '@angular/core';
import {MemoryModel} from '../../../../core/models/memory.model';

@Component({
  selector: 'app-bio-print-page-photos',
  templateUrl: 'bio-print-page-photos.component.html'
})
export class BioPrintPagePhotosComponent implements OnInit {

  @Input()
  public page: number;

  @Input()
  public photos: any[];

  @Input()
  public memory: MemoryModel;

  constructor() {
    this.photos = [];
    this.memory = new MemoryModel();
  }

  ngOnInit(): void {

  }

}
