
<div class="modal-header modal-memory-view">
  <h4 class="modal-title">
    {{ memory?.title }}
    <div class="small">
      <span class="btn btn-sm btn-gold"><fa-icon [icon]="faTag"></fa-icon>
        {{ jar?.title }}</span>
      <span class="btn btn-sm btn-blue ml-2"><fa-icon [icon]="faTag"></fa-icon>
        {{ (memory?.memoryTag|options:'memoryTag')?.label }}</span>
      <span class="btn btn-sm btn-blue ml-2"><fa-icon [icon]="faClock"></fa-icon>
        {{ (memory?.timeLock|options:'timeLock')?.label }}</span>
      <span class="btn btn-sm btn-blue ml-2"><fa-icon [icon]="faEye"></fa-icon>
        {{ (memory?.secretKeeper|options:'secretKeeper')?.label }}</span>
    </div>
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body modal-memory-view">

  <app-file-uploader [disableUploads]="true" [fileList]="memory?.files" [memory]="memory"
                     (fileClick)="showLightbox($event)"
                     preview="true"></app-file-uploader>

  <app-expandable-text [text]="memory?.description" [minLength]="400"></app-expandable-text>

  <div class="row mt-4 mb-2">
    <div *ngIf="memory?.location" class="col-sm-8">
      <strong><fa-icon [icon]="faMapMarker"></fa-icon> Where</strong>
      <br/>{{ memory?.location }}
    </div>
    <div *ngIf="memory?.date" class="col-sm-4 text-sm-right">
      <strong><fa-icon [icon]="faCalendar"></fa-icon> When</strong>
      <br/>{{ memory?.date | date: 'mediumDate' }}
    </div>
  </div>

  <button *ngIf="memory.allowShare" class="btn btn-gold mr-2" (click)="addToJarPicker($event)" type="button">
    <fa-icon [icon]="faShare"></fa-icon>
    Add to Jar
  </button>

  <button *ngIf="memory.allowComments" class="btn btn-gold" (click)="showComments($event)" type="button">
    <fa-icon [icon]="faComment"></fa-icon>
    Recollections ({{ memory.commentCount }})
  </button>

</div>
<div class="modal-footer modal-memory-view">
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('Cross click')">
    Close
  </button>
</div>
<app-content-loader></app-content-loader>
