import {AppState} from '../index';
import {createSelector} from '@ngrx/store';
import {IListOption} from '../../core/models/option.model';
import {AppStoreUtils} from '../app-store.utils';
import {IListOptionGroup} from '../../core/models/option-group.model';
import {NodeType} from '../../core/common/enums';

export const selectOptionsState = (state: AppState) => state.options;

export const selectOptions = createSelector(
  selectOptionsState,
  state => state
);

export const selectMarket = createSelector(
  selectOptionsState,
  state => state.market
);

export const selectRelationshipTypes = createSelector(
  selectOptionsState,
  state => state.relationshipTypes.reduce((list: IListOption[], option) => {
    if (list.some(o => o.id === option.groupId) === false) {
      list.push({
        id: option.groupId,
        label: option.groupLabel,
        type: option.type
      });
    }
    return list;
  }, [])
);


export const selectEthnicityGroupOptions = createSelector(
  selectOptionsState,
  state => {
    const optionGroups = {};
    state.ethnicTypes.forEach((et: IListOption) => {
      if (optionGroups[et.groupId] == null) {
        optionGroups[et.groupId] = {
          id: et.groupId,
          label: et.groupLabel,
          options: [],
          type: et.type,
        };
      }
      optionGroups[et.groupId].options.push({
        id: et.id,
        label: et.label,
        type: et.type,
      });
    });
    return Object.values(optionGroups);
  }
);


export const selectGenderType = (id: number) => createSelector(
  selectOptionsState,
  state => state.genderTypes.find(x => x.id === id)
);

export const selectAddressTypes = createSelector(
  selectOptionsState,
  state => state.addressTypes
);

export const selectAddressType = (id: number) => createSelector(
  selectOptionsState,
  state => state.addressTypes.find(x => x.id === id)
);

export const selectReligion = (id: any) => createSelector(
  selectOptionsState,
  state => {
    return state.religions.find(x => x.id === id);
  }
);

export const selectPracticingReligionStatus = (id: number) => createSelector(
  selectOptionsState,
  state => state.practicingReligionStatuses.find(x => x.id === id)
);

export const selectEthnicity = (id: any) => createSelector(
  selectOptionsState,
  state => state.ethnicTypes.find(x => x.id === id)
);

export const selectCustomTradition = (id: number) => createSelector(
  selectOptionsState,
  state => state.customTraditions.find(x => x.id === id)
);

export const selectJarTags = createSelector(
  selectOptionsState,
  state => {
    return [
      ...state.memoryJarTypes,
      ...state.thingJarTypes,
      ...state.passwordJarTypes
    ];
  }
);

export const selectMemoryJarType = (id: number) => createSelector(
  selectJarTags,
  state => state.find(x => x.id === id)
);
export const selectThingJarType = selectMemoryJarType;
export const selectPasswordJarType = selectMemoryJarType;


export const selectMemoryTags = createSelector(
  selectOptionsState,
  state => [
    ...state.memoryTagTypes,
    ...state.thingTagTypes,
    ...state.passwordTagTypes
  ]
);
export const selectMemoryTagType = (id: number) => createSelector(
  selectMemoryTags,
  state => state.find(x => x.id === id)
);
export const selectThingTagType = selectMemoryTagType;
export const selectPasswordTagType = selectMemoryTagType;

export const selectTagsByJarTag = (jarTagId: number) => createSelector(
  selectMemoryTags,
  state => AppStoreUtils.filterSubTypes(state, jarTagId)
);

export const selectSecretKeeperType = (id: number) => createSelector(
  selectOptionsState,
  state => state.secretKeeperTypes.find(x => x.id === id)
);

export const selectTimeLockType = (id: number) => createSelector(
  selectOptionsState,
  state => state.timeLockTypes.find(x => x.id === id)
);

export const selectRelationshipType = (id: number) => createSelector(
  selectOptionsState,
  state => state.relationshipTypes.find(x => x.id === id)
);

export const selectRelationshipSubTypeByType = (relationshipId: number) => createSelector(
  selectOptionsState,
  state => {
    return state.relationshipTypes.filter((item: IListOption) => {
      return item.groupId === relationshipId;
    });
  }
);

export const selectPrivacyStatus = (id: number) => createSelector(
  selectOptionsState,
  state => state.privacyStatus.find(x => x.id === id)
);

export const selectDonationPartners = createSelector(
  selectOptionsState,
  state => state.donationPartners
);

export const selectSubscriptions = createSelector(
  selectOptionsState,
  state => state.subscriptions
);

export const selectNodeType = (id: number) => createSelector(
  selectOptionsState,
  state => {
    return {
      id,
      label: NodeType[id]
    };
  }
);
