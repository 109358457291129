import {Component} from '@angular/core';
import {NodeType} from '../../../core/common/enums';
import {AbstractMemoryDefaultsComponent} from '../abstract-memory-defaults/abstract-memory-defaults.component';
import {IMemory, MemoryModel} from '../../../core/models/memory.model';
import {MemoryActions} from '../../../store/memories';
import {PasswordDefaultModalComponent} from '../../../shared/modals/admin/password-default/password-default-modal.component';

@Component({templateUrl: '../abstract-memory-defaults/abstract-memory-defaults.component.html'})
export class PasswordDefaultsComponent extends AbstractMemoryDefaultsComponent{
  public PageTitle = 'Password Defaults';
  public NodeType: NodeType = NodeType.PasswordDefault;

  public edit(password?: IMemory) {
    this.store.dispatch(
      MemoryActions.setCurrentMemoryId({ id: password?.id })
    );
    const modal = this.modalService.open(PasswordDefaultModalComponent, {size: 'lg', backdrop: 'static'});
    modal.componentInstance.memory = password || new MemoryModel();
    modal.result.then((editedMemory: IMemory) => {
      this.search();
    }, (reason) => {
      console.log(reason);
    });
  }
}
