import {initialUserState, UsersState} from './users.state';
import {Action, createReducer, on} from '@ngrx/store';
import {UserActions} from './index';
import {User} from '../../core/models/user.model';
import {Bio} from '../../core/models/bio.model';

const mergeUserWithCurrentUser = (state: UsersState, user: User) => {
  return {...state, currentUser: {...state.currentUser, ...user}};
};

const mergeUsersWithState = (state: UsersState, users: User[]) => {
  const byId = {};
  for (const user of users) {
    byId[user.id] = user;
  }

  return {
    ...state,
    byId: {
      ...state.byId,
      ...byId
    }
  };
};

const reducer = createReducer<UsersState>(
  initialUserState,

  on(UserActions.setCurrentUser, (state, {user}) => {
    if (user == null) {
      return {...state, currentUser: null, currentUserSubscription: null, currentUserBios: []};
    }
    return mergeUsersWithState({...state, currentUser: user}, [user]);
  }),

  on(UserActions.incrementCurrentUserBioCounter, (state, {bio}) => {
    if (state.currentUser != null) {
      let ownedBios = state.currentUser.ownedBios;
      let ownedLegacyBios = state.currentUser.ownedLegacyBios;

      if (Bio.isLifeBio(bio)) {
        ownedBios = ownedBios + 1;

      } else if (Bio.isLegacyBio(bio)) {
        ownedLegacyBios = ownedLegacyBios + 1;
      }
      return {...state, currentUser: {...state.currentUser, ...{ownedBios, ownedLegacyBios}}};
    }

    // No change;
    return state;
  }),

  on(UserActions.decrementCurrentUserBioCounter, (state, {bio}) => {
    if (state.currentUser != null) {
      let ownedBios = state.currentUser.ownedBios;
      let ownedLegacyBios = state.currentUser.ownedLegacyBios;

      if (Bio.isLifeBio(bio)) {
        ownedBios = ownedBios - 1;

      } else if (Bio.isLegacyBio(bio)) {
        ownedLegacyBios = ownedLegacyBios - 1;
      }
      return {...state, currentUser: {...state.currentUser, ...{ownedBios, ownedLegacyBios}}};
    }

    // No change;
    return state;
  }),

  on(UserActions.setCurrentUserSubscription, (state, {userSubscription}) => {
    return {...state, currentUserSubscription: userSubscription};
  }),

  on(UserActions.getUserRequestSuccess, (state, {user}) => {
    if (state.currentUser !== null && state.currentUser.id === user.id) {
      return mergeUsersWithState(mergeUserWithCurrentUser(state, user), [user]);
    }
    return mergeUsersWithState(state, [user]);
  }),

  on(UserActions.getUserSubscriptionRequestSuccess, (state, {userSubscription}) => {

    if (state.currentUser !== null && state.currentUser.id === userSubscription.user) {
      return {...state, currentUserSubscription: userSubscription};
    }

    // todo: We probably should have a store of subscriptions
    return state;
  }),

  on(UserActions.saveUserRequest, (state, {user}) => {
    if (state.currentUser !== null && state.currentUser.id === user.id) {
      return mergeUsersWithState(mergeUserWithCurrentUser(state, user), [user]);
    }
    return mergeUsersWithState(state, [user]);
  }),


  on(UserActions.saveUserMetaDataRequest, UserActions.resetTutorialsRequest, (state, {user, metaData}) => {
    if (user == null) {
      user = state.currentUser;
    }
    const newUser = {
      ...user,
      ...{
        metaData: {
          ...user.metaData,
          ...metaData
        }
      }
    };
    if (state.currentUser !== null && state.currentUser.id === user.id) {
      return mergeUsersWithState(mergeUserWithCurrentUser(state, newUser), [newUser]);
    }
    return mergeUsersWithState(state, [newUser]);
  }),

  on(UserActions.getOwnedBiosSuccess, (state, {bios}) => {
    return {...state, ownedBios: bios};
  }),

  on(UserActions.updateLoginSettingsRequest, (state, {enable2Fa, rememberMe}) => {
    if (state.currentUser == null) {
      return state;
    }
    return {
      ...state,
      currentUser: {
        ...state.currentUser,
        enable2Fa,
        rememberMe
      }
    };
  }),

);

export function UsersReducer(state: UsersState, action: Action) {
  return reducer(state, action);
}
