import {Injectable} from '@angular/core';
import {AbstractControl, FormGroup, ValidationErrors} from '@angular/forms';

@Injectable({providedIn: 'root'})
export class ControlValidationService {

  public static getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
    const config = {
      required: 'This field is required',
      invalid: 'This field is invalid',
      invalidCreditCard: 'Is invalid credit card number',
      invalidEmailAddress: 'Invalid email address',
      invalidUrl: 'Invalid website address',
      invalidPassword: 'Invalid password. Password must be at least 8 characters long and contain a number.',
      minlength: `Minimum length ${validatorValue.requiredLength}`,
      maxlength: `Maximum length ${validatorValue.requiredLength}`,
      passwordsNotEqual: 'Confirm password and password don\'t match',
      emailsNotEqual: 'Confirm email and email don\'t match',
      deleteRequired: 'Please enter the word DELETE to continue',

      // Custom
      ngbDate: 'This date is invalid',
      customError: ''
    };

    // Show passed custom error message.
    if (validatorName === 'customError') {
      return validatorValue.message;
    }

    return config[validatorName];
  }

  public static deleteValidator(control: AbstractControl): ValidationErrors|null  {
    if (control.value && control.value.toUpperCase().trim() === 'DELETE') {
      return null;
    } else {
      return {deleteRequired: true};
    }
  }

  public static creditCardValidator(control: AbstractControl): ValidationErrors|null {

    // Allow empty to pass through. Add 'Validators.required' to the control to force a value
    if (!control.value) {
      return null;
    }

    // Visa, MasterCard, American Express, Diners Club, Discover, JCB
    // tslint:disable-next-line:max-line-length
    if (control.value.match(/^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/)) {
      return null;
    } else {
      return {invalidCreditCard: true};
    }
  }

  public static emailValidator(control: AbstractControl): ValidationErrors|null {

    // Allow empty to pass through. Add 'Validators.required' to the control to force a value
    if (!control.value) {
      return null;
    }

    // RFC 2822 compliant regex
    // tslint:disable-next-line:max-line-length
    if (control.value.match(/[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/)) {
      return null;
    } else {
      return {invalidEmailAddress: true};
    }
  }

  public static urlValidator(control: AbstractControl): ValidationErrors|null {

    // Allow empty to pass through. Add 'Validators.required' to the control to force a value
    if (!control.value) {
      return null;
    }

    if (control.value.match( /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/ ) ) {
      return null;
    } else {
      return {invalidUrl: true};
    }
  }

  static passwordValidator(control: AbstractControl): ValidationErrors|null {

    // Allow empty to pass through. Add 'Validators.required' to the control to force a value
    if (!control.value) {
      return null;
    }

    // {8,100}           - Assert password is between 8 and 100 characters
    // (?=.*[0-9])       - Assert a string has at least one number
    if (control.value.match(/^(?=.*[0-9])[a-zA-Z0-9 \!\"\#\$\%\&\'\(\)\*\+\,\-\.\/\:\;\<\=\>\?\@\[\\\]\^\_\`\{\|\}\~]{8,100}$/)) {
      return null;
    } else {
      return {invalidPassword: true};
    }
  }

  public static RepeatPasswordValidator(group: AbstractControl): ValidationErrors|null {
    const password = group.get('password');
    const confirmPassword = group.get('passwordConfirm');
    if (password && confirmPassword && password.value === confirmPassword.value) {
      return null;
    } else {
      return {passwordsNotEqual: true};
    }
  }

  public static RepeatEmailValidator(group: AbstractControl): ValidationErrors|null {
    const email = group.get('email');
    const confirmEmail = group.get('emailConfirm');
    if (email && confirmEmail && email.value === confirmEmail.value) {
      return null;
    } else {
      return {emailsNotEqual: true};
    }
  }

}
