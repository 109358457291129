import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Bio} from '../../../../../core/models/bio.model';
import {AlertService} from '../../../../../shared/components/alert/alert.service';

import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {BioService} from '../../../../../core/services/bio.service';
import {AppDateFormatter} from '../../../../../core/format/date.format';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../store';
import {BioUserService} from '../../../../../core/services/bio-user.service';
import {LayoutService} from '../../../../../core/services/layout.service';
import {AbstractBioEditComponent} from '../abstract-bio-edit.component';

@Component({
  selector: 'app-bio-edit-pet',
  templateUrl: 'bio-edit-pet.component.html'
})
export class BioEditPetComponent extends AbstractBioEditComponent {

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected modalService: NgbModal,
    protected formBuilder: FormBuilder,
    protected store: Store<AppState>,
    protected bioService: BioService,
    protected bioUserService: BioUserService,
    protected alertService: AlertService,
    protected dateFormatter: AppDateFormatter,
    protected layoutService: LayoutService
  ) {
    super(router, route, modalService, formBuilder, store, bioService, bioUserService, alertService, dateFormatter, layoutService);

    this.bioForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      gender: [null, Validators.required],
      birthday: [null, Validators.required],
      preferredNames: this.formBuilder.array([]),
      privacy: [2, Validators.required],
      allowComments: [true]
    });
    this.setBio(this.bio);
  }

  protected configureOptionalFormFields() {

    // Life Bio
    if (this.bio.isLegacy === false) {
      this.bioForm.removeControl('deathday');
      this.bioForm.removeControl('restingLocation');

      // Legacy Bio
    } else {

      this.bioForm.addControl('deathday', new FormControl('', [Validators.required]));
      this.bioForm.get('deathday').setValue(this.dateFormatter.parse(this.bio.deathday, 'YYYY-MM-DD'));

      this.bioForm.addControl('restingLocation', new FormControl(''));
      this.bioForm.get('restingLocation').setValue(this.bio.restingLocation);
    }
  }

  public setBio(bio: Bio) {
    this.bio = bio;
    if (this.bio == null) {
      return;
    }

    this.layoutService.setTitle(`${this.bio.firstName} ${this.bio.lastName} Edit - My LifeJars`);

    this.bioForm.patchValue(this.bio);

    // ngBootstrap Dates need to be in a 'stupid' format
    this.bioForm.get('birthday').setValue(this.dateFormatter.parse(this.bio.birthday, 'YYYY-MM-DD'));

    // initialize multi valued form fields.
    this.initializeMultiValuedFormFields('preferredNames');

    // Configure Conditional Form Fields
    this.configureOptionalFormFields();
  }

}
