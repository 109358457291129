
<div class="container public-bio-legacy">

  <div class="row">
    <div class="col-12">

     <app-bio-legacy-view></app-bio-legacy-view>

    </div>
  </div>

  <app-content-loader></app-content-loader>
</div><!--/container-->
