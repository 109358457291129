import {Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges, EventEmitter, Output} from '@angular/core';
import {faAngleRight} from '@fortawesome/free-solid-svg-icons';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-paginator',
  templateUrl: 'paginator.component.html',
  styleUrls: ['paginator.component.css']
})
export class PaginatorComponent implements OnChanges {
  @Input() pages: number;
  @Input() page: number;
  @Output() pageChange = new EventEmitter<number>();
  public pageList: number[];

  constructor() { }

  public ngOnChanges(changes: SimpleChanges) {
    this.pageList = [];
    for (let i = 1; i <= this.pages; i++) {
      this.pageList.push(i);
    }
  }

  public previous() {
    if ( --this.page < 1 ) {
      this.page = 1;
    }
    this.pageChange.emit(this.page);
  }

  public goto( num: number ) {
    if (num >= 1 && num <= this.pages) {
      this.page = num;
    }
    this.pageChange.emit(this.page);
  }

  public next() {
    if ( ++this.page < 1 ) {
      this.page = this.pages;
    }
    this.pageChange.emit(this.page);
  }

  public isFirst(): boolean {
    return this.page === 1;
  }

  public isLast(): boolean {
    return this.page === this.pages;
  }

}
