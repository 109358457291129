import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {Bio} from '../../../../core/models/bio.model';
import {FileModel} from '../../../../core/models/file.model';
import {faImage} from '@fortawesome/free-solid-svg-icons';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../store';
import {BioActions} from '../../../../store/bios';
import {JarModel} from '../../../../core/models/jar.model';
import {JarSelectors} from '../../../../store/jars';
import {filter} from 'rxjs/operators';
import {Subscription} from 'rxjs';

@Component({
  templateUrl: 'profile-picker.component.html'
})
export class ProfilePickerComponent implements OnInit, OnDestroy {
  private readonly subscription: Subscription;
  private debounceTimer;

  @Input()
  public bio: Bio;

  public memoryJars: JarModel[];

  public faImage = faImage;

  public active = 0;

  constructor(
    public activeModal: NgbActiveModal,
    protected store: Store<AppState>,
  ) {
    this.subscription = new Subscription();

    this.memoryJars = [];
    this.subscription.add(this.store
      .pipe(
        select(JarSelectors.selectMemoryJars),
        filter((jars: JarModel[]) => jars.length > 0)
      )
      .subscribe((jars: JarModel[]) => {
        this.memoryJars = jars;
      })
    );
  }

  public ngOnInit() {
  }

  public selectFile(file: FileModel) {
    if (typeof window !== 'undefined') {
      window.clearTimeout(this.debounceTimer);
      this.debounceTimer = window.setTimeout(() => {
        this.store.dispatch(
          BioActions.saveBioProfileRequest({bio: this.bio, file})
        );
      }, 500);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
